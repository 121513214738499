<div class="fleet-check-container">
  <h4 class="dialog-title">Order Fleet Check</h4>
  <div fxLayout="column">
    <div fxLayout="row" class="fleet-check-select">
      <mat-form-field fxFlex>
        <mat-select
          placeholder="Choose Fleet"
          [(ngModel)]="selectedFleet"
          (selectionChange)="onFleetSelected()"
          [disabled]="data?.readOnlyLocation"
        >
          <mat-option *ngFor="let fleet of fleets" [value]="fleet._id">
            {{ fleet.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" class="fleet-check-select">
      <mat-form-field fxFlex *ngIf="selectedFleet">
        <mat-select
          placeholder="Choose Location"
          [(ngModel)]="selectedLocation"
          [disabled]="data?.readOnlyLocation"
        >
          <mat-option *ngFor="let location of locations" [value]="location._id">
            {{ location.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <p style="text-align: center">Set a date for the fleet check</p>
    <div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <my-date-picker
          [options]="myDatePickerOptions"
          (dateChanged)="onDateChanged($event)"
          [selDate]="datepickerDate"
        ></my-date-picker>
      </div>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="selected-date-container"
    >
      <p>Selected Date:</p>
      <h3 *ngIf="selectedDate">
        {{ selectedDate | momentFormatPipe: 'ddd. MMM D, YYYY' }}
      </h3>
      <h3 *ngIf="!selectedDate">&nbsp;&nbsp;</h3>
    </div>
    <div>
      <p class="error-message">
        {{ error }}
      </p>
    </div>
    <div fxLayout="row">
      <button type="button" mat-raised-button (click)="submit()">Submit</button>
      <button type="button" mat-button (click)="dialogRef.close()">
        Cancel
      </button>
    </div>
  </div>
</div>
