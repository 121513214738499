// tslint:disable:no-access-missing-member
import * as _ from 'lodash'

import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core'
import { DocumentManagementService } from '../../../document-management/client/document-management.service'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { ModalDialogsService } from '../../../core/client/modal-dialogs/modal-dialogs.service'
import { OrderBaseComponent } from './order-base.class'

export interface OrderDocument {
  description: string
  displayName: string
  placeholder: string
  fileIdentity: string
  existingDocument?: any
  number: string
  index: number
  required: boolean
  uploading?: boolean
  numberRequired: boolean
  isWheelsOnly?: boolean
  isBONL?: boolean
  selectedFileName?: string
  selectedFile?: any
  invalidType?: boolean // set to true if user uploads invalid file type
}

@Component({
  selector: 'app-order-documents',
  templateUrl: './order-documents.component.html',
  styleUrls: ['../order.component.scss', './order-documents.component.scss'],
  providers: []
})
export class OrderDocumentsComponent
  extends OrderBaseComponent
  implements OnInit
{
  @ViewChildren('fileInput') fileInputs: QueryList<ElementRef>
  requiredDocumentList: OrderDocument[]
  orderDocuments: any[]
  requiredUploads: any[] = []
  additionalUploads: any[] = []
  downloadDocuments: any[] = []
  uploadCategories: string[] = []
  initialPageLoad = false
  gettingDocuments = false

  constructor(
    private documentManagementService: DocumentManagementService,
    private dialogService: ModalDialogsService
  ) {
    super()
  }

  handleUpdateComplete() {
    this.getDocumentsFromDb()
    super.handleUpdateComplete()
  }

  handleResponse(error: CoreResponse): boolean {
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    if (
      !this.coreUser ||
      !this.order ||
      (this.order.orderType === 'ServiceCall' && !this.order.callType)
    ) {
      // if not signed in or no order is present, don't show
      this.isVisible = false
    } else {
      const accountType = this.coreUser.accountType

      const docUploadIsBlacklisted = Boolean(
        this.order.state.blacklistedOrderParts &&
          this.order.state.blacklistedOrderParts.includes('documents')
      )
      const isAllowedAccountType =
        accountType === 'Stm' ||
        accountType === 'Vendor' ||
        accountType === 'Fleet' ||
        (accountType === 'Location' &&
          this.order.location.fleet.name !== 'AAA Cooper') // Don't show to location for AAA Cooper

      this.isVisible =
        !this.order.isNoOrderNoPickup &&
        docUploadIsBlacklisted === false &&
        isAllowedAccountType === true
    }
  }

  setOrder(order) {
    super.setOrder(order)

    if (!this.initialPageLoad) {
      this.getDocumentsFromDb()
      this.initialPageLoad = true
    }

    // TODO figure out how to get rid of hard-coded ACT stuff
    const bonLLocations = ['NEN-NEWNAN', 'CAR-CARTHAGE']
    if (bonLLocations.includes(this.order.location.name)) {
      this.order.needsBONL = true
    } else {
      this.order.needsBONL = false
    }
    if (
      this.order.additionalServicePO &&
      this.order.additionalServicePO.length
    ) {
      const wheelsOnlyEntry = _.find(
        this.order.additionalServicePO,
        (po: any) => po.description === 'Wheels Only'
      )
      const bonLEntry = _.find(
        this.order.additionalServicePO,
        (po: any) => po.description === 'BON-L'
      )
      this.order.hasWheelsOnly = wheelsOnlyEntry !== undefined
      this.order.hasBONL = bonLEntry !== undefined
    } else {
      this.order.hasWheelsOnly = false
      this.order.hasBONL = false
    }
  }

  getDocumentsFromDb() {
    this.gettingDocuments = true

    if (this.order) {
      this.documentManagementService
        .getOrderDocuments(this.order._id)
        .subscribe(
          (documentList) => {
            this.orderDocuments = <any[]>documentList
            this.orderDocuments.forEach(
              (d) => (d.icon = this.getFileTypeIcon(d))
            )
            this.getRequiredDocumentList()
            this.checkForMissingDocuments()
            this.buildComponents()
          },
          (err) => {
            this.handleFailure([
              <CoreResponse>{
                message: 'Error getting documents, please try again later.'
              }
            ])
            this.gettingDocuments = false
          },
          () => {
            this.gettingDocuments = false
          }
        )
    }
  }

  getRequiredDocumentList() {
    this.requiredDocumentList = []
    if (this.order.orderType === 'FleetCheck') {
      this.requiredDocumentList.push({
        description: 'Fleet Check Paperwork ',
        displayName: 'Fleet Check Paperwork',
        placeholder: 'Description',
        fileIdentity: 'fleet_check_paperwork',
        existingDocument: null,
        number: '',
        numberRequired: false,
        index: -1,
        required: true,
        uploading: false
      })
    } else if (
      !this.order.isNoOrderWithPickup &&
      !this.order.isNoOrderNoPickup
    ) {
      this.requiredDocumentList.push({
        description: 'Delivery Receipt #',
        displayName: 'Delivery Receipt',
        placeholder: 'Receipt #',
        fileIdentity: 'delivery_receipt',
        existingDocument: null,
        number: null,
        numberRequired: true,
        index: -1,
        required: this.order.orderType !== 'ServiceCall',
        uploading: false
      })
    }

    if (this.order.orderType === 'ServiceCall') {
      this.requiredDocumentList[0].displayName += ' (Optional)'
    }

    if (
      (this.order.regularItems &&
        this.order.location.fleet.options &&
        this.order.location.fleet.options.requireServiceWO &&
        !this.order.isNoOrderNoPickup &&
        !this.order.isNoOrderWithPickup) ||
      this.order.orderType === 'ServiceCall'
    ) {
      this.requiredDocumentList.push({
        description: 'Service Work Order #',
        displayName: 'Service Work Order',
        placeholder: 'Work Order #',
        fileIdentity: 'service_wo',
        existingDocument: null,
        number: null,
        numberRequired: true,
        index: -1,
        required: true,
        uploading: false
      })
    }

    if (
      this.order.orderType === 'RegularOrder' &&
      !this.order.isNoOrderNoPickup &&
      this.order.location.fleet.options &&
      this.order.location.fleet.options.requireRetreadWO
    ) {
      this.requiredDocumentList.push({
        description: 'Retread Work Order #',
        displayName: 'Retread Work Order',
        placeholder: 'Work Order #',
        fileIdentity: 'retread_wo',
        existingDocument: null,
        number: null,
        numberRequired: true,
        index: 0,
        required: true,
        uploading: false
      })
    }

    if (this.order.forkliftItems && this.order.forkliftItems.length) {
      this.order.forkliftItems.forEach((f) => {
        this.requiredDocumentList.push({
          description: `Forklift #${f.forkliftNumber} Service WO #`,
          displayName: `Forklift #${f.forkliftNumber} Service Work Order`,
          placeholder: 'Service WO #',
          fileIdentity: `forklift:${f.forkliftNumber}`,
          existingDocument: null,
          number: null,
          numberRequired: true,
          index: -1,
          required: true,
          uploading: false
        })
      })
    }

    const requiredDocuments = _.filter(
      this.requiredDocumentList,
      (d) => d.required
    )

    // Once we've determined what documents are required, we attach info about required docs on the order
    // This is used in order action component to prevent submitting paperwork unless all required are present
    this.order.requiredDocuments = {}
    _.forEach(
      requiredDocuments,
      (d: { fileIdentity: string; displayName: string }) => {
        this.order.requiredDocuments[d.fileIdentity] = {
          displayName: d.displayName,
          uploaded: false
        }
      }
    )
  }

  checkForMissingDocuments() {
    _.forEach(this.orderDocuments, (document) => {
      if (this.order.requiredDocuments[document.fileIdentity]) {
        this.order.requiredDocuments[document.fileIdentity].uploaded = true
      }
    })
  }

  getFileTypeIcon(existingDocument: any) {
    switch (existingDocument.mimeType) {
      case 'image/svg+xml':
      case 'image/gif':
      case 'image/png':
      case 'image/jpeg':
      case 'image/bmp':
        return 'image.png'
      case 'application/pdf':
        return 'pdf.png'
      default:
        return 'text.png'
    }
  }

  buildComponents() {
    this.downloadDocuments = []
    const uploadDocuments: OrderDocument[] = []
    let uploadIdx = 0

    this.requiredDocumentList.forEach((docInfo) => {
      // index needed to keep track of which file input belongs to which document
      docInfo.index = uploadIdx
      uploadIdx++

      // add to upload list
      uploadDocuments.push(docInfo)
    })

    uploadDocuments.push({
      description: 'Other',
      displayName: 'Other',
      placeholder: 'Description',
      fileIdentity: 'other',
      required: false,
      index: uploadIdx,
      number: null,
      numberRequired: true
    })

    const partition = _.partition(uploadDocuments, (d) => d.required)
    this.requiredUploads = partition.length && partition[0]
    this.additionalUploads =
      partition.length && partition.length > 1 && partition[1]

    this.orderDocuments.sort(
      (a, b) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )

    this.orderDocuments.forEach((d) => {
      this.downloadDocuments.push({
        description: d.description,
        fileIdentity: d.fileIdentity,
        existingDocument: d,
        number: null,
        index: -1
      })
    })

    this.uploadCategories = ['Required Documents', 'Additional Documents']
  }

  updateOrderDocuments(document) {
    const idParts = document.fileIdentity.split(':')

    switch (idParts[0]) {
      case 'delivery_receipt':
        this.order.delivery = this.order.delivery || {}
        break
      case 'service_wo':
        this.order.regularItems = this.order.regularItems || {}
        break
      case 'forklift':
        break
      // TODO figure out how to get rid of hard-coded ACT stuff
      case 'wheels_only_wo':
        this.order.additionalPO = this.order.additionalPO || []
        _.remove(
          this.order.additionalServicePO,
          (po: any) => po.description === 'Wheels Only'
        )
        const wheelsOnlyEntry = { description: 'Wheels Only' }
        this.order.additionalServicePO.push(wheelsOnlyEntry)
        break
      case 'bonL_ticket':
        this.order.additionalPO = this.order.additionalPO || []
        _.remove(
          this.order.additionalServicePO,
          (po: any) => po.description === 'BON-L'
        )
        const bonLEntry = { description: 'BON-L' }
        this.order.additionalServicePO.push(bonLEntry)
        break
    }

    this.update()
  }

  deleteDocument(document) {
    this.dialogService
      .confirm(
        'Delete Document',
        `Are you sure you want to delete ${document.description}?`,
        'Delete It'
      )
      .subscribe((doDelete) => {
        if (doDelete) {
          this.documentManagementService
            .remove(document.existingDocument)
            .subscribe((result) => {
              // TODO figure out how to get rid of hard-coded ACT stuff
              if (document.fileIdentity === 'wheels_only_wo') {
                _.remove(
                  this.order.additionalServicePO,
                  (po: any) => po.description === 'Wheels Only'
                )
                this.update()
              } else if (document.fileIdentity === 'bonL_ticket') {
                _.remove(
                  this.order.additionalServicePO,
                  (po: any) => po.description === 'BON-L'
                )
                this.update()
              }
              // end hardcoded ACT

              this.getDocumentsFromDb()
            })
        }
      })
  }

  chooseFile(document) {
    const index = document.index
    this.fileInputs.toArray()[index].nativeElement.click()
  }

  fileInputChanged(document) {
    const validFileTypes = [
      'image/png',
      'image/jpeg',
      'text/plain',
      'application/pdf'
    ]
    const input = this.fileInputs.toArray()[document.index]
    document.selectedFileName = null
    if (input.nativeElement.files && input.nativeElement.files.length) {
      const file = input.nativeElement.files[0]
      document.invalidType = !validFileTypes.includes(file.type)
      // set the filename
      // i put this in a timeout because setting invalid type to false takes a second to get rid of error message
      // this was causing filename text to be on two lines which caused an ellipses
      setTimeout(() => {
        document.selectedFileName = file.name
      }, 10)
    }
  }

  updateDocument(document) {
    this.documentManagementService
      .update(document.existingDocument)
      .subscribe((result) => {
        this.getDocumentsFromDb()
      })
  }

  // TODO figure out how to get rid of hard-coded ACT stuff
  isWheelsOnlyChanged(document) {
    if (document.isWheelsOnly) {
      document.isBONL = false
    }
  }

  isBONLChanged(document) {
    if (document.isBONL) {
      document.isWheelsOnly = false
    }
  }
  // end hard-coded ACT stuff
}
