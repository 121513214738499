import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { environment } from '../../../environments/environment'

@Injectable()
export class FleetService {
  fleetsURL = `${environment.apiUrl}/fleet`

  constructor(private http: HttpClient) {}

  // Get all posts from the API
  getFleets(searchParams: object = {}) {
    let params = new HttpParams()

    Object.keys(searchParams).forEach((key) => {
      params = params.append(key, searchParams[key])
    })

    return this.http.get(this.fleetsURL, { params })
  }

  getFleetById(fleetId) {
    return this.http.get(`${this.fleetsURL}/${fleetId}`)
  }

  getFleetLogo(fleedId) {
    return this.http.get(`${this.fleetsURL}/${fleedId}/logo`)
  }

  save(fleet: any) {
    if (fleet._id) {
      return this.http.put(`${this.fleetsURL}/${fleet._id}`, fleet)
    } else {
      return this.http.post(this.fleetsURL, fleet)
    }
  }

  update(fleet: any) {
    return this.http.put(`${this.fleetsURL}/${fleet._id}`, fleet)
  }
}
