<div class="account-switcher">
  <div *ngIf="showLocations || showVendors || showFleets">
    <mat-icon
      class="account-switcher-icon"
      [matMenuTriggerFor]="shoppingCartMenu"
      matTooltip="Switch Accounts"
      matTooltipPosition="right"
      >swap_horiz</mat-icon
    >
    <mat-menu #shoppingCartMenu="matMenu">
      <div *ngIf="showLocations">
        <h5>Locations</h5>
        <ul class="account-switcher-list location-list">
          <li
            mat-menu-item
            *ngFor="let location of allowedLocations"
            (click)="switchAccount('Location', location)"
          >
            {{ location.name }}
            <mat-icon>directions_bus</mat-icon>
          </li>
        </ul>
      </div>
      <div *ngIf="showVendors">
        <h5>Vendors</h5>
        <ul class="account-switcher-list vendor-list">
          <li
            mat-menu-item
            *ngFor="let vendor of allowedVendors"
            (click)="switchAccount('Vendor', vendor)"
          >
            {{ vendor.name }}
            <mat-icon>build</mat-icon>
          </li>
        </ul>
      </div>
    </mat-menu>
  </div>
  <div>
    <button
      class="switch-account-button"
      mat-button
      (click)="switchToDefaultAccount()"
      *ngIf="!isDefaultAccount"
    >
      DEFAULT!
    </button>
  </div>
</div>
