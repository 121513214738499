import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { NgxPaginationModule } from 'ngx-pagination'

import { NgxUploaderModule } from 'ngx-uploader'
import { ToastrModule, ToastrService } from 'ngx-toastr'
import { FlexLayoutModule } from '@angular/flex-layout'
import { CoreUserModule } from '../../core-user/client/core-user.module'

import { MaterialComponentsModule } from '../../material-components.module'
import { HamburgerComponent } from './hamburger/hamburger.component'
import { AccountSwitcherComponent } from './account-switcher/account-switcher.component'
import { SearchPipe } from './pipes/search.pipe'
import { FilterPipe } from './pipes/filter.pipe'
import { MomentFormatPipe } from './pipes/momentFormat.pipe'
import { ContainerWidgetComponent } from './container-widget/container-widget.component'
import { StateBroadcastService } from './state-broadcast/state-broadcast.service'
import { FileUploaderComponent } from './file-uploader/file-uploader.component'
import { AlertDialogComponent } from './modal-dialogs/alert-dialog.component'
import { ConfirmDialogComponent } from './modal-dialogs/confirm-dialog.component'
import { InputDialogComponent } from './modal-dialogs/input-dialog.component'
import { ModalDialogsService } from './modal-dialogs/modal-dialogs.service'
import { LoggerService } from './log.service'
import { OrderByPipe } from './pipes/orderBy.pipe'
import { PhonePipe } from './pipes/phone.pipe'
import { ArrayToString } from './pipes/arrayToString.pipe'
import { EllipsesDirective } from './directives/ellipses.directive'
import { FileEllipsesDirective } from './directives/fileEllipses.directive'
import { CapitalCasePipe } from './pipes/capitalCase.pipe'
import { DataTableComponent } from './datatable/datatable.component'
import { DataTableRowComponent } from './datatable/datatable-row.component'
import { ListViewWidgetComponent } from './object-list-with-dynamic-detail/list-view-widget.component'
import { PopTartComponent } from './poptart/poptart.component'
import { PopTartService } from './poptart/poptart.service'

@NgModule({
  declarations: [
    HamburgerComponent,
    AccountSwitcherComponent,
    SearchPipe,
    FilterPipe,
    MomentFormatPipe,
    OrderByPipe,
    PhonePipe,
    CapitalCasePipe,
    ContainerWidgetComponent,
    FileUploaderComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    InputDialogComponent,
    ArrayToString,
    EllipsesDirective,
    FileEllipsesDirective,
    DataTableComponent,
    DataTableRowComponent,
    ListViewWidgetComponent,
    PopTartComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CoreUserModule,
    NgxUploaderModule,
    MaterialComponentsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    FlexLayoutModule
  ],
  providers: [
    StateBroadcastService,
    ModalDialogsService,
    LoggerService,
    ToastrService,
    PopTartService
  ],
  exports: [
    HamburgerComponent,
    AccountSwitcherComponent,
    ContainerWidgetComponent,
    SearchPipe,
    FileUploaderComponent,
    FilterPipe,
    MomentFormatPipe,
    OrderByPipe,
    PhonePipe,
    CapitalCasePipe,
    ArrayToString,
    EllipsesDirective,
    FileEllipsesDirective,
    DataTableComponent,
    ListViewWidgetComponent,
    PopTartComponent
  ],
  entryComponents: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    InputDialogComponent
  ]
})
export class CoreModule {}
