import { Component, Input, OnInit } from '@angular/core'
import * as _ from 'lodash'

import { Subscription } from 'rxjs'
import { CoreResponseHandler } from '../../core/client/client-error-handler/error-handler.class'
import { StateBroadcastService } from '../../core/client/state-broadcast/state-broadcast.service'
import { OrderService } from '../../order/client/order.service'
import { LocationService } from './location.service'

@Component({
  selector: 'app-naughty-list',
  templateUrl: './naughty-list.component.html',
  styleUrls: ['./naughty-list.component.scss'],
  providers: []
})
export class NaughtyListComponent
  extends CoreResponseHandler
  implements OnInit
{
  title: any = 'Shop Order Dashboard'
  naughtyNiceListByDate: any = []
  pieChartLabels: string[] = ['Reporting', 'Not']
  pieChartData: number[] = []
  pieChartType = 'pie'
  pieChartColors: any[] = [{ backgroundColor: ['#49BB04', '#A1040A'] }]
  connection: Subscription

  constructor(
    private locationService: LocationService,
    private stateBroadcastService: StateBroadcastService,
    private orderService: OrderService
  ) {
    super()
  }

  handleResponse() {
    return false // let default handlers take care of responses
  }

  ngOnInit() {
    this.getNaughtyNiceList()
    this.connection = this.stateBroadcastService
      .getStateUpdateBroadcast()
      .subscribe(
        (data: {
          orderId: string
          orderType: string
          stateWillChange: boolean
          wasDeleted: boolean
        }) => {
          if (data.wasDeleted) {
            this.getNaughtyNiceList()
          } else {
            this.orderService
              .isMyOrder(data.orderId, data.orderType)
              .subscribe((isMyOrder) => {
                if (isMyOrder) {
                  this.getNaughtyNiceList()
                }
              })
          }
        }
      )
  }

  broadcastNaughtyListReminder() {
    this.locationService.broadcastNaughtyListReminder().subscribe(
      () => {
        this.handleSuccess('sentSuccess', 'Reminder Sent!')
      },
      (error) => {
        this.handleFailure(error)
      }
    )
  }

  private getNaughtyNiceList() {
    // Retrieve data from the API
    this.locationService.getNaughtyNiceList().subscribe((naughtyNiceList) => {
      const niceList = naughtyNiceList.filter((x) => !x.naughty)
      const naughtyList = naughtyNiceList.filter((x) => x.naughty)
      this.pieChartData = [niceList.length, naughtyList.length]
      const dateGroupings = {}
      // format addresses
      naughtyList.forEach((item) => {
        if (!dateGroupings[item.dueDate]) {
          dateGroupings[item.dueDate] = {
            dueDate: item.dueDate,
            sortOrder: item.sortOrder,
            items: []
          }
        }
        dateGroupings[item.dueDate].items.push(item)
      })
      for (const group in dateGroupings) {
        dateGroupings[group].items.sort((a, b) => {
          if (a.location.fleet.name > b.location.fleet.name) {
            return 1
          }
          if (a.location.fleet.name < b.location.fleet.name) {
            return -1
          }
          return 0
        })
      }
      this.naughtyNiceListByDate = _.values(dateGroupings).sort(
        (a: any, b: any) => a.sortOrder - b.sortOrder
      )
    })
  }
}
