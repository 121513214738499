import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialComponentsModule } from '../../material-components.module'

import { DocumentManagementService } from './document-management.service'
import { DocumentManagementRoutingModule } from './document-management-routing.module'

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DocumentManagementRoutingModule,
    MaterialComponentsModule
  ],
  providers: [DocumentManagementService],
  exports: []
})
export class DocumentManagementModule {}
