import * as _ from 'lodash'

import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { Subject } from 'rxjs'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../../core/client/client-error-handler/error-handler.class'

@Directive()
export abstract class OrderBaseComponent
  extends CoreResponseHandler
  implements OnInit
{
  @Input() order: any
  @Input() coreUser: any
  @Output() updateRequested = new EventEmitter<any>()
  @Input() updateCompleteNotification: Subject<string>
  @Output() registerOrderPart = new EventEmitter<any>()

  unmodifiedOrder: any = null

  canUpdate = false
  isVisible = false
  errorMessage: string
  private title = ''

  constructor() {
    super()
    this.title = this.constructor.name
  }

  ngOnInit() {
    this.setUserVisibility()
    this.updateCompleteNotification.subscribe((orderId) => {
      if (this.order._id === orderId) {
        this.handleUpdateComplete()
      }
    })

    this.registerOrderPart.emit(this)
  }

  handleUpdateComplete() {
    this.setUnmodifiedOrder()
  }

  // set order sets the order, but does not update unmodifiedOrder (unless unmodifiedOrder has never been set)
  // unmodifiedOrder is only updated when the order in the db changes by emitting updateRequested
  setOrder(order) {
    this.order = order
    if (!this.unmodifiedOrder) {
      this.setUnmodifiedOrder()
    }

    this.setUserVisibility()
  }

  update(propToUpdate?: string) {
    if (this.preUpdate(propToUpdate)) {
      this.updateRequested.emit(propToUpdate)
    }
  }

  preUpdate(propToUpdate?: string) {
    return true
  }

  abstract setUserVisibility()
  abstract handleResponse(errors: CoreResponse): boolean

  private setUnmodifiedOrder() {
    this.unmodifiedOrder = _.cloneDeep(this.order)
  }
}
