import { EMAIL_VALIDATOR_FN } from './validators/email.validator'
import { PHONE_US_VALIDATOR_FN } from './validators/phone-us.validator'
import { ZIPCODE_VALIDATOR_FN } from './validators/zipcode.validator'
import { WHOLE_NUMBER_VALIDATOR_FN } from './validators/whole-number.validator'
import { ARRAY_LENGTH_VALIDATOR_FN } from './validators/array-length.validator'

export class CustomFormValidators {
  static email = EMAIL_VALIDATOR_FN
  static phoneUS = PHONE_US_VALIDATOR_FN
  static zipcode = ZIPCODE_VALIDATOR_FN
  static wholeNumber = WHOLE_NUMBER_VALIDATOR_FN
  static arrayLength = ARRAY_LENGTH_VALIDATOR_FN
}
