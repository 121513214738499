import { Component, OnInit, ViewChild } from '@angular/core'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'
/* tslint:disable */
@Component({
  selector: 'app-order-vendor-detail',
  template: ` <div *ngIf="isVisible" class="app-order-vendor-detail order-part">
    <div
      class="order-vendor-row-header"
      [class.rushHeader]="order?.rushRequested"
    >
      <div>Vendor Details</div>
    </div>
    <div class="order-part-container">
      <div class="order-detail-row">
        <div class="label">Vendor</div>
        <div class="info">
          {{ order?.vendorName || orderVendor?.name }}
        </div>
      </div>
      <div class="order-detail-row" *ngIf="order?.orderType !== 'FleetCheck'">
        <div class="label">Estimated Delivery/Pickup</div>
        <div class="info">
          {{ order?.delivery?.estimated | date: 'M/d/yyyy' }}
        </div>
      </div>
      <div class="order-creator-row">
        <div class="label">Vendor Email</div>
        <div class="info">{{ orderVendor?.email }}</div>
      </div>
      <div class="order-creator-row">
        <div class="label">Vendor Phone</div>
        <div class="info">{{ orderVendor?.phone | phonePipe }}</div>
      </div>
      <div class="order-creator-row">
        <div class="label" [class.isRushOrder]="order?.rushRequested">
          <span>Rush Order</span>
        </div>
        <input
          type="checkbox"
          *ngIf="order"
          [(ngModel)]="order.rushRequested"
          (change)="setRushOrder()"
          [disabled]="inputDisabled"
        />
      </div>
      <div class="order-creator-row">
        <div class="label">Notes</div>
        <div class="notes-input-container">
          <textarea
            [ngClass]="{
              'notes-yellow': order.notes,
              'empty-notes-box': !order.notes
            }"
            rows="4"
            cols="40"
            *ngIf="order"
            [(ngModel)]="order.notes"
            placeholder="Click here to enter notes"
          >
          </textarea>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: end">
        <button
          mat-button
          mat-raised-button
          class="blue-btn"
          color="primary"
          type="button"
          (click)="updateNotes()"
        >
          Save
        </button>
      </div>
      <div
        class="order-creator-row"
        *ngIf="
          orderFleet?.options?.showUsedInventoryInput &&
          order?.orderType === 'RegularOrder'
        "
      >
        <div class="label">Used Inventory:</div>
        <div class="label">Drive</div>
        <div>
          <div class="info" *ngIf="order && usedInventoryDisabled">
            {{ order.usedInventory.drive }}
          </div>
          <input
            type="number"
            *ngIf="order && !usedInventoryDisabled"
            [(ngModel)]="order.usedInventory.drive"
          />
        </div>
        <div class="label">Wb Drive</div>
        <div>
          <div class="info" *ngIf="order && usedInventoryDisabled">
            {{ order.usedInventory.wbDrive }}
          </div>
          <input
            type="number"
            *ngIf="order && !usedInventoryDisabled"
            [(ngModel)]="order.usedInventory.wbDrive"
          />
        </div>
        <div class="label">Trailer</div>
        <div>
          <div class="info" *ngIf="order && usedInventoryDisabled">
            {{ order.usedInventory.trailer }}
          </div>
          <input
            type="number"
            *ngIf="order && !usedInventoryDisabled"
            [(ngModel)]="order.usedInventory.trailer"
          />
        </div>
        <div class="label">Wb Trailer</div>
        <div>
          <div class="info" *ngIf="order && usedInventoryDisabled">
            {{ order.usedInventory.wbTrailer }}
          </div>
          <input
            type="number"
            *ngIf="order && !usedInventoryDisabled"
            [(ngModel)]="order.usedInventory.wbTrailer"
          />
        </div>
      </div>
      <div
        class="order-creator-row"
        *ngIf="
          orderFleet?.options?.showPickedUpCasingsInput &&
          order?.orderType === 'RegularOrder' &&
          !order?.isNoOrderNoPickup
        "
        style="justify-content:start;padding-top:5px;"
      >
        <div class="label" style="margin-top: 6px;margin-right:10px">
          Picked up casings:
        </div>
        <div>
          <div class="info" *ngIf="order && pickedUpCasingsDisabled">
            {{ order.pickedUpCasings }}
          </div>
          <input
            style="width: 100px"
            type="number"
            *ngIf="order && !pickedUpCasingsDisabled"
            [(ngModel)]="order.pickedUpCasings"
          />
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: ['../order.component.scss'],
  providers: []
})
/* tslint:enable */
export class OrderVendorDetailComponent
  extends OrderBaseComponent
  implements OnInit
{
  // @ViewChild(OrderStmNotesComponent, { static: true }) private orderStmNotesComponent: OrderStmNotesComponent;

  orderVendor = {}
  orderFleet: any
  inputDisabled = false
  usedInventoryDisabled = false
  pickedUpCasingsDisabled = false

  constructor() {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible = true
  }

  setOrder(order) {
    super.setOrder(order)

    if (order.vendor) {
      this.orderVendor = order.vendor
    } else {
      this.orderVendor =
        order.location &&
        order.location.vendors &&
        order.location.vendors.length
          ? order.location.vendors[0].vendor
          : null
    }

    this.orderFleet = order.location.fleet

    this.inputDisabled = this.coreUser.accountType === 'Vendor'
    this.usedInventoryDisabled =
      this.coreUser.accountType === 'Vendor' ||
      this.coreUser.accountType === 'Stm'
    this.pickedUpCasingsDisabled = this.coreUser.accountType !== 'Vendor'
  }

  setRushOrder() {
    if (this.order) {
      this.update('rushRequested')
    }
  }

  updateNotes() {
    if (this.order) {
      this.order.stateHistory.push({
        actionType: 'STATE_CHANGED',
        date: new Date(),
        description: [],
        notes: this.order.notes,
        stateDescription: 'Update Note',
        user: this.coreUser._id || this.order.placedBy?._id,
        state: this.order.state._id || this.order.state
      })
      this.update('notes')
      this.update('stateHistory')
    }
  }
}
