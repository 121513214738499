import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable()
export class PopTartService {
  constructor(private toastrService: ToastrService) {}

  /**
   *
   *
   * @param {string} type
   * @param {string} msg
   * @param {string} [title]
   * @param {string} [positionClass] see https://github.com/scttcper/ngx-toastr/blob/master/src/lib/toastr.css for options
   *
   * @memberOf PopTartService
   */
  show(
    type: 'success' | 'error' | 'info',
    msg: string,
    title?: string,
    positionClass?: string
  ) {
    const toastConfig: any = {}
    switch (type) {
      case 'success':
        toastConfig.timeOut = 4000
        title = title || 'Success'
        toastConfig.positionClass = positionClass || 'toast-bottom-right'
        this.toastrService.success(msg, title, toastConfig)
        break
      case 'error':
        toastConfig.closeButton = true
        toastConfig.timeOut = 4000
        toastConfig.positionClass = positionClass || 'toast-center-center'
        title = title || 'Error'
        this.toastrService.error(msg, title, toastConfig)
        break
      case 'info':
        toastConfig.timeOut = 4000
        toastConfig.positionClass = positionClass || 'toast-bottom-right'
        this.toastrService.info(msg, title, toastConfig)
        break
      default:
        throw new Error('Invalid poptart type')
    }
  }
}
