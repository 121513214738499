import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { OrderFlowComponent } from './order-flow.component'

import { OrderFlowService } from './order-flow.service'

import { OrderFlowRoutingModule } from './order-flow-routing.module'

@NgModule({
  declarations: [OrderFlowComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    OrderFlowRoutingModule,
    CoreModule,
    MaterialComponentsModule
  ],
  providers: [OrderFlowService],
  exports: [OrderFlowComponent]
})
export class OrderFlowModule {}
