import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'
import { OrderComponent } from './order.component'
import { OrderBuilderComponent } from './order-builder.component'

import { OrderDeliveryDateComponent } from './order-parts/order-delivery-date.component'
import { OrderSearchComponent } from './order-search.component'

const orderRoutes: Routes = [
  // { path: 'order',  component: OrderComponent },
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: [
      { path: 'order', component: OrderComponent },
      { path: 'order/search', component: OrderSearchComponent },
      { path: 'order/:id', component: OrderComponent },
      { path: 'orderBuilder', component: OrderBuilderComponent },
      { path: 'orderCal', component: OrderDeliveryDateComponent }
      // ^^^ THIS ROUTE IS FOR TESTING if commited... delete it
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(orderRoutes)],
  exports: [RouterModule]
})
export class OrderRoutingModule {}
