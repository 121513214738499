import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { FlexLayoutModule } from '@angular/flex-layout'
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { FleetComponent } from './fleet.component'
// import {FleetCorporateDetailComponent} from "./fleet-corporate-detail.component";

import { FleetService } from './fleet.service'

import { FleetRoutingModule } from './fleet-routing.module'

@NgModule({
  declarations: [FleetComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FleetRoutingModule,
    CoreModule,
    FlexLayoutModule,
    MaterialComponentsModule
  ],
  providers: [FleetService],
  exports: [FleetComponent]
})
export class FleetModule {}
