import { mergeMap } from 'rxjs/operators'
import * as _ from 'lodash'

import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'

import { Observable, Subscription, of } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { OrderService } from '../../order/client/order.service'
import {
  NavRoute,
  SideNavComponent
} from '../../core-layout/client/side-nav/side-nav.component'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { StateBroadcastService } from '../../core/client/state-broadcast/state-broadcast.service'
import { FleetService } from '../../fleet/client/fleet.service'
import { ModalDialogsService } from '../../core/client/modal-dialogs/modal-dialogs.service'
import { LoggerService } from '../../core/client/log.service'
import { StateActionType } from '../../order/order-state-action-type.enum'
import { LocationService } from '../../location/client/location.service'
import { DataTableComponent } from '../../core/client/datatable/datatable.component'
import { IDataTablePage } from '../../core/client/datatable/dataTableConfig.class'
import { FleetCheckDialogComponent } from './fleet-check-dialog.component'
import { DashboardService } from './dashboard.service'

class OrderTimeout extends EventEmitter<string> {
  private orderId
  private timeoutMs
  // @Output() updateRequested = new EventEmitter<any>();

  constructor(orderId, timeoutMinutes) {
    super()
    this.orderId = orderId
    this.timeoutMs = timeoutMinutes * 1000 * 60
    setTimeout(() => {
      // this.stateBroadcastService.broadcastUpdate(this.orderId);
      this.emit(this.orderId)
    }, this.timeoutMs)
  }
}

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
  providers: []
})
export class DashboardComponent implements OnInit, OnDestroy {
  static _orderExistsIn(search, dataArray): number {
    if (dataArray.length === 0) {
      return -1
    } else {
      for (let i = 0; i < dataArray.length; i++) {
        if (search === dataArray[i]._id) {
          return i
        }
      }
    }
    return -1
  }

  private static btnColors = {
    Wait: '#3FA103',
    Action: '#A1040A',
    Complete: '#929292',
    Error: '#000000'
  }

  private static btnIcons = {
    Wait: 'visibility',
    Action: 'play_arrow',
    Complete: 'visibility',
    Error: 'warning'
  }

  orders: any[] = []
  coreUser: any
  userAccountType = ''
  coreUserFleetLogo = ''
  showCreateOrder = false
  showNaughtyList = false
  showFleetLogo = true
  connection: Subscription
  updatedOrderId = ''
  useFullScreenWidth = false
  vendorPending = []
  completedPanelHeaderText = 'Show Completed Orders'
  activeOrdersPageDocCount = 15
  closedOrdersPageDocCount = 15
  fleetLogoCache: { [logoId: string]: string } = {}
  fleetCheckDate: Date
  @ViewChild('userActionTable', { static: true })
  userActionTable: DataTableComponent

  @ViewChild('noUserActionTable', { static: true })
  noUserActionTable: DataTableComponent

  @ViewChild('closedOrdersTable', { static: true })
  closedOrdersTable: DataTableComponent

  orderQueueColumns = [
    {
      width: 17,
      display: 'Fleet',
      field: 'location.fleet.logo',
      altText: 'location.fleet.name',
      type: 'image',
      hideOnMobile: true
    },
    {
      width: 18,
      display: 'Location',
      field: 'location.name',
      hideOnMobile: true
    },
    {
      width: 15,
      display: 'Ord. #',
      field: 'orderNumber',
      hideOnMobile: false
    },
    {
      width: 15,
      display: 'Placed On',
      field: 'placedDate',
      formatFunction: DashboardService.dateDisplay('In Cart'),
      hideOnMobile: true
    },
    {
      width: 20,
      display: 'Placed By',
      field: 'placedBy.displayName',
      hideOnMobile: true
    },
    {
      width: 15,
      display: 'Status',
      field: 'state.description',
      hideOnMobile: false
    }
  ]

  userActionOrdersConfig = DataTableComponent.createDataTableConfig({
    title: 'Stuff I need to do...',
    columns: DataTableComponent.createDataTableColumnsConfig(
      this.orderQueueColumns
    ),
    headerBackgroundColor: '#A1040A',
    defaultSortFields: ['priority', '-orderNumber'],
    includeActionButtons: true,
    dataArray: [],
    rowClassesFunction: this.getClass,
    pageSize: this.activeOrdersPageDocCount,
    dataIsFromServer: true,
    emptyMessage: 'You have no items in your queue!',
    emptyIcon: 'done',
    emptyIconColor: 'green',
    getPageFn: (
      pageNumber: number,
      table: DataTableComponent,
      sortFields?: string[]
    ): Observable<IDataTablePage> => {
      return this.orderService
        .getUserOrdersPage(
          [StateActionType.Action],
          this.activeOrdersPageDocCount,
          pageNumber,
          sortFields
        )
        .pipe(
          mergeMap((orderPage) => {
            this.addOrdersToQueue(orderPage.pageItems, StateActionType.Action)
            return of(orderPage)
          })
        )
    }
  })

  noUserActionOrdersConfig = DataTableComponent.createDataTableConfig({
    title: "Stuff I'm waiting on...",
    columns: DataTableComponent.createDataTableColumnsConfig(
      this.orderQueueColumns
    ),
    headerBackgroundColor: '#3FA103',
    defaultSortFields: ['priority', '-orderNumber'],
    includeActionButtons: true,
    dataArray: [],
    rowClassesFunction: this.getClass,
    pageSize: this.activeOrdersPageDocCount,
    dataIsFromServer: true,
    emptyMessage: 'You have no items in your queue!',
    emptyIcon: 'done',
    emptyIconColor: 'green',
    getPageFn: (
      pageNumber: number,
      table: DataTableComponent,
      sortFields?: string[]
    ): Observable<IDataTablePage> => {
      return this.orderService
        .getUserOrdersPage(
          [StateActionType.Wait],
          this.activeOrdersPageDocCount,
          pageNumber,
          sortFields
        )
        .pipe(
          mergeMap((orderPage) => {
            this.addOrdersToQueue(orderPage.pageItems, StateActionType.Wait)
            return of(orderPage)
          })
        )
    }
  })

  closedOrdersConfig = DataTableComponent.createDataTableConfig({
    title: 'Stuff that is finished...',
    columns: DataTableComponent.createDataTableColumnsConfig(
      this.orderQueueColumns
    ),
    headerBackgroundColor: '#929292',
    includeActionButtons: true,
    rowClassesFunction: this.getClass,
    dataIsFromServer: true,
    defaultSortFields: ['-orderNumber'],
    pageSize: this.closedOrdersPageDocCount,
    emptyMessage: "You don't have any completed orders",
    expanded: false,
    getPageFn: (
      pageNumber: number,
      table: DataTableComponent,
      sortFields?: string[]
    ): Observable<IDataTablePage> => {
      return this.orderService
        .getUserOrdersPage(
          [StateActionType.Complete],
          this.closedOrdersPageDocCount,
          pageNumber,
          sortFields
        )
        .pipe(
          mergeMap((orderPage) => {
            this.addOrdersToQueue(orderPage.pageItems, StateActionType.Complete)
            this.closedPanelHasBeenOpened = false
            return of(orderPage)
          })
        )
    }
  })

  closedPanelHasBeenOpened = false
  orderDays = ''
  deliveryDays = ''
  fleets: any[] = []
  showFleetCheckButton = false
  showServiceCallButton = false

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private coreUserService: CoreUserService,
    private orderService: OrderService,
    private stateBroadcastService: StateBroadcastService,
    private modalDialogsService: ModalDialogsService,
    private fleetService: FleetService,
    private logger: LoggerService,
    private dialog: MatDialog,
    private locationService: LocationService
  ) {}

  addOrdersToQueue(orders: any[], actionType: StateActionType) {
    orders.forEach((order) => {
      this.addOrderToQueue(order, actionType)
    })
  }

  addOrderToQueue(order: any, actionType: StateActionType) {
    this.pullFromOtherRows(order, actionType)
    this.addVendorOverdueTimer(order)
    this.createActionBtn(
      order,
      actionType === StateActionType.Action
        ? order.state.dashboardButtonLabel
        : 'View'
    )
    this.getFleetLogo(order)
  }

  pullFromOtherRows(order: any, newActionType: StateActionType) {
    switch (newActionType) {
      case StateActionType.Action:
        this.noUserActionTable.removeRow(order)
        this.closedOrdersTable.removeRow(order)
        break
      case StateActionType.Wait:
        this.userActionTable.removeRow(order)
        this.closedOrdersTable.removeRow(order)
        break
      case StateActionType.Complete:
        this.userActionTable.removeRow(order)
        this.noUserActionTable.removeRow(order)
        break
      default:
        break
    }
  }

  addVendorOverdueTimer(order: any) {
    // Start a timer on this order, when it becomes overdue,
    // refresh it (so it will move to Stm's action table if needed)
    if (order.vendorMinutesTillOverdue) {
      const o = new OrderTimeout(order._id, order.vendorMinutesTillOverdue)
      this.vendorPending.push(o)
      o.subscribe((id: string) => {
        this.refreshOrder(id)
      })
    }
  }

  createActionBtn(order: any, actionButtonLabel = 'View') {
    // Create the action button for this order's row in the table
    const btnState = StateActionType[order.stateActionType]
    let action

    if (order.state.isImmediatelyEditable) {
      action = this.dashboardService.buildOrder(order._id, order.orderType)
    } else {
      action = this.dashboardService.viewOrder
    }

    order.actionBtn = DataTableComponent.createActionButton(
      actionButtonLabel,
      action,
      DashboardComponent.btnColors[btnState],
      DashboardComponent.btnIcons[btnState]
    )
  }

  getFleetLogo(order: any) {
    if (
      order.location.hasOwnProperty('fleet') &&
      !order.location.fleet.hasOwnProperty('logo')
    ) {
      if (typeof order.location.fleet === 'string') {
        const fleetId = order.location.fleet
        if (this.fleetLogoCache[fleetId] === undefined) {
          this.fleetService.getFleetLogo(fleetId).subscribe((fleetLogoUrl) => {
            this.fleetLogoCache[fleetId] = fleetLogoUrl.toString()
            order.location.fleet = {
              _id: fleetId,
              logo: fleetLogoUrl
            }
          })
        } else {
          order.location.fleet = {
            _id: fleetId,
            logo: this.fleetLogoCache[fleetId]
          }
        }
      }
    }
  }

  getClass(order) {
    const cssClassArray = []

    if (order.rushRequested) {
      cssClassArray.push('rush-order-row')
    }
    if (order.isNoOrderNoPickup || order.isNoOrderWithPickup) {
      cssClassArray.push('no-order-row')
    }
    if (order.orderType === 'FleetCheck') {
      cssClassArray.push('fleet-check-row')
    }
    if (order.orderType === 'ServiceCall') {
      cssClassArray.push('service-call-row')
    }

    return cssClassArray
  }

  ngOnInit() {
    this.coreUserService.getLoggedInUser().subscribe((user) => {
      this.coreUser = user

      this.setUserAccountType()

      // locations action orders won't have been placed yet, so show created info instead
      if (this.coreUser.accountType === 'Location') {
        this.userActionOrdersConfig.columns[3].display = 'Started On'
        this.userActionOrdersConfig.columns[3].field = 'createdDate'
        this.userActionOrdersConfig.columns[4].display = 'Started By'
        this.userActionOrdersConfig.columns[4].field = 'createdBy.displayName'
      }

      if (this.coreUserService.isAdmin()) {
        const adminRoutes: NavRoute[] = []

        adminRoutes.push(
          new NavRoute('System Management', 'settings', '/systemManagement')
        )
        adminRoutes.push(
          new NavRoute('Inventory Management', 'view_comfy', '/product/list')
        )
        SideNavComponent.addRoutes(adminRoutes)
      }

      if (
        this.coreUserService.isAdmin() ||
        this.coreUser.roles.includes('canCreateFleetCheck')
      ) {
        this.showFleetCheckButton = true
      }

      if (this.coreUser.accountType === 'Vendor') {
        this.showServiceCallButton = true
      }

      this.connection = this.stateBroadcastService
        .getStateUpdateBroadcast()
        .subscribe(
          (data: {
            orderId: string
            orderType: string
            stateWillChange: boolean
            wasDeleted: boolean
          }) => {
            this.updatedOrderId = String(data.orderId)
            if (data.wasDeleted) {
              this.refreshOrder(data.orderId, data.wasDeleted)
            } else {
              this.orderService
                .isMyOrder(data.orderId, data.orderType)
                .subscribe((isMyOrder) => {
                  if (isMyOrder) {
                    this.refreshOrder(data.orderId)
                  }
                })
            }
          }
        )
    })
  }

  ngOnDestroy() {
    this.connection.unsubscribe()
  }

  refreshOrder(id?: string, wasDeleted?: boolean) {
    if (wasDeleted) {
      this.userActionTable.refresh()
      this.noUserActionTable.refresh()
      this.closedOrdersTable.refresh()
    } else {
      const updatingIdList: string[] = id ? [id] : null
      this.userActionTable.refresh(updatingIdList)
      this.noUserActionTable.refresh(updatingIdList)
      this.closedOrdersTable.refresh(updatingIdList)
    }
  }

  setUserAccountType() {
    const navRoutes: NavRoute[] = []
    this.userAccountType = this.coreUser.accountType

    navRoutes.push(new NavRoute('Home', 'home', '/dashboard'))
    navRoutes.push(new NavRoute('My Profile', 'face', '/user/profile'))
    navRoutes.push(new NavRoute('Search', 'search', '/order/search'))

    switch (this.userAccountType) {
      case 'Vendor':
        this.useFullScreenWidth = true
        this.showFleetLogo = false
        break
      case 'Location':
        this.showCreateOrder = true
        this.coreUserFleetLogo = this.coreUser.account.fleet.logo
        this.showFleetLogo = false // EBS 4/17/2017 Issue #134
        this.orderDays =
          this.coreUser.account.fleet.options &&
          this.coreUser.account.fleet.options.hasSchedule &&
          this.coreUser.account.vendors[0].orderDays
            ? this.coreUser.account.vendors[0].orderDays.join(', ')
            : ''
        this.deliveryDays =
          this.coreUser.account.fleet.options &&
          this.coreUser.account.fleet.options.hasSchedule &&
          this.coreUser.account.vendors[0].deliveryDays
            ? this.coreUser.account.vendors[0].deliveryDays.join(', ')
            : ''
        break
      case 'Stm':
        this.showNaughtyList = true
        this.showFleetLogo = false
        break
      case 'Fleet':
        this.showNaughtyList =
          this.coreUser.account.options &&
          this.coreUser.account.options.hasSchedule
        this.coreUserFleetLogo = this.coreUser.account.logo

        break
      default:
        break
    }

    SideNavComponent.addRoutes(navRoutes)
  }

  goToNewOrder(orderType = 'RegularOrder') {
    this.router.navigate(['/orderBuilder'], { queryParams: { orderType } })
  }

  pickupOnlyClicked() {
    const mentionUsedCounts =
      this.coreUser &&
      this.coreUser.account &&
      this.coreUser.account.fleet &&
      this.coreUser.account.fleet.options &&
      this.coreUser.account.fleet.options.showUsedInventoryInput

    const subheader = `Enter any notes${
      mentionUsedCounts ? ' or used counts' : ''
    } for this order, then click confim`
    const placeholder = `Enter notes${
      mentionUsedCounts ? ' / Used Counts' : ''
    } Here`
    this.modalDialogsService
      .input(
        'Confirm Pickup Only',
        subheader,
        placeholder,
        'Confirm',
        'Cancel',
        10,
        40,
        'empty-notes-box'
      )
      .subscribe((res) => {
        if (res !== false) {
          this.orderService
            .createNoOrder({
              isNoOrderWithPickup: true,
              isNoOrderNoPickup: false,
              notes: res
            })
            .subscribe((savedOrder) => {
              // broadcast update so tables on dashboard will update for all applicable users
              this.stateBroadcastService.broadcastUpdate(
                savedOrder._id,
                savedOrder.orderType,
                true
              )
            })
        }
      })
  }

  noOrderClicked() {
    this.modalDialogsService
      .input(
        'Confirm No Order or Pickup',
        'Enter any notes or used counts for this order, then click confirm',
        'Enter Notes / Used Counts Here',
        'Confirm',
        'Cancel',
        10,
        40,
        'empty-notes-box'
      )
      .subscribe((res) => {
        if (res !== false) {
          this.orderService
            .createNoOrder({
              isNoOrderWithPickup: false,
              isNoOrderNoPickup: true,
              notes: res
            })
            .subscribe((savedOrder) => {
              // broadcast update so tables on dashboard will update for all applicable users
              this.stateBroadcastService.broadcastUpdate(
                savedOrder._id,
                savedOrder.orderType,
                true
              )
            })
        }
      })
  }

  fleetCheckClicked() {
    const dialogRef = this.dialog.open(FleetCheckDialogComponent, {})
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderService
          .createFleetCheck({
            dueDate: result.date,
            location: result.locationId
          })
          .subscribe((savedOrder) => {
            // broadcast update so tables on dashboard will update for all applicable users
            this.stateBroadcastService.broadcastUpdate(
              savedOrder._id,
              savedOrder.orderType,
              true
            )
          })
      }
    })
  }

  serviceCallClicked() {
    const userLocations = this.coreUser.allowedLocations.map((l) => l._id || l)
    this.locationService
      .getUserServiceCallLocations()
      .subscribe((locations) => {
        if (locations && locations.length) {
          this.orderService
            .createServiceCall({
              location: locations[0],
              vendor:
                this.coreUser.accountType === 'Vendor'
                  ? this.coreUser.account
                  : null
            })
            .subscribe((savedOrder) => {
              // broadcast update so tables on dashboard will update for all applicable users
              this.stateBroadcastService.broadcastUpdate(
                savedOrder._id,
                savedOrder.orderType,
                true
              )
              this.router.navigate(['/orderBuilder'], {
                queryParams: {
                  orderId: savedOrder._id,
                  orderType: 'ServiceCall'
                }
              })
            })
        } else {
          this.modalDialogsService.alert(
            'Not Authorized',
            'You are not authorized to create a service call, contact support for help.'
          )
        }
      })

    // const dialogRef = this.dialog.open(ServiceCallDialogComponent, {});
    // dialogRef.afterClosed().subscribe(result => {
    //     if (result) {

    //         this.orderService.createServiceCall({ location: result.locationId })
    //             .subscribe(savedOrder => {
    //                 // broadcast update so tables on dashboard will update for all applicable users
    //                 this.stateBroadcastService.broadcastUpdate(savedOrder._id, savedOrder.orderType, true);
    //                 this.router.navigate(['/orderBuilder'], {queryParams: {orderId: savedOrder._id, orderType: 'ServiceCall'}});
    //             });
    //     }
    // });
  }

  getCompletedOrdersData() {
    /*        if (this.closedPanelHasBeenOpened === false) {
            this.closedPanelHasBeenOpened = true;
            this.closedOrdersTable.refresh(null, false, true);
        }
        this.completedPanelHeaderText = 'Hide Completed Orders'; */
  }

  onCompletedPanelClose() {
    this.completedPanelHeaderText = 'Show Completed Orders'
  }
}
