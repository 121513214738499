'use strict'

// node_modules imports
import { Injectable } from '@angular/core'

// local imports

@Injectable()
export class SystemManagementService {
  constructor() {}
}
