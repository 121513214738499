import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'

const documentManagementRoutes: Routes = [
  // { path: 'order',  component: OrderComponent },
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: []
  }
]

@NgModule({
  imports: [RouterModule.forChild(documentManagementRoutes)],
  exports: [RouterModule]
})
export class DocumentManagementRoutingModule {}
