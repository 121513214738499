import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from './core-layout.component'
// import { LayoutDetailComponent }    from './core-CoreLayout-detail.component';

const layoutRoutes: Routes = [
  { path: 'CoreLayout', component: CoreLayoutComponent }
  // { path: 'core-CoreLayout/:id', component: LayoutDetailComponent }
]
@NgModule({
  imports: [RouterModule.forChild(layoutRoutes)],
  exports: [RouterModule]
})
export class CoreLayoutRoutingModule {}
