import { Observable, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'

import { Router } from '@angular/router'

import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable()
export class CoreUserService {
  public loggedInUser: any = null
  private coreUsersURL = `${environment.apiUrl}/coreUser`

  constructor(private http: HttpClient, private router: Router) {
    this.authenticate().subscribe((user) => {
      this.setLoggedInUser(user)
    })
  }

  // Get all users from the API
  getUsers(searchParams: object): Observable<any[]> {
    let params = new HttpParams()

    Object.keys(searchParams).forEach((key) => {
      params = params.append(key, searchParams[key])
    })

    return this.http.get<any[]>(this.coreUsersURL, { params })
  }

  getLoggedInUser(): Observable<any> {
    if (this.loggedInUser) {
      return of(this.loggedInUser)
    } else {
      return this.authenticate().pipe(
        mergeMap((user) => {
          this.setLoggedInUser(user)
          return of(user)
        })
      )
    }
  }

  isAdmin() {
    return this.loggedInUser
      ? this.loggedInUser.roles && this.loggedInUser.roles.includes('admin')
      : false
  }

  save(user: any) {
    if (user._id) {
      return this.http.put(`${this.coreUsersURL}/${user._id}`, user).pipe(
        map((userObj: any) => {
          if (this.loggedInUser && userObj._id === this.loggedInUser._id) {
            this.setLoggedInUser(userObj)
          }
          return userObj
        })
      )
    } else {
      return this.http.post(this.coreUsersURL, user)
    }
  }

  isLoggedIn(): Observable<boolean> {
    if (this.loggedInUser) {
      return of(true)
    } else {
      return this.getLoggedInUser().pipe(
        mergeMap((user) => {
          return of(!!user)
        })
      )
    }
  }

  setLoggedInUser(user) {
    this.loggedInUser = user
  }

  // Get user by id
  getUserById(userId) {
    return this.http.get(`${this.coreUsersURL}/userid/${userId}`)
  }

  // Get user by id
  getUserByKeycloakId(keycloakId) {
    return this.http.get(`${this.coreUsersURL}/keycloakId/${keycloakId}`)
  }

  getStmStoreUsers(storeNumber) {
    return this.http.get(
      `${this.coreUsersURL}/stmStoreUsers?storeNumber=${storeNumber}`
    )
  }

  switchAccount(accountType, accountId) {
    return this.http
      .put(
        `${this.coreUsersURL}/switchAccount?accountType=${accountType}&accountId=${accountId}`,
        null
      )
      .pipe(
        map((user) => {
          this.setLoggedInUser(user)
          return this.loggedInUser
        })
      )
  }

  switchToDefaultAccount() {
    return this.http
      .put(`${this.coreUsersURL}/switchAccount?default=true`, null)
      .pipe(
        map((user) => {
          this.setLoggedInUser(user)
          return this.loggedInUser
        })
      )
  }

  logout() {
    return this.http.post(`${this.coreUsersURL}/logout`, {}).pipe(
      map((res) => {
        this.router.navigate(['/login'])
        return res
      })
    )
  }

  private authenticate() {
    return this.http.get(`${this.coreUsersURL}/checkAuthentication`)
  }
}
