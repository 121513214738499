import { Directive, ElementRef, Input, OnChanges } from '@angular/core'
import { EllipsesDirective } from './ellipses.directive'

/**
 * File Ellipses Directive
 * Truncates text whenever it is too long to fit on a single line but allows displays the file extension
 * @Input appFileEllipses {string} Set this to the text that should be displayed/truncated
 * Usage example <div [appFileEllipses]="user.application.filename"></div>
 * For example if the value "my-job-application.pdf" is too long, it might get truncated like "my-job-appl...pdf"
 */
@Directive({ selector: '[appFileEllipses]' })
export class FileEllipsesDirective
  extends EllipsesDirective
  implements OnChanges
{
  @Input() appFileEllipses: string

  constructor(elementRef: ElementRef) {
    super(elementRef)

    // You cannot have rightCharCount on FileEllipsesDirective because we will always show file extension
    // So get rid of it here
    this.rightCharCount = undefined
    delete this.rightCharCount
  }

  /**
   * Called whenever the value of appFileEllipses is updated.
   * This sets the new value and calls transformText
   */
  ngOnChanges() {
    this.originalText = this.appFileEllipses || ''
    this.suffix = ''
    // Pull the extension off of the text and set this.suffix to the extension
    let fileExtIndex = this.originalText.lastIndexOf('.')
    if (fileExtIndex !== -1 && fileExtIndex + 1 < this.originalText.length) {
      fileExtIndex++ // move to the char right past the dot (cuz the ellipses will already have a dot there)
      this.suffix = this.originalText.substr(fileExtIndex)
      this.originalText = this.originalText.substring(0, fileExtIndex)
    }

    this.appEllipses = this.originalText
    super.ngOnChanges()
  }
}
