<div>
  <app-list-view-widget #users></app-list-view-widget>
</div>

<div>
  <app-list-view-widget #vendors></app-list-view-widget>
</div>

<div>
  <app-list-view-widget #fleets></app-list-view-widget>
</div>

<div>
  <app-list-view-widget #manufacturers></app-list-view-widget>
</div>
