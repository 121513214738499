import { Directive, ElementRef, Input, OnChanges } from '@angular/core'

/**
 * Ellipses Directive
 * Truncates text whenever it is too long to fit on a single line
 * @Input appEllipses {string} Set this to the text that should be displayed/truncated
 * @Input rightCharCount {number} If you want a certain number of chars to show on the right side
 * For example if rightCharCount = 3, the value "This is some text" might be truncated to "This is s...ext" instead of "This is some..."
 * Usage example <div [appEllipses]="user.university.name" rightCharCount="5"></div>
 */
@Directive({ selector: '[appEllipses]' })
export class EllipsesDirective implements OnChanges {
  private static registeredEllipsesDirectives: EllipsesDirective[] = []
  private static windowListenerTimeout
  private static transformerTimeout
  private static listenerAdded = false

  /**
   * Splits the value into two halves (left and right)
   * @param {string} value
   * @returns {{left: string; right: string}}
   */
  private static splitStringInHalves(value: string): {
    left: string
    right: string
  } {
    const splitIdx = Math.ceil(value.length / 2)
    return {
      left: value.substr(0, splitIdx),
      right: value.substr(splitIdx)
    }
  }

  /**
   * Calls the transform text function on each registered EllipsesDirective
   */
  private static transformRegisteredDirectives() {
    // loop through each registered directive and call transform text
    // The timeout is here because sometimes the text was getting stuck on two lines if the window was resized very quickly
    // This just gives the system a little time to finish thinking and reset
    clearTimeout(EllipsesDirective.transformerTimeout)
    EllipsesDirective.transformerTimeout = setTimeout(() => {
      EllipsesDirective.registeredEllipsesDirectives.forEach((dir) => {
        dir.transformText()
      })
    }, 10)
  }

  @Input() appEllipses: string // set this to the value that should be displayed/truncated
  @Input() rightCharCount = 0
  protected originalText: string
  protected suffix = ''
  private element: any // handle to the dom element that the directive is used as an attribute
  private targetHeight: number
  private currentHeight: number
  private registered: boolean

  constructor(private elementRef: ElementRef) {
    this.element = elementRef.nativeElement
    this.register()

    // Set up window resize listener to check and transform ellipses directives if needed
    if (!EllipsesDirective.listenerAdded) {
      window.addEventListener('resize', () => {
        // This clearTimeout pattern prevents the listener from firing until the user has stopped resizing
        // This prevents huge amount of function calls
        clearTimeout(EllipsesDirective.windowListenerTimeout)
        EllipsesDirective.windowListenerTimeout = setTimeout(
          EllipsesDirective.transformRegisteredDirectives,
          100
        )
      })
      // Make sure the listener is only added once
      EllipsesDirective.listenerAdded = true
    }
  }

  /**
   * Called whenever the value of appEllipses is updated.
   * This sets the new value and calls transformText
   */
  ngOnChanges() {
    this.originalText = this.appEllipses || ''

    // Pull the rightCharCount of characters off of the text and set it as the suffix
    if (this.rightCharCount > 0) {
      const charCount = Math.min(this.rightCharCount, this.originalText.length)
      this.suffix = this.originalText.substr(
        this.originalText.length - charCount
      )
      this.originalText = this.originalText.substr(
        0,
        this.originalText.length - charCount
      )
    }

    this.element.innerHTML = this.originalText
    this.transformText()
  }

  /**
   * Get the height of a single line of text
   */
  private calculateTargetHeight(): void {
    // Set the html to one char to force it to be one line, get the height, then restore the html
    const startHTML = this.element.innerHTML || ''
    this.element.innerHTML = '*'
    this.targetHeight = this.element.offsetHeight
    this.element.innerHTML = startHTML
  }

  /**
   * Get the height that would result if the entire appEllipses value was displayed
   */
  private calculateCurrentHeight(): void {
    // Set the html to the actual value of appEllipses, get the height, then restore the html
    const startHTML = this.element.innerHTML || ''
    this.element.innerHTML = (this.originalText || '') + this.suffix
    this.currentHeight = this.element.offsetHeight
    this.element.innerHTML = startHTML
  }

  /**
   * Determines if a potential new text value would cause the text to show on more than one line
   * @param {string} transformedText
   * @returns {boolean}
   */
  private tooLong(transformedText: string): boolean {
    // set the html to the transformed text, get the height, restore the html and determine if transformedHeight is more than one line
    this.calculateTargetHeight()
    const startHTML = this.element.innerHTML
    this.element.innerHTML = transformedText
    const transformedHeight = this.element.offsetHeight
    this.element.innerHTML = startHTML
    return transformedHeight > this.targetHeight
  }

  /**
   * Determines if the current value should be ellipsed
   * @returns {boolean}
   */
  private shouldEllipse() {
    this.calculateCurrentHeight()
    this.calculateTargetHeight()
    return this.currentHeight > this.targetHeight
  }

  /**
   * Truncates text and adds ellipses if too long
   */
  private transformText(): void {
    if (this.shouldEllipse()) {
      const splitString = EllipsesDirective.splitStringInHalves(
        this.originalText
      )
      this.transform(splitString.left, splitString.right)
    } else if (this.element.innerHTML !== this.originalText + this.suffix) {
      this.element.innerHTML = this.originalText + this.suffix
    }
  }

  /**
   * Recursive function that determines how much text should be truncated and then updates it
   * @param {string} left
   * @param {string} right
   */
  private transform(left: string, right = ''): void {
    // This function recursively truncates the right half of the string until it is not greater than one line
    // Then it recursively adds chunks of the right side as much as it can without becoming too long
    const _suffix = `...${this.suffix}`
    left += _suffix

    // remove the ellipses and keep going
    if (this.tooLong(left)) {
      left = this.removeSuffix(left)
      if (right.length === 0) {
        left = left.substr(0, left.length - 1)
        this.element.innerHTML = left + _suffix
        return
      }
      const leftSplit = EllipsesDirective.splitStringInHalves(left)
      this.transform(leftSplit.left, leftSplit.right)
    } else {
      left = this.removeSuffix(left)
      if (right.length === 0) {
        this.element.innerHTML = left + _suffix
        return
      }
      const rightSplit = EllipsesDirective.splitStringInHalves(right)
      this.transform(left + rightSplit.left, rightSplit.right)
    }
  }

  /**
   * Remove the suffix from the string
   * @param {string} value
   * @returns {string}
   */
  private removeSuffix(value: string): string {
    const ellipIndex = value.lastIndexOf(`...${this.suffix}`)
    if (ellipIndex !== -1) {
      return value.substr(0, ellipIndex)
    }
  }

  /**
   * Register an EllipsesDirective
   */
  private register(): void {
    if (!this.registered) {
      EllipsesDirective.registeredEllipsesDirectives.push(this)
      this.registered = true
    }
  }
}
