import { MatDialogRef } from '@angular/material/dialog'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-alert-dialog',
  template: `
    <h4 class="dialog-title">{{ title }}</h4>
    <div [innerHTML]="messageHTML"></div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        type="button"
        mat-raised-button
        fxFlexAlign="end"
        (click)="dialogRef.close()"
      >
        {{ closeButtonText }}
      </button>
    </div>
  `,
  styles: []
})
export class AlertDialogComponent implements OnInit {
  title: string
  message: string
  isHTML: boolean
  closeButtonText: string
  messageHTML: string

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>) {}

  ngOnInit() {
    if (this.isHTML) {
      this.messageHTML = this.message
    } else {
      this.messageHTML = `<pre class="default-text">${this.message}</pre>`
    }
  }
}
