// "npm" modules
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { FlexLayoutModule } from '@angular/flex-layout'

// 'app' modules

// Services & Guards
import { ManufacturerModule } from '../../manufacturer/client/manufacturer.module'
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { ProductService } from './product.service'
// Routers
import { ProductRoutingModule } from './product-routing.module'

// Components
import { ProductComponent } from './product.component'
import { ProductListComponent } from './product-list.component'
// import {ProductDetailComponent} from './product-detail.component';
import { BrandArrayPipe } from './brand-array.pipe'

@NgModule({
  declarations: [ProductComponent, ProductListComponent, BrandArrayPipe],
  imports: [
    BrowserModule,
    FormsModule,
    ProductRoutingModule,
    CoreModule,
    MaterialComponentsModule,
    FlexLayoutModule
  ],
  providers: [ProductService],
  exports: [ProductComponent, ProductListComponent, BrandArrayPipe]
})
export class ProductModule {}
