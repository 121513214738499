import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { environment } from '../../../environments/environment'

export enum LogLevel {
  emerg = 0,
  alert,
  crit,
  error,
  warn,
  notice,
  info,
  debug,
  verbose,
  silly
}

@Injectable()
export class LoggerService {
  private browserInfo: string
  private osInfo: string
  private loggedInUserName

  constructor(
    private http: HttpClient,
    private coreUserService: CoreUserService
  ) {
    this.browserInfo = this.getBrowserInfo()
    this.osInfo = this.getOSInfo()
    this.coreUserService.getLoggedInUser().subscribe((user) => {
      this.loggedInUserName = user ? user.username : ''
    })
  }

  // NOTE: Don't use this often! It sends a lot of data to the server. Only use it to capture significant errors/events
  logToServer(logLevel: string, message: string): void {
    if (LogLevel[logLevel] === undefined) {
      throw new Error(`Invalid Log Level: ${logLevel}`)
    }

    this.http
      .post(`${environment.apiUrl}/log`, {
        logLevel,
        message,
        browserInfo: this.browserInfo,
        osInfo: this.osInfo
      })
      .subscribe(
        () => {},
        (err) => {
          /* tslint:disable-next-line:no-console */
          console.error('Logging Error:')
          /* tslint:disable-next-line:no-console */
          console.error(err)
        }
      )
  }

  private getBrowserInfo() {
    // pulled from http://www.javascripter.net/faq/browsern.htm
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let fullVersion = `${parseFloat(navigator.appVersion)}`
    let majorVersion
    let nameOffset, verOffset, ix

    // In Opera, the true version is after 'Opera' or after 'Version'
    // eslint-disable-next-line no-cond-assign
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browserName = 'Opera'
      fullVersion = nAgt.substring(verOffset + 6)

      // eslint-disable-next-line no-cond-assign
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8)
      }

      // In MSIE, the true version is after 'MSIE' in userAgent
      // eslint-disable-next-line no-cond-assign
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer'
      fullVersion = nAgt.substring(verOffset + 5)

      // In Chrome, the true version is after 'Chrome'
      // eslint-disable-next-line no-cond-assign
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome'
      fullVersion = nAgt.substring(verOffset + 7)

      // In Safari, the true version is after 'Safari' or after 'Version'
      // eslint-disable-next-line no-cond-assign
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browserName = 'Safari'
      fullVersion = nAgt.substring(verOffset + 7)
      // eslint-disable-next-line no-cond-assign
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8)
      }

      // In Firefox, the true version is after 'Firefox'
      // eslint-disable-next-line no-cond-assign
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox'
      fullVersion = nAgt.substring(verOffset + 8)

      // In most other browsers, 'name/version' is at the end of userAgent
    } else if (
      // eslint-disable-next-line no-cond-assign
      (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      // eslint-disable-next-line no-cond-assign
      (verOffset = nAgt.lastIndexOf('/'))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset)
      fullVersion = nAgt.substring(verOffset + 1)
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName
      }
    }
    // trim the fullVersion string at semicolon/space if present
    // eslint-disable-next-line no-cond-assign
    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix)
    }
    // eslint-disable-next-line no-cond-assign
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix)
    }

    majorVersion = parseInt(`${fullVersion}`, 10)
    if (isNaN(majorVersion)) {
      fullVersion = `${parseFloat(navigator.appVersion)}`
      majorVersion = parseInt(navigator.appVersion, 10)
    }

    return (
      `\tBrowser Name: ${browserName}\n\tFull Version: ${fullVersion}\n\tMajor Version: ${majorVersion}` +
      `\n\tnavigator.appName: ${navigator.appName}\n\tnavigator.userAgent: ${navigator.userAgent}`
    )
  }

  private getOSInfo() {
    // pulled from http://www.javascripter.net/faq/operatin.htm
    // This script sets OSName variable as follows:
    // 'Windows'    for all versions of Windows
    // 'MacOS'      for all versions of Macintosh OS
    // 'Linux'      for all versions of Linux
    // 'UNIX'       for all other UNIX flavors
    // 'Unknown OS' indicates failure to detect the OS

    let OSName = 'Unknown OS'
    if (navigator.appVersion.includes('Win')) {
      OSName = 'Windows'
    }
    if (navigator.appVersion.includes('Mac')) {
      OSName = 'MacOS'
    }
    if (navigator.appVersion.includes('X11')) {
      OSName = 'UNIX'
    }
    if (navigator.appVersion.includes('Linux')) {
      OSName = 'Linux'
    }

    return OSName
  }
}
