import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-hamburger',
  templateUrl: 'hamburger.component.html',
  styleUrls: ['hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {
  isActive = false

  constructor() {}

  ngOnInit() {}
}
