import {
  FormArray,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms'

/**
 * Validates than a FormArray has at least `minLength` controls
 */
export const ARRAY_LENGTH_VALIDATOR_FN = (minLength: number) => {
  return (control: FormArray): ValidationErrors | null => {
    if (!control.controls || !control.controls.length) {
      return {
        arrayLength: {
          valid: false
        }
      }
    }
    return null
  }
}
