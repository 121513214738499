// tslint:disable:no-access-missing-member
import * as _ from 'lodash'
import { Component, OnInit, ViewChild } from '@angular/core'

import { FormBuilder, FormGroup } from '@angular/forms'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { ListViewWidgetComponent } from '../../core/client/object-list-with-dynamic-detail/list-view-widget.component'
import { CoreUserComponent } from '../../core-user/client/core-user.component'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { UtilsClass } from '../../core/client/utils/utils.component'
import { VendorService } from './vendor.service'
import { timezones } from './../../constants'

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent extends CoreResponseHandler implements OnInit {
  vendor: any = {}
  stmVendors: any = []
  savedVendorUsers: any = []
  stmKeycloakUsers: any = []
  responses: any = {}
  vendorForm: FormGroup
  viewUserList: Boolean = false
  saving = false
  timezones = timezones
  @ViewChild('users', { static: true })
  UsersListViewWidget: ListViewWidgetComponent

  constructor(
    private vendorService: VendorService,
    private userService: CoreUserService,
    private fb: FormBuilder
  ) {
    super()
    this.vendor = this.newDefaultVendor()
  }

  ngOnInit() {
    this.vendorForm = this.fb.group({})
    this.getVendorUsers()
    this.vendorService.GetSTMVendors(true).subscribe((stmVendors) => {
      this.stmVendors = stmVendors
    })

    if (this.vendor && this.vendor.storeNumber !== undefined) {
      this.getStmStoreUsers()
    }

    if (this.vendor) {
      this.vendor.address = this.vendor.address || {}
      this.vendor.address.timezone =
        this.vendor.address.timezone || 'America/Chicago'
    }
    // User Component
    this.UsersListViewWidget.setListArray([], 'displayName', ['person'])
    this.UsersListViewWidget.setViewComponent(CoreUserComponent, 'user')
    this.UsersListViewWidget.modifyObject('user', 'accountType', 'Vendor')
    this.UsersListViewWidget.modifyObject(
      'user',
      'defaultAccountType',
      'Vendor'
    )
    this.UsersListViewWidget.modifyObject('user', 'account', this.vendor)
    this.UsersListViewWidget.modifyObject('user', 'defaultAccount', this.vendor)
    if (this.vendor.company === 'Southern Tire Mart') {
      this.UsersListViewWidget.modifyObject(
        'storeInfo',
        'storeNumber',
        this.vendor.storeNumber
      )
    }
  }

  save(): void {
    this.saving = true
    this.responses = {}
    this.vendorService.save(this.vendor).subscribe(
      (response) => {
        if (!this.vendor._id) {
          UtilsClass.mergeObjByValues(this.vendor, response)
          this.UsersListViewWidget.modifyObject('user', 'account', this.vendor)
          if (this.vendor.company === 'Southern Tire Mart') {
            this.UsersListViewWidget.modifyObject(
              'storeInfo',
              'storeNumber',
              this.vendor.storeNumber
            )
          }
        } else {
          this.vendorService
            .getVendorById(this.vendor._id)
            .subscribe((vendor) => {
              UtilsClass.mergeObjByValues(this.vendor, vendor)
              if (this.vendor.storeNumber !== undefined) {
                this.getStmStoreUsers()
              }
            })
        }
        this.handleSuccess('saveSuccess', 'Vendor saved successfully!')
        this.saving = false
      },
      (error) => {
        this.handleFailure(error)
        this.saving = false
      }
    )
  }

  viewUserListComponent() {
    this.viewUserList = true
    this.UsersListViewWidget.setTitle(`${this.vendor.name} Users`)
  }

  getVendorUsers() {
    if (this.vendor._id) {
      this.userService
        .getUsers({
          defaultAccountType: 'Vendor',
          defaultAccount: this.vendor._id
        })
        .subscribe((users) => {
          this.UsersListViewWidget.viewModelArray = users
          this.savedVendorUsers = users
          this.removeSavedUsersFromStmUsers()
        })
    } else {
      this.UsersListViewWidget.viewModelArray = []
    }
  }

  getStmStoreUsers() {
    this.userService
      .getStmStoreUsers(this.vendor.storeNumber)
      .subscribe((stmStoreUsers) => {
        this.stmKeycloakUsers = _.map(<any[]>stmStoreUsers, (u) => {
          return {
            keycloakId: u.id,
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            username: u.email,
            notificationRoute: {},
            alreadyInKeycloak: true,
            account: this.vendor, // not id because we want the populated object
            defaultAccount: this.vendor, // not id because we want the populated object
            accountType: 'Vendor',
            defaultAccountType: 'Vendor'
          }
        })

        // We only want to show stm keycloak users that don't already exist in our system
        this.removeSavedUsersFromStmUsers()
        this.UsersListViewWidget.modifyObject(
          'storeInfo',
          'storeUsers',
          this.stmKeycloakUsers
        )
      })
  }

  // We only want to show stm keycloak users that don't already exist in our system
  removeSavedUsersFromStmUsers() {
    if (this.stmKeycloakUsers && this.savedVendorUsers) {
      _.remove(<any[]>this.stmKeycloakUsers, (u) => {
        return _.find(
          <any[]>this.savedVendorUsers,
          (su) => su.email === u.email
        )
      })
    }
  }

  newDefaultVendor() {
    const newVendor: object = {
      name: '',
      address: {
        street1: '',
        city: '',
        state: '',
        zip: '',
        timezone: 'America/Chicago'
      },
      company: ''
    }

    return newVendor
  }

  // Switches form to STM Vendor dropdown
  stmSwitch() {
    this.vendor.company = this.vendor.company ? 'Southern Tire Mart' : ''
  }

  // Assigns an STM vendor as the current Vendor.
  stmVendorAssign(stmVendor) {
    UtilsClass.mergeObjByValues(this.vendor, stmVendor)
    this.vendor.company = 'Southern Tire Mart'
    this.vendor.address.street1 = this.vendor.address.street
    this.vendor.storeNumber = this.vendor._id
    this.vendor.name = `${this.vendor.name} (STM)`
    delete this.vendor._id
    this.getStmStoreUsers()
  }

  handleResponse(response: CoreResponse): boolean {
    if (response.offendingKey) {
      switch (response.offendingKey) {
        case 'name':
        case 'abbreviation':
        case 'address.errors.street1':
        case 'address.errors.state':
        case 'address.errors.zip':
        case 'phone':
        case 'fax':
        case 'email':
          this.responses[response.offendingKey] = response.message
          return true
        default:
          return false
      }
    } else {
      switch (response.type) {
        case 'saveSuccess':
        case 'updateSuccess':
        case 'deleteSuccess':
          this.responses[response.type] = response.message
          return true
        default:
          return false
      }
    }
  }
}
