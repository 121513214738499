import { Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FleetService } from '../../fleet/client/fleet.service'
import { LocationService } from '../../location/client/location.service'

@Component({
  selector: 'fleet-check-dialog',
  templateUrl: 'fleet-check-dialog.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class FleetCheckDialogComponent {
  myDatePickerOptions = { inline: true }
  datepickerDate: { year: number; month: number; day: number }
  selectedDate: Date = null
  fleets: any[] = null
  locations: any[] = null
  selectedFleet: any = null
  selectedLocation: any = null
  error: string = null

  constructor(
    private fleetService: FleetService,
    private locationService: LocationService,
    public dialogRef: MatDialogRef<FleetCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { readOnlyLocation: boolean; order: any }
  ) {
    this.fleetService.getFleets().subscribe((fleets: any[]) => {
      this.fleets = fleets
      if (this.fleets.length === 1) {
        this.selectedFleet = this.fleets[0]._id
      }
      if (this.data && this.data.order) {
        this.selectedFleet =
          this.data.order.location.fleet._id || this.data.order.location.fleet
        this.selectedDate = new Date(this.data.order.dueDate)
        this.datepickerDate = {
          year: this.selectedDate.getFullYear(),
          month: this.selectedDate.getMonth() + 1,
          day: this.selectedDate.getDate()
        }
      }
      this.getFleetLocations().subscribe(() => {
        if (this.data && this.data.order) {
          this.selectedLocation = this.data.order.location._id
        }
      })
    })
  }

  onDateChanged(ev) {
    this.selectedDate = ev.jsdate
  }

  onFleetSelected(): void {
    this.locations = []
    this.selectedLocation = null
    this.getFleetLocations().subscribe()
  }

  getFleetLocations(): Observable<null> {
    if (!this.selectedFleet) {
      this.locations = []
      return of(null)
    }
    return this.locationService
      .getLocations({ fleet: this.selectedFleet })
      .pipe(
        mergeMap((locations: any[]) => {
          this.locations = locations
          if (this.locations.length === 1) {
            this.selectedLocation = this.locations[0]._id
          }
          return of(null)
        })
      )
  }

  submit() {
    if (!this.selectedFleet) {
      this.error = 'Please select a fleet'
    } else if (!this.selectedLocation) {
      this.error = 'Please select a location'
    } else if (!this.selectedDate) {
      this.error = 'Please select a date'
    } else {
      this.dialogRef.close({
        locationId: this.selectedLocation,
        date: this.selectedDate
      })
    }
  }
}
