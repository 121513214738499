// tslint:disable:no-access-missing-member
import * as _ from 'lodash'

import { Component, OnInit } from '@angular/core'
import { OrderService } from '../order.service'
import { StateEnum } from '../../order-state.enum'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-po-sales',
  template: `
    <div *ngIf="isVisible" class="app-order-po order-part">
      <div class="po-input" *ngIf="order">
        <app-container-widget
          title="Tire Purchase Orders"
          headerBarColor="rgb(63, 161, 3)"
          faIcon="fas fa-truck"
        >
          <mat-card class="card-po">
            <ul
              *ngIf="
                order.orderType !== 'ServiceCall' &&
                !this.order.location.fleet.options.onePOPerManufacturer
              "
            >
              <li
                *ngIf="order?.regularItems?.manufacturers?.length > 0"
                style="list-style: none"
              >
                <div class="sales-po-item-container" fxLayout="row">
                  <div
                    fxFlex="40%"
                    fxLayoutAlign="start center"
                    style="font-weight: bolder; font-size: 30px"
                  >
                    Purchase Order #
                  </div>
                  <div fxFlex="60%" fxLayoutAlign="start center">
                    <p>
                      <strong *ngIf="inputDisabled">{{
                        order.regularItems.generalPO
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="order.regularItems.generalPO"
                        placeholder="TIRE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
              <li
                *ngIf="
                  order?.regularItems?.manufacturers?.length === 0 &&
                  order?.forkliftItems?.length > 0
                "
                style="list-style: none"
              >
                <div
                  *ngIf="
                    order.location.fleet.options.allowGeneralPOForForkliftOrders
                  "
                  class="sales-po-item-container"
                  fxLayout="row"
                >
                  <div
                    fxFlex="40%"
                    fxLayoutAlign="start center"
                    style="font-weight: bolder; font-size: 30px"
                  >
                    Purchase Order #
                  </div>
                  <div fxFlex="60%" fxLayoutAlign="start center">
                    <p>
                      <input
                        [(ngModel)]="order.regularItems.generalPO"
                        placeholder="FORKLIFT PO#"
                      />
                    </p>
                  </div>
                </div>
                <h3
                  *ngIf="
                    !order.location.fleet.options
                      .allowGeneralPOForForkliftOrders
                  "
                >
                  No PO #'s needed (Forklift Only)
                </h3>
              </li>
            </ul>
            <ul
              *ngIf="
                order.orderType !== 'ServiceCall' &&
                this.order.location.fleet.options.onePOPerManufacturer
              "
            >
              <li
                *ngFor="let manufac of order?.regularItems?.manufacturers"
                style="list-style: none"
              >
                <div
                  class="sales-po-item-container"
                  fxLayout="row"
                  *ngIf="
                    manufac.manufacturer.name !== 'Bandag Retread' ||
                    hasBridgestone === false
                  "
                >
                  <div fxFlex="50%" fxLayoutAlign="start center">
                    <img
                      [alt]="manufac.manufacturer.name"
                      [src]="manufac.manufacturer.logo"
                      class="po-logo"
                    />
                  </div>
                  <div fxFlex="50%" fxLayoutAlign="start center">
                    <p>
                      PO #
                      <strong *ngIf="inputDisabled">{{
                        manufac.poNumber
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="manufac.poNumber"
                        placeholder="TIRE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
              <li
                *ngIf="
                  !order?.regularItems?.manufacturers?.length &&
                  order?.forkliftItems?.items?.length
                "
              >
                <h3>No PO #'s needed (Forklift Only)</h3>
              </li>
            </ul>
            <ul *ngIf="order.orderType === 'ServiceCall'">
              <li style="list-style: none">
                <div class="sales-po-item-container" fxLayout="row">
                  <div fxFlex fxLayoutAlign="start center">
                    <p>
                      Tire PO #
                      <strong *ngIf="inputDisabled">{{
                        order.tirePONumber
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="order.tirePONumber"
                        placeholder="TIRE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </mat-card>
        </app-container-widget>
      </div>
    </div>
  `,
  styleUrls: ['../order.component.scss'],
  providers: []
})
export class OrderPOSalesComponent
  extends OrderBaseComponent
  implements OnInit
{
  inputDisabled = true
  hasBridgestone = false
  extraPO: { [propName: string]: boolean }

  constructor(private orderService: OrderService) {
    super()
    this.orderService.itemAddedToCartNotification.subscribe((item) =>
      this.setUserVisibility()
    )
    this.orderService.itemRemovedFromCartNotification.subscribe((item) =>
      this.setUserVisibility()
    )
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      !this.order.isNoOrderNoPickup &&
      !this.order.isNoOrderWithPickup &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes('po-sales')) &&
      (this.order.orderType === 'ServiceCall' ||
        (this.order.regularItems &&
          this.order.regularItems.manufacturers &&
          this.order.regularItems.manufacturers.length) ||
        (this.order.forkliftItems && this.order.forkliftItems.length))

    console.info('setUserVisibility', this.order)

    this.handleBridgestone()
  }

  setOrder(order) {
    super.setOrder(order)

    // TODO figure out how to get rid of this ACT specific logic
    this.handleBridgestone()

    this.setAdditionalPO()

    const isAAACooper =
      this.order.location.fleet._id === '58f1253791541b40c8d03c14'
    this.inputDisabled =
      this.coreUser.accountType === 'Vendor' ||
      this.coreUser.accountType === 'Stm' ||
      (isAAACooper && this.coreUser.accountType === 'Location')
  }

  // TODO figure out how to get rid of this ACT specific logic
  handleBridgestone() {
    if (
      this.order &&
      this.order.regularItems &&
      this.order.regularItems.manufacturers
    ) {
      const bridgestone = _.find(
        this.order.regularItems.manufacturers,
        (m: any) => {
          return m.manufacturer.name === 'Bridgestone'
        }
      )

      const bandag = _.find(this.order.regularItems.manufacturers, (m: any) => {
        return m.manufacturer.name === 'Bandag Retread'
      })

      this.hasBridgestone = bridgestone !== undefined

      if (this.hasBridgestone && bandag) {
        if (!bandag.poNumber && bridgestone.poNumber) {
          bandag.poNumber = bridgestone.poNumber
        } else if (!bridgestone.poNumber && bandag.poNumber) {
          bridgestone.poNumber = bandag.poNumber
        }
      }
    }
  }

  handleItemAdded() {
    this.setUserVisibility()
  }

  toggleExtraPO(poDescription) {
    this.order.additionalPO = this.order.additionalPO || []
    if (this.extraPO[poDescription]) {
      this.addExtraPO(poDescription, this.order[`${poDescription}PO`])
    } else {
      this.removeExtraPO(poDescription)
    }
  }

  updateExtraPO(poDescription) {
    if (this.order[`${poDescription}PO`]) {
      this.addExtraPO(poDescription, this.order[`${poDescription}PO`])
    } else {
      this.removeExtraPO(poDescription)
    }
  }

  addExtraPO(poDescription, poNumber) {
    const match = _.find(
      this.order.additionalPO,
      (po: any) => po.description === `${poDescription}PO`
    )
    if (this.order[`${poDescription}PO`]) {
      if (match) {
        match.poNumber = this.order[`${poDescription}PO`]
      } else {
        this.order.additionalPO.push({
          description: poDescription,
          poNumber: this.order[`${poDescription}PO`]
        })
      }
    } else {
      this.removeExtraPO(poDescription)
    }
  }

  removeExtraPO(poDescription) {
    this.order.additionalPO = this.order.additionalPO || []
    _.remove(
      this.order.additionalPO,
      (po: any) => po.description === `${poDescription}PO`
    )
  }

  setAdditionalPO() {
    this.extraPO = this.extraPO || {}
    this.order.additionalPO = this.order.additionalPO || []
    _.forEach(this.order.additionalPO, (po: any) => {
      this.order[`${po.description}PO`] = po.poNumber
      this.extraPO[po.description] = true
    })
  }
}
