import * as _ from 'lodash'
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core'

import { DataTableService } from './datatable.service'
import { DataTableConfig, IDataTableConfig } from './dataTableConfig.class'
import {
  DataTableColumnConfig,
  IDataTableColumnConfig
} from './dataTableColumnConfig.class'
import { ActionBtn } from './actionBtn.class'
import { DataTableRowComponent } from './datatable-row.component'

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  providers: [DataTableService]
})
export class DataTableComponent implements OnInit {
  static tables: DataTableComponent[] = []

  static createDataTableConfig(config: IDataTableConfig) {
    return new DataTableConfig(config)
  }

  static createDataTableColumnsConfig(columns: IDataTableColumnConfig[]) {
    const colsReturnArray: DataTableColumnConfig[] = []
    if (!columns.length) {
      return []
    }
    let sortedColumnFound = false
    for (const c of columns) {
      if (c.sort && c.sort !== 'none') {
        if (sortedColumnFound) {
          throw new Error(
            'DataTable Config Error: Only one column can be sorted'
          )
        }
        sortedColumnFound = true
      }
      colsReturnArray.push(new DataTableColumnConfig(c))
    }
    return colsReturnArray
  }

  static createActionButton(_label, _function, _buttonColor, _buttonIcon) {
    return new ActionBtn(_label, _function, _buttonColor, _buttonIcon)
  }

  @Input() config: DataTableConfig
  @ViewChildren(DataTableRowComponent) rows: QueryList<ElementRef>
  paginateOpts: any
  loading = false
  originalItems: any[] = []
  renderedItems: any[] = []
  sortField: string
  sortDirection: 'none' | 'asc' | 'desc' = 'none'
  idIndexMap: { [id: string]: { current: number; original: number } } = {}
  tableId: string
  totalItems = 0
  pathFields: {
    originalField: string
    originalFieldPaths: string[]
    newField: string
  }[] = []

  originalFields: string[] = []
  altTextPathFields: {
    originalField: string
    originalFieldPaths: string[]
    newField: string
  }[] = []

  firstIndex = 1
  lastIndex = 32
  itemsResolved = false

  constructor(public dataTableService: DataTableService) {
    this.tableId = `table_${DataTableComponent.tables.length}`
    DataTableComponent.tables.push(this)
  }

  ngOnInit() {
    const totalColumns = this.config.columns.length
    let totalShowMobileColumns = 0
    for (const column of this.config.columns) {
      if (!column.hideOnMobile) {
        totalShowMobileColumns++
      }
    }
    this.dataTableService.setMobileColumnWidthMultiplier(
      totalColumns / totalShowMobileColumns
    )
    if (this.config.includeActionButtons) {
      this.dataTableService.setActionBtnStatus(true)
      this.dataTableService.setActionBtnColumnWidth(
        this.dataTableService.useMobileLayout() ? '30px' : '100px'
      )
    }

    this.paginateOpts = {
      itemsPerPage: this.config.pageSize,
      currentPage: this.config.startPage,
      totalItems: 0,
      id: this.tableId
    }

    this.config.columns.forEach((col) => {
      if (col.field.includes('.')) {
        const fields = col.field.split('.')
        const newField = `__${col.field}`
        this.pathFields.push({
          originalField: col.field,
          originalFieldPaths: fields,
          newField
        })
        this.originalFields.push(col.field)
        col.field = newField
      }
      if (col.altText && col.altText.includes('.')) {
        const altTextFields = col.altText.split('.')
        const newAltTextField = `__${col.altText}`
        this.altTextPathFields.push({
          originalField: col.altText,
          originalFieldPaths: altTextFields,
          newField: newAltTextField
        })
        col.altText = newAltTextField
      }
    })

    if (this.config.dataIsFromServer) {
      this.getPageItems(1, true)
    } else {
      this.populateTable(
        this.config.dataArray,
        this.config.dataArray.length,
        this.config.startPage
      )
      this.setCurrentPage(this.config.startPage)
      this.refreshTable()
    }
  }

  preTraverseNestedFields(item: any) {
    this.pathFields.forEach(
      (pField: {
        originalField: string
        originalFieldPaths: string[]
        newField: string
      }) => {
        let value = item

        for (const p of pField.originalFieldPaths) {
          value = value[p]
          if (!value) {
            // this part of the path is undefined, so get out or get a null ref error
            break
          }
        }
        item[pField.newField] = value
      }
    )

    this.altTextPathFields.forEach(
      (altField: {
        originalField: string
        originalFieldPaths: string[]
        newField: string
      }) => {
        if (!this.originalFields.includes(altField.originalField)) {
          let value = item
          for (const p of altField.originalFieldPaths) {
            value = value[p]
            if (!value) {
              break
            }
          }
          item[altField.newField] = value
        }
      }
    )
  }

  getPageItems(
    pageNumber,
    showLoadingSpinner = true,
    updatingIds?: string[],
    refreshAll = false
  ) {
    if (this.config.dataIsFromServer) {
      this.itemsResolved = false
      let sortFields: string[] = []
      if (this.sortField && this.sortDirection !== 'none') {
        if (this.sortDirection === 'asc') {
          sortFields.push(this.sortField)
        } else {
          sortFields.push(`-${this.sortField}`)
        }
      } else if (this.config.defaultSortFields) {
        sortFields = sortFields.concat(this.config.defaultSortFields)
      }
      sortFields = sortFields.map((field) => field.replace(/__/g, ''))
      if (!sortFields.length) {
        sortFields = undefined
      }
      const zeroIndexPageNumber = pageNumber - 1

      if (showLoadingSpinner) {
        this.loading = true
      }
      this.config
        .getPageFn(zeroIndexPageNumber, this, sortFields)
        .subscribe((pageData: { pageItems: any[]; totalCount: number }) => {
          this.populateTable(
            pageData.pageItems,
            pageData.totalCount,
            pageNumber
          )
          this.setCurrentPage(pageNumber)
          this.refreshTable(updatingIds, refreshAll)
          setTimeout(() => {
            this.loading = false
          }, 200)
        })
    } else {
      this.setCurrentPage(pageNumber)
      this.refreshTable()
    }
  }

  populateTable(rowData: any[], totalCount: number, pageNumber: number) {
    this.originalItems = _.cloneDeep(rowData)
    this.originalItems.forEach((item: any, idx: number) => {
      item.__originalIndex = idx
      this.preTraverseNestedFields(item)
    })
    this.paginateOpts.totalItems = totalCount
    this.totalItems = totalCount
    this.itemsResolved = true
  }

  setCurrentPage(pageNumber: number) {
    this.paginateOpts.currentPage = pageNumber
    this.setIndeces()
  }

  setIndeces() {
    const itemCount =
      this.paginateOpts.itemsPerPage > -1
        ? this.paginateOpts.itemsPerPage
        : this.totalItems
    this.firstIndex = itemCount * (this.paginateOpts.currentPage - 1) + 1
    this.lastIndex = Math.min(this.firstIndex + itemCount - 1, this.totalItems)
  }

  insertRow(newRowData: any) {
    newRowData.__originalIndex = this.originalItems.length
    this.preTraverseNestedFields(newRowData)
    this.originalItems.push(newRowData)

    const willSort = this.tableWillSort()

    if (willSort) {
      this.refreshTable()
    } else {
      this.renderedItems.push(_.cloneDeep(newRowData))
      this.buildIdIndexMap()
    }

    this.totalItems++
    this.setIndeces()
  }

  removeRow(rowToRemove: any) {
    const removalIndex = this.idIndexMap[rowToRemove[this.config.idField]]
    if (removalIndex === undefined) {
      return
    }

    const willSort = this.tableWillSort()

    this.originalItems.splice(removalIndex.original, 1)
    for (let i = removalIndex.original; i < this.originalItems.length; i++) {
      this.originalItems[i].__originalIndex -= 1
    }

    if (willSort) {
      this.refreshTable()
    } else {
      this.renderedItems.splice(removalIndex.current, 1)
      for (let i = removalIndex.current; i < this.renderedItems.length; i++) {
        this.renderedItems[i].__originalIndex -= 1
      }
      this.buildIdIndexMap()
    }

    this.totalItems--
    this.setIndeces()
  }

  updateRow(rowToUpdate: any) {
    const updateIndex = this.idIndexMap[rowToUpdate[this.config.idField]]
    if (updateIndex === undefined) {
      return
    }

    const willSort = this.tableWillSort()

    rowToUpdate.__originalIndex = updateIndex.original
    rowToUpdate.__updating = true
    this.preTraverseNestedFields(rowToUpdate)
    this.originalItems[updateIndex.original] = rowToUpdate

    if (willSort) {
      this.refreshTable()
    } else {
      this.renderedItems[updateIndex.current] = rowToUpdate
      this.buildIdIndexMap()
    }
  }

  buildIdIndexMap() {
    this.idIndexMap = {}
    this.renderedItems.forEach((item: any, idx: number) => {
      this.idIndexMap[item[this.config.idField]] = {
        current: idx,
        original: item.__originalIndex
      }
    })
  }

  sort(column: DataTableColumnConfig) {
    const currentSortField = this.sortField
    if (this.config.isSortable) {
      this.sortField = column.type === 'image' ? column.altText : column.field
      if (currentSortField === this.sortField) {
        switch (this.sortDirection) {
          case 'none':
            this.sortDirection = 'asc'
            break
          case 'asc':
            this.sortDirection = 'desc'
            break
          default:
            this.sortDirection = 'none'
            break
        }
      } else {
        this.sortDirection = 'asc'
      }
      this.config.columns.forEach((c) => (c.sort = 'none'))
      column.sort = this.sortDirection
      if (this.sortDirection !== 'none') {
        this.getPageItems(1)
      } else {
        this.getPageItems(this.paginateOpts.currentPage)
      }
    }
  }

  refresh(
    updatingIds?: string[],
    refreshAll = false,
    showLoadingSpinner = false
  ) {
    if (this.config.dataIsFromServer) {
      updatingIds = updatingIds || []
      this.getPageItems(
        this.paginateOpts.currentPage,
        showLoadingSpinner,
        updatingIds,
        refreshAll
      )
    } else {
      throw new Error(
        'refresh method is only available for tables that load data from server.' +
          'Use the insertRow, updateRow, or deleteRow method instead'
      )
    }
  }

  private tableWillSort() {
    return (
      (this.sortField && this.sortDirection !== 'none') ||
      this.config.defaultSortFields
    )
  }

  private refreshTable(updatingIds?: string[], refreshAll = false) {
    updatingIds = updatingIds || []
    let tempItems: any[] = _.cloneDeep(this.originalItems)

    // if data is local and needs sorting, sort it. Server data should be sorted on server
    if (!this.config.dataIsFromServer && this.tableWillSort()) {
      tempItems = tempItems.sort((a: any, b: any) => this.tableSort(a, b))
    }

    tempItems.forEach((item: any, position: number) => {
      if (this.renderedItems.length > position) {
        // only re render this row if the index is different (meaning something moved)
        if (
          refreshAll ||
          this.renderedItems[position][this.config.idField] !==
            item[this.config.idField] ||
          updatingIds.includes(item[this.config.idField]) ||
          item.__updating
        ) {
          this.renderedItems[position] = item

          if (item.__updating) {
            item.__updating = false
            // TODO call onRowUpdate function here
          } else {
            // TODO call onRowChangePosition here
          }
        }
      } else if (this.renderedItems.length === position) {
        this.renderedItems.push(item)
        // TODO call onRowAdded function
      } else {
        throw new Error('Invalid index')
      }
    })

    if (this.renderedItems.length > tempItems.length) {
      this.renderedItems.splice(tempItems.length)
    }
    this.buildIdIndexMap()
  }

  private tableSort(a: any, b: any) {
    if (this.sortField && this.sortDirection !== 'none') {
      return this.getSortValue(
        a,
        b,
        this.sortField,
        <'asc' | 'desc'>this.sortDirection
      )
    } else if (this.config.defaultSortFields) {
      for (let i = 0; i < this.config.defaultSortFields.length; i++) {
        let field = this.config.defaultSortFields[i]
        let direction: 'asc' | 'desc' = 'asc'
        if (field[0] === '-') {
          direction = 'desc'
          field = field.substr(1)
        }
        const sortVal = this.getSortValue(a, b, field, direction)
        if (sortVal !== 0) {
          return sortVal
        }
      }
    }
    return a.__originalIndex - b.__originalIndex
  }

  private getSortValue(
    a: any,
    b: any,
    field: string,
    direction: 'asc' | 'desc'
  ) {
    let aValue = a[field]
    let bValue = b[field]

    if (typeof aValue === 'string') {
      aValue = aValue.toLowerCase()
    }

    if (typeof bValue === 'string') {
      bValue = bValue.toLowerCase()
    }
    if (aValue > bValue) {
      return direction === 'asc' ? 1 : -1
    }

    if (aValue < bValue) {
      return direction === 'desc' ? 1 : -1
    }

    return a.__originalIndex - b.__originalIndex
  }
}
