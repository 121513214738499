export class ActionBtn {
  label = ''
  action = null
  btn = {
    color: '#0C6BA1',
    icon: 'visibility'
  }

  constructor(
    label: string,
    action: any,
    buttonColor: string,
    buttonIcon: string
  ) {
    this.label = label
    this.action = action
    this.btn = {
      color: buttonColor,
      icon: buttonIcon
    }
  }
}
