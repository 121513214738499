<div
  *ngIf="isVisible"
  class="vendor-denial-reason-container order-part"
  fxLayout="column"
  fxLayout.gt-sm="row"
>
  <app-container-widget
    [title]="'Vendor Paperwork Rejected - Reason:'"
    [headerBarColor]="'#b71c1c'"
    fxFlex="100%"
    fxFlex.gt-sm="30%"
  >
    <h3 class="reject-reason-text">
      <i>"{{ rejectReason }}"</i>
    </h3>
    <p class="rejector-text">- {{ rejector }}</p>
    <p class="reject-time-text">at {{ rejectTime | date: 'short' }}</p>
  </app-container-widget>
</div>
