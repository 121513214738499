import { mergeMap } from 'rxjs/operators'
// tslint:disable:no-access-missing-member
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms'
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker'
import { of } from 'rxjs'
import { CoreResponse } from '../../core/client/client-error-handler/error-handler.class'

import { LocationService } from '../../location/client/location.service'
import { CoreUserService } from '../../core-user/client/core-user.service'
import * as constants from '../../constants'
import { ModalDialogsService } from '../../core/client/modal-dialogs/modal-dialogs.service'
import { CustomFormValidators } from '../../core/client/custom-form-validators/custom-form-validators.class'
import { OrderBaseComponent } from './order-parts/order-base.class'
import { OrderDocumentsComponent } from './order-parts/order-documents.component'
import { OrderActionComponent } from './order-parts/order-action.component'

const GENERIC_VENDOR_NAME = '1 Service Calls'
const GENERIC_LOCATION_NAME = 'Service Calls'

@Component({
  selector: 'app-service-call-form',
  templateUrl: './service-call-form.component.html',
  styleUrls: ['./service-call-form.component.scss'],
  providers: []
})
export class ServiceCallComponent extends OrderBaseComponent implements OnInit {
  @Input() public docComponent: OrderDocumentsComponent
  @Input() public actionComponent: OrderActionComponent
  @Input() public modifyMode: boolean
  @Output() readyToUploadDocuments = new EventEmitter<void>()

  serviceCall: any = {
    wheels: [
      {
        position: null,
        remainingTread32nds: null,
        causeOfFailure: null,
        hasTreadRemaining: true
      }
    ]
  }

  serviceCallForm: SubmittableForm
  unitTypes: string[] = ['TRUCK', 'TRAILER', 'DOLLY']
  callTypes: { value: string; description: string }[] = [
    {
      value: 'YARD',
      description: "Call was performed on ACT's yard"
    },
    {
      value: 'BREAKDOWN',
      description: "Call was outside of ACT's yard"
    }
  ]

  datepickerOpts: IAngularMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  }

  responses: any = {}
  submitFormErr: string = null
  saving = false
  showUploadPORequestsLabel = true
  formEdited = false
  initialPageLoad = true
  orderDocuments: any[]
  inputDisabled = false
  dpCallDate: any
  fleets: any[] = []
  locations: any[] = []
  fleetLocations: any[] = []
  vendor: any

  wheelPositions = constants.wheelPositions
  causesOfFailure = constants.causesOfFailure
  usStates = constants.usStates
  treadAnswers = [
    { value: true, display: 'YES' },
    { value: false, display: 'NO' }
  ]

  constructor(
    private locationService: LocationService,
    private fb: FormBuilder
  ) {
    super()
  }

  ngOnInit() {
    super.ngOnInit()
    this.orderDocuments = this.docComponent.orderDocuments
    this.inputDisabled = this.modifyMode === false
  }

  setUserVisibility() {
    return true
  }

  setOrder(order: any) {
    super.setOrder(order)
    this.vendor = order.vendor || order.location.vendors[0]
    if (this.vendor.name === GENERIC_VENDOR_NAME && !this.order.vendorName) {
      this.order.vendorName = null
    } else {
      this.order.vendorName = this.order.vendorName || this.vendor.name
    }
    if (this.order.callType) {
      this.showUploadPORequestsLabel = false
    }
    if (this.initialPageLoad) {
      this.getFleetsAndLocations().subscribe(() => {
        this.buildServiceCallForm()
        this.populateServiceCallForm()
        this.addPreStateChangeFunction()
      })
      this.initialPageLoad = false
    }
  }

  getFleetsAndLocations() {
    this.locations = []
    this.fleets = []
    return this.locationService.getServiceCallLocations().pipe(
      mergeMap((serviceCallLocations) => {
        this.locations = serviceCallLocations
        this.locations.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          }

          if (b.name > a.name) {
            return -1
          }

          return 0
        })
        const visitedFleets = []
        this.locations.forEach((l) => {
          if (!visitedFleets.includes(l.fleet._id || l.fleet)) {
            this.fleets.push(l.fleet)
            visitedFleets.push(l.fleet._id || l.fleet)
          }
        })

        return of(null)
      })
    )
  }

  preUpdate() {
    return false
  }

  handleUpdateComplete() {
    this.docComponent.isVisible = true
    this.actionComponent.isVisible = true
    this.order.readyForDocuments = true
    this.addPreStateChangeFunction()
  }

  buildServiceCallForm() {
    const genericLocation = this.locations.find(
      (l) => l.name === GENERIC_LOCATION_NAME
    )
    const locationPattern = genericLocation
      ? `^((?!^${genericLocation._id}$).)*$`
      : null
    const todayDate: Date = new Date()
    const year = todayDate.getFullYear()
    const month = todayDate.getMonth() + 1
    const day = todayDate.getDate()
    const dateModel: IMyDateModel = {
      isRange: false,
      singleDate: { date: { year, month, day } },
      dateRange: null
    }

    // create all of the static form controls
    this.serviceCallForm = new SubmittableForm({
      vendorName: this.fb.control({
        value: null,
        disabled: this.vendor.name !== GENERIC_VENDOR_NAME
      }),
      callType: this.fb.control(null),
      fleet: this.fb.control(null),
      location: this.fb.control(
        null,
        locationPattern ? Validators.pattern(locationPattern) : null
      ),
      stmReferenceNumber: this.fb.control(null),
      callDate: this.fb.control(dateModel),
      unitNumber: this.fb.control(null),
      unitType: this.fb.control(null),
      licenseNumber: this.fb.control(null),
      licenseState: this.fb.control(null),
      serviceTechName: this.fb.control(null),
      breakdownInfo: this.fb.group({
        breakdownLocation: this.fb.group({
          addressOrMarker: this.fb.control(null),
          city: this.fb.control(null),
          state: this.fb.control(null)
        }),
        driverName: this.fb.control(null),
        driverId: this.fb.control(null)
      }),
      wheels: new WheelRepairCtrlArray(this.responses, [new WheelRepairCtrl()])
    })

    this.serviceCallForm.valueChanges.subscribe(() => {
      this.formEdited = true
    })

    if (this.modifyMode === false) {
      this.serviceCallForm.disable()
    }
  }

  populateServiceCallForm() {
    if (!this.order) {
      return
    }

    this.vendorNameCtrl.setValue(this.order.vendorName || null)
    this.callTypeCtrl.setValue(this.order.callType || null)
    this.fleetCtrl.setValue(
      this.order.location.fleet._id || this.order.location.fleet || null
    )
    this.locationCtrl.setValue(
      this.order.location._id || this.order.location || null
    )
    this.stmReferenceNumberCtrl.setValue(this.order.stmReferenceNumber || null)
    if (this.order.callDate) {
      const dateString = this.order.callDate.toString()
      const year = Number(dateString.substr(0, 4))
      const month = Number(dateString.substr(4, 2))
      const day = Number(dateString.substr(6, 2))

      const dateModel: IMyDateModel = {
        isRange: false,
        singleDate: { date: { year, month, day } },
        dateRange: null
      }
      this.callDateCtrl.patchValue(dateModel)
    }
    this.unitNumberCtrl.setValue(this.order.unitNumber || null)
    this.unitTypeCtrl.setValue(this.order.unitType || null)
    this.licenseNumberCtrl.setValue(this.order.licenseNumber || null)
    this.licenseStateCtrl.setValue(this.order.licenseState || null)
    this.serviceTechNameCtrl.setValue(this.order.serviceTechName || null)

    if (this.order.breakdownInfo) {
      this.breakdownDriverNameCtrl.setValue(
        this.order.breakdownInfo.driverName || null
      )
      this.breakdownDriverIdCtrl.setValue(
        this.order.breakdownInfo.driverId || null
      )

      if (this.order.breakdownInfo.breakdownLocation) {
        this.breakdownAddressCtrl.setValue(
          this.order.breakdownInfo.breakdownLocation.addressOrMarker || null
        )
        this.breakdownCityCtrl.setValue(
          this.order.breakdownInfo.breakdownLocation.city || null
        )
        this.breakdownStateCtrl.setValue(
          this.order.breakdownInfo.breakdownLocation.state || null
        )
      }
    }

    if (this.order.wheels && this.order.wheels.length) {
      this.wheelCtrlArray.clearControls()
      this.order.wheels.forEach((c) => {
        const ctrl = new WheelRepairCtrl(c)
        this.wheelCtrlArray.push(ctrl)
      })
    }

    if (this.modifyMode === false) {
      this.serviceCallForm.disable()
    }

    this.fleetChanged()
  }

  populateOrderFromForm() {
    this.order.vendorName = this.vendorNameCtrl.value
    this.order.callType = this.callTypeCtrl.value
    this.order.location =
      this.locations.find((l) => l._id === this.locationCtrl.value) || null
    this.order.stmReferenceNumber = this.stmReferenceNumberCtrl.value
    const dVal = this.callDateCtrl.value
    this.order.callDate =
      dVal && dVal.singleDate && dVal.singleDate.date
        ? dVal.singleDate.date.year * 10000 +
          dVal.singleDate.date.month * 100 +
          dVal.singleDate.date.day
        : null
    this.order.unitNumber = this.unitNumberCtrl.value
    this.order.unitType = this.unitTypeCtrl.value
    this.order.licenseNumber = this.licenseNumberCtrl.value
    this.order.licenseState = this.licenseStateCtrl.value
    this.order.serviceTechName = this.serviceTechNameCtrl.value
    if (this.breakdownInfoCtrl.value) {
      this.order.breakdownInfo = this.breakdownInfoCtrl.value
    }
    if (this.wheelCtrlArray.value) {
      this.order.wheels = this.wheelCtrlArray.value
    }
  }

  get vendorNameCtrl() {
    return this.serviceCallForm.get('vendorName')
  }

  get callTypeCtrl() {
    return this.serviceCallForm.get('callType')
  }

  get fleetCtrl() {
    return this.serviceCallForm.get('fleet')
  }

  get locationCtrl() {
    return this.serviceCallForm.get('location')
  }

  get stmReferenceNumberCtrl() {
    return this.serviceCallForm.get('stmReferenceNumber')
  }

  get callDateCtrl() {
    return this.serviceCallForm.get('callDate')
  }

  get unitNumberCtrl() {
    return this.serviceCallForm.get('unitNumber')
  }

  get unitTypeCtrl() {
    return this.serviceCallForm.get('unitType')
  }

  get licenseNumberCtrl() {
    return this.serviceCallForm.get('licenseNumber')
  }

  get licenseStateCtrl() {
    return this.serviceCallForm.get('licenseState')
  }

  get serviceTechNameCtrl() {
    return this.serviceCallForm.get('serviceTechName')
  }

  get breakdownInfoCtrl() {
    return this.serviceCallForm.get('breakdownInfo')
  }

  get breakdownLocationCtrl() {
    return this.serviceCallForm.get('breakdownInfo').get('breakdownLocation')
  }

  get breakdownAddressCtrl() {
    return this.serviceCallForm
      .get('breakdownInfo')
      .get('breakdownLocation')
      .get('addressOrMarker')
  }

  get breakdownCityCtrl() {
    return this.serviceCallForm
      .get('breakdownInfo')
      .get('breakdownLocation')
      .get('city')
  }

  get breakdownStateCtrl() {
    return this.serviceCallForm
      .get('breakdownInfo')
      .get('breakdownLocation')
      .get('state')
  }

  get breakdownDriverNameCtrl() {
    return this.serviceCallForm.get('breakdownInfo').get('driverName')
  }

  get breakdownDriverIdCtrl() {
    return this.serviceCallForm.get('breakdownInfo').get('driverId')
  }

  get wheelCtrlArray() {
    return this.serviceCallForm.get('wheels') as WheelRepairCtrlArray
  }

  get callTypeErrs() {
    return (this.responses && this.responses.callType) ||
      (this.callTypeCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.callTypeCtrl.errors
  }

  get fleetErrs() {
    return (this.responses && this.responses.fleet) ||
      (this.fleetCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.fleetCtrl.errors
  }

  get locationErrs() {
    return (this.responses && this.responses.location) ||
      (this.locationCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.locationCtrl.errors
  }

  get stmReferenceNumberErrs() {
    return (this.responses && this.responses.stmReferenceNumber) ||
      (this.stmReferenceNumberCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.stmReferenceNumberCtrl.errors
  }

  get callDateErrs() {
    return (this.responses && this.responses.callDate) ||
      (this.callDateCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.callDateCtrl.errors
  }

  get unitNumberErrs() {
    return (this.responses && this.responses.unitNumber) ||
      (this.unitNumberCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.unitNumberCtrl.errors
  }

  get unitTypeErrs() {
    return (this.responses && this.responses.unitType) ||
      (this.unitTypeCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.unitTypeCtrl.errors
  }

  get licenseNumberErrs() {
    return (this.responses && this.responses.licenseNumber) ||
      (this.licenseNumberCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.licenseNumberCtrl.errors
  }

  get licenseStateErrs() {
    return (this.responses && this.responses.licenseState) ||
      (this.licenseStateCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.licenseStateCtrl.errors
  }

  get serviceTechNameErrs() {
    return (this.responses && this.responses.serviceTechName) ||
      (this.serviceTechNameCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.serviceTechNameCtrl.errors
  }

  get vendorNameErrs() {
    return (this.responses && this.responses.vendorName) ||
      (this.vendorNameCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.vendorNameCtrl.errors
  }

  get breakdownInfoErrs() {
    return (this.responses && this.responses.breakdownInfo) ||
      (this.breakdownInfoCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownInfoCtrl.errors
  }

  get breakdownLocationErrs() {
    return (this.responses &&
      this.responses.breakdownInfo &&
      this.responses.breakdownInfo.breakdownLocation) ||
      (this.breakdownLocationCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownLocationCtrl.errors
  }

  get breakdownAddressErrs() {
    const hasAddressResponse =
      this.responses &&
      this.responses.breakdownInfo &&
      this.responses.breakdownInfo.breakdownLocation &&
      this.responses.breakdownInfo.breakdownLocation.addressOrMarker

    return hasAddressResponse ||
      (this.breakdownAddressCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownAddressCtrl.errors
  }

  get breakdownCityErrs() {
    const hasCityResponse =
      this.responses &&
      this.responses.breakdownInfo &&
      this.responses.breakdownInfo.breakdownLocation &&
      this.responses.breakdownInfo.breakdownLocation.city

    return hasCityResponse ||
      (this.breakdownCityCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownCityCtrl.errors
  }

  get breakdownStateErrs() {
    const hasStateResponse =
      this.responses &&
      this.responses.breakdownInfo &&
      this.responses.breakdownInfo.breakdownLocation &&
      this.responses.breakdownInfo.breakdownLocation.state

    return hasStateResponse ||
      (this.breakdownStateCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownStateCtrl.errors
  }

  get breakdownDriverNameErrs() {
    return (this.responses &&
      this.responses.breakdownInfo &&
      this.responses.breakdownInfo.driverName) ||
      (this.breakdownDriverNameCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownDriverNameCtrl.errors
  }

  get breakdownDriverIdErrs() {
    return (this.responses &&
      this.responses.breakdownInfo &&
      this.responses.breakdownInfo.driverId) ||
      (this.breakdownDriverIdCtrl.untouched &&
        this.serviceCallForm.submitAttempted === false)
      ? null
      : this.breakdownDriverIdCtrl.errors
  }

  handleResponse(response: CoreResponse): boolean {
    if (response.offendingKey) {
      switch (response.offendingKey) {
        case 'vendorName':
        case 'callType':
        case 'location':
        case 'callDate':
        case 'stmReferenceNumber':
        case 'unitNumber':
        case 'unitType':
        case 'licenseNumber':
        case 'licenseState':
        case 'serviceTechName':
        case 'breakdownInfo':
        case 'breakdownInfo.breakdownLocation':
        case 'breakdownInfo.breakdownLocation.addressOrMarker':
        case 'breakdownInfo.breakdownLocation.city':
        case 'breakdownInfo.breakdownLocation.state':
        case 'breakdownInfo.driverName':
        case 'breakdownInfo.driverId':
        case 'wheels':
          this.responses[response.offendingKey] = response.message
          return true
        default:
          if (response.offendingKey.includes('wheels.')) {
            const offKey = response.offendingKey
            const badIndexPosition = offKey.indexOf('.') + 1
            const badKeyPosition = offKey.indexOf('.', badIndexPosition) + 1
            const badIndex = offKey.substring(
              badIndexPosition,
              badKeyPosition - 1
            )
            const badKey = offKey.substring(badKeyPosition)
            this.responses.wheels = this.responses.wheels || {}
            this.responses.wheels[badIndex] =
              this.responses.wheels[badIndex] || {}
            this.responses.wheels[badIndex][badKey] = response.message
            return true
          }
          return false
      }
    } else {
      switch (response.type) {
        case 'saveSuccess':
        case 'updateSuccess':
        case 'deleteSuccess':
          this.responses[response.type] = response.message
          return true
        default:
          return false
      }
    }
  }

  addWheelPosition() {
    this.wheelCtrlArray.push(new WheelRepairCtrl())
    if (this.modifyMode === false) {
      this.serviceCallForm.disable()
    }
  }

  removeWheelPosition(index: number) {
    this.wheelCtrlArray.removeAt(index)
    if (this.responses && this.responses.wheels) {
      this.responses.wheels = null
    }
  }

  callTypeChanged() {
    if (this.serviceCall.callType === 'BREAKDOWN') {
      this.serviceCall.breakdownInfo = { breakdownLocation: {} }
    } else {
      this.serviceCall.breakdownInfo = null
    }
  }

  vendorNameChanged() {
    this.order.vendorName = this.vendorNameCtrl.value
  }

  treadDepthChanged(index: number) {
    const wheel = this.wheelCtrlArray.at(index).value

    if (wheel.remainingTread32nds > 32) {
      wheel.remainingTread32nds = 32
    }

    if (
      wheel.remainingTread32nds < 0 ||
      isNaN(Number(wheel.remainingTread32nds))
    ) {
      wheel.remainingTread32nds = 0
    }
  }

  fleetChanged() {
    const selectedFleetId = this.fleetCtrl.value
    this.fleetLocations = this.locations.filter(
      (l) => (l.fleet._id || l.fleet) === selectedFleetId
    )
  }

  saveFormAndShowDocumentUpload() {
    this.saving = true
    this.submitFormErr = null
    this.serviceCallForm.submitAttempted = true
    this.wheelCtrlArray.controls.forEach(
      (c: WheelRepairCtrl) => (c.isNewControl = false)
    )

    if (!this.serviceCallForm.invalid) {
      this.showUploadPORequestsLabel = false
      // this.populateOrderFromForm();
      this.docComponent.isVisible = true
      this.actionComponent.isVisible = true
      this.formEdited = false
    }

    this.saving = false
  }

  private addPreStateChangeFunction() {
    const fnName = 'checkServiceCallFormValidity'
    // TODO: populate order before trying to change states, figure out a better way
    this.order.preStateChangeFns = this.order.preStateChangeFns || []
    for (let i = this.order.preStateChangeFns.length - 1; i >= 0; i--) {
      if (this.order.preStateChangeFns[i].name === fnName) {
        this.order.preStateChangeFns.splice(i, 1)
        break
      }
    }
    this.order.preStateChangeFns.push({
      name: 'checkServiceCallFormValidity',
      fn: () => {
        this.saveFormAndShowDocumentUpload()
        if (!this.serviceCallForm.invalid) {
          this.populateOrderFromForm()
          return true
        }

        return false
      }
    })
    this.initialPageLoad = false
  }
}

/********************************************
HELPER CLASSES
**********************************************/
class WheelRepairCtrl extends FormGroup {
  responses: any
  index: number
  isNewControl: boolean
  parentForm: SubmittableForm

  constructor(control: { [key: string]: AbstractControl } = null) {
    const fb = new FormBuilder()
    let theControl: { [key: string]: any }
    const validators = [
      Validators.min(0),
      Validators.max(32),
      Validators.required
    ]
    if (!control) {
      theControl = {
        position: fb.control(null),
        causeOfFailure: fb.control(null),
        remainingTread32nds: fb.control(null, validators),
        hasTreadRemaining: fb.control(true)
      }
    } else {
      theControl = {}
      // these if statements allow a plain object or an object with form controls to be passed in
      if (control.position) {
        theControl.position =
          typeof control.position === 'object'
            ? control.position
            : fb.control(control.position)
      } else {
        theControl.position = fb.control(null)
      }

      if (control.causeOfFailure) {
        theControl.causeOfFailure =
          typeof control.causeOfFailure === 'object'
            ? control.causeOfFailure
            : fb.control(control.causeOfFailure)
      } else {
        theControl.causeOfFailure = fb.control(null)
      }

      if (
        control.remainingTread32nds !== null &&
        control.remainingTread32nds !== undefined
      ) {
        theControl.remainingTread32nds =
          typeof control.remainingTread32nds === 'object'
            ? control.remainingTread32nds
            : fb.control(control.remainingTread32nds)
      } else {
        theControl.remainingTread32nds = fb.control(null)
      }

      theControl.remainingTread32nds.setValidators(validators)

      if (
        control.hasTreadRemaining !== null &&
        control.hasTreadRemaining !== undefined
      ) {
        theControl.hasTreadRemaining =
          typeof control.hasTreadRemaining === 'object'
            ? control.hasTreadRemaining
            : fb.control(control.hasTreadRemaining)
      } else {
        theControl.hasTreadRemaining = fb.control(null)
      }
    }

    super(theControl)
    this.isNewControl = true
    this.parentForm = <SubmittableForm>this.root
  }

  get positionCtrl() {
    return this.get('position')
  }

  get causeOfFailureCtrl() {
    return this.get('causeOfFailure')
  }

  get remainingTreadDepthCtrl() {
    return this.get('remainingTread32nds')
  }

  get hasTreadRemainingCtrl() {
    return this.get('hasTreadRemaining')
  }

  get positionErrs() {
    const hasPositionResponse =
      this.responses &&
      this.responses.wheels &&
      this.responses.wheels[this.index] &&
      this.responses.wheels[this.index].position

    return hasPositionResponse ||
      (this.positionCtrl.untouched &&
        (this.isNewControl || this.parentForm.submitAttempted === false))
      ? null
      : this.positionCtrl.errors
  }

  get causeOfFailureErrs() {
    const hasCauseResponse =
      this.responses &&
      this.responses.wheels &&
      this.responses.wheels[this.index] &&
      this.responses.wheels[this.index].causeOfFailure

    return hasCauseResponse ||
      (this.causeOfFailureCtrl.untouched &&
        (this.isNewControl || this.parentForm.submitAttempted === false))
      ? null
      : this.causeOfFailureCtrl.errors
  }

  get remainingTread32ndsErrs() {
    const hasRemainingTreadDepthResponse =
      this.responses &&
      this.responses.wheels &&
      this.responses.wheels[this.index] &&
      this.responses.wheels[this.index].position

    return hasRemainingTreadDepthResponse ||
      (this.remainingTreadDepthCtrl.untouched &&
        (this.isNewControl || this.parentForm.submitAttempted === false))
      ? null
      : this.remainingTreadDepthCtrl.errors
  }

  get hasTreadRemainingErrs() {
    const hasTreadResponse =
      this.responses &&
      this.responses.wheels &&
      this.responses.wheels[this.index] &&
      this.responses.wheels[this.index].hasRemainingTread

    return hasTreadResponse ||
      (this.hasTreadRemainingCtrl.untouched &&
        (this.isNewControl || this.parentForm.submitAttempted === false))
      ? null
      : this.hasTreadRemainingCtrl.errors
  }
}

class WheelRepairCtrlArray extends FormArray {
  responses: any
  controls: WheelRepairCtrl[]
  parentForm: SubmittableForm

  constructor(responses: any, controls: WheelRepairCtrl[]) {
    controls.forEach((ctrl) => (ctrl.responses = responses))
    super(controls, CustomFormValidators.arrayLength(1))
    this.responses = responses
    this.parentForm = <SubmittableForm>this.root
  }

  push(ctrl: WheelRepairCtrl) {
    ctrl.index = this.length
    ctrl.responses = this.responses
    super.push(ctrl)
  }

  insert(index: number, ctrl: WheelRepairCtrl) {
    for (let i = index; i < this.controls.length; i++) {
      this.controls[i].index++
    }
    ctrl.index = index
    ctrl.responses = this.responses
    super.insert(index, ctrl)
  }

  setControl(index: number, ctrl: WheelRepairCtrl) {
    ctrl.index = index
    ctrl.responses = this.responses
    super.setControl(index, ctrl)
  }

  removeAt(index: number) {
    for (let i = index; i < this.controls.length; i++) {
      this.controls[i].index--
    }

    super.removeAt(index)
  }

  clearControls() {
    while (this.length !== 0) {
      this.removeAt(0)
    }
  }
}

class SubmittableForm extends FormGroup {
  submitAttempted = false
}
