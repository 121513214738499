// add https://github.com/jkuri/ngx-uploader
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import { UploadInput, UploadOutput, UploaderOptions } from 'ngx-uploader'

@Component({
  selector: 'app-file-uploader',
  template: ` <div class="file-uploader">
    <input
      type="file"
      ngFileSelect
      [options]="uploadOptions"
      (uploadOutput)="handleUpload($event)"
      [uploadInput]="uploadInput"
    />
    <br />
  </div>`,
  styleUrls: ['file-uploader.component.scss']
})
export class FileUploaderComponent implements OnChanges {
  @Input() attachTo: String
  @Input() identity: String
  @Input() description: String
  @Input() autoUpload = true

  @Output() fileUploaded: EventEmitter<string> = new EventEmitter<string>()

  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>()
  uploadOptions: UploaderOptions
  uploadInputEvent: UploadInput
  previewData: any
  errorMessage: string
  response: any

  constructor() {
    this.uploadOptions = {
      maxUploads: 25,
      allowedContentTypes: ['image/jpg', 'image/png', 'image/jpeg'],
      concurrency: 1
    }

    this.uploadInputEvent = {
      url: '/documents/',
      fieldName: 'upload',
      method: 'POST',
      data: {},
      type: 'uploadAll'
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const key in changes) {
      if (changes.hasOwnProperty(key)) {
        switch (key) {
          case 'attachTo':
            this.uploadInputEvent.data.attachTo = changes[key].currentValue
            break
          case 'identity':
            this.uploadInputEvent.data.identity = changes[key].currentValue
            break
          case 'description':
            this.uploadInputEvent.data.description = changes[key].currentValue
            break
        }
      }
    }
  }

  handleUpload(output: UploadOutput) {
    switch (output.type) {
      case 'allAddedToQueue':
        if (this.autoUpload) {
          this.uploadInput.emit(this.uploadInputEvent)
        }
        break
      case 'rejected':
        alert(
          'There was an error uploading the file. Make sure it is a .jpg or .png file.'
        )
        break
      case 'done':
        if (
          output.file &&
          output.file.response &&
          output.file.response.downloadUrl
        ) {
          this.fileUploaded.emit(output.file.response.downloadUrl)
        } else {
          alert(
            'An unkown error occurred while uploading the file. Try again later.'
          )
        }
        break
    }
  }
}
