import { MatDialogRef } from '@angular/material/dialog'
import { Component } from '@angular/core'

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h4 class="dialog-title">{{ title }}</h4>
    <p>{{ message }}</p>
    <button type="button" mat-raised-button (click)="dialogRef.close(true)">
      {{ trueButtonText }}
    </button>
    <button type="button" mat-button (click)="dialogRef.close()">
      {{ falseButtonText }}
    </button>
  `,
  styles: []
})
export class ConfirmDialogComponent {
  title: string
  message: string
  trueButtonText: string
  falseButtonText: string

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}
