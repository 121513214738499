// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-vendor-denial',
  template: `
    <div
      *ngIf="isVisible"
      class="vendor-denial-reason-container order-part"
      fxLayout="column"
      fxLayout.gt-sm="row"
    >
      <app-container-widget
        [title]="'Reason for Vendor Denial'"
        [headerBarColor]="'#b71c1c'"
        fxFlex="100%"
        fxFlex.gt-sm="30%"
      >
        <h3 class="reject-reason-text">
          <i>"{{ rejectReason }}"</i>
        </h3>
        <p class="rejector-text">
          - {{ rejector }} <strong>{{ vendorName }}</strong>
        </p>
        <p class="reject-time-text">at {{ rejectTime | date: 'short' }}</p>
      </app-container-widget>
      <app-container-widget [title]="'Notes'" fxFlex="100%" fxFlex.gt-sm="70%">
        <textarea
          class="stm-denial-notes-input"
          [(ngModel)]="stmDenialNotes"
          (blur)="updateDenialNotes()"
        ></textarea>
      </app-container-widget>
    </div>
  `,
  styleUrls: [
    '../order.component.scss',
    './order-vendor-denial.component.scss'
  ],
  providers: []
})
export class OrderVendorDenialComponent
  extends OrderBaseComponent
  implements OnInit
{
  isVendorRejected = false
  rejectReason: string
  rejector: string
  rejectTime: Date
  vendorName: string
  stmDenialNotes: string

  constructor() {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      !this.order.isNoOrderNoPickup &&
      !this.order.isNoOrderWithPickup &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes('vendor-denial')) &&
      this.isVendorRejected &&
      (this.coreUser.accountType === 'Stm' ||
        this.coreUser.accountType === 'Vendor')
  }

  setOrder(order) {
    super.setOrder(order)

    const history = (this.order && this.order.stateHistory) || []
    history.forEach((current) => {
      if (current.stateDescription === 'Deny') {
        this.isVendorRejected = true
        this.rejectReason =
          current.description && current.description.length
            ? current.description[0]
            : 'Unknown Reason'
        this.rejector =
          current.user && current.user.displayName
            ? current.user.displayName
            : 'Unknown User'
        this.vendorName =
          current.user && current.user.account
            ? current.user.account.name
            : 'Unknown Vendor'
        this.rejectTime = current.date
        this.stmDenialNotes = current.notes

        // break out of the loop
        return false
      }
    })

    this.setUserVisibility()
  }

  updateDenialNotes() {
    const history = (this.order && this.order.stateHistory) || []
    history.forEach((current, index) => {
      if (current.stateDescription === 'Deny') {
        current.notes = this.stmDenialNotes
        this.order.modifiedPath = `vendorActions.${index}.notes`
        this.update()

        // break out of the loop
        return false
      }
    })
  }
}
