import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'
import { DashboardComponent } from './dashboard.component'

const dashboardRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivate: [LoginGuard],
    children: [{ path: 'dashboard', component: DashboardComponent }]
  }
]

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
