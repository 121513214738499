import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { CoreUserComponent } from './core-user.component'
import { LoginComponent } from './login.component'

import { CoreUserProfileComponent } from './core-user-profile.component'

const userRoutes: Routes = [
  {
    path: 'userPath',
    component: CoreLayoutComponent,
    children: [{ path: 'user', component: CoreUserComponent }]
  },
  {
    path: 'user',
    component: CoreLayoutComponent,
    children: [{ path: 'profile', component: CoreUserProfileComponent }]
  },
  { path: 'login', component: LoginComponent }
]

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule]
})
export class CoreUserRoutingModule {}
