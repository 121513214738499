import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'

// "app" modules

// Services & Guards
import { MaterialComponentsModule } from '../../material-components.module'
import { CoreUserService } from './core-user.service'
import { LoginGuard } from './login.guard'

// Routers
import { CoreUserRoutingModule } from './core-user-routing.module'

// Components
import { LoginComponent } from './login.component'
import { CoreUserComponent } from './core-user.component'
import { CoreUserProfileComponent } from './core-user-profile.component'
import { AdminGuard } from './admin.guard'

@NgModule({
  declarations: [CoreUserComponent, LoginComponent, CoreUserProfileComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CoreUserRoutingModule,
    MaterialComponentsModule
  ],
  entryComponents: [],
  providers: [CoreUserService, LoginGuard, AdminGuard],
  exports: [CoreUserComponent]
})
export class CoreUserModule {}
