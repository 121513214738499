import { FormControl, ValidatorFn, Validators } from '@angular/forms'

/**
 * Whole number validator, requires number to be an integer. Allows empty values
 */
export const WHOLE_NUMBER_VALIDATOR_FN: ValidatorFn = (
  control: FormControl
) => {
  if (!control.value) {
    return null
  }

  const strValue = control.value.toString()
  const dotIndex = strValue.indexOf('.')
  if (
    dotIndex > -1 &&
    Number(strValue.substring(0, dotIndex)) !== control.value
  ) {
    return {
      wholeNumber: {
        valid: false
      }
    }
  }

  return null
}
