<div *ngIf="isVisible" class="order-part-column-container order-items">
  <div class="card-container">
    <h3 *ngIf="order?.orderType !== 'FleetCheck'" class="shopping-cart-header">
      Shopping Cart
      <span *ngIf="showInventoryInfo" class="font-bold text-gray text-xs"
        >(Inventory levels as of yesterday)</span
      >
    </h3>
    <span *ngIf="showInventoryInfo" class="italic text-xs text-gray">
      Be sure to check inventory when red
    </span>
    <div
      class="list-header-container"
      [ngClass]="{ rushHeader: order?.rushRequested }"
    >
      <div class="pic-column" *ngIf="order?.orderType !== 'FleetCheck'">
        Image
      </div>
      <div class="item-column" *ngIf="order?.orderType !== 'FleetCheck'">
        Product
      </div>
      <div class="add-inst-column" *ngIf="order?.orderType !== 'FleetCheck'">
        Description
      </div>
      <div class="qty-column" *ngIf="order?.orderType !== 'FleetCheck'">
        Quantity
      </div>
      <div
        class="delete-column"
        *ngIf="!inputDisabled && order?.orderType !== 'FleetCheck'"
      >
        Delete
      </div>
      <div *ngIf="order?.orderType === 'FleetCheck'">&nbsp;</div>
    </div>

    <!--Regular items -->
    <!--<div *ngIf="regularOrdersExist">-->
    <div *ngIf="order?.orderType !== 'FleetCheck'">
      <div
        *ngFor="let manufacturer of order?.regularItems?.manufacturers"
        class="list-container-full"
      >
        <div *ngFor="let item of manufacturer.items" class="list-container">
          <div class="pic-column">
            <img [src]="item?.tire?.image" style="height: 50px" />
          </div>
          <div class="item-column item-data">
            <span>
              {{ item?.tire?.item }}
              <span
                *ngIf="showInventoryInfo"
                [class]="
                  getStockInfoClasses(item?.qty, getItemQuantity(item?.tire))
                "
              >
                {{ formatStockInfo(item?.tire) }}
              </span>
            </span>
            <span *ngIf="item?.wheel">
              {{ item?.wheel?.item }}
              <span
                *ngIf="showInventoryInfo"
                [class]="
                  getStockInfoClasses(item?.qty, getItemQuantity(item?.wheel))
                "
              >
                {{ formatStockInfo(item?.wheel) }}
              </span>
            </span>
          </div>
          <div class="add-inst-column">{{ item?.tire?.description }}</div>
          <div class="qty-column">
            <p *ngIf="inputDisabled" style="font-size: 24px; font-weight: bold">
              {{ item.qty }}
            </p>
            <input
              *ngIf="!inputDisabled"
              type="number"
              min="1"
              style="
                font-size: 24px;
                width: auto;
                max-width: 50px;
                max-height: 30px;
              "
              [(ngModel)]="item.qty"
              (change)="quantityChange(item)"
            />
          </div>
          <div *ngIf="!inputDisabled" class="delete-column">
            <button
              mat-mini-fab
              (click)="deleteRegularProduct(manufacturer, item)"
            >
              <mat-icon class="mat-24">add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Forklift items -->
    <!--<div *ngIf="forkliftOrdersExist">-->
    <div *ngIf="order?.orderType !== 'FleetCheck'">
      <div
        *ngFor="let forklift of order?.forkliftItems"
        class="list-container-full"
      >
        <div *ngFor="let item of forklift?.items" class="list-container">
          <div class="pic-column">
            <img [src]="item?.tire?.image" style="height: 50px" />
          </div>
          <div class="item-column">{{ item?.tire?.description }}</div>
          <div class="desc-column">NA</div>
          <div class="add-inst-column">
            Forklift #: {{ forklift.forkliftNumber }}
          </div>
          <div class="qty-column">
            <p *ngIf="inputDisabled" style="font-size: 24px; font-weight: bold">
              {{ item.qty }}
            </p>
            <input
              *ngIf="!inputDisabled"
              type="number"
              min="1"
              max="2"
              style="
                font-size: 24px;
                width: auto;
                max-width: 50px;
                max-height: 30px;
              "
              [(ngModel)]="item.qty"
              (change)="quantityChange(item, forklift.forkliftNumber)"
            />
          </div>
          <div *ngIf="!inputDisabled" class="delete-column">
            <button
              mat-mini-fab
              (click)="deleteForkliftProduct(forklift, item)"
            >
              <mat-icon class="mat-24">add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="order?.noOrderText" class="no-order-text">
      {{ order?.noOrderText }}
    </div>
    <div *ngIf="bannerText" class="no-order-text">
      {{ bannerText.line1 || bannerText }}<br />
      {{ bannerText.line2 }}
    </div>
    <div
      *ngIf="
        !order?.noOrderText &&
        !bannerText &&
        !order?.forkliftItems?.length &&
        !order?.regularItems.manufacturers?.length
      "
    >
      <h4>You have no items in your cart.</h4>
    </div>
  </div>
</div>
