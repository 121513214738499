import * as moment from 'moment'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'momentFormatPipe'
})
export class MomentFormatPipe implements PipeTransform {
  public transform(dateVal, formatStr: string) {
    if (!dateVal) {
      return 'Unknown Date'
    }

    const momentDate = moment(dateVal)
    if (!momentDate.isValid()) {
      return 'Invalid Date'
    } else {
      return momentDate.format(formatStr)
    }
  }
}
