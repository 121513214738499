<div
  *ngIf="
    isVisible &&
    ((order?.nextStates && order?.nextStates?.length) ||
      (clientPseudoStates && clientPseudoStates.length))
  "
  class="order-action-component"
>
  <div *ngIf="orderChangedBySomeoneElse">
    <p class="order-changed-msg">Order has changed, please reload.</p>
    <div (click)="reloadOrder()" class="action-button blue-btn">
      <div class="app-order-action">
        <span>Reload Order</span>
      </div>
    </div>
  </div>
  <div *ngIf="orderChangedBySomeoneElse === false">
    <ng-container *ngFor="let nextState of order?.nextStates">
      <div
        *ngIf="!nextState.hidden"
        (click)="changeState(nextState)"
        class="action-button"
        [ngClass]="[nextState.buttonClass || 'blue-btn']"
      >
        <div class="app-order-action">
          <span>{{
            nextState.orderActionLabelOverride ||
              nextState.state.orderActionLabel
          }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="
      clientPseudoStates &&
      clientPseudoStates.length &&
      orderChangedBySomeoneElse === false
    "
  >
    <div
      *ngFor="let pseudoState of clientPseudoStates"
      (click)="changeState(pseudoState)"
      class="action-button"
      [ngClass]="[pseudoState.buttonClass || 'blue-btn']"
    >
      <div class="app-order-action">
        <span>{{ pseudoState.orderActionLabel }}</span>
      </div>
    </div>
  </div>
</div>
