import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { AdminGuard } from '../../core-user/client/admin.guard'
import { ProductComponent } from './product.component'
import { ProductListComponent } from './product-list.component'
// import { ProductDetailComponent }    from './product-detail.component';

const productRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [AdminGuard],
    canActivate: [AdminGuard],
    children: [
      { path: 'product/new', component: ProductComponent },
      { path: 'product/list', component: ProductListComponent },
      {
        path: 'product/list/location/:locationId',
        component: ProductListComponent
      },
      {
        path: 'product/list/fleet/:fleetId',
        component: ProductListComponent
      },
      { path: 'product/:productId', component: ProductComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(productRoutes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {}
