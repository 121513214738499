import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'
import { ManufacturerComponent } from './manufacturer.component'

const manufacturerRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: [{ path: 'manufacturer', component: ManufacturerComponent }]
  }
]

@NgModule({
  imports: [RouterModule.forChild(manufacturerRoutes)],
  exports: [RouterModule]
})
export class ManufacturerRoutingModule {}
