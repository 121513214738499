import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { environment } from '../../../environments/environment'

@Injectable()
export class OrderStateService {
  orderStatesURL = `${environment.apiUrl}/orderState`

  constructor(private http: HttpClient) {}

  // Get all posts from the API
  getOrderStates() {
    return this.http.get(`${this.orderStatesURL}/`)
  }

  getOrderStateById(orderStateId) {
    return this.http.get(`${this.orderStatesURL}/${orderStateId}`)
  }

  getOrderStateLogo(fleedId) {
    return this.http.get(`${this.orderStatesURL}/${fleedId}/logo`)
  }

  save(orderState: any) {
    if (orderState._id) {
      return this.http.put(
        `${this.orderStatesURL}/${orderState._id}`,
        orderState
      )
    } else {
      return this.http.post(this.orderStatesURL, orderState)
    }
  }

  update(orderState: any) {
    return this.http.put(`${this.orderStatesURL}/${orderState._id}`, orderState)
  }
}
