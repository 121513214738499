import { Pipe, PipeTransform } from '@angular/core'
import * as _ from 'lodash'

@Pipe({
  name: 'arrayToString',
  pure: false
})

// Transforms an Array to a comma separated strings.
export class ArrayToString implements PipeTransform {
  public transform(array) {
    return _.join(array, ', ')
  }
}
