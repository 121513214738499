import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable()
export class OrderFlowService {
  orderFlowsURL = `${environment.apiUrl}/orderFlow`

  constructor(private http: HttpClient) {}

  // Get all posts from the API
  getOrderFlows(groupByName = true): Observable<any[]> {
    let url = this.orderFlowsURL
    if (groupByName) {
      url += '?groupByName=true'
    }
    return this.http.get<any[]>(url)
  }

  getOrderFlowById(orderFlowId): Observable<any> {
    return this.http.get<any>(`${this.orderFlowsURL}/${orderFlowId}`)
  }

  save(orderFlow: any): Observable<any> {
    if (orderFlow._id) {
      return this.http.put<any>(
        `${this.orderFlowsURL}/${orderFlow._id}`,
        orderFlow
      )
    } else {
      return this.http.post<any>(this.orderFlowsURL, orderFlow)
    }
  }

  update(orderFlow: any): Observable<any> {
    return this.http.put<any>(
      `${this.orderFlowsURL}/${orderFlow._id}`,
      orderFlow
    )
  }
}
