import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'
import { VendorComponent } from './vendor.component'
// import { VendorDetailComponent }    from './vendor-detail.component';

const vendorRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: [{ path: 'vendor', component: VendorComponent }]
  }
]
@NgModule({
  imports: [RouterModule.forChild(vendorRoutes)],
  exports: [RouterModule]
})
export class VendorRoutingModule {}
