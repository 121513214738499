<div class="service-call-dialog-container">
  <h4 class="dialog-title">New Service Call</h4>
  <div fxLayout="column">
    <div fxLayout="row" class="fleet-check-select">
      <mat-form-field fxFlex>
        <mat-select
          placeholder="Choose Fleet"
          [(ngModel)]="selectedFleet"
          (selectionChange)="onFleetSelected()"
          [disabled]="data?.readOnlyLocation"
        >
          <mat-option *ngFor="let fleet of fleets" [value]="fleet._id">
            {{ fleet.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" class="fleet-check-select">
      <mat-form-field fxFlex *ngIf="selectedFleet">
        <mat-select
          placeholder="Choose Location"
          [(ngModel)]="selectedLocation"
          [disabled]="data?.readOnlyLocation"
        >
          <mat-optgroup label="My Locations">
            <mat-option
              *ngFor="let location of myLocations"
              [value]="location._id"
            >
              {{ location.name }}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Other Locations">
            <mat-option
              *ngFor="let location of otherLocations"
              [value]="location._id"
            >
              {{ location.name }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <p class="error-message">
        {{ error }}
      </p>
    </div>
    <div fxLayout="row">
      <button type="button" mat-raised-button (click)="submit()">Submit</button>
      <button type="button" mat-button (click)="dialogRef.close()">
        Cancel
      </button>
    </div>
  </div>
</div>
