import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work

import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { ManufacturerComponent } from './manufacturer.component'

import { ManufacturerService } from './manufacturer.service'

import { ManufacturerRoutingModule } from './manufacturer-routing.module'

@NgModule({
  declarations: [ManufacturerComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ManufacturerRoutingModule,
    CoreModule,
    MaterialComponentsModule
  ],
  providers: [ManufacturerService],
  exports: [ManufacturerComponent]
})
export class ManufacturerModule {}
