<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
/>
<div id="orderRoot" class="mat-card-wrapper">
  <div>
    <!--&lt;!&ndash;<mat-card-header>&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<mat-card-title>Welcome, {{coreUser.firstName}}!</mat-card-title>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<i class="material-icons">add_shopping_cart</i>{{orderComponent.itemsInCart}}&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;</mat-card-header>&ndash;&gt;-->
    <!--{{order}}-->
    <div class="orderRoot" *ngIf="orderType === 'RegularOrder'">
      <mat-card class="layout-left" *ngIf="productList.length">
        <div class="buttons-column">
          <mat-card
            class="action-button-category-container"
            [ngStyle]="{ 'margin-bottom': '15px' }"
          >
            <mat-card-header>
              <mat-card-title>Type</mat-card-title>
            </mat-card-header>
            <mat-card-content class="action-buttons">
              <div class="action-button">
                <button
                  *ngIf="showNew"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedType('NEW TIRE')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedType === 'NEW TIRE'"
                  >
                    New
                  </div>
                </button>
                <button
                  *ngIf="showRetread"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedType('RETREAD')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedType === 'RETREAD'"
                  >
                    Retread
                  </div>
                </button>
                <button
                  *ngIf="showForklift"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedType('FORKLIFT')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedType === 'FORKLIFT'"
                  >
                    Forklift
                  </div>
                </button>
                <button
                  *ngIf="showWheel"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedType('WHEEL')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedType === 'WHEEL'"
                  >
                    Wheel
                  </div>
                </button>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card
            *ngIf="selectedType && showUseCategories"
            class="action-button-category-container"
          >
            <mat-card-header>
              <mat-card-title>Use</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="action-button">
                <button
                  *ngIf="showDrive"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedUse('DRIVE')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedUse === 'DRIVE'"
                  >
                    Drive
                  </div>
                </button>
                <button
                  *ngIf="showTrailer"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedUse('TRAILER')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedUse === 'TRAILER'"
                  >
                    Trailer
                  </div>
                </button>
                <button
                  *ngIf="showSteer"
                  mat-button
                  mat-raised-button
                  class="btn mat-elevation-z4"
                  (click)="setSelectedUse('STEER')"
                >
                  <div
                    class="title"
                    [class.buttonDepressed]="selectedUse === 'STEER'"
                  >
                    Steer
                  </div>
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card>

      <mat-card
        class="results-container"
        *ngIf="productList && productList.length && selectedUse"
      >
        <mat-card-header>
          <mat-card-title class="search-title">Product Results</mat-card-title>
        </mat-card-header>
        <mat-card-content class="search-results">
          <div *ngIf="(products | json) === '{}'">No Products Found</div>
          <mat-card
            *ngFor="let product of currentProductList.tires; let idx = index"
            class="results-columns"
          >
            <img
              *ngIf="product.brand && product.brand?.logo"
              [src]="product.brand?.logo"
              [alt]="product.brand?.name"
              class="mat-avatar"
              style="height: 30px"
            />
            <img
              [src]="product.image"
              class="mat-avatar"
              style="height: 100px"
            />
            <mat-card-content class="product-list">
              <div class="product-description">
                <h5>{{ product.item }}</h5>
                <p>{{ product.description }}</p>
              </div>
            </mat-card-content>
            <mat-card-footer class="search-grid-bottom">
              <div *ngIf="showInventoryInfo" class="search-grid-tile">
                <p
                  class="order-stock"
                  [class]="
                    product.summary?.qtyAvailable > optimalStockQuantity()
                      ? 'order-stock--adequate'
                      : 'order-stock--inadequate'
                  "
                >
                  {{ formatStockInfo(product.summary) }}
                </p>
              </div>
              <div class="search-grid-tile">
                <p *ngIf="product.productType != 'FORKLIFT'">Quantity:</p>
                <!-- For AAA Cooper -->
                <p
                  *ngIf="
                    orderFleet?.name?.toLowerCase()?.includes('aaa') &&
                    product.productType == 'FORKLIFT'
                  "
                >
                  Quantity:<br />(Only 2 per unit #)
                </p>
                <!-- For Other fleets -->
                <p
                  *ngIf="
                    !orderFleet?.name?.toLowerCase()?.includes('aaa') &&
                    product.productType == 'FORKLIFT'
                  "
                >
                  Quantity:<br />
                </p>
                <input
                  *ngIf="product.productType != 'FORKLIFT'"
                  type="number"
                  min="1"
                  style="width: 60%; margin: auto"
                  [(ngModel)]="product.qty"
                />
                <!-- For AAA Cooper -->
                <input
                  *ngIf="
                    orderFleet?.name?.toLowerCase()?.includes('aaa') &&
                    product.productType == 'FORKLIFT'
                  "
                  type="number"
                  min="1"
                  max="2"
                  style="width: 60%; margin: auto"
                  [(ngModel)]="product.qty"
                />
                <!-- For Other fleets -->
                <input
                  *ngIf="
                    !orderFleet?.name?.toLowerCase()?.includes('aaa') &&
                    product.productType == 'FORKLIFT'
                  "
                  type="number"
                  min="1"
                  max="999"
                  style="width: 60%; margin: auto"
                  [(ngModel)]="product.qty"
                />
              </div>
              <div
                *ngIf="
                  product.productType !== 'WHEEL' &&
                  product.productType !== 'FORKLIFT' &&
                  orderFleet?.options?.mountedWheelsProgram
                "
                class="search-grid-tile"
              >
                <mat-form-field>
                  <mat-label>Select Wheel</mat-label>
                  <mat-select
                    panelClass="wide-mat-select"
                    [ngModel]="product.rim"
                    (ngModelChange)="onChangeWheel(product, $event)"
                    *ngIf="
                      product.productType == 'NEW TIRE' ||
                      product.productType == 'RETREAD'
                    "
                  >
                    <mat-option
                      *ngFor="
                        let wheel of wheels
                          | appWheelMatchFilterPipe
                            : product.wheelMatch
                            : product.productUse
                      "
                      [value]="wheel"
                    >
                      {{ wheel.item }}
                      <span *ngIf="showInventoryInfo">
                        ({{ formatStockInfo(wheel.summary) }})
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                *ngIf="product.productType == 'FORKLIFT'"
                class="search-grid-tile"
              >
                <p>ForkLift #:</p>
                <input
                  size="2"
                  placeholder="Optional"
                  [(ngModel)]="product.forkliftNumber"
                />
              </div>
              <p class="search-grid-tile">
                <button
                  (click)="addToOrder(product)"
                  [disabled]="product.addingToOrder"
                >
                  <span *ngIf="!product.addingToOrder">Add to Cart</span>
                  <img
                    *ngIf="product.addingToOrder"
                    src="/assets/images/spinner.gif"
                    height="10"
                  />
                </button>
              </p>
            </mat-card-footer>
          </mat-card>
        </mat-card-content>
      </mat-card>
      <mat-card
        *ngIf="!productList || !productList.length"
        class="empty-product-warning"
      >
        No Products are available. Contact your representative for help.
      </mat-card>
    </div>

    <app-order [modifyMode]="true"> </app-order>

    <!--<button (click)='orderComponent.submitForApproval()'>Submit For Approval</button>-->
  </div>
</div>
