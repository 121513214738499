<div id="orderRoot">
  <div class="app-order-layout-section top" fxLayout="row">
    <div class="app-order-information">
      <div
        class="app-order-information-top"
        *ngIf="!modifyMode || order?.orderType === 'ServiceCall'"
      >
        <app-order-detail
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-detail>
        <app-order-vendor-detail
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-vendor-detail>
      </div>
      <div class="app-order-items-container">
        <div
          [ngClass]="{ 'app-order-items': 1, editing: modifyMode }"
          *ngIf="order?.orderType !== 'ServiceCall'"
        >
          <app-order-items
            (updateRequested)="childRequestsUpdate($event)"
            [updateCompleteNotification]="updateCompleteEmitter"
            (registerOrderPart)="registerOrderPart($event)"
            (logOrderModifications)="logOrderModification($event)"
            [order]="order"
            [modifyMode]="modifyMode"
            [coreUser]="coreUser"
          >
          </app-order-items>
        </div>
        <mat-card-content *ngIf="order?.orderType === 'ServiceCall'">
          <app-service-call-form
            style="width: 100%"
            (updateRequested)="childRequestsUpdate($event)"
            [updateCompleteNotification]="updateCompleteEmitter"
            (readyToUploadDocuments)="showDocuments()"
            (registerOrderPart)="registerOrderPart($event)"
            [order]="order"
            [docComponent]="orderDocumentsComponent"
            [actionComponent]="orderActionComponent"
            [modifyMode]="modifyMode"
            [coreUser]="coreUser"
          >
          </app-service-call-form>
        </mat-card-content>
      </div>
      <div
        class="app-order-information-top"
        *ngIf="modifyMode && order?.orderType !== 'ServiceCall'"
      >
        <app-order-detail
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-detail>
        <app-order-vendor-detail
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-vendor-detail>
      </div>
    </div>
  </div>

  <div class="app-order-layout-section bottom" fxLayout="row">
    <div fxFlex="100%">
      <div
        class="order-part-wrapper"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <app-order-paperwork-rejection
          fxFlex="50%"
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-paperwork-rejection>
      </div>

      <div class="order-part-wrapper" fxLayout="row">
        <div
          class="app-order-documents order-part"
          fxLayout.gt-xs="row"
          fxLayout="column"
          fxFlex
        >
          <app-order-po-sales
            class="po-container"
            fxFlex="1 1 auto"
            (updateRequested)="childRequestsUpdate($event)"
            [updateCompleteNotification]="updateCompleteEmitter"
            (registerOrderPart)="registerOrderPart($event)"
            [order]="order"
            [coreUser]="coreUser"
          >
          </app-order-po-sales>
          <app-order-po-service
            class="po-container"
            fxFlex="1 1 auto"
            (updateRequested)="childRequestsUpdate($event)"
            [updateCompleteNotification]="updateCompleteEmitter"
            (registerOrderPart)="registerOrderPart($event)"
            [order]="order"
            [orderDocuments]="orderDocumentsComponent.orderDocuments"
            [coreUser]="coreUser"
          >
          </app-order-po-service>
        </div>
      </div>

      <div class="order-part-wrapper" fxLayout="row">
        <div class="app-order-documents order-part" fxFlex>
          <app-fleet-check-items
            class="po-container"
            fxFlex
            (updateRequested)="childRequestsUpdate($event)"
            [updateCompleteNotification]="updateCompleteEmitter"
            (registerOrderPart)="registerOrderPart($event)"
            [order]="order"
            [coreUser]="coreUser"
          >
          </app-fleet-check-items>
        </div>
      </div>

      <div class="order-part-wrapper" fxLayout="row">
        <app-order-vendor-denial
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-vendor-denial>
      </div>

      <div class="order-part-wrapper" fxLayout="row">
        <app-order-delivery-date
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-delivery-date>
      </div>

      <div class="order-part-wrapper" fxLayout="row">
        <app-order-documents
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-documents>
      </div>

      <div class="order-part-wrapper" fxLayout="row">
        <app-order-log
          (updateRequested)="childRequestsUpdate($event)"
          [updateCompleteNotification]="updateCompleteEmitter"
          (registerOrderPart)="registerOrderPart($event)"
          [order]="order"
          [coreUser]="coreUser"
        >
        </app-order-log>
      </div>
    </div>
  </div>

  <app-order-action
    (updateRequested)="childRequestsUpdate($event)"
    [updateCompleteNotification]="updateCompleteEmitter"
    (registerOrderPart)="registerOrderPart($event)"
    (logOrderModifications)="logOrderModification($event)"
    [(order)]="order"
    [modifyMode]="modifyMode"
    [coreUser]="coreUser"
  >
  </app-order-action>
</div>
