// 'npm' modules
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

// 'app' modules

import { NgxMyDatePickerModule } from 'ngx-mydatepicker'
import { FleetModule } from './fleet/client/fleet.module'
import { CoreModule } from './core/client/core-module'
import { LocationModule } from './location/client/location.module'
import { OrderModule } from './order/client/order.module'
import { ProductModule } from './product/client/product.module'
import { VendorModule } from './vendor/client/vendor.module'
import { DashboardModule } from './dashboard/client/dashboard.module'
import { CoreLayoutModule } from './core-layout/client/core-layout.module'
import { CoreUserModule } from './core-user/client/core-user.module'
import { OrderFlowModule } from './order-flow/client/order-flow.module'
// Services & Directives
// import {FileSelectDirective} from 'ng2-file-upload';

// Routers
import { AppRoutingModule } from './app-routing.module'

// Components
import { AppComponent } from './app.component'
import { PageNotFoundComponent, TestComponent } from './not-found.component'
import { RedirectComponent } from './redirect.component'
import { SystemManagementModule } from './system-management/client/system-management.module'
import { ManufacturerModule } from './manufacturer/client/manufacturer.module'
import { OrderStateModule } from './order-state/client/order-state.module'

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    TestComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxMyDatePickerModule.forRoot(),
    FleetModule,
    LocationModule,
    CoreModule,
    ProductModule,
    OrderModule,
    OrderStateModule,
    VendorModule,
    DashboardModule,
    CoreLayoutModule,
    CoreUserModule,
    OrderFlowModule,
    ManufacturerModule,
    SystemManagementModule,
    ReactiveFormsModule,
    AppRoutingModule // This needs to be last due to wildcard match
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
