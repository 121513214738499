import { Component, OnInit } from '@angular/core'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-side-nav-header',
  templateUrl: 'side-nav-header.component.html',
  styleUrls: ['side-nav-header.component.scss'],
  providers: []
})
export class SideNavHeaderComponent implements OnInit {
  state = 'closed'

  constructor() {}

  ngOnInit() {}

  setState(state: string) {
    this.state = state
  }

  // Header
  setHeader() {}
}
