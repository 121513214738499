<div *ngIf="isVisible" class="delivery-date-container order-part">
  <div class="mat-card">
    <app-container-widget title="Please Choose a Delivery Date">
      <div class="calendar-container" *ngIf="order" style="min-height: 300px">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="delivery-day-info-label"
          *ngIf="
            order.location.fleet.options &&
            order.location.fleet.options.hasSchedule
          "
        >
          <p>Location's Next Normal Delivery Day</p>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="delivery-day-info"
          *ngIf="
            order.location.fleet.options &&
            order.location.fleet.options.hasSchedule
          "
        >
          <p>{{ nextDefaultDeliveryDay || 'Unknown' }}</p>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="delivery-day-info-label"
          *ngIf="
            order.location.fleet.options &&
            order.location.fleet.options.hasSchedule
          "
        >
          <p>Next Normal Delivery Date:</p>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="delivery-day-info default-day"
          *ngIf="
            order.location.fleet.options &&
            order.location.fleet.options.hasSchedule
          "
        >
          <p>{{ nextDefaultDeliveryDate | date: 'MM/dd/yyyy' }}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <my-date-picker
            [options]="myDatePickerOptions"
            (dateChanged)="onDateChanged($event)"
            [selDate]="selectedDate"
          ></my-date-picker>
        </div>
      </div>
    </app-container-widget>
  </div>
</div>
