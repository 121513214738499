import * as _ from 'lodash'

export interface CoreResponse {
  readonly type: string
  readonly message: string
  readonly offendingKey?: string
}

export interface ResponseHandler {
  handleSuccess(response: CoreResponse): void
  handleFailure(responses: CoreResponse[]): void
}

export abstract class CoreResponseHandler {
  static defaultResponseHandler: ResponseHandler
  static setDefaultResponseHandler(handler: ResponseHandler) {
    CoreResponseHandler.defaultResponseHandler = handler
  }

  public ResponseHandlers: CoreResponseHandler[] = []

  constructor() {}

  // Each component using the error handler should define what responses can handle.
  abstract handleResponse(response: CoreResponse): boolean

  public handleSuccess(type: string, message: string) {
    const coreResponse: CoreResponse = { type, message }
    const coreResponseArr = [coreResponse]
    this.handleResponses(coreResponseArr)

    if (coreResponseArr && coreResponseArr.length) {
      if (CoreResponseHandler.defaultResponseHandler) {
        CoreResponseHandler.defaultResponseHandler.handleSuccess(coreResponse)
      } else {
        this.handleLeftOverSuccess(coreResponse)
      }
    }
  }

  public handleFailure(response: any) {
    // In case the errror is a server response, parse it here
    if (response._body) {
      try {
        response = JSON.parse(response._body)
      } catch (e) {
        response = [
          {
            type: 'Uknown',
            message: 'Something went wrong.'
          }
        ]
      }
    }

    if (response.error) {
      response = response.error
    }

    if (!Array.isArray(response)) {
      response = [response]
    }

    this.handleResponses(response)

    if (CoreResponseHandler.defaultResponseHandler) {
      CoreResponseHandler.defaultResponseHandler.handleFailure(response)
    } else {
      this.handleLeftOverFailures(response)
    }
  }

  public handleResponses(responses: CoreResponse[]): void {
    this.ResponseHandlers.forEach((handler) => {
      handler.handleResponses(responses)
    })

    _.remove(responses, (err) => this.handleResponse(err))
  }

  public addResponseHandler(handler: CoreResponseHandler) {
    this.ResponseHandlers.push(handler)
  }

  public addResponseHandlers(handlers: CoreResponseHandler[]) {
    handlers.forEach((handler) => {
      this.addResponseHandler(handler)
    })
  }

  public handleLeftOverSuccess(response: CoreResponse) {
    /* tslint:disable-next-line:no-console */
    console.log('Unhandled response (sucess)', this.constructor.name)
  }

  public handleLeftOverFailures(responses: CoreResponse[]) {
    if (responses && responses.length) {
      /* tslint:disable-next-line:no-console */
      console.log('Unhandled responses', this.constructor.name)
      responses.forEach((err) => {
        /* tslint:disable-next-line:no-console */
        console.log(err)
      })
    }
  }
}
