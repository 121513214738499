import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AlertDialogComponent } from './alert-dialog.component'
import { ConfirmDialogComponent } from './confirm-dialog.component'
import { InputDialogComponent } from './input-dialog.component'

@Injectable()
export class ModalDialogsService {
  constructor(private dialog: MatDialog) {}

  public alert(
    title: string,
    message: string,
    closeButtonText = 'OK',
    isHTML = false
  ): Observable<void> {
    const dialogRef: MatDialogRef<AlertDialogComponent> =
      this.dialog.open(AlertDialogComponent)
    dialogRef.componentInstance.title = title
    dialogRef.componentInstance.isHTML = isHTML
    dialogRef.componentInstance.message = message
    dialogRef.componentInstance.closeButtonText = closeButtonText

    return dialogRef.afterClosed()
  }

  public confirm(
    title: string,
    message: string,
    trueButtonText = 'OK',
    falseButtonText = 'Cancel'
  ): Observable<boolean> {
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
      ConfirmDialogComponent
    )
    dialogRef.componentInstance.title = title
    dialogRef.componentInstance.message = message
    dialogRef.componentInstance.trueButtonText = trueButtonText
    dialogRef.componentInstance.falseButtonText = falseButtonText

    return dialogRef.afterClosed()
  }

  public input(
    title: string,
    message: string,
    placeholder = '',
    trueButtonText = 'OK',
    falseButtonText = 'Cancel',
    rowCount = 10,
    colCount = 40,
    inputClass = ''
  ): Observable<boolean> {
    const dialogRef: MatDialogRef<InputDialogComponent> =
      this.dialog.open(InputDialogComponent)
    dialogRef.componentInstance.title = title
    dialogRef.componentInstance.message = message
    dialogRef.componentInstance.trueButtonText = trueButtonText
    dialogRef.componentInstance.falseButtonText = falseButtonText
    dialogRef.componentInstance.rowCount = rowCount
    dialogRef.componentInstance.colCount = colCount
    dialogRef.componentInstance.placeholder = placeholder
    dialogRef.componentInstance.inputClass = inputClass

    return dialogRef.afterClosed()
  }
}
