// tslint:disable:no-access-missing-member
import * as _ from 'lodash'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import * as moment from 'moment'
import { OrderService } from '../order.service'
import { ModalDialogsService } from '../../../core/client/modal-dialogs/modal-dialogs.service'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { StateActionType } from '../../order-state-action-type.enum'
import { StateBroadcastService } from '../../../core/client/state-broadcast/state-broadcast.service'
import { FleetCheckDialogComponent } from '../../../dashboard/client/fleet-check-dialog.component'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-action',
  templateUrl: 'order-action.component.html',
  styleUrls: ['order-action.component.scss'],
  providers: []
})
export class OrderActionComponent
  extends OrderBaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() modifyMode: boolean
  @Output() logOrderModifications = new EventEmitter<any>()
  requestedNavigationRoute: string
  requestedNavigationQueryParams: any
  orderHadItemsOnLoad = null
  orderChangedBySomeoneElse = false
  changeWatcher = null

  // client side only "pseduoStates" this are used as transition states
  // These are used when we need to keep track of a temporary state that should not be persisted,
  // while switching to another page
  clientPseudoStates: {
    state: { name: string }
    orderActionLabel: string
    buttonClass?: string
    redirectTo?: string
    redirecToQueryParams?: any
  }[] = []

  constructor(
    private router: Router,
    private modalDialogsService: ModalDialogsService,
    private orderService: OrderService,
    private stateBroadcastService: StateBroadcastService,
    private dialog: MatDialog
  ) {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  ngOnDestroy() {
    if (this.changeWatcher !== null) {
      this.changeWatcher.unsubscribe()
    }
    this.changeWatcher = null
  }

  ngOnChanges() {
    if (this.order) {
      this.setOrder(this.order)
    }
  }

  handleUpdateComplete() {
    super.handleUpdateComplete()
    if (this.requestedNavigationRoute) {
      this.router.navigate([this.requestedNavigationRoute], {
        queryParams: this.requestedNavigationQueryParams
      })
    }
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      (this.order.orderType !== 'ServiceCall' ||
        this.order.callType ||
        this.order.readyForDocuments)
  }

  changeState(toState) {
    // TODO: this is so we can validate forms and such before updating, need to figure out a better way
    if (
      toState.state.name !== 'PseudoClientOrderModificationCancel' &&
      this.order.preStateChangeFns
    ) {
      for (let i = 0; i < this.order.preStateChangeFns.length; i++) {
        if (this.order.preStateChangeFns[i].fn() === false) {
          return false
        }
      }
    }

    const _self = this
    let onCompleteRoute = toState.redirectTo || '/dashboard'
    let onCompleteQueryParams = toState.redirectToQueryParams || {}
    const pickedUpCasings = this.order.pickedUpCasings
    const pickedUpCasingsIsSet =
      pickedUpCasings !== null &&
      pickedUpCasings !== undefined &&
      !isNaN(Number(pickedUpCasings))

    if (toState.requiredInputPrompt && toState.requiredInputPrompt.message) {
      this.modalDialogsService
        .input(
          toState.requiredInputPrompt.title || '',
          toState.requiredInputPrompt.message,
          toState.requiredInputPrompt.placeholder || '',
          toState.requiredInputPrompt.buttonText || 'Submit'
        )
        .subscribe((res) => {
          if (res) {
            this.order.stateChangeDescription = res
            _setStateAndUpdate()
          }
        })
    } else {
      switch (toState.state.name) {
        case 'VendorProcessingWithRequiredPO':
          const fleet = this.order.location && this.order.location.fleet
          if (
            fleet.options &&
            fleet.options.onePOPerManufacturer &&
            this.order.regularItems &&
            this.order.regularItems.manufacturers
          ) {
            for (const m of this.order.regularItems.manufacturers) {
              if (!m.poNumber) {
                this.modalDialogsService
                  .alert(
                    'Missing PO #',
                    'A PO# must be entered for each manufacturer before submitted the order'
                  )
                  .subscribe()
                return
              }
            }
          } else {
            let requiredPOMinLength: number | null = null
            let requiredPOMaxLength: number | null = null
            const enteredPONumber =
              (this.order.regularItems && this.order.regularItems.generalPO) ||
              ''
            const fleetName =
              this.order.location &&
              this.order.location.fleet &&
              this.order.location.fleet.name
            switch (fleetName) {
              case 'FedEx Freight':
                requiredPOMinLength = 7
                requiredPOMaxLength = 9
                break
            }
            const validPOEntered =
              enteredPONumber &&
              (!requiredPOMinLength ||
                enteredPONumber.length >= requiredPOMinLength) &&
              (!requiredPOMaxLength ||
                enteredPONumber.length <= requiredPOMaxLength)
            const isForkliftOnlyOrder =
              this.order?.regularItems?.manufacturers?.length === 0 &&
              this.order?.forkliftItems?.length > 0
            if (!validPOEntered && !isForkliftOnlyOrder) {
              let message
              if (requiredPOMinLength && requiredPOMaxLength)
                message = `${requiredPOMinLength} to ${requiredPOMaxLength} digit `
              else if (requiredPOMinLength)
                message = `At least ${requiredPOMinLength} digit `
              else if (requiredPOMaxLength)
                message = `At most ${requiredPOMaxLength} digit `
              message += 'PO # is required before submitting the order'
              this.modalDialogsService
                .alert('Invalid/Missing PO #', message)
                .subscribe()
              return
            }
          }
          _setStateAndUpdate()
          break
        case 'FleetProcessingFleetCheck':
          let itemsAreValid = true
          for (let i = this.order.items.length - 1; i >= 0; i--) {
            if (
              !this.order.items[i].woNumber &&
              !this.order.items[i].drNumber
            ) {
              this.order.items.splice(i, 1)
            } else if (
              this.order.items[i].drNumber &&
              !this.order.items[i].woNumber
            ) {
              this.modalDialogsService
                .alert('Missing WO #', 'WO # is required')
                .subscribe()
              itemsAreValid = false
              break
            }
          }
          if (!this.order.items || !this.order.items.length) {
            this.modalDialogsService
              .alert('Missing Items', 'Must add at least one item')
              .subscribe()
            itemsAreValid = false
          }

          if (itemsAreValid) {
            _setStateAndUpdate()
          }
          break
        // TODO figure out how to get rid of this specific flow dependency
        case 'StmServicePOProcessing':
        case 'StmServiceCallPaperworkProcessing':
        case 'StmProcessingFleetCheck':
        case 'FleetReceiving':
        case 'OrderComplete':
          // Make sure all required documents are present
          const missingDocs = []
          let isValid = true

          if (
            this.order.location.fleet.options.showPickedUpCasingsInput &&
            !pickedUpCasingsIsSet &&
            !this.order.isNoOrderNoPickup
          ) {
            isValid = false
            this.modalDialogsService
              .alert(
                'Missing Picked up Casings',
                'Please enter the number of casings that were picked up (top right of page)'
              )
              .subscribe()
          } else if (this.order.requiredDocuments) {
            _.forEach(
              this.order.requiredDocuments,
              (requiredDoc: {
                displayName: string
                fileIdentity: string
                uploaded
              }) => {
                if (!requiredDoc.uploaded) {
                  missingDocs.push(requiredDoc.displayName)
                }
              }
            )

            if (missingDocs.length) {
              isValid = false
              const message = `<p>Please upload the following required documents:</p>
                             <h4>${missingDocs.join('<br />')}</h4>`
              this.modalDialogsService
                .alert('Missing Required Paperwork', message, undefined, true)
                .subscribe()
            }
          }

          if (isValid) {
            _setStateAndUpdate()
          }
          break
        // TODO figure out how to get rid of this specific flow dependency
        case 'FleetPOProcessing':
        case 'VendorDeliveryProcessingForkliftOnly':
        case 'VendorDeliveryProcessingWithPO':
          // Make sure delivery date is set and in the future
          let dateIsValid = true

          if (!this.order.delivery || !this.order.delivery.estimated) {
            dateIsValid = false
            const message = 'Please select a delivery date'
            this.modalDialogsService
              .alert('Missing Delivery Date', message, undefined, false)
              .subscribe()
          } else {
            const delDate = Number(
              moment(this.order.delivery.estimated).format('YYYYMMDD')
            )
            const now = Number(moment().format('YYYYMMDD'))
            if (now >= delDate) {
              dateIsValid = false
              const message =
                'Warning: Delivery date is not in the future. Click OK if this is correct.'
              this.modalDialogsService
                .confirm('Invalid Delivery Date', message)
                .subscribe((confirmed) => {
                  if (confirmed) {
                    _setStateAndUpdate()
                  }
                })
            }
          }

          if (dateIsValid) {
            _setStateAndUpdate()
          }
          break
        case 'PseudoClientOrderModification':
          this.router.navigate(['/orderBuilder'], {
            queryParams: {
              orderId: this.order._id,
              orderType: this.order.orderType
            }
          })
          break
        case 'PseudoClientFleetCheckDueDateModification':
          const dialogRef = this.dialog.open(FleetCheckDialogComponent, {
            data: { readOnlyLocation: true, order: this.order }
          })
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              const saveCurrentToState = toState
              this.logOrderModifications.emit({
                type: 'fleetCheckDueDateChanged',
                oldDate: this.order.dueDate,
                newDate: result.date
              })
              this.order.dueDate = result.date
              // TODO figure out how to get rid of this specific flow dependency
              if (this.order.state === 'VendorPaperworkOverdueFleetCheck') {
                toState = 'VendorPaperworkProcessingFleetCheck'
              }
              toState = { state: this.order.state }
              if (_setStateAndUpdate(true) === false) {
                toState = saveCurrentToState
              }
            }
          })
          break
        case 'PseudoClientOrderModificationSaveAndExit':
          const saveToState = toState
          toState = { state: this.order.state }
          if (_setStateAndUpdate() === false) {
            toState = saveToState
          }
          break
        case 'PseudoClientOrderModificationCancel':
          // navigate only
          this.router.navigate([onCompleteRoute], {
            queryParams: onCompleteQueryParams
          })
          break
        case 'PseudoClientOrderDelete':
          this.modalDialogsService
            .confirm(
              'Confirm Delete',
              'Are you sure you want to delete this order?',
              'Yes',
              'No'
            )
            .subscribe((reallyDelete) => {
              if (reallyDelete === true) {
                _deleteOrder()
              }
            })
          break
        default:
          _setStateAndUpdate()
          break
      }
    }

    function _setStateAndUpdate(goBackToOrderDetails = false): boolean {
      if (
        _self.orderHasItems() === false &&
        _self.order.orderType !== 'FleetCheck' &&
        _self.order.orderType !== 'ServiceCall' &&
        !_self.order.isNoOrderNoPickup &&
        !_self.order.isNoOrderWithPickup
      ) {
        _self.modalDialogsService.alert(
          'No Items',
          'Order must have at least one item'
        )
        return false
      }

      // If we had to remove next states (so that the buttons wouldn't show during a pseudo state), put them back here
      if (_self.order.tempStates) {
        _self.order.nextStates = _self.order.tempStates
        _self.order.tempStates = null
      }
      // save the current state in case something goes wrong with update
      const savedState = _self.order.state

      // update the order
      _self.order.state = toState.state._id || toState.state
      _self.update()

      if (toState.trigger === 'ORDER_MODIFIED' || goBackToOrderDetails) {
        onCompleteRoute = `order/${_self.order._id}`
        onCompleteQueryParams = { orderType: _self.order.orderType }
      }

      _self.requestedNavigationRoute = onCompleteRoute
      _self.requestedNavigationQueryParams = onCompleteQueryParams

      // reset everything back the way it was in case update fails
      // if update is successful, user will be rerouted and order will be pulled again from db, so these won't matter
      _self.order.state = savedState
      _self.order.nextStates = _self.order.tempStates

      return true
    }

    function _deleteOrder() {
      const id = _self.order._id
      const orderType = _self.order.orderType
      _self.orderService.deleteOrder(_self.order).subscribe((res) => {
        if (res) {
          _self.stateBroadcastService.broadcastDelete(id, orderType)
          _self.router.navigate([onCompleteRoute], {
            queryParams: onCompleteQueryParams
          })
        }
      })
    }
  }

  update() {
    if (this.changeWatcher !== null) {
      this.changeWatcher.unsubscribe()
      this.changeWatcher = null
    }
    super.update()
  }

  setOrder(order) {
    super.setOrder(order)

    if (this.changeWatcher === null) {
      this.changeWatcher = this.stateBroadcastService
        .getStateUpdateBroadcast()
        .subscribe(
          (data: {
            orderId: string
            orderType: string
            stateWillChange: boolean
          }) => {
            const orderState = this.order.state._id || this.order.state

            if (
              data.stateWillChange &&
              data.orderId.toString() === this.order._id.toString() &&
              this.order.state._id
            ) {
              this.modalDialogsService.alert(
                'Order Changed',
                'WARNING: This order has been changed by someone else.\n\nYou can still upload files and edit' +
                  ' notes.\nYou will need to click "Reload Order" to make any other changes.',
                'OK'
              )
              this.orderChangedBySomeoneElse = true
            }
          }
        )
    }

    this.orderHadItemsOnLoad =
      this.orderHadItemsOnLoad === null
        ? this.orderHasItems()
        : this.orderHadItemsOnLoad
    this.order.hasBeenPlaced = this.orderService.hasBeenPlaced(this.order)

    // create pseudo states array so the pseudo state buttons will show up in the compnoent
    this.clientPseudoStates = []
    const discardButton = {
      state: { name: 'PseudoClientOrderModificationCancel' },
      orderActionLabel: 'Cancel',
      redirectTo: `/order/${this.order._id}`,
      redirectToQueryParams: { orderType: this.order.orderType }
    }

    if (this.modifyMode) {
      // Save the next states in a temp variable.
      // We are going to remove the states whose buttons should be hidden during modifyMode
      // We will use this variable to put them back before saving
      this.order.tempStates = this.order.nextStates

      // If the order hasn't been placed:
      // 1.  Add one button:
      //      "Save and Exit" - saves order and goes back to the dashboard
      // 2. Replace "Place Order" button text with "Save and Place Order"
      // 3. Remove any other possible states, because you shouldn't be able to go to them while in modifyMode
      if (this.order.hasBeenPlaced === false) {
        this.clientPseudoStates.push({
          state: { name: 'PseudoClientOrderModificationSaveAndExit' },
          orderActionLabel: 'Save and Exit'
        })
        for (let i = this.order.nextStates.length - 1; i >= 0; i--) {
          const s = this.order.nextStates[i]
          if (s.trigger !== 'ORDER_PLACED') {
            this.order.nextStates.splice(i, 1)
          }
        }

        // Add cancel button
        // For location this will return to dashboard, for all others it will return to order details
        discardButton.redirectTo = '/dashboard'
        if (this.orderHadItemsOnLoad) {
          this.clientPseudoStates.push(discardButton)
        }
      } else {
        // Add cancel button
        // For location this will return to dashboard, for all others it will return to order details
        this.clientPseudoStates.push(discardButton)
        for (let i = this.order.nextStates.length - 1; i >= 0; i--) {
          const s = this.order.nextStates[i]
          if (s.trigger !== 'ORDER_MODIFIED') {
            this.order.nextStates.splice(i, 1)
          }
        }
      }
      this.clientPseudoStates.push({
        state: { name: 'PseudoClientOrderDelete' },
        orderActionLabel:
          this.orderHadItemsOnLoad ||
          this.order.isNoOrderNoPickup ||
          this.order.isNoOrderWithPickup ||
          this.order.orderType === 'ServiceCall'
            ? 'Delete Order'
            : 'Cancel',
        buttonClass: 'red-btn',
        redirectTo: '/dashboard'
      })
    } else if (
      this.order.orderType === 'FleetCheck' &&
      (this.coreUser.accountType === 'Fleet' ||
        this.coreUser.accountType === 'Stm') &&
      (this.coreUser.roles.includes('admin') ||
        this.coreUser.roles.includes('canChangeFleetCheckDueDate'))
    ) {
      this.clientPseudoStates.push({
        state: { name: 'PseudoClientFleetCheckDueDateModification' },
        orderActionLabel: 'Change Due Date'
      })
    } else {
      const modifiableState = _.find(
        this.order.nextStates,
        (s: any) => s.trigger === 'ORDER_MODIFIED'
      )
      if (modifiableState) {
        modifiableState.hidden = true
        this.clientPseudoStates.push({
          state: { name: 'PseudoClientOrderModification' },
          orderActionLabel: 'Modify Order'
        })
      } else if (
        this.coreUser.roles.includes('canDeleteOrders') &&
        this.order.stateActionType !== StateActionType.Complete
      ) {
        this.clientPseudoStates.push({
          state: { name: 'PseudoClientOrderDelete' },
          orderActionLabel:
            this.orderHadItemsOnLoad ||
            this.order.isNoOrderNoPickup ||
            this.order.isNoOrderWithPickup ||
            this.order.orderType === 'ServiceCall'
              ? 'Delete Order'
              : 'Cancel',
          buttonClass: 'red-btn',
          redirectTo: '/dashboard'
        })
      }
    }
  }

  reloadOrder() {
    this.router.navigate(['/redirect'], {
      queryParams: {
        returnUrl: `/order/${this.order._id}?orderType=${this.order.orderType}`
      }
    })
  }

  private orderHasItems(): boolean {
    let orderHasItems = false
    if (this.order.regularItems && this.order.regularItems.manufacturers) {
      this.order.regularItems.manufacturers.forEach((m) => {
        if (m.items && m.items.length) {
          orderHasItems = true
          return false // break out of loop
        }
      })
    }

    if (!orderHasItems && this.order.forkliftItems) {
      this.order.forkliftItems.forEach((f) => {
        if (f.items && f.items.length) {
          orderHasItems = true
          return false // break out of loop
        }
      })
    }

    return orderHasItems
  }
}
