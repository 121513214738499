import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'

import { HamburgerComponent } from '../../core/client/hamburger/hamburger.component'
import { environment } from '../../../environments/environment'
import { appVersion } from '../../constants'
import { NavRoute, SideNavComponent } from './side-nav/side-nav.component'
import { TopNavComponent } from './top-nav/top-nav.component'

@Component({
  selector: 'app-layout',
  templateUrl: 'core-layout.component.html',
  styleUrls: ['core-layout.component.scss'],
  providers: []
})
export class CoreLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('topNav', { static: true }) TopNavComponent: TopNavComponent
  @ViewChild('sideNav', { static: true }) SideNavComponent: SideNavComponent
  @ViewChild('topHamburger', { static: true })
  HamburgerComponent: HamburgerComponent

  title: any = 'CoreLayout'
  leftMarginClass: string
  appName: string = environment.appName
  appVersion = appVersion

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  toggleSideNav() {
    if (this.SideNavComponent.state === 'closed') {
      this.SideNavComponent.setState('open')
      this.leftMarginClass = 'sideNavOpen'
    } else {
      this.SideNavComponent.setState('closed')
      this.leftMarginClass = 'sideNavClosed'
    }
  }

  setHeader() {}

  setFooter() {}
}
