<div [hidden]="viewUserList">
  <div class="info">
    <div class="info-card">
      <mat-toolbar>
        {{ vendor._id ? 'Edit' : 'New' }} Vendor
        <button
          *ngIf="vendor._id"
          mat-raised-button
          class="black"
          (click)="viewUserListComponent()"
        >
          View Users
        </button>
      </mat-toolbar>
      <mat-card-content>
        <form
          novalidate
          (ngSubmit)="save()"
          [formGroup]="vendorForm"
          class="form vendor-form"
        >
          <!--Slider to decide if vendor's company is STM or not-->
          <div fxLayout="row">
            <mat-slide-toggle
              [disabled]="vendor._id"
              class="example-margin"
              [(ngModel)]="vendor.company"
              (change)="stmSwitch()"
              [ngModelOptions]="{ standalone: true }"
            >
              Southern Tire Mart Vendor
            </mat-slide-toggle>
          </div>

          <!--Show STM vendor dropdown if STM is selected-->
          <div *ngIf="vendor.company === 'Southern Tire Mart'">
            <mat-form-field>
              <mat-select
                [disabled]="vendor._id"
                placeholder="Select Vendor"
                [(ngModel)]="stmVendor"
                name="stmVendor"
                (selectionChange)="stmVendorAssign(stmVendor)"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="let stmVendor of stmVendors | orderByPipe: 'name'"
                  [value]="stmVendor"
                >
                  {{ stmVendor._id }} - {{ stmVendor.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <br />
          </div>

          <!--Hide Form if STM vendor-->
          <div *ngIf="vendor.company !== 'Southern Tire Mart'">
            <div fxLayout="row">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Name"
                  [(ngModel)]="vendor.name"
                  [ngModelOptions]="{ standalone: true }"
                  required
                />
                <mat-hint
                  *ngIf="responses?.name"
                  class="error-message"
                  align="start"
                  >{{ responses?.name }}</mat-hint
                >
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Abbreviation"
                  [(ngModel)]="vendor.abbreviation"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.abbreviation"
                  class="error-message"
                  align="start"
                  >{{ responses?.abbreviation }}</mat-hint
                >
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Address"
                  [(ngModel)]="vendor.address.street1"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.address?.errors?.street1"
                  class="error-message"
                  align="start"
                  >{{ responses?.address?.errors?.street1 }}</mat-hint
                >
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="City"
                  [(ngModel)]="vendor.address.city"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.address?.errors?.city"
                  class="error-message"
                  align="start"
                  >{{ responses?.address?.errors?.city }}</mat-hint
                >
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="State"
                  [(ngModel)]="vendor.address.state"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.address?.errors.state"
                  class="error-message"
                  align="start"
                  >{{ responses?.address?.errors?.state }}</mat-hint
                >
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Zip"
                  [(ngModel)]="vendor.address.zip"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.address?.errors?.zip"
                  class="error-message"
                  align="start"
                  >{{ responses?.address?.errors?.zip }}</mat-hint
                >
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <mat-form-field>
                <mat-select
                  placeholder="Timezone"
                  [(ngModel)]="vendor.address.timezone"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-option
                    *ngFor="let timezone of timezones"
                    [value]="timezone.value"
                  >
                    {{ timezone.display }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Phone"
                  [(ngModel)]="vendor.phone"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.phone"
                  class="error-message"
                  align="start"
                  >{{ responses?.phone }}</mat-hint
                >
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Fax"
                  [(ngModel)]="vendor.fax"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.fax"
                  class="error-message"
                  align="start"
                  >{{ responses?.fax }}</mat-hint
                >
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Email"
                  [(ngModel)]="vendor.email"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint
                  *ngIf="responses?.email"
                  class="error-message"
                  align="start"
                  >{{ responses?.email }}</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column">
            <mat-hint *ngIf="responses?.saveSuccess" class="success-message">{{
              responses?.saveSuccess
            }}</mat-hint>
            <div>
              <button
                mat-raised-button
                [disabled]="
                  saving ||
                  (vendor._id && vendor.company === 'Southern Tire Mart')
                "
                class="blue-btn"
              >
                <span *ngIf="!saving">{{
                  vendor._id ? 'Update' : 'Save'
                }}</span>
                <img
                  *ngIf="saving"
                  src="/assets/images/spinner.gif"
                  height="30"
                  width="30"
                />
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </div>
  </div>
</div>

<div [hidden]="!viewUserList">
  <app-list-view-widget #users></app-list-view-widget>
</div>
