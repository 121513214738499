<div>
  <mat-toolbar [hidden]="formTitle"
    >{{formTitle ? formTitle : (user._id ? 'Edit User' : 'New
    User')}}</mat-toolbar
  >
  <form class="form" novalidate (ngSubmit)="save()" [formGroup]="userForm">
    <!--Show store user dropdown if STM store-->
    <div
      class="stm-store-user-dropdown"
      *ngIf="!user._id && storeInfo?.storeNumber"
    >
      <div *ngIf="!storeInfo?.storeUsers">Loading Store Users...</div>
      <div
        *ngIf="storeInfo?.storeUsers && storeInfo?.storeUsers.length === 0"
        class="error-message"
      >
        * All of this store's users have been added. If someone is missing, make
        sure they have TOM permissions turned on in the portal.
      </div>
      <div *ngIf="storeInfo?.storeUsers?.length">
        <mat-form-field>
          <mat-select
            placeholder="Select User"
            [(ngModel)]="stmUser"
            name="stmUser"
            (selectionChange)="stmUserAssign()"
            [ngModelOptions]="{standalone: true}"
          >
            <mat-option
              *ngFor="let storeUser of storeInfo?.storeUsers"
              [value]="storeUser"
            >
              {{storeUser.firstName}} {{storeUser.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br />
        <br />
      </div>
    </div>
    <div *ngIf="!storeInfo?.storeNumber || stmUser || user._id" fxLayout>
      <div fxFlex="40%">
        <div class="error-message" *ngIf="loggedInIsAdmin && user.isStmUser">
          * Southern Tire Mart employees' basic info will need to be updated in
          the portal.
        </div>
        <div fxLayout>
          <mat-form-field *ngIf="loggedInIsAdmin">
            <input
              matInput
              type="text"
              placeholder="First Name"
              [(ngModel)]="user.firstName"
              [ngModelOptions]="{standalone: true}"
              required
              [disabled]="user.isStmUser"
            />
            <mat-hint *ngIf="responses?.firstName" class="error-message"
              >{{responses?.firstName}}</mat-hint
            >
          </mat-form-field>
          <div *ngIf="!loggedInIsAdmin" class="user-readonly-field">
            <p class="user-readonly-label">First Name</p>
            <p class="user-readonly-value">{{user.firstName}}</p>
          </div>
          <mat-form-field *ngIf="loggedInIsAdmin">
            <input
              matInput
              type="text"
              placeholder="Last Name"
              [(ngModel)]="user.lastName"
              [ngModelOptions]="{standalone: true}"
              required
              [disabled]="user.isStmUser"
            />
            <mat-hint
              *ngIf="responses?.lastName"
              class="error-message"
              align="start"
            >
              {{responses?.lastName}}
            </mat-hint>
          </mat-form-field>
          <div *ngIf="!loggedInIsAdmin" class="user-readonly-field">
            <p class="user-readonly-label">Last Name</p>
            <p class="user-readonly-value">{{user.lastName}}</p>
          </div>
        </div>

        <div fxLayout>
          <mat-form-field *ngIf="loggedInIsAdmin">
            <input
              matInput
              type="email"
              placeholder="Email Address"
              [(ngModel)]="user.email"
              [ngModelOptions]="{standalone: true}"
              required
              [disabled]="user.isStmUser"
            />
            <mat-hint
              *ngIf="responses?.email"
              class="error-message"
              align="start"
            >
              {{responses?.email}}
            </mat-hint>
          </mat-form-field>
          <div *ngIf="!loggedInIsAdmin" class="user-readonly-field">
            <p class="user-readonly-label">Email</p>
            <p class="user-readonly-value">{{user.email}}</p>
          </div>
          <mat-form-field
            [ngStyle]="{'margin-top': loggedInIsAdmin ? '5px' : '25px'}"
          >
            <input
              matInput
              type="text"
              placeholder="Cell Phone"
              [(ngModel)]="user.cell"
              [ngModelOptions]="{standalone: true}"
            />
            <mat-hint
              *ngIf="responses?.cell"
              class="error-message"
              align="start"
            >
              {{responses?.cell}}
            </mat-hint>
          </mat-form-field>
        </div>

        <div formGroupName="account">
          <div>
            <mat-form-field *ngIf="loggedInIsAdmin">
              <input
                matInput
                type="text"
                placeholder="Username"
                [(ngModel)]="user.username"
                [ngModelOptions]="{standalone: true}"
                required
                [disabled]="user.isStmUser"
              />
              <mat-hint
                *ngIf="responses?.username"
                class="error-message"
                align="start"
              >
                {{responses?.username}}
              </mat-hint>
            </mat-form-field>
            <div *ngIf="!loggedInIsAdmin" class="user-readonly-field">
              <p class="user-readonly-label">Username</p>
              <p class="user-readonly-value">{{user.username}}</p>
            </div>
          </div>
          <div fxLayout *ngIf="loggedInIsAdmin && !user.isStmUser">
            <mat-form-field>
              <input
                matInput
                type="password"
                placeholder="Password"
                formControlName="password"
                [(ngModel)]="user.password"
                autocomplete="new-password"
                required="{{!user._id}}"
              />
              <mat-hint
                *ngIf="responses?.password"
                class="error-message"
                align="start"
              >
                {{responses?.password}}
              </mat-hint>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                type="password"
                placeholder="Confirm Password"
                formControlName="confirm"
                autocomplete="new-password"
                [(ngModel)]="passwordConfirm"
                required="{{!user._id}}"
              />
              <mat-hint
                *ngIf="userForm.get('account').touched && userForm.get('account').hasError('nomatch')"
                class="error-message"
                align="start"
              >
                Passwords must match
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" *ngIf="loggedInIsAdmin">
          <mat-checkbox
            [(ngModel)]="userIsAdmin"
            [ngModelOptions]="{standalone: true}"
            >System Admin</mat-checkbox
          >
        </div>

        <div fxLayout="row">
          <div fxLayout="column">
            <p>Notify me when an order hits my queue:</p>
            <mat-checkbox
              [(ngModel)]="user.notificationRoute.textMessage"
              [ngModelOptions]="{standalone: true}"
              >Text
            </mat-checkbox>
            <mat-checkbox
              [(ngModel)]="user.notificationRoute.email"
              [ngModelOptions]="{standalone: true}"
              >Email
            </mat-checkbox>
          </div>
        </div>
        <div>
          <mat-hint *ngIf="responses?.saveSuccess" class="success-message"
            >{{responses?.saveSuccess}}</mat-hint
          >
          <div>
            <button
              mat-raised-button
              [disabled]="saving"
              class="user-update-btn"
            >
              <span *ngIf="!saving">{{user._id ? 'Update' : 'Save'}}</span>
              <img
                *ngIf="saving"
                src="/assets/images/spinner.gif"
                height="30"
                width="30"
              />
            </button>
          </div>
        </div>

        <!--<button mat-raised-button>{{user._id ? 'Update' : 'Update'}}</button>
                <mat-hint *ngIf="responses?.updateSuccess" class="success-message">{{responses?.updateSuccess}}</mat-hint>

                <button mat-raised-button>{{user._id ? 'Update' : 'Delete'}}</button>
                <mat-hint *ngIf="responses?.deleteSuccess" class="success-message">{{responses?.deleteSuccess}}</mat-hint>-->
      </div>

      <div
        fxLayout="column"
        fxFlex="60%"
        *ngIf="loggedInIsAdmin && user.defaultAccountType === 'Fleet'"
      >
        <mat-checkbox
          [(ngModel)]="user.readOnly"
          (change)="readOnlyChanged()"
          [ngModelOptions]="{standalone: true}"
          >Read Only
        </mat-checkbox>
        <mat-checkbox
          [(ngModel)]="user.canPlaceOrder"
          (change)="canPlaceOrderChanged()"
          [ngModelOptions]="{standalone: true}"
          >Can Place Orders
        </mat-checkbox>

        <div class="location-list-container">
          <div class="label">Visibility</div>
          <div class="visibility-checkbox">
            <mat-checkbox
              [(ngModel)]="doSelectAllLocations"
              (change)="toggleSelectAllLocations()"
              [ngModelOptions]="{standalone: true}"
              >Select All Locations
            </mat-checkbox>
          </div>
          <ul class="location-list">
            <li *ngFor="let location of locationList" fxLayout="row">
              <mat-checkbox
                [(ngModel)]="location.visible"
                (change)="locationVisiblityChanged()"
                [ngModelOptions]="{standalone: true}"
              >
              </mat-checkbox>
              <div fxLayout="row" fxLayoutAlign="space-between none">
                {{location.name}}
                <mat-icon>directions_bus</mat-icon>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        fxLayout="column"
        fxFlex="60%"
        *ngIf="user.defaultAccountType === 'Vendor' && loggedInIsAdmin"
      >
        <h3>Vendor User Roles</h3>

        <mat-checkbox
          *ngFor="let role of vendorUserRoles"
          (change)="toggleVendorUserRole(role)"
          [checked]="vendorUserRolesFlags[role]"
        >
          {{displayVendorRole(role)}}
        </mat-checkbox>
      </div>
    </div>
  </form>
</div>
