'use strict'

// node_modules imports
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

// local imports
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { AdminGuard } from '../../core-user/client/admin.guard'
import { SystemManagementComponent } from './system-management.component'

const systemManagementRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [AdminGuard],
    canActivate: [AdminGuard],
    children: [
      { path: 'systemManagement', component: SystemManagementComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(systemManagementRoutes)],
  exports: [RouterModule]
})
export class SystemManagementRoutingModule {}
