<!--Side-nav Footer-->
<div class="nav-footer">
  <ul class="nav">
    <li>
      <a
        (click)="logout()"
        fxLayoutAlign="{{ state === 'closed' ? 'center' : 'start' }} center"
      >
        <mat-icon>input</mat-icon>
        <span class="nav-label pad-left" [hidden]="state === 'closed'"
          >Logout</span
        >
      </a>
    </li>
  </ul>
</div>
