import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PageNotFoundComponent } from './not-found.component'
import { RedirectComponent } from './redirect.component'

const appRoutes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'redirect', component: RedirectComponent },
  { path: '**', component: PageNotFoundComponent }
]

@NgModule({
  imports: [
    // TODO: figure out how to use PathLocationStrategy and turn useHash to false
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
