// tslint:disable:no-access-missing-member
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import * as moment from 'moment'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderService } from '../order.service'
import { ProductService } from '../../../product/client/product.service'
import { VendorService } from '../../../vendor/client/vendor.service'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['../order.component.scss'],
  providers: []
})
export class OrderItemsComponent extends OrderBaseComponent implements OnInit {
  @Output() logOrderModifications = new EventEmitter<any>()
  @Input() modifyMode = false
  inputDisabled = false
  bannerText: string | { line1: string; line2: string }

  constructor(
    private orderService: OrderService,
    private productService: ProductService,
    private vendorService: VendorService
  ) {
    super()
  }

  // NOTE: don't show inventory info to customers
  // Changing this logic can be dangerous!!
  get showInventoryInfo() {
    const hasInventoryAccess = ['stm', 'vendor'].includes(
      (this.coreUser.accountType ?? '').toLowerCase()
    )
    return hasInventoryAccess
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  ngOnInit() {
    super.ngOnInit()

    // if (this.order) {
    //     this.orderReviewTitle = 'Order #' + String(this.order.orderNumber);
    //     console.log('** OrderReviewComponent / ngOnInit / this.order ** ');
    //     console.log(this.order);
    //     console.log('ORDER EXISTS!!');
    //     if (this.order.regularItems.manufacturers.length) {
    //         console.log('There are regular items!!');
    //         this.regularOrdersExist = true;
    //     }
    //     if (this.order.forkliftItems.length) {
    //         console.log('There are forklift items!!');
    //         this.forkliftOrdersExist = true;
    //     }
    // }
    // determine if truly blank order Gman
  }

  setOrder(order) {
    super.setOrder(order)

    if (this.showInventoryInfo) {
      const vendor = this.order?.location?.vendors?.[0]?.vendor
      if (!vendor) {
        console.error('ngOnInit: vendor not found...')
        return
      }
      console.log('vendor', vendor)
      this.vendorService.getVendorById(vendor._id).subscribe((v) => {
        this.order?.regularItems?.manufacturers?.forEach((m) => {
          m.items.forEach((item) => {
            console.log('item', item)
            const tire = item?.tire
            const wheel = item?.wheel
            const productId = tire?._id || wheel?._id
            this.productService
              .getById(productId, {
                includeSummary: 1,
                storeNumber: v.storeNumber
              })
              .subscribe((p) => {
                console.log('product', p)
                if (tire) item.tire.summary = p.summary
                if (wheel) item.wheel.summary = p.summary
              })
          })
        })
      })
    }
    this.inputDisabled = this.modifyMode === false
    if (this.order) {
      if (this.order.orderType === 'RegularOrder') {
        this.order.noOrderText = ''
        if (
          (!this.order.forkliftItems || !this.order.forkliftItems.length) &&
          (!this.order.regularItems ||
            !this.order.regularItems.manufacturers.length)
        ) {
          if (this.order.isNoOrderNoPickup) {
            this.order.noOrderText = 'No Order and No Pickup This Week'
          } else if (this.order.isNoOrderWithPickup) {
            this.order.noOrderText = 'Pickup Only This Week'
          }
        } else {
          this.order.noOrderText = ''
        }
      } else if (this.order.orderType === 'FleetCheck') {
        this.bannerText = {
          line1: `Fleet Check`,
          line2: `Due ${moment(this.order.dueDate).format('ddd. M/D/YYYY')}`
        }
      }
    }
  }

  setUserVisibility() {
    this.isVisible = true
  }

  getItemCount(): number {
    return this.getRegularItemCount() + this.getForkliftItemCount()
  }

  getRegularItemCount(): number {
    return 0
  }

  getForkliftItemCount(): number {
    return 0
  }

  deleteRegularProduct(manufacturer, product) {
    for (
      let i = this.order.regularItems.manufacturers.length - 1;
      i >= 0;
      i--
    ) {
      const man = this.order.regularItems.manufacturers[i]
      if (man === manufacturer) {
        for (let j = man.items.length - 1; j >= 0; j--) {
          const item = man.items[j]
          if (item === product) {
            man.items.splice(j, 1)
          }
        }
        // remove manufacturer if no more items
        if (man.items.length === 0) {
          this.order.regularItems.manufacturers.splice(i, 1)
        }
        // this.orderLogComponent.logOrderModification('deleted ' + product.name, 'ORDER_MODIFIED');

        this.logOrderModifications.emit({
          type: 'productDeleted',
          item: product,
          isForklift: false
        })
        this.orderService.notifyItemRemovedFromCart(product)
      }
    }
  }

  deleteForkliftProduct(forklift, product) {
    this.order.forkliftItems.forEach((fork, index) => {
      if (fork === forklift) {
        this.order.forkliftItems.splice(index, 1)
      }
    })
    // this.orderLogComponent.logOrderModification('deleted ' + product.name, 'ORDER_MODIFIED');

    this.logOrderModifications.emit({
      type: 'productDeleted',
      item: product,
      forkliftNumber: forklift.forkliftNumber,
      isForklift: true
    })

    this.orderService.notifyItemRemovedFromCart(product)
  }

  quantityChange(item, forkliftNumber?) {
    let isForklift = false
    if (forkliftNumber !== null && forkliftNumber !== undefined) {
      isForklift = true
      // Forklift number and qty constraint is no longer required - 12/7/23
      // if (item.qty > 2) {
      // alert('You can only add 2 tires of each type per unit number')
      // item.qty = 2
      // return
      // }
    }
    this.logOrderModifications.emit({
      type: 'quantityChange',
      item,
      forkliftNumber,
      isForklift
    })
  }

  formatStockInfo(itemInfo: {
    summary: Record<string, any> | null
    [key: string]: any
  }) {
    const unavailableMsg = '(Stock info unavailable!)'
    if (itemInfo.summary === undefined) {
      return unavailableMsg
    }
    if (itemInfo.summary) {
      return `(Stock: ${itemInfo.summary.qtyAvailable})`
    }
    return unavailableMsg
  }

  getItemQuantity(itemInfo: {
    summary: Record<string, any> | null
    [key: string]: any
  }): number {
    console.log('itemInfo', itemInfo.summary)
    if (!('summary' in itemInfo)) {
      console.error('getItemQuantity: summary not found in itemInfo')
      return 0
    }
    if (itemInfo.summary) {
      return itemInfo.summary.qtyAvailable
    }
    return 0
  }

  getStockInfoClasses(
    requestedQuantity: number,
    stockQuantity: number
  ): string {
    const defaultClasses = ['order-stock']
    const adequateClasses = ['order-stock--adequate']
    const inadequateClasses = ['order-stock--inadequate']
    if (!requestedQuantity || !stockQuantity) {
      return defaultClasses.concat(inadequateClasses).join(' ')
    }
    const isAdequate =
      requestedQuantity + this.getOptimalQuantity() <= stockQuantity
    const newClasses = isAdequate ? adequateClasses : inadequateClasses
    return defaultClasses.concat(newClasses).join(' ')
    // return isAdequate ? 'order-stock--adequate' : "order-stock--inadequate";
  }

  getOptimalQuantity() {
    return this.productService.getOptimalQuantity()
  }
}
