<div class="container-widget-shadow-base">
  <div class="container-widget">
    <!--Container Widget Header-->
    <div
      class="container-widget-header"
      [ngStyle]="{ 'background-color': headerBarColor }"
    >
      <div class="container-widget-title">
        <i
          *ngIf="materialIcon"
          class="container-widget-icon material-icons"
          [ngStyle]="{ color: iconColor || 'white' }"
          >{{ materialIcon }}</i
        >
        <i
          *ngIf="faIcon"
          class="container-widget-icon fa-icon {{ faIcon }}"
          [ngStyle]="{ color: iconColor || 'white' }"
        ></i>
        {{ title }}
      </div>
    </div>

    <ng-content></ng-content>
  </div>
</div>
