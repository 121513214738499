// tslint:disable:no-access-missing-member
import { of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import {
  Component,
  EventEmitter,
  Inject,
  Injectable,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild,
  ViewEncapsulation
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { isNil } from 'lodash'
import '../../core/client/utils/array.extensions'
import { ProductService } from '../../product/client/product.service'
import { VendorService } from '../../vendor/client/vendor.service'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { OrderService } from '../../order/client/order.service'
// import {Items} from './items-order.model';
import { LocationService } from '../../location/client/location.service'
import { FleetService } from '../../fleet/client/fleet.service'
import { PopTartService } from '../../core/client/poptart/poptart.service'
import { OrderComponent } from './order.component'

class ProductList {
  tires: any[]
  rim: any
  forkliftNumber: number
  qty = 1

  constructor(public type: string, public use: string) {}
}

@Pipe({
  name: 'appWheelMatchFilterPipe',
  pure: false
})
@Injectable()
export class WheelMatchFilterPipe implements PipeTransform {
  transform(items: any[], wheelMatch: string, productUse: string): any {
    return items.filter((item) => item.wheelMatch === wheelMatch)
  }
}

@Component({
  selector: 'app-new-order',
  templateUrl: './order-builder.component.html',
  styleUrls: ['./order-builder.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class OrderBuilderComponent implements OnInit, OnDestroy {
  coreUser: any
  order: any
  orderId: string
  orderType: string
  orderFleet: any
  orderLocation: any
  paramSub: any
  type: string
  use: string
  orderButtonClicked: boolean
  useButtonClicked: boolean
  showNew = false
  showRetread = false
  showForklift = false
  showTrailer = false
  showSteer = false
  showDrive = false
  showWheel = false
  showUseCategories = false

  // NOTE: don't show inventory info to customers
  // DON'T TURN THIS ON
  showInventoryInfo = false

  productList: any[] = []
  currentProductList: ProductList = null
  currentVendor: any
  wheels: any[] = []
  looseWheelProducts: any[] = []
  newDriveProducts: any[] = []
  newTrailerProducts: any[] = []
  newSteerProducts: any[] = []
  retreadDriveProducts: any[] = []
  retreadSteerProducts: any[] = []
  retreadTrailerProducts: any[] = []
  forkliftDriveProducts: any[] = []
  forkliftSteerProducts: any[] = []
  wheelSelected = false
  selectedType: 'NEW TIRE' | 'RETREAD' | 'FORKLIFT' = null
  selectedUse: 'DRIVE' | 'STEER' | 'TRAILER' = null

  @Output() logOrderModifications = new EventEmitter<any>()
  @ViewChild(OrderComponent, { static: true })
  private orderComponent: OrderComponent

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private coreUserService: CoreUserService,
    private orderService: OrderService,
    private locationService: LocationService,
    private vendorService: VendorService,
    private fleetService: FleetService,
    private popTartService: PopTartService
  ) {
    this.coreUserService.getLoggedInUser().subscribe((user) => {
      this.coreUser = user
    })
  }

  get isStmVendor(): boolean {
    const v = this.currentVendor
    return (
      v &&
      (v?.company?.toLowerCase() === 'southern tire mart' ||
        v?.name?.toLowerCase().contains('stm') ||
        !isNil(v?.storeNumber))
    )
  }

  ngOnInit() {
    this.resetView()
    this.paramSub = this.route.queryParams
      .pipe(
        mergeMap((params) => {
          this.orderId = params.orderId
          this.orderType = params.orderType
          return this.getOrder()
        }),
        mergeMap(() => {
          if (this.orderType === 'RegularOrder') {
            return this.productService.getAllProducts()
          } else {
            return of(null)
          }
        })
      )
      .subscribe((products) => {
        const currentLocation = this.orderLocation
        console.log('onInit currentLocation', currentLocation)
        if (this.orderType === 'RegularOrder') {
          this.productList = products
          const locationProductMap = {}
          if (this.orderLocation.productList.useFleetDefault) {
            this.orderFleet.defaultProductList.forEach((p) => {
              locationProductMap[p.product] = p
            })
          } else {
            this.orderLocation.productList.products.forEach((p) => {
              locationProductMap[p.product] = p
            })
          }
          console.info('ORDERFLEET', this.orderFleet)
          products.forEach((product: any) => {
            let key = product.productType
            product.qty = 1
            key += `_${product.productUse || ''}`

            const locationProduct = locationProductMap[product._id]
            // if (this.orderFleet.options && this.orderFleet.options.mountedWheelsProgram) {
            //     product.requiresWheelSelection = true;
            //     // product.requiresVehicleNumber = locationProduct.requiresVehicleNumber;
            // }
            if (locationProduct) {
              switch (key) {
                case 'NEW TIRE_DRIVE':
                  this.newDriveProducts.push(product)
                  this.showNew = true
                  break
                case 'NEW TIRE_TRAILER':
                  this.newTrailerProducts.push(product)
                  this.showNew = true
                  break
                case 'NEW TIRE_STEER':
                  this.newSteerProducts.push(product)
                  this.showNew = true
                  break
                case 'RETREAD_DRIVE':
                  this.retreadDriveProducts.push(product)
                  this.showRetread = true
                  break
                case 'RETREAD_STEER':
                  this.retreadSteerProducts.push(product)
                  this.showRetread = true
                  break
                case 'RETREAD_TRAILER':
                  this.retreadTrailerProducts.push(product)
                  this.showRetread = true
                  break
                case 'FORKLIFT_DRIVE':
                  this.forkliftDriveProducts.push(product)
                  this.showForklift = true
                  break
                case 'FORKLIFT_STEER':
                  this.forkliftSteerProducts.push(product)
                  this.showForklift = true
                  break
                case 'WHEEL_':
                  if (
                    this.orderFleet.options &&
                    this.orderFleet.options.mountedWheelsProgram
                  ) {
                    if (this.showInventoryInfo) {
                      if (!this.hasValidVendor()) {
                        return
                      }
                      const vendorAtLocation =
                        currentLocation?.vendors?.[0]?.vendor
                      if (!vendorAtLocation) {
                        this.popTartService.show(
                          'error',
                          'No vendor at location'
                        )
                        return
                      }
                      this.vendorService
                        .getVendorById(vendorAtLocation._id)
                        .subscribe((v) => {
                          this.currentVendor = v
                          console.log('vendorAtLocation', this.currentVendor)
                          this.productService
                            .getById(product._id, {
                              includeSummary: 1,
                              storeNumber: this.currentVendor.storeNumber
                            })
                            .subscribe((p) => {
                              console.log('product', product)
                              product.summary = p.summary
                              this.wheels.push(product)
                              this.showWheel = false
                            })
                        })
                    } else {
                      this.wheels.push(product)
                      this.showWheel = false
                    }
                  } else {
                    this.looseWheelProducts.push(product)
                    this.showWheel = true
                  }
                  break
                default:
                  /* tslint:disable-next-line:no-console */
                  console.error(
                    `Invalid product type or use ${key}. The following product will not be visible:`
                  )
                  /* tslint:disable-next-line:no-console */
                  console.error(product)
              }
            }
          })
        }
      })
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe()
  }

  hasValidVendor() {
    const currentLocation = this.orderLocation
    if (currentLocation?.vendors && currentLocation?.vendors?.length <= 0) {
      this.popTartService.show('error', 'No vendor at location.')
      console.error('No vendor at location.')
      return false
    }
    return true
  }

  getOrder() {
    if (this.orderId) {
      return this.orderService.getOrderById(this.orderId, this.orderType).pipe(
        mergeMap((order) => {
          this.orderLocation = order.location
          this.order = order
          this.orderComponent.setOrder(order)
          return this.getOrderFleet()
        }),
        mergeMap((fleet) => {
          this.orderFleet = fleet
          return of(this.order)
        })
      )
    } else if (this.coreUser.accountType === 'Location') {
      return this.orderService
        .getUserLocationProcessingOrder('RegularOrder')
        .pipe(
          mergeMap((order) => {
            this.orderLocation = order.location
            this.order = order
            this.orderComponent.setOrder(order)
            return this.getOrderFleet()
          }),
          mergeMap((fleet) => {
            this.orderFleet = fleet
            return of(this.order)
          })
        )
    } else {
      this.orderComponent.setOrder(null)
      return of(null)
    }
  }

  getOrderFleet() {
    const fleetId = this.order
      ? this.order.location.fleet._id || this.order.location.fleet
      : null
    if (fleetId) {
      return this.fleetService.getFleetById(fleetId)
    } else {
      return of(null)
    }
  }

  setSelectedType(type) {
    this.selectedType = type
    this.selectedUse = null
    this.currentProductList = null
    this.showDrive = false
    this.showTrailer = false
    this.showSteer = false
    switch (type) {
      case 'NEW TIRE':
        this.showDrive = this.newDriveProducts.length > 0
        this.showTrailer = this.newTrailerProducts.length > 0
        this.showSteer = this.newSteerProducts.length > 0
        this.showUseCategories = true
        break
      case 'RETREAD':
        this.showDrive = this.retreadDriveProducts.length > 0
        this.showTrailer = this.retreadTrailerProducts.length > 0
        this.showSteer = this.retreadSteerProducts.length > 0
        this.showUseCategories = true
        break
      case 'FORKLIFT':
        this.showDrive = this.forkliftDriveProducts.length > 0
        this.showTrailer = false
        this.showSteer = this.forkliftSteerProducts.length > 0
        this.showUseCategories = true
        break
      case 'WHEEL':
        this.showUseCategories = false
        this.showWheel = this.looseWheelProducts.length > 0
        this.setSelectedUse('ALL')
        break
      default:
        /* tslint:disable-next-line:no-console */
        console.error(`Invalid product type ${type}.`)
    }
  }

  setSelectedUse(use) {
    const productUse = use || ''
    this.selectedUse = productUse
    this.currentProductList = new ProductList(this.selectedType, productUse)
    const currentLocation = this.orderLocation
    const key = `${this.selectedType}_${productUse}`

    switch (key) {
      case 'NEW TIRE_DRIVE':
        this.currentProductList.tires = this.newDriveProducts
        break
      case 'NEW TIRE_TRAILER':
        this.currentProductList.tires = this.newTrailerProducts
        break
      case 'NEW TIRE_STEER':
        this.currentProductList.tires = this.newSteerProducts
        break
      case 'RETREAD_DRIVE':
        this.currentProductList.tires = this.retreadDriveProducts
        break
      case 'RETREAD_STEER':
        this.currentProductList.tires = this.retreadSteerProducts
        break
      case 'RETREAD_TRAILER':
        this.currentProductList.tires = this.retreadTrailerProducts
        break
      case 'FORKLIFT_DRIVE':
        this.currentProductList.tires = this.forkliftDriveProducts
        break
      case 'FORKLIFT_STEER':
        this.currentProductList.tires = this.forkliftSteerProducts
        break
      case 'WHEEL_ALL':
        this.currentProductList.tires = this.looseWheelProducts
        break
      default:
        /* tslint:disable-next-line:no-console */
        console.error(`Invalid product type or use ${key}`)
    }

    if (this.showInventoryInfo) {
      if (currentLocation.vendors.length > 0) {
        const vendorAtLocation = currentLocation.vendors[0].vendor
        this.vendorService
          .getVendorById(vendorAtLocation._id)
          .subscribe((vendor) => {
            this.currentVendor = vendor
            console.log('vendorAtLocation', this.currentVendor)
            this.currentProductList.tires.forEach((tire) => {
              console.log('tire', tire._id)
              console.log('currentVendor', this.currentVendor)
              this.productService
                .getById(tire._id, {
                  includeSummary: 1,
                  storeNumber: this.currentVendor.storeNumber
                })
                .subscribe((product) => {
                  console.log('product', product)
                  tire.summary = product.summary
                })
            })
          })
      } else {
        this.popTartService.show('error', 'No vendor at location.')
      }
    }
  }

  getQuantityFor(productId: string, storeNumber: string) {
    // const {quantity} = this.productService.getInventoryInfo(productId, storeNumber);
    return 10
  }

  addToOrder(product) {
    if (
      this.currentProductList.type !== 'WHEEL' &&
      this.currentProductList.type !== 'FORKLIFT' &&
      !product.rim &&
      this.orderFleet.options &&
      this.orderFleet.options.mountedWheelsProgram
    ) {
      alert('Wheel type is required.')
      return
    }

    if (this.currentProductList.type === 'FORKLIFT') {
      // Forklift number and qty constraint is no longer required - 12/7/23
      // if (!product.forkliftNumber) {
      //   alert('Forklift number is required.')
      //   return
      // }
      //
      // if (product.qty > 2) {
      //   alert('You can only add 2 tires of each type per unit number')
      //   return
      // }
    }

    product.addingToOrder = true

    // set timeout so we see spinner for minimum amount of time, otherwise just looks like a glitch
    setTimeout(() => {
      product.addingToOrder = false
      this.orderComponent.addToCart(product)
      this.resetProduct(product)
    }, 500)
  }

  resetProduct(product) {
    product.qty = 1
    product.rim = null
    product.forkliftNumber = null
  }

  formatStockInfo(stockInfo: Record<string, any> | null) {
    if (stockInfo === undefined) {
      return ''
    }
    if (stockInfo) {
      return `${stockInfo?.qtyAvailable} in stock`
    }
    return 'Stock info unavailable.'
  }

  optimalStockQuantity() {
    return this.productService.getOptimalQuantity()
  }

  /**
   * reset view
   */
  resetView() {
    this.orderButtonClicked = false
    this.useButtonClicked = false
    this.wheelSelected = false
    this.selectedType = null
    this.selectedUse = null
    this.currentProductList = null
    this.showDrive = false
    this.showTrailer = false
    this.showSteer = false
  }

  onChangeWheel(product, wheelObj) {
    product.rim = wheelObj
    this.wheelSelected = true
  }
}
