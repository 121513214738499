// tslint:disable:no-access-missing-member
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core'
import { StateEnum } from '../../order-state.enum'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'
import { OrderDocumentsComponent } from './order-documents.component'

@Component({
  selector: 'app-order-po-service',
  template: `
    <div *ngIf="isVisible" class="app-order-po order-part">
      <div class="po-input">
        <app-container-widget
          title="Service Purchase Orders"
          faIcon="fas fa-wrench"
        >
          <mat-card class="card-po w-full">
            <ul *ngIf="order && order.orderType !== 'ServiceCall'">
              <li *ngIf="order" style="list-style: none" class="service-po-li">
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="50%">
                    <h2 fxFlex="100%">Standard Service PO</h2>
                  </div>
                  <div fxFlex="50%">
                    <p>
                      PO #&nbsp;
                      <strong *ngIf="inputDisabled">{{
                        order.regularItems.servicePO
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="order.regularItems.servicePO"
                        placeholder="SERVICE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
              <li
                *ngFor="let manufac of order?.forkliftItems"
                class="service-po-li"
              >
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="50%">
                    <h2 fxFlex="100%">
                      Forklift Number
                      {{ manufac.forkliftNumber }}
                    </h2>
                  </div>
                  <div fxFlex="50%">
                    <p>
                      PO #&nbsp;
                      <strong *ngIf="inputDisabled">{{
                        manufac.servicePONumber
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="manufac.servicePONumber"
                        placeholder="SERVICE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
              <li
                *ngFor="let extraPO of order?.additionalServicePO"
                class="service-po-li"
              >
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="50%">
                    <h2 fxFlex="100%">
                      {{ extraPO.description }}
                    </h2>
                  </div>
                  <div fxFlex="50%">
                    <p>
                      PO #&nbsp;
                      <strong *ngIf="inputDisabled">{{
                        extraPO.poNumber
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="extraPO.poNumber"
                        placeholder="SERVICE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <ul *ngIf="order && order.orderType === 'ServiceCall'">
              <li
                *ngFor="let po of requiredPOs; let i = index"
                class="service-po-li"
              >
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="50%">
                    <p>
                      Service PO
                      #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <strong *ngIf="inputDisabled">{{
                        order.servicePONumbers[i]
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="order.servicePONumbers[i]"
                        placeholder="SERVICE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
              <li
                *ngFor="let extraPO of order?.additionalServicePO"
                class="service-po-li"
              >
                <div fxLayout="row" fxFlex="100%">
                  <div fxFlex="50%">
                    <p>
                      {{ extraPO.description }} PO #&nbsp;
                      <strong *ngIf="inputDisabled">{{
                        extraPO.poNumber
                      }}</strong>
                      <input
                        *ngIf="!inputDisabled"
                        [(ngModel)]="extraPO.poNumber"
                        placeholder="SERVICE PO#"
                      />
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </mat-card>
        </app-container-widget>
      </div>
    </div>
  `,
  styleUrls: ['../order.component.scss'],
  providers: []
})
export class OrderPOServiceComponent
  extends OrderBaseComponent
  implements OnInit, OnChanges
{
  inputDisabled = true
  requiredPOs: any[] = []
  @Input() orderDocuments: any[]

  constructor() {
    super()
  }

  ngOnInit() {
    super.ngOnInit()
  }

  ngOnChanges() {
    this.requiredPOs =
      this.orderDocuments && this.orderDocuments.length
        ? this.orderDocuments.filter(
            (d) => d.fileIdentity === 'service_wo' && d.visibleToClient
          )
        : []
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      this.coreUser.accountType !== 'Location' &&
      !this.order.isNoOrderNoPickup &&
      !this.order.isNoOrderWithPickup &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes('po-service'))
  }

  setOrder(order) {
    super.setOrder(order)

    this.inputDisabled =
      this.coreUser.accountType === 'Vendor' ||
      this.coreUser.accountType === 'Stm' ||
      (this.order.location.fleet._id === '58f1253791541b40c8d03c14' &&
        this.coreUser.accountType === 'Location')

    this.order.servicePONumbers = this.order.servicePONumbers || []
  }
}
