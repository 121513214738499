import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  CoreResponse,
  CoreResponseHandler,
  ResponseHandler
} from './core/client/client-error-handler/error-handler.class'
import { PopTartService } from './core/client/poptart/poptart.service'

class DefaultResponseHandler implements ResponseHandler {
  constructor(private popTartService: PopTartService) {}

  handleSuccess(successResponse: CoreResponse): void {
    this.popTartService.show(
      'success',
      successResponse.message,
      '',
      'toast-bottom-center'
    )
  }

  handleFailure(errors: CoreResponse[]): void {
    errors.forEach((err) => {
      this.popTartService.show('error', err.message)
    })
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private popTartService: PopTartService) {}

  ngOnInit() {
    CoreResponseHandler.setDefaultResponseHandler(
      new DefaultResponseHandler(this.popTartService)
    )
    this.router.navigate(['/dashboard'], {})
  }
}
