import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'

@Injectable()
export class VendorService {
  vendorsURL = `${environment.apiUrl}/vendor`

  constructor(private http: HttpClient) {}

  // Get all posts from the API
  getVendors(searchParams: object = {}): Observable<any[]> {
    let params = new HttpParams()

    Object.keys(searchParams).forEach((key) => {
      params = params.append(key, searchParams[key])
    })

    return this.http.get<any[]>(this.vendorsURL, { params })
  }

  getVendorById(vendorId): Observable<any> {
    return this.http.get<any>(`${this.vendorsURL}/${vendorId}`)
  }

  save(vendor: any): Observable<any> {
    if (vendor._id) {
      return this.http.put<any>(`${this.vendorsURL}/${vendor._id}`, vendor)
    } else {
      return this.http.post<any>(this.vendorsURL, vendor)
    }
  }

  update(vendor: any): Observable<any> {
    return this.http.put<any>(`${this.vendorsURL}/${vendor._id}`, vendor)
  }

  GetSTMVendors(unregisteredOnly = false): Observable<any[]> {
    let url = `${this.vendorsURL}/stmVendors`
    url += unregisteredOnly ? '?unregisteredOnly=true' : ''
    return this.http.get<any>(url)
  }
}
