<div class="nav-container" [ngClass]="state">
  <a class="closebtn" (click)="setState('closed')">&times;</a>
  <!--Side-nav Header-->
  <app-side-nav-header #header></app-side-nav-header>

  <!--Side-nav Links-->
  <ul class="nav">
    <li
      *ngFor="let navRoute of navRoutes"
      [ngClass]="{ active: activeRoute(navRoute.route) }"
    >
      <a
        [routerLink]="[navRoute.route]"
        fxLayoutAlign="{{ state === 'closed' ? 'center' : 'start' }} center"
      >
        <mat-icon>{{ navRoute.icon }}</mat-icon>
        <span class="nav-label pad-left" [hidden]="state === 'closed'">{{
          navRoute.name
        }}</span>
      </a>
    </li>
  </ul>

  <!--Side-nav Footer-->
  <app-side-nav-footer #footer></app-side-nav-footer>
</div>
