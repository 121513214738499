import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '../../../environments/environment'

@Injectable()
export class LocationService {
  locationsURL = `${environment.apiUrl}/location`

  constructor(private http: HttpClient) {}

  // Get locations from the API
  getLocations(searchParams: object = {}): Observable<any[]> {
    let params = new HttpParams()

    Object.keys(searchParams).forEach((key) => {
      params = params.append(key, searchParams[key])
    })

    return this.http.get<any[]>(this.locationsURL, { params })
  }

  getAllowedLocations(): Observable<any[]> {
    return this.http.get<any[]>(`${this.locationsURL}/allowedLocations`)
  }

  getUserServiceCallLocations(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.locationsURL}/allowedLocations?serviceCallLocationsOnly=true`
    )
  }

  getServiceCallLocations(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.locationsURL}?serviceCallLocationsOnly=true`
    )
  }

  // Get list of locations that the user can see with a naughty boolean flag
  getNaughtyNiceList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.locationsURL}/naughty-nice-list`)
  }

  broadcastNaughtyListReminder() {
    return this.http.post(`${this.locationsURL}/naughty-list-reminder`, {})
  }

  getLocationById(locationId) {
    return this.http.get(`${this.locationsURL}/${locationId}`)
  }

  save(location: any) {
    if (location._id) {
      return this.http.put(`${this.locationsURL}/${location._id}`, location)
    } else {
      return this.http.post(this.locationsURL, location)
    }
  }

  update(location: any) {
    return this.http.put(`${this.locationsURL}/${location._id}`, location)
  }
}
