// tslint:disable:no-access-missing-member
import * as moment from 'moment'

import { Component, OnInit, ViewChild } from '@angular/core'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-delivery-date',
  templateUrl: './order-delivery-date.component.html',
  styleUrls: [
    '../order.component.scss',
    './order-delivery-date.component.scss'
  ],
  providers: []
})
export class OrderDeliveryDateComponent
  extends OrderBaseComponent
  implements OnInit
{
  selectedDate: { year: number; month: number; day: number }
  myDatePickerOptions = { inline: true }
  nextDefaultDeliveryDay: string
  nextDefaultDeliveryDate: Date = null
  viewDate = new Date()

  // TODO load in holidays as events and prevent click
  events = []
  /* events: CalendarEvent[] = [{
     start: new Date(2017,3,19),
     title: 'Test',
     color: {primary:'#ad2121',secondary:'#FAE3E3'},
     }]; */

  constructor() {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  onDateChanged(ev) {
    if (!this.order.delivery) {
      this.order.delivery = {}
    }
    this.order.delivery.estimated = ev.jsdate
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      !this.order.isNoOrderNoPickup &&
      !this.order.isNoOrderWithPickup &&
      (this.coreUser.accountType === 'Vendor' ||
        this.coreUser.accountType === 'Stm') &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes('delivery-date'))
  }

  setOrder(order) {
    super.setOrder(order)

    this.getNextDefaultDeliveryDate()
  }

  getNextDefaultDeliveryDate() {
    const tomorrow = moment().add(1, 'days').startOf('day')

    // get the current delivery date off the order, if already set (it should be set upon order creation)
    const deliveryDate: Date =
      this.order.delivery && this.order.delivery.estimated
        ? moment(this.order.delivery.estimated).toDate()
        : null

    // Calculate the next delivery date/day of week
    // Find the next possible delivery day, based on the location's set delivery days, starting with tomorrow (so the delivery date is in the future)
    if (
      this.order.location &&
      this.order.location.vendors[0].deliveryDays &&
      this.order.location.vendors[0].deliveryDays.length
    ) {
      const defaultOrderDays = this.order.location.vendors[0].deliveryDays
      // starting with tomorrow, find the next available delivery date
      // Only check 7 days in the future, so we don't loop forever if no delivery day is set
      for (let i = 0; i < 7; i++) {
        const defaultDateMoment = moment(tomorrow).add(i, 'days').startOf('day')
        const defaultDateDayOfWeek = defaultDateMoment.format('dddd')
        if (defaultOrderDays.includes(defaultDateDayOfWeek)) {
          this.nextDefaultDeliveryDay = defaultDateDayOfWeek // set the next delivery day of week
          this.nextDefaultDeliveryDate = defaultDateMoment.toDate() // set the next delivery date
          break
        }
      }
    }

    // set the order's delivery date to the default if it's not already set (even though it should be)
    if (!this.order.delivery) {
      this.order.delivery = {}
    }
    this.order.delivery.estimated =
      deliveryDate || this.nextDefaultDeliveryDate || null

    // set the date on the calendar to the current order delivery date
    this.selectedDate = this.order.delivery.estimated && {
      year: this.order.delivery.estimated.getFullYear(),
      month: this.order.delivery.estimated.getMonth() + 1,
      day: this.order.delivery.estimated.getDate()
    }
  }
}
