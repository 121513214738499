import { Directive, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[appDashboardDirective]'
})
export class DashboardDirective {
  constructor(public viewContainerRef: ViewContainerRef) {
    //  console.log('Inside DashboardDirective constructor!');
  }
}
