import { Component, OnInit } from '@angular/core'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'

@Component({
  selector: 'app-order-state',
  templateUrl: './order-state.component.html',
  styleUrls: ['./order-state.component.scss'],
  providers: []
})
export class OrderStateComponent extends CoreResponseHandler implements OnInit {
  ngOnInit() {}

  handleResponse(response: CoreResponse): boolean {
    return false
  }
}
