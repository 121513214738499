<div *ngIf="isVisible" class="app-order-documents order-part">
  <app-container-widget
    title="Document Upload"
    *ngIf="
      coreUser.accountType !== 'Fleet' && coreUser.accountType !== 'Location'
    "
  >
    <div class="app-list">
      <!-------------------->
      <!-- Upload Section -->
      <!-------------------->
      <ul class="upload-list upload-list-top">
        <li><strong>Required Documents</strong></li>
        <li
          *ngIf="
            cat === 'Required Documents' &&
            !requiredUploads.length &&
            order.orderType !== 'FleetCheck'
          "
          class="no-docs-message"
        >
          You have no required documents to upload.
        </li>
        <li *ngFor="let doc of requiredUploads">
          <app-order-document-upload
            [document]="doc"
            [attachmentId]="order._id"
            [fleet]="order.location.fleet"
            (documentUploaded)="updateOrderDocuments($event)"
          ></app-order-document-upload>
        </li>
      </ul>
      <ul class="upload-list upload-list-top">
        <li><strong>Additional Documents</strong></li>
        <li *ngFor="let doc of additionalUploads">
          <app-order-document-upload
            [document]="doc"
            [attachmentId]="order._id"
            [fleet]="order.location.fleet"
            (documentUploaded)="updateOrderDocuments($event)"
          ></app-order-document-upload>
          <div
            class="sales-po-item-container"
            fxLayout="column"
            *ngIf="doc.description === 'Other'"
          >
            <mat-checkbox
              *ngIf="order?.hasWheelsOnly === false"
              name="isWheelsOnly"
              [(ngModel)]="doc.isWheelsOnly"
              (change)="isWheelsOnlyChanged(doc)"
              >This is for Wheels Only</mat-checkbox
            >
            <mat-checkbox
              *ngIf="order.needsBONL && order?.hasBONL === false"
              name="isBONL"
              [(ngModel)]="doc.isBONL"
              (change)="isBONLChanged(doc)"
              >This is a BON-L Ticket</mat-checkbox
            >
          </div>
        </li>
      </ul>
      <!--<ul *ngFor="let cat of uploadCategories"
                [ngClass]="{'upload-list':1, 'upload-list-top': cat === 'Required Documents' || cat === 'Fleet Check Service POs',
                        'upload-list-bottom': cat === 'Additional Documents'}">
                <li><strong>{{cat}}</strong></li>
                <li *ngIf="cat === 'Required Documents' && !requiredUploads.length && order.orderType !== 'FleetCheck'" class="no-docs-message">
                    You have no required documents to upload.
                </li>
                <li *ngIf="cat === 'Additional Documents' && !additionalUploads.length" class="no-docs-message">
                    You have no required documents to upload.
                </li>
                <li *ngFor="let doc of (cat === 'Required Documents' ? requiredUploads : (cat === 'Fleet Check Service POs' ? fleetCheckServicePOs : additionalUploads))">
                    <form (submit)="updateOrderAndUploadFile(doc)">
                        <div fxLayout="row">
                            <h4 class="document-display-name">{{doc.displayName}}</h4>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="32%">
                                <input name="docNumber" class="document-input"
                                       [(ngModel)]="doc.number"
                                       placeholder="{{doc.placeholder}}"/>
                            </div>
                            <div fxFlex="68%" fxLayoutAlign="start center">
                                <button type="button" mat-raised-button
                                        class="mat-raised file-btn file-choose-btn"
                                        (click)="chooseFile(doc)">
                                    Choose
                                </button>
                                <input class="hide-me"
                                       name="upload"
                                       #fileInput type="file"
                                       [(ngModel)]="doc.selectedFile"
                                       (change)="fileInputChanged(doc)"/>
                                <button *ngIf="!doc.uploading" [disabled]="doc.invalidType" mat-raised-button class="mat-raised file-btn file-upload-btn">
                                    Upload
                                </button>
                                <button *ngIf="doc.uploading" [disabled]="true" mat-raised-button class="mat-raised file-btn file-upload-btn">
                                    Uploading <img src="../../../../assets/images/spinner.gif" height="25" />
                                </button>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlexOffset="32%" fxLayoutAlign="start center" fxFlex>
                                <div *ngIf="!doc.invalidType" class="filename" [appFileEllipses]="doc.selectedFileName"></div>
                                <div *ngIf="doc.invalidType" class="filename error-message">Must be a PDF, image, or text file</div>
                            </div>
                        </div>
                        &lt;!&ndash; TODO figure out how to get rid of this hardcoded ACT stuff &ndash;&gt;
                        <div *ngIf="doc.fileId ==='other'" class="sales-po-item-container" fxLayout="column">
                            <mat-checkbox
                                *ngIf="order?.hasWheelsOnly === false"
                                name="isWheelsOnly"
                                [(ngModel)]="doc.isWheelsOnly" (change)="isWheelsOnlyChanged(doc)">This is for Wheels Only</mat-checkbox>
                            <mat-checkbox
                                *ngIf="order.needsBONL && order?.hasBONL === false"
                                name="isBONL"
                                [(ngModel)]="doc.isBONL" (change)="isBONLChanged(doc)">This is a BON-L Ticket</mat-checkbox>
                        </div>

                    </form>
                </li>
            </ul>-->
    </div>
  </app-container-widget>

  <!---------------------->
  <!-- Download Section -->
  <!---------------------->
  <app-container-widget title="Document Review">
    <div
      *ngIf="!downloadDocuments || !downloadDocuments.length"
      class="no-docs-message"
    >
      You have no documents to review.
    </div>
    <div class="app-list">
      <div *ngIf="downloadDocuments && downloadDocuments.length">
        <ul>
          <li>
            <div fxLayout="row">
              <div
                fxFlex="15%"
                fxLayoutAlign="start center"
                *ngIf="coreUser.accountType === 'Stm'"
              >
                Show Client?
              </div>
              <div
                fxFlex="{{ coreUser.accountType === 'Stm' ? '83%' : '98%' }}"
                fxLayoutAlign="start center"
              >
                Click icon to download
              </div>
              <div fxFlex="2%"></div>
            </div>
          </li>
          <li *ngFor="let doc of downloadDocuments">
            <div fxLayout="row">
              <div
                fxFlex="15%"
                fxLayoutAlign="start center"
                *ngIf="coreUser.accountType === 'Stm'"
              >
                <mat-checkbox
                  [(ngModel)]="doc.existingDocument.visibleToClient"
                  (change)="updateDocument(doc)"
                >
                </mat-checkbox>
              </div>
              <a
                class="download-box"
                href="{{ doc.existingDocument.downloadUrl }}"
                target="_blank"
                fxFlex="{{ coreUser.accountType === 'Stm' ? '83%' : '98%' }}"
              >
                <div fxLayoutAlign="start center" fxLayout="row">
                  <div fxLayout="5%" fxFlexAlign="start center">
                    <img
                      src="../../../../assets/images/{{
                        doc.existingDocument.icon
                      }}"
                      width="30"
                      height="30"
                      class="file-icon"
                    />
                  </div>
                  <div fxLayout fxFlexAlign="start center">
                    {{ doc.existingDocument.description }}
                  </div>
                </div>
              </a>
              <div fxLayoutAlign="end center" fxFlex="2%">
                <button
                  mat-raised-button
                  (click)="deleteDocument(doc)"
                  *ngIf="coreUser.accountType !== 'Fleet'"
                >
                  <i class="material-icons">delete</i>
                </button>
              </div>
            </div>
            <small>
              Uploaded by
              <i>
                {{
                  doc.existingDocument.createdBy &&
                  doc.existingDocument.createdBy.displayName
                    ? doc.existingDocument.createdBy.displayName
                    : 'Unknown User'
                }}
              </i>
              -
              <i>
                {{
                  doc.existingDocument.createdDate
                    | momentFormatPipe: 'MMM D, YYYY h:mm:ss A'
                }}
              </i>
            </small>
          </li>
          <li *ngIf="gettingDocuments">Loading documents...</li>
        </ul>
      </div>
    </div>
  </app-container-widget>
</div>
