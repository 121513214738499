import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CoreUserService } from '../../../core-user/client/core-user.service'

export class TopBarIcons {
  constructor(
    public name: string,
    public icon: string,
    public tooltip: string,
    public route: string
  ) {
    this.name = name
    this.icon = icon
    this.tooltip = tooltip
    this.route = route
  }
}

export interface ITopBarIcons {
  navRoutes: Array<TopBarIcons>

  addIcons(routes: Array<TopBarIcons>): void
  clearIcons(): void
}

@Component({
  selector: 'app-top-nav',
  templateUrl: 'top-nav.component.html',
  styleUrls: ['top-nav.component.scss'],
  providers: []
})
export class TopNavComponent implements OnInit {
  static icons: Array<TopBarIcons> = []

  static addIcons(routes: Array<TopBarIcons>): void {
    routes.forEach((route) => {
      TopNavComponent.icons.push(route)
    })
  }

  static clearIcons(): void {
    TopNavComponent.icons = []
    const iconsLength = TopNavComponent.icons.length
    for (let i = 0; i < iconsLength; i++) {
      TopNavComponent.icons.pop()
    }
  }

  icons: Array<TopBarIcons> = []
  loggedInUser: any = {}
  showAccountSwitcher = false
  // user = this.coreUserService.getLoggedInUser();

  @Output() toggleSideNavEmit: EventEmitter<any> = new EventEmitter()

  constructor(private coreUserService: CoreUserService) {
    this.coreUserService.getLoggedInUser().subscribe((user) => {
      this.loggedInUser = user
    })
  }

  ngOnInit() {
    const accountTypeCanPlaceOrder =
      this.loggedInUser.accountType === 'Location' ||
      this.loggedInUser.accountType === 'Fleet'
    const user = this.loggedInUser
    const accountId = (
      user && user.account ? user.account._id || user.account : 'null'
    ).toString()
    const defaultAccountId = (
      user && user.defaultAccount
        ? user.defaultAccount._id || user.defaultAccount
        : 'null'
    ).toString()
    const isDefaultAccount = accountId === defaultAccountId
    this.showAccountSwitcher =
      (accountTypeCanPlaceOrder && this.loggedInUser.canPlaceOrder) ||
      this.loggedInUser.accountType === 'Stm' ||
      !isDefaultAccount
    this.icons = TopNavComponent.icons
  }

  toggleSideNav() {
    this.toggleSideNavEmit.emit(null)
  }
}
