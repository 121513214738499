<mat-card>
  <form class="form" novalidate (ngSubmit)="submit()">
    <table>
      <tr>
        <td>
          <label>Picture</label>
        </td>
        <td>
          <img *ngIf="product?.image" [src]="product.image" />
          <app-file-uploader
            autoUpload="true"
            identity="img:product"
            (fileUploaded)="handleUploadedImage($event)"
          >
          </app-file-uploader>
        </td>
      </tr>
      <tr>
        <td>
          <label>Item</label>
        </td>
        <td>
          <mat-form-field>
            <input
              matInput
              name="product.item"
              [(ngModel)]="product.item"
              name="item"
              required
            />
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <label>Item Description</label>
        </td>
        <td>
          <mat-form-field>
            <textarea
              matInput
              name="product.description"
              [(ngModel)]="product.description"
              name="description"
              #description
            >
            </textarea>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>
          <label>Type</label>
        </td>
        <td>
          <mat-radio-group
            name="product.productType"
            [(ngModel)]="product.productType"
            name="productType"
            (change)="productTypeSelected(product)"
          >
            <mat-radio-button #newTireOption name="type" value="NEW TIRE"
              >New Tire</mat-radio-button
            >
            <mat-radio-button #reTreadTireOption name="type" value="RETREAD"
              >Retread</mat-radio-button
            >
            <mat-radio-button #forkliftTireOption name="type" value="FORKLIFT"
              >Forklift</mat-radio-button
            >
            <mat-radio-button #wheelOption name="type" value="WHEEL"
              >Wheel</mat-radio-button
            >
          </mat-radio-group>
        </td>
      </tr>
      <tr *ngIf="product.productType && product.productType !== 'WHEEL'">
        <td>
          <label>Use</label>
        </td>
        <td>
          <mat-radio-group
            name="product.productUser"
            [(ngModel)]="product.productUse"
            name="productUse"
          >
            <mat-radio-button name="use" value="STEER">Steer</mat-radio-button>
            <mat-radio-button name="use" value="DRIVE">Drive</mat-radio-button>
            <mat-radio-button
              name="use"
              value="TRAILER"
              *ngIf="product.productType != 'FORKLIFT'"
              >Trailer</mat-radio-button
            >
          </mat-radio-group>
        </td>
        <!-- <td *ngIf="product.productType === 'WHEEL'">
                <mat-radio-group name="product.productUser" [(ngModel)]="product.productUse" name="productUse">
                    <mat-radio-button name="use" value="LOOSE">Loose</mat-radio-button>
                    <mat-radio-button name="use" value="MOUNTED">Mounted</mat-radio-button>
                    <mat-radio-button name="use" value="BOTH">Both</mat-radio-button>
                </mat-radio-group>
            </td> -->
      </tr>
      <tr *ngIf="product.productType">
        <td>
          <label>Brand</label>
        </td>
        <td *ngIf="brands && brands.length">
          <mat-radio-group [(ngModel)]="product.brand" name="brand">
            <span *ngFor="let brand of brands">
              <span
                *ngIf="
                  product.productType !== 'FORKLIFT' &&
                  product.productType !== 'WHEEL'
                "
              >
                <mat-radio-button
                  *ngIf="
                    brand.name.toUpperCase() !== 'FORKLIFT' &&
                    brand.name.toUpperCase() !== 'WHEEL'
                  "
                  name="brand"
                  value="{{ brand._id }}"
                >
                  {{ brand?.name }}
                </mat-radio-button>
              </span>
              <span *ngIf="product.productType === 'FORKLIFT'">
                <mat-radio-button
                  *ngIf="brand.name.toUpperCase() === 'FORKLIFT'"
                  name="brand"
                  value="{{ brand._id }}"
                  [checked]="true"
                >
                  {{ brand?.name }}
                </mat-radio-button>
              </span>
              <span *ngIf="product.productType === 'WHEEL'">
                <mat-radio-button
                  *ngIf="brand.name.toUpperCase() === 'WHEELS'"
                  name="brand"
                  value="{{ brand._id }}"
                  [checked]="true"
                >
                  {{ brand?.name }}
                </mat-radio-button>
              </span>
            </span>
          </mat-radio-group>
        </td>
        <td *ngIf="!brands || !brands.length">
          <label id="brandMessage"
            >There are currently no manufacturers. Add them to system
            management</label
          >
        </td>
      </tr>

      <tr *ngIf="product.productType">
        <td>
          <label>Wheel Match</label>
        </td>
        <td>
          <mat-radio-group
            name="wheelMatch"
            [(ngModel)]="product.wheelMatch"
            name="wheelMatch"
          >
            <mat-radio-button
              *ngIf="product.productType !== 'FORKLIFT'"
              name="wheelMatch"
              value="LOW PRO 22"
              >Low Pro 22</mat-radio-button
            >
            <mat-radio-button
              *ngIf="product.productType !== 'FORKLIFT'"
              name="wheelMatch"
              value="WIDE BASE"
              >Wide Base</mat-radio-button
            >
            <mat-radio-button
              *ngIf="product.productType === 'FORKLIFT'"
              name="wheelMatch"
              value="FORKLIFT"
              >Forklift</mat-radio-button
            >
          </mat-radio-group>
        </td>
      </tr>

      <tr *ngIf="product.productType">
        <td>
          <label>PO Requirement</label>
        </td>
        <td>
          <mat-radio-group
            name="pRequirement"
            [(ngModel)]="product.poRequirement"
            name="poRequirement"
          >
            <mat-radio-button name="pRequirement" value="ORDER DATE"
              >Order Date</mat-radio-button
            >
            <mat-radio-button name="pRequirement" value="AFTER DELIVERY"
              >After Delivery</mat-radio-button
            >
            <mat-radio-button name="pRequirement" value="REQUIRES SEPARATE PO"
              >Requires Separate PO</mat-radio-button
            >
            <mat-radio-button name="pRequirement" value="BILL WITH SERVICE"
              >Bill with Service</mat-radio-button
            >
          </mat-radio-group>
        </td>
      </tr>

      <tr *ngIf="product.productType">
        <td>
          <label>Notes</label>
        </td>
        <td>
          <mat-form-field>
            <textarea
              matInput
              #notes
              name="notes"
              [(ngModel)]="product.comment"
              name="comment"
            >
            </textarea>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </form>

  <div *ngIf="saveErrors && saveErrors.length">
    <h5 class="error-message" *ngFor="let err of saveErrors">{{ err }}</h5>
  </div>
  <button mat-raised-button color="primary" (click)="submit()">
    {{ product._id ? 'Update' : 'Create' }}
  </button>
  <button mat-raised-button color="primary" (click)="cancel()">
    Return to Product List
  </button>
  <button
    class="delete-button"
    mat-raised-button
    color="warn"
    (click)="remove()"
    *ngIf="product._id"
  >
    Delete
  </button>
</mat-card>
