<div>
  <app-side-nav #sideNav></app-side-nav>

  <div [ngClass]="leftMarginClass" class="main-wrapper" fxLayout="column">
    <!-- Top navigation -->
    <app-top-nav (toggleSideNavEmit)="toggleSideNav()" #topNav></app-top-nav>

    <div>
      <!-- include our toast service -->
      <app-poptart></app-poptart>
      <router-outlet></router-outlet>
    </div>
    <div
      fxLayout.gt-xs="row"
      fxLayout="column"
      fxLayoutAlign="center center"
      class="version-info"
    >
      <div class="app-title">{{ appName }}</div>
      <div fxShow.lt-xs>&nbsp;-&nbsp;</div>
      <div class="app-version">Version {{ appVersion }}</div>
    </div>
  </div>
</div>
