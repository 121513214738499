<div
  class="service-call-form-container"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <!-- <div fxLayout="row">
        <p fxHide.lt-xs fxHide.gt-xs>XS</p>
        <p fxHide.lt-sm fxHide.gt-sm>SM</p>
        <p fxHide.lt-md fxHide.gt-md>MD</p>
        <p fxHide.lt-lg fxHide.gt-lg>LG</p>
        <p fxHide.lt-xl fxHide.gt-xl>XL</p>
    </div> -->
  <div fxLayout="row" fxLayoutAlign="start">
    <h2>Service Call Information</h2>
  </div>
  <div class="row" fxLayoutAlign="center center" style="width: 100%">
    <form
      *ngIf="serviceCallForm"
      class="form"
      novalidate
      [formGroup]="serviceCallForm"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxFlex
      fxFlex.sm="80"
      fxFlex.md="60"
      fxFlex.gt-md="50"
    >
      <div fxLayout="row" style="width: 100%">
        <mat-form-field fxFlex>
          <input
            matInput
            type="text"
            name="vendorName"
            placeholder="Vendor Name"
            formControlName="vendorName"
            (input)="vendorNameChanged()"
            required
          />
          <mat-hint
            *ngIf="responses?.vendorName"
            class="error-message"
            align="start"
            >{{ responses?.vendorName }}</mat-hint
          >
          <mat-hint
            *ngIf="vendorNameErrs?.required"
            class="error-message"
            align="start"
            >Vendor name is required</mat-hint
          >
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="10px"
        style="width: 100%"
        *ngIf="order.vendorName"
      >
        <mat-form-field fxFlex>
          <mat-select
            placeholder="Select Service Call Type"
            formControlName="callType"
            (selectionChange)="callTypeChanged()"
            required
          >
            <mat-option *ngFor="let type of callTypes" [value]="type.value">
              {{ type.value }} - {{ type.description }}
            </mat-option>
          </mat-select>
          <mat-hint
            *ngIf="responses?.callType"
            class="error-message"
            align="start"
            >{{ responses?.callType }}</mat-hint
          >
          <mat-hint
            *ngIf="callTypeErrs?.required"
            class="error-message"
            align="start"
            >Call type is required</mat-hint
          >
        </mat-form-field>
      </div>
      <div fxLayout="column" *ngIf="callTypeCtrl?.value" style="width: 100%">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex>
            <mat-select
              placeholder="Select Fleet"
              formControlName="fleet"
              (selectionChange)="fleetChanged()"
              required
            >
              <mat-option *ngFor="let fleet of fleets" [value]="fleet._id">
                {{ fleet.name }}
              </mat-option>
            </mat-select>
            <mat-hint
              *ngIf="responses?.fleet"
              class="error-message"
              align="start"
              >{{ responses?.fleet }}</mat-hint
            >
            <mat-hint
              *ngIf="fleetErrs?.required"
              class="error-message"
              align="start"
              >Fleet is required</mat-hint
            >
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex>
            <mat-select
              placeholder="Select Location"
              formControlName="location"
              required
            >
              <mat-option
                *ngFor="let location of fleetLocations"
                [value]="location._id"
              >
                {{ location.name }}
              </mat-option>
            </mat-select>
            <mat-hint
              *ngIf="responses?.location"
              class="error-message"
              align="start"
              >{{ responses?.location }}</mat-hint
            >
            <mat-hint
              *ngIf="locationErrs?.required"
              class="error-message"
              align="start"
              >Location is required</mat-hint
            >
            <mat-hint
              *ngIf="locationErrs?.pattern"
              class="error-message"
              align="start"
              >Location is required</mat-hint
            >
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex fxFlex.gt-sm="70">
            <input
              matInput
              type="text"
              placeholder="STM Reference #"
              formControlName="stmReferenceNumber"
              required
              autocomplete="off"
            />
            <mat-hint
              *ngIf="responses?.stmReferenceNumber"
              class="error-message"
              align="start"
              >{{ responses?.stmReferenceNumber }}</mat-hint
            >
            <mat-hint
              *ngIf="stmReferenceNumberErrs?.required"
              class="error-message"
              align="start"
              >Reference # is required</mat-hint
            >
          </mat-form-field>
          <mat-form-field
            fxFlex
            fxFlex.gt-sm="30"
            class="call-date-input-container"
          >
            <div
              class="datepicker-cover"
              (click)="dpCallDate.toggleCalendar()"
            ></div>
            <!--required so we can show calendar on input click without causing angular form errors-->
            <input
              matInput
              angular-mydatepicker
              placeholder="Service Date"
              formControlName="callDate"
              [options]="datepickerOpts"
              #dpCallDate="angular-mydatepicker"
              required
              autocomplete="off"
            />
            <mat-icon matSuffix (click)="dpCallDate.toggleCalendar()"
              >date_range</mat-icon
            >
            <mat-hint
              *ngIf="responses?.callDate"
              class="error-message"
              align="start"
              >{{ responses?.callDate }}</mat-hint
            >
            <mat-hint *ngIf="callDateErrs?.required" class="error-message"
              >Service date is required</mat-hint
            >
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
          <mat-form-field fxFlex.gt-xs="40">
            <input
              matInput
              type="text"
              placeholder="Unit #"
              formControlName="unitNumber"
              required
              autocomplete="off"
            />
            <mat-hint
              *ngIf="responses?.unitNumber"
              class="error-message"
              align="start"
              >{{ responses?.unitNumber }}</mat-hint
            >
            <mat-hint
              *ngIf="unitNumberErrs?.required"
              class="error-message"
              align="start"
              >Unit # is required</mat-hint
            >
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="60">
            <mat-select
              placeholder="Unit Type"
              formControlName="unitType"
              required
            >
              <mat-option *ngFor="let unitType of unitTypes" [value]="unitType">
                {{ unitType | capitalCasePipe }}
              </mat-option>
            </mat-select>
            <mat-hint
              *ngIf="responses?.unitType"
              class="error-message"
              align="start"
              >{{ responses?.unitType }}</mat-hint
            >
            <mat-hint
              *ngIf="unitTypeErrs?.required"
              class="error-message"
              align="start"
              >Unit type is required</mat-hint
            >
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayout.gt-sm="row">
          <mat-form-field fxFlex.gt-xs="50">
            <input
              matInput
              type="text"
              placeholder="License #"
              formControlName="licenseNumber"
              required
              autocomplete="off"
            />
            <mat-hint
              *ngIf="responses?.licenseNumber"
              class="error-message"
              align="start"
              >{{ responses?.licenseNumber }}</mat-hint
            >
            <mat-hint
              *ngIf="licenseNumberErrs?.required"
              class="error-message"
              align="start"
              >License # is required</mat-hint
            >
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="50">
            <mat-select
              placeholder="License State"
              formControlName="licenseState"
              required
            >
              <mat-option
                *ngFor="let state of usStates"
                [value]="state.abbreviation"
              >
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-hint
              *ngIf="responses?.licenseState"
              class="error-message"
              align="start"
              >{{ responses?.licenseState }}</mat-hint
            >
            <mat-hint
              *ngIf="licenseStateErrs?.required"
              class="error-message"
              align="start"
              >License state is required</mat-hint
            >
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <input
              matInput
              type="text"
              name="serviceTechName"
              placeholder="Service Tech Name"
              formControlName="serviceTechName"
              required
            />
            <mat-hint
              *ngIf="responses?.serviceTechName"
              class="error-message"
              align="start"
              >{{ responses?.serviceTechName }}</mat-hint
            >
            <mat-hint
              *ngIf="serviceTechNameErrs?.required"
              class="error-message"
              align="start"
              >Tech name is required</mat-hint
            >
          </mat-form-field>
        </div>
        <div
          fxLayout="column"
          *ngIf="callTypeCtrl?.value === 'BREAKDOWN'"
          formGroupName="breakdownInfo"
        >
          <div fxLayout="row">
            <h2>Breakdown Location</h2>
          </div>
          <div fxLayout="column" formGroupName="breakdownLocation">
            <div fxLayout="row">
              <mat-form-field fxFlex>
                <input
                  matInput
                  type="text"
                  placeholder="Address/Intersection/Mile Marker"
                  formControlName="addressOrMarker"
                  required
                  autocomplete="off"
                />
                <mat-hint
                  *ngIf="
                    responses &&
                    responses['breakdownInfo.breakdownLocation.addressOrMarker']
                  "
                  class="error-message"
                  align="start"
                  >{{
                    responses['breakdownInfo.breakdownLocation.addressOrMarker']
                  }}</mat-hint
                >
                <mat-hint
                  *ngIf="breakdownAddressErrs?.required"
                  class="error-message"
                  align="start"
                  >Address or marker is required</mat-hint
                >
              </mat-form-field>
            </div>
            <div fxLayout="column" fxLayout.gt-sm="row">
              <mat-form-field fxFlex.gt-xs="50">
                <input
                  matInput
                  type="text"
                  placeholder="City"
                  name="city"
                  formControlName="city"
                  required
                />
                <mat-hint
                  *ngIf="
                    responses &&
                    responses['breakdownInfo.breakdownLocation.city']
                  "
                  class="error-message"
                  align="start"
                  >{{
                    responses['breakdownInfo.breakdownLocation.city']
                  }}</mat-hint
                >
                <mat-hint
                  *ngIf="breakdownCityErrs?.required"
                  class="error-message"
                  align="start"
                  >City is required</mat-hint
                >
              </mat-form-field>
              <mat-form-field fxFlex.gt-xs="50">
                <mat-select
                  placeholder="State"
                  formControlName="state"
                  required
                >
                  <mat-option
                    *ngFor="let state of usStates"
                    [value]="state.abbreviation"
                  >
                    {{ state.name }}
                  </mat-option>
                </mat-select>
                <mat-hint
                  *ngIf="
                    responses &&
                    responses['breakdownInfo.breakdownLocation.state']
                  "
                  class="error-message"
                  align="start"
                  >{{
                    responses['breakdownInfo.breakdownLocation.state']
                  }}</mat-hint
                >
                <mat-hint
                  *ngIf="breakdownStateErrs?.required"
                  class="error-message"
                  align="start"
                  >State is required</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field fxFlex.gt-xs="50">
              <input
                matInput
                type="text"
                placeholder="Driver Name"
                formControlName="driverName"
                required
              />
              <mat-hint
                *ngIf="responses && responses['breakdownInfo.driverName']"
                class="error-message"
                align="start"
                >{{ responses['breakdownInfo.driverName'] }}</mat-hint
              >
              <mat-hint
                *ngIf="breakdownDriverNameErrs?.required"
                class="error-message"
                align="start"
                >Driver name is required</mat-hint
              >
            </mat-form-field>
            <mat-form-field fxFlex.gt-xs="50">
              <input
                matInput
                type="text"
                placeholder="Driver Id #"
                formControlName="driverId"
                required
              />
              <mat-hint
                *ngIf="responses && responses['breakdownInfo.driverId']"
                class="error-message"
                align="start"
                >{{ responses['breakdownInfo.driverId'] }}</mat-hint
              >
              <mat-hint
                *ngIf="breakdownDriverIdErrs?.required"
                class="error-message"
                align="start"
                >Driver id is required</mat-hint
              >
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <h2>Repairs</h2>
        </div>
        <div fxLayout="row">
          <mat-hint
            *ngIf="wheelCtrlArray?.errors?.arrayLength"
            class="error-message"
            align="start"
            >At least one wheel is required.</mat-hint
          >
        </div>
        <div
          formArrayName="wheels"
          *ngFor="let wheel of wheelCtrlArray.controls; let idx = index"
          class="mixed-input-type-row wheel-row"
          fxLayout="column"
        >
          <div fxLayout="row" [formGroupName]="idx">
            <div fxLayout="column" style="width: 100%">
              <div fxLayout="column" fxLayout.gt-sm="row" style="width: 100%">
                <mat-form-field fxFlex="50">
                  <mat-select
                    placeholder="Wheel Position"
                    formControlName="position"
                    required
                  >
                    <mat-option
                      *ngFor="let position of wheelPositions"
                      [value]="position.abbreviation"
                      title="{{ position.name }}"
                    >
                      {{ position.abbreviation }} - {{ position.name }}
                    </mat-option>
                  </mat-select>
                  <mat-hint
                    *ngIf="
                      responses?.wheels && responses?.wheels[idx]?.position
                    "
                    class="error-message"
                    align="start"
                    >{{ responses?.wheels[idx]?.position }}</mat-hint
                  >
                  <mat-hint
                    *ngIf="wheel.positionErrs?.required"
                    class="error-message"
                    align="start"
                    >Position is required</mat-hint
                  >
                </mat-form-field>
                <mat-form-field fxFlex="50">
                  <mat-select
                    placeholder="Cause of Failure"
                    formControlName="causeOfFailure"
                    required
                  >
                    <mat-option
                      *ngFor="let cause of causesOfFailure"
                      [value]="cause.abbreviation"
                      title="{{ cause.name }}"
                    >
                      {{ cause.abbreviation }} - {{ cause.name }}
                    </mat-option>
                  </mat-select>
                  <mat-hint
                    *ngIf="
                      responses?.wheels &&
                      responses?.wheels[idx]?.causeOfFailure
                    "
                    class="error-message"
                    align="start"
                    >{{ responses?.wheels[idx]?.causeOfFailure }}</mat-hint
                  >
                  <mat-hint
                    *ngIf="wheel.causeOfFailureErrs?.required"
                    class="error-message"
                    align="start"
                    >Cause is required</mat-hint
                  >
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutGap.gt-sm="25px">
                <div fxLayout="column">
                  <label class="tread-input-label"
                    >Remaining Tread Depth *</label
                  >
                  <mat-form-field class="tread-input">
                    <input
                      matInput
                      type="number"
                      min="0"
                      max="32"
                      class="remaining-tread-input"
                      formControlName="remainingTread32nds"
                      (change)="treadDepthChanged(idx)"
                      required
                      autocomplete="off"
                    />
                    <p matSuffix>
                      /
                      32"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <mat-hint
                      *ngIf="
                        responses?.wheels &&
                        responses?.wheels[idx]?.remainingTread32nds
                      "
                      class="error-message"
                      align="start"
                      >{{
                        responses?.wheels[idx]?.remainingTread32nds
                      }}</mat-hint
                    >
                    <mat-hint
                      *ngIf="wheel.remainingTread32ndsErrs?.required"
                      class="error-message"
                      align="start"
                      >Remaining tread is required</mat-hint
                    >
                    <mat-hint
                      *ngIf="wheel.remainingTread32ndsErrs?.min"
                      class="error-message"
                      align="start"
                      >Must be between 0 and 32</mat-hint
                    >
                    <mat-hint
                      *ngIf="wheel.remainingTread32ndsErrs?.max"
                      class="error-message"
                      align="start"
                      >Must be between 0 and 32</mat-hint
                    >
                  </mat-form-field>
                </div>

                <mat-form-field
                  fxFlex.xs="50"
                  fxFlex="200px"
                  *ngIf="wheel.remainingTreadDepthCtrl?.value === 0"
                >
                  <mat-select
                    placeholder="Any Tread Remaining?"
                    formControlName="hasTreadRemaining"
                    required
                  >
                    <mat-option
                      *ngFor="let ans of treadAnswers"
                      [value]="ans.value"
                    >
                      {{ ans.display }}
                    </mat-option>
                  </mat-select>
                  <mat-hint
                    *ngIf="
                      responses?.wheels &&
                      responses?.wheels[idx]?.hasTreadRemaining
                    "
                    class="error-message"
                    align="start"
                    >{{ responses?.wheels[idx]?.hasTreadRemaining }}</mat-hint
                  >
                  <mat-hint
                    *ngIf="wheel.hasTreadRemainingErrs?.required"
                    class="error-message"
                    align="start"
                    >Choose yes or no</mat-hint
                  >
                </mat-form-field>
              </div>
            </div>
            <div
              fxLayout="row"
              fxFlex.gt-sm="6"
              fxLayoutAlign="center start"
              fxLayoutAlign.gt-sm="end"
            >
              <button
                *ngIf="modifyMode"
                mat-mini-fab
                type="button"
                title="Remove this wheel"
                class="wheel-position-delete-button"
                (click)="removeWheelPosition(idx)"
              >
                <mat-icon>remove</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row">
            <div class="divider">&nbsp;</div>
          </div>
        </div>
        <div fxLayout="row">
          <button
            *ngIf="modifyMode"
            mat-raised-button
            type="button"
            title="Add Another Wheel Position"
            class="wheel-position-add-button"
            (click)="addWheelPosition()"
          >
            <mat-icon>add</mat-icon> Add Another Wheel Position
          </button>
        </div>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="save-and-upload-button"
        *ngIf="
          docComponent.isVisible === false &&
          callTypeCtrl?.value &&
          (!orderDocuments || !orderDocuments.length) &&
          !order?.callType
        "
      >
        <button
          mat-raised-button
          [disabled]="saving"
          color="primary"
          (click)="saveFormAndShowDocumentUpload()"
        >
          Upload PO Requests
        </button>
      </div>
    </form>
  </div>
</div>
