// 'npm' modules
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { RouterModule } from '@angular/router'

// 'app' modules

// Services

// Routers
import { FlexLayoutModule } from '@angular/flex-layout'
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { CoreLayoutRoutingModule } from './core-layout-routing.module'

// Components
import { CoreLayoutComponent } from './core-layout.component'
import { TopNavComponent } from './top-nav/top-nav.component'
import { SideNavComponent } from './side-nav/side-nav.component'
import { SideNavFooterComponent } from './side-nav-footer/side-nav-footer.component'
import { SideNavHeaderComponent } from './side-nav-header/side-nav-header.component'

// import {LayoutDetailComponent} from "./layout-detail.component";

@NgModule({
  declarations: [
    CoreLayoutComponent,
    TopNavComponent,
    SideNavComponent,
    SideNavHeaderComponent,
    SideNavFooterComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    CoreLayoutRoutingModule,
    FlexLayoutModule,
    CoreModule,
    MaterialComponentsModule
  ],
  providers: [],
  exports: [CoreLayoutComponent]
})
export class CoreLayoutModule {}
