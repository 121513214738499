import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { FlexLayoutModule } from '@angular/flex-layout'

import { ChartsModule } from 'ng2-charts'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { LocationComponent } from './location.component'
import { NaughtyListComponent } from './naughty-list.component'

import { LocationService } from './location.service'

import { LocationRoutingModule } from './location-routing.module'

@NgModule({
  declarations: [LocationComponent, NaughtyListComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LocationRoutingModule,
    ChartsModule,
    CoreModule,
    FlexLayoutModule,
    MaterialComponentsModule
  ],
  providers: [LocationService],
  exports: [LocationComponent, NaughtyListComponent]
})
export class LocationModule {}
