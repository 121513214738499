import { Component, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-login',
  template: '<div></div>'
})
export class LoginComponent implements OnInit {
  ngOnInit() {
    // TODO: Give redirect_to query from history
    window.location.href = `${environment.apiUrl}/coreuser/authenticate?redirect_to=/dashboard`
  }
}
