import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { OrderStateComponent } from './order-state.component'

import { OrderStateService } from './order-state.service'

import { OrderStateRoutingModule } from './order-state-routing.module'

@NgModule({
  declarations: [OrderStateComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    OrderStateRoutingModule,
    CoreModule,
    MaterialComponentsModule
  ],
  providers: [OrderStateService],
  exports: [OrderStateComponent]
})
export class OrderStateModule {}
