'use strict'

// node_modules imports
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable()
export class ManufacturerService {
  manufacturersURL = `${environment.apiUrl}/manufacturer`

  constructor(private http: HttpClient) {}

  // Get all manufacturers from the API
  getManufacturers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.manufacturersURL}/`)
  }

  getManufacturerById(manufacturerId): Observable<any> {
    return this.http.get<any[]>(`${this.manufacturersURL}/${manufacturerId}`)
  }

  save(manufacturer: any): Observable<any> {
    if (manufacturer._id) {
      return this.http.put<any>(
        `${this.manufacturersURL}/${manufacturer._id}`,
        manufacturer
      )
    } else {
      return this.http.post<any>(this.manufacturersURL, manufacturer)
    }
  }

  update(manufacturer: any): Observable<any> {
    return this.http.put<any>(
      `${this.manufacturersURL}/${manufacturer._id}`,
      manufacturer
    )
  }
}
