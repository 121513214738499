import * as _ from 'lodash'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'phonePipe'
})
export class PhonePipe implements PipeTransform {
  public transform(phoneStr) {
    if (!phoneStr) {
      return
    }

    const strLen = phoneStr.length

    // remove any dashes,spaces or parens
    phoneStr = phoneStr
      .replace(/-/g, '')
      .replace(/ /g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
    if (strLen >= 10) {
      return `${phoneStr.slice(0, -10)} (${phoneStr.slice(
        -10,
        -7
      )}) ${phoneStr.slice(-7, -4)}-${phoneStr.slice(-4)}`
    } else {
      return phoneStr
    }
  }
}
