import { Component } from '@angular/core'
@Component({
  template: '<h2>Page not found</h2>'
})
export class PageNotFoundComponent {}

// import { Component } from '@angular/core';
@Component({
  template: '<h2>Test Component</h2>'
})
export class TestComponent {}
