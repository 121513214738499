import { MatDialogRef } from '@angular/material/dialog'
import { Component } from '@angular/core'

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h4 class="dialog-title">{{ title }}</h4>
    <p>{{ message }}</p>
    <div>
      <textarea
        [ngClass]="inputClass"
        rows="{{ rowCount }}"
        cols="{{ colCount }}"
        placeholder="{{ placeholder }}"
        [(ngModel)]="inputText"
      ></textarea>
    </div>
    <button
      type="button"
      mat-raised-button
      (click)="dialogRef.close(inputText)"
    >
      {{ trueButtonText }}
    </button>
    <button type="button" mat-button (click)="dialogRef.close(false)">
      {{ falseButtonText }}
    </button>
  `
})
export class InputDialogComponent {
  title: string
  message: string
  inputText: string
  trueButtonText: string
  falseButtonText: string
  rowCount: number
  colCount: number
  placeholder: string
  inputClass: string

  constructor(public dialogRef: MatDialogRef<InputDialogComponent>) {}
}
