import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
@Component({
  template: '<h2>Loading...</h2>'
})
export class RedirectComponent implements OnInit, OnDestroy {
  private sub: any

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      this.router.navigate([params.returnUrl], {})
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
