import { mergeMap } from 'rxjs/operators'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FleetService } from '../../fleet/client/fleet.service'
import { LocationService } from '../../location/client/location.service'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { ProductService } from './product.service'

@Component({
  selector: 'app-product',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: []
})
export class ProductListComponent
  extends CoreResponseHandler
  implements OnInit
{
  // instantiate product to an empty array
  products: any[] = []
  fleet: any = null
  location: any = null
  allChecked = false
  // allRequiresWheelChecked = false;
  // allRequiresVehicleChecked = false;
  currentSelectedProductList: any[] = []
  fleetProductList: any[] = []
  locationProductList: any[] = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private fleetService: FleetService,
    private locationService: LocationService
  ) {
    super()
  }

  handleResponse(error: CoreResponse) {
    return false
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const fleetId = params.fleetId
      const locationId = params.locationId

      if (fleetId) {
        this.fleetService.getFleetById(fleetId).subscribe((fleet) => {
          this.fleet = fleet
          this.setCurrentProductList()
          this.getAllProducts()
        })
      } else if (locationId) {
        this.locationService
          .getLocationById(locationId)
          .pipe(
            mergeMap((location) => {
              this.location = location
              return this.fleetService.getFleetById(this.location.fleet._id)
            })
          )
          .subscribe((fleet) => {
            this.fleet = fleet
            this.setCurrentProductList()
            this.getAllProducts()
          })
      } else {
        this.getAllProducts()
      }
    })
  }

  setCurrentProductList() {
    this.locationProductList =
      this.location && this.location.productList
        ? this.location.productList.products
        : []
    this.fleetProductList =
      this.fleet && this.fleet.defaultProductList
        ? this.fleet.defaultProductList
        : []

    if (this.location) {
      if (this.location.productList.useFleetDefault) {
        this.currentSelectedProductList = this.fleetProductList
      } else {
        this.currentSelectedProductList = this.locationProductList
      }
    } else if (this.fleet) {
      this.currentSelectedProductList = this.fleetProductList
    }
  }

  getAllProducts() {
    // Retrieve data from the API
    this.productService.getAllProducts().subscribe((products) => {
      this.products = products

      this.products.forEach((p) => {
        const fleetMatch = this.fleetProductList.find(
          (flProd) => flProd.product === p._id
        )
        const locationMatch = this.locationProductList.find(
          (locProd) => locProd.product === p._id
        )

        if (fleetMatch) {
          p.selectedInFleet = true
          // p.requiresWheelInFleet = fleetMatch.requiresWheelSelection;
          // p.requiresVehicleInFleet = fleetMatch.requiresVehicleNumber;
        } else {
          p.selectedInFleet = false
        }

        if (this.location && this.location.productList.useFleetDefault) {
          p.selectedInLocation = p.selectedInFleet
          // p.requiresWheelInLocation = p.requiresWheelInFleet;
          // p.requiresVehicleInLocation = p.requiresVehicleInFleet;
        } else if (locationMatch) {
          p.selectedInLocation = true
          // p.requiresWheelInLocation = locationMatch.requiresWheelSelection;
          // p.requiresVehicleInLocation = locationMatch.requiresVehicleNumber;
        } else {
          p.selectedInLocation = false
        }
      })
    })
  }

  newProduct() {
    this.router.navigate(['/product/new'])
  }

  saveProductList() {
    if (this.location) {
      this.location.productList = this.location.productList || {}
      this.location.productList.useFleetDefault =
        this.location.productList.useFleetDefault || false
      this.location.productList.products = this.products
        .filter((p) => p.selectedInLocation)
        .map((p) => {
          return {
            product: p
            // requiresWheelSelection: p.requiresWheelInLocation,
            // requiresVehicleNumber: p.requiresVehicleInLocation
          }
        })

      this.locationService.save(this.location).subscribe(
        () => {
          this.router.navigate(['/systemManagement'])
        },
        (err) => {
          this.handleFailure(err)
        }
      )
    } else if (this.fleet) {
      this.fleet.defaultProductList = this.products
        .filter((p) => p.selectedInFleet)
        .map((p) => {
          return {
            product: p
            // requiresWheelSelection: p.requiresWheelInFleet,
            // requiresVehicleNumber: p.requiresVehicleInFleet
          }
        })
      this.fleetService.save(this.fleet).subscribe(
        () => {
          this.router.navigate(['/systemManagement'])
        },
        (err) => {
          this.handleFailure(err)
        }
      )
    }
  }

  cancel() {
    this.router.navigate(['/systemManagement'])
  }

  goToProduct(productId) {
    //  this.router.navigate(['/product-edit']);
    this.router.navigate(['/product/', productId])
  }

  useDefaultChanged(): void {
    this.allChecked = false
    // this.allRequiresVehicleChecked = false;
    // this.allRequiresWheelChecked = false;
    if (this.location.productList.useFleetDefault) {
      this.products.forEach((p) => {
        p.selectedInLocation = p.selectedInFleet
        // p.requiresWheelInLocation = p.requiresWheelInFleet;
        // p.requiresVehicleInLocation = p.requiresVehicleInFleet;
      })
    }
  }

  selectedChanged(product) {
    this.allChecked = false
    // this.allRequiresWheelChecked = false;
    // this.allRequiresVehicleChecked = false;

    if (this.location && !product.selectedInLocation) {
      // product.requiresWheelInLocation = false;
      // product.requiresVehicleInLocation = false;
    } else if (this.fleet && !product.selectedInFleet) {
      // product.requiresWheelInFleet = false;
      // product.requiresVehicleInFleet = false;
    }
  }

  allCheckedChanged(): void {
    // this.allRequiresWheelChecked = false;
    // this.allRequiresVehicleChecked = false;

    if (this.location) {
      this.products.forEach((p) => {
        p.selectedInLocation = this.allChecked
        if (!p.selectedInLocation) {
          // p.requiresWheelInLocation = false;
          // p.requiresVehicleInLocation = false;
        }
      })
    } else if (this.fleet) {
      this.products.forEach((p) => {
        p.selectedInFleet = this.allChecked
        if (!p.selectedInFleet) {
          // p.requiresWheelInFleet = false;
          // p.requiresVehicleInFleet = false;
        }
      })
    }
  }

  // allRequiresWheelChanged(): void {
  //     if (this.location) {
  //         this.products.forEach(p => {
  //             if (p.selectedInLocation && p.productType !== 'WHEEL' && this.allRequiresWheelChecked) {
  //                 p.requiresWheelInLocation = true;
  //             } else if (!this.allRequiresWheelChecked) {
  //                 p.requiresWheelInLocation = false;
  //             }
  //         });
  //     } else if (this.fleet) {
  //         this.products.forEach(p => {
  //             if (p.selectedInFleet && p.productType !== 'WHEEL' && this.allRequiresWheelChecked) {
  //                 p.requiresWheelInFleet = true;
  //             } else if (!this.allRequiresWheelChecked) {
  //                 p.requiresWheelInFleet = false;
  //             }
  //         });
  //     }
  // }

  // allRequiresVehicleChanged(): void {
  //     if (this.location) {
  //         this.products.forEach(p => {
  //             if (p.selectedInLocation && this.allRequiresVehicleChecked) {
  //                 p.requiresVehicleInLocation = true;
  //             } else if (!this.allRequiresVehicleChecked) {
  //                 p.requiresVehicleInLocation = false;
  //             }
  //         });
  //     } else if (this.fleet) {
  //         this.products.forEach(p => {
  //             if (p.selectedInFleet && this.allRequiresVehicleChecked) {
  //                 p.requiresVehicleInFleet = true;
  //             } else if (!this.allRequiresVehicleChecked) {
  //                 p.requiresVehicleInFleet = false;
  //             }
  //         });
  //     }
  // }

  duplicateProduct(product): void {
    const newProduct = Object.assign({}, product)
    newProduct._id = undefined
    newProduct.__v = undefined
    newProduct.createdBy = undefined
    newProduct.updatedBy = undefined
    newProduct.createdDate = undefined
    newProduct.createdBy = undefined
    newProduct.item = `(COPY) ${newProduct.item}`
    newProduct.description = `(COPY) ${newProduct.description}`
    this.productService
      .addProduct(newProduct)
      .subscribe((savedNewProduct: any) => {
        this.goToProduct(savedNewProduct._id)
      })
  }
}
