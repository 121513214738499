import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'

import { ListViewWidgetComponent } from '../../core/client/object-list-with-dynamic-detail/list-view-widget.component'
import { LocationService } from '../../location/client/location.service'
import { LocationComponent } from '../../location/client/location.component'
import { CoreUserComponent } from '../../core-user/client/core-user.component'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { UtilsClass } from '../../core/client/utils/utils.component'
import { OrderFlowService } from '../../order-flow/client/order-flow.service'
import { FleetService } from './fleet.service'
import { timezones } from './../../constants'

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss'],
  providers: []
})
export class FleetComponent extends CoreResponseHandler implements OnInit {
  fleet: any = {}
  locations: any
  responses: any = {}
  fleetForm: FormGroup
  viewUserList = false
  viewLocationList = false
  orderFlows: any[]
  selectedOrderFlow: any
  saving = false
  timezones = timezones
  @ViewChild('locations', { static: true })
  LocationsListViewWidget: ListViewWidgetComponent

  @ViewChild('users', { static: true })
  UsersListViewWidget: ListViewWidgetComponent

  constructor(
    private fleetService: FleetService,
    private locationService: LocationService,
    private userService: CoreUserService,
    private fb: FormBuilder,
    private orderFlowService: OrderFlowService,
    private router: Router
  ) {
    super()
    this.fleet = this.createDefaultFleet()
  }

  ngOnInit() {
    this.fleetForm = this.fb.group({})
    this.getLocations()
    this.getFleetUsers()
    this.getOrderFlows()

    this.fleet.options = this.fleet.options || {}
    if (this.fleet.options.requireSTMPaperworkValidation !== false) {
      this.fleet.options.requireSTMPaperworkValidation = true
    }

    if (this.fleet.options.hasSchedule !== false) {
      this.fleet.options.hasSchedule = true
    }

    if (this.fleet.options.requireServiceWO !== false) {
      this.fleet.options.requireServiceWO = true
    }

    if (!this.fleet.options.requireRetreadWO) {
      this.fleet.options.requireRetreadWO = false
    }

    if (this.fleet.options.onePOPerManufacturer !== false) {
      this.fleet.options.onePOPerManufacturer = true
    }

    if (!this.fleet.options.allowGeneralPOForForkliftOrders) {
      this.fleet.options.allowGeneralPOForForkliftOrders = false
    }

    if (!this.fleet.options.mountedWheelsProgram) {
      this.fleet.options.mountedWheelsProgram = false
    }

    if (!this.fleet.options.showUsedInventoryInput) {
      this.fleet.options.showUsedInventoryInput = false
    }

    if (!this.fleet.options.showPickedUpCasingsInput) {
      this.fleet.options.showPickedUpCasingsInput = false
    }

    if (!this.fleet.address.timezone) {
      this.fleet.address.timezone = 'America/Chicago'
    }

    // Location Component
    this.LocationsListViewWidget.setListArray([], 'name', ['directions_bus'])
    this.LocationsListViewWidget.setViewComponent(LocationComponent, 'location')
    this.LocationsListViewWidget.modifyObject('location', 'fleet', this.fleet)

    // User Component
    this.UsersListViewWidget.setListArray([], 'displayName', ['person'])
    this.UsersListViewWidget.setViewComponent(CoreUserComponent, 'user')
    this.UsersListViewWidget.modifyObject('user', 'accountType', 'Fleet')
    this.UsersListViewWidget.modifyObject('user', 'account', this.fleet._id)
  }

  save(): void {
    this.saving = true
    this.responses = {}
    const savedOrderFlow = this.fleet.defaultOrderFlow
    if (!this.fleet.defaultOrderFlow || !this.fleet.defaultOrderFlow._id) {
      this.fleet.defaultOrderFlow = null
    }
    this.fleetService.save(this.fleet).subscribe(
      (response) => {
        if (!this.fleet._id) {
          UtilsClass.mergeObjByValues(this.fleet, response)
          this.LocationsListViewWidget.modifyObject(
            'location',
            'fleet',
            this.fleet._id
          )
          this.UsersListViewWidget.modifyObject(
            'user',
            'account',
            this.fleet._id
          )
        } else {
          this.fleetService.getFleetById(this.fleet._id).subscribe((fleet) => {
            UtilsClass.mergeObjByValues(this.fleet, fleet)
            this.setSelectedOrderFlow()
          })
        }

        this.handleSuccess('saveSuccess', 'Fleet saved successfully!')
        this.saving = false
      },
      (error) => {
        this.fleet.defaultOrderFlow = savedOrderFlow
        this.handleFailure(error)
        this.saving = false
      }
    )
  }

  viewLocationListComponent() {
    this.viewLocationList = true
    this.LocationsListViewWidget.setTitle(`${this.fleet.name} Locations`)
  }

  viewUserListComponent() {
    this.viewUserList = true
    this.UsersListViewWidget.setTitle(`${this.fleet.name} Users`)
  }

  // Get a fleet's locations
  getLocations() {
    if (this.fleet._id) {
      this.locationService
        .getLocations({ fleet: this.fleet._id })
        .subscribe((locations) => {
          this.LocationsListViewWidget.viewModelArray = locations
          this.locations = locations
        })
    } else {
      this.LocationsListViewWidget.viewModelArray = []
    }
  }

  getFleetUsers() {
    if (this.fleet._id) {
      this.userService
        .getUsers({
          defaultAccountType: 'Fleet',
          defaultAccount: this.fleet._id
        })
        .subscribe((users) => {
          this.UsersListViewWidget.viewModelArray = users
        })
    } else {
      this.UsersListViewWidget.viewModelArray = []
    }
  }

  getOrderFlows() {
    this.orderFlowService.getOrderFlows().subscribe((orderFlows: any) => {
      this.orderFlows = orderFlows
      this.setSelectedOrderFlow()
    })
  }

  createDefaultFleet() {
    const newFleet: any = {
      address: {
        street1: '',
        city: '',
        state: '',
        zip: '',
        timezone: 'America/Chicago'
      },
      defaultOrderFlow: {},
      options: {
        requireSTMPaperworkValidation: true,
        hasSchedule: true,
        requireServiceWO: true,
        requireRetreadWO: false,
        onePOPerManufacturer: true,
        allowGeneralPOForForkliftOrders: false,
        mountedWheelsProgram: false,
        showUsedInventoryInput: false,
        showPickedUpCasingsInput: false
      }
    }

    if (this.fleet._id) {
      newFleet._id = this.fleet._id
    }

    return newFleet
  }

  handleUploadedImage(imageUrl: any) {
    this.fleet.logo = imageUrl
  }

  setSelectedOrderFlow() {
    const orderFlowId = this.fleet.defaultOrderFlow
      ? this.fleet.defaultOrderFlow._id || this.fleet.defaultOrderFlow
      : null
    if (orderFlowId) {
      this.selectedOrderFlow = this.orderFlows.find((flow: any) => {
        return (
          flow.versions.find((version) => version._id === orderFlowId) !==
          undefined
        )
      })
    } else {
      this.selectedOrderFlow = null
    }
  }

  selectedOrderFlowChanged(): void {
    // reset fleet default order flow to empty object, or the version id won't get nulled out and it won't change
    this.fleet.defaultOrderFlow = {}
  }

  goToProductList(): void {
    this.router.navigate([`/product/list/fleet/${this.fleet._id}`])
  }

  handleResponse(response: CoreResponse): boolean {
    if (response.offendingKey) {
      switch (response.offendingKey) {
        case 'name':
        case 'abbreviation':
        case 'address.errors.street1':
        case 'address.errors.street2':
        case 'address.errors.state':
        case 'address.errors.zip':
        case 'phone':
        case 'fax':
        case 'email':
        case 'defaultOrderFlow':
          this.responses[response.offendingKey] = response.message
          return true
        default:
          return false
      }
    } else {
      switch (response.type) {
        case 'saveSuccess':
        case 'updateSuccess':
        case 'deleteSuccess':
          this.responses[response.type] = response.message
          return true
        default:
          return false
      }
    }
  }
}
