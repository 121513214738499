export const appVersion = '3.0.0'

export const timezones = [
  {
    value: 'America/Los_Angeles',
    display: 'Pacific'
  },
  {
    value: 'America/Denver',
    display: 'Mountain'
  },
  {
    value: 'America/Chicago',
    display: 'Central'
  },
  {
    value: 'America/New_York',
    display: 'Eastern'
  },
  {
    value: 'America/Indiana/Indianapolis',
    display: 'Indianapolis'
  },
  {
    value: 'America/Phoenix',
    display: 'Phoenix'
  }
]

export type StateNameType =
  | 'Alabama'
  | 'Alaska'
  | 'American Samoa'
  | 'Arizona'
  | 'Arkansas'
  | 'California'
  | 'Colorado'
  | 'Connecticut'
  | 'Delaware'
  | 'District Of Columbia'
  | 'Federated States Of Micronesia'
  | 'Florida'
  | 'Georgia'
  | 'Guam'
  | 'Hawaii'
  | 'Idaho'
  | 'Illinois'
  | 'Indiana'
  | 'Iowa'
  | 'Kansas'
  | 'Kentucky'
  | 'Louisiana'
  | 'Maine'
  | 'Marshall Islands'
  | 'Maryland'
  | 'Massachusetts'
  | 'Michigan'
  | 'Minnesota'
  | 'Mississippi'
  | 'Missouri'
  | 'Montana'
  | 'Nebraska'
  | 'Nevada'
  | 'New Hampshire'
  | 'New Jersey'
  | 'New Mexico'
  | 'New York'
  | 'North Carolina'
  | 'North Dakota'
  | 'Northern Mariana Islands'
  | 'Ohio'
  | 'Oklahoma'
  | 'Oregon'
  | 'Palau'
  | 'Pennsylvania'
  | 'Puerto Rico'
  | 'Rhode Island'
  | 'South Carolina'
  | 'South Dakota'
  | 'Tennessee'
  | 'Texas'
  | 'Utah'
  | 'Vermont'
  | 'Virgin Islands'
  | 'Virginia'
  | 'Washington'
  | 'West Virginia'
  | 'Wisconsin'
  | 'Wyoming'

export type StateAbbreviationType =
  | 'AL'
  | 'AK'
  | 'AS'
  | 'AZ'
  | 'AR'
  | 'CA'
  | 'CO'
  | 'CT'
  | 'DE'
  | 'DC'
  | 'FM'
  | 'FL'
  | 'GA'
  | 'GU'
  | 'HI'
  | 'ID'
  | 'IL'
  | 'IN'
  | 'IA'
  | 'KS'
  | 'KY'
  | 'LA'
  | 'ME'
  | 'MH'
  | 'MD'
  | 'MA'
  | 'MI'
  | 'MN'
  | 'MS'
  | 'MO'
  | 'MT'
  | 'NE'
  | 'NV'
  | 'NH'
  | 'NJ'
  | 'NM'
  | 'NY'
  | 'NC'
  | 'ND'
  | 'MP'
  | 'OH'
  | 'OK'
  | 'OR'
  | 'PW'
  | 'PA'
  | 'PR'
  | 'RI'
  | 'SC'
  | 'SD'
  | 'TN'
  | 'TX'
  | 'UT'
  | 'VT'
  | 'VI'
  | 'VA'
  | 'WA'
  | 'WV'
  | 'WI'
  | 'WY'

export type WheelPositionNameType =
  | 'Right Steer'
  | 'Left Steer'
  | 'Left Front Outer'
  | 'Left Front Inner'
  | 'Left Rear Outer'
  | 'Left Rear Inner'
  | 'Right Front Outer'
  | 'Right Front Inner'
  | 'Right Rear Outer'
  | 'Right Rear Inner'
export type WheelPositionAbbreviationType =
  | 'RS'
  | 'LS'
  | 'LFO'
  | 'LFI'
  | 'LRO'
  | 'LRI'
  | 'RFO'
  | 'RFI'
  | 'RRO'
  | 'RRI'

export type CauseOfFailureType =
  | 'Brake Skid Damage'
  | 'Damage to Crown Area'
  | 'Damage to Sidewall Area'
  | 'Excessive Wear Reaching Steel'
  | 'Misaligned Hand Holes'
  | 'Mismatched Tread Depth'
  | 'Pull Point Low Tread'
  | 'Flat Repair/Low Pressure'
  | 'Run Flat/Blowout'
  | 'Tire Warranty'
export type CauseOfFailureAbbreviationType =
  | 'BSD'
  | 'DCA'
  | 'DSA'
  | 'EWS'
  | 'MHH'
  | 'MTD'
  | 'PPT'
  | 'PSI'
  | 'RFB'
  | 'TWY'

export const usStates: {
  name: StateNameType
  abbreviation: StateAbbreviationType
}[] = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Palau',
    abbreviation: 'PW'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
]

export const wheelPositions: {
  name: WheelPositionNameType
  abbreviation: WheelPositionAbbreviationType
}[] = [
  {
    name: 'Right Steer',
    abbreviation: 'RS'
  },
  {
    name: 'Left Steer',
    abbreviation: 'LS'
  },
  {
    name: 'Left Front Outer',
    abbreviation: 'LFO'
  },
  {
    name: 'Left Front Inner',
    abbreviation: 'LFI'
  },
  {
    name: 'Left Rear Outer',
    abbreviation: 'LRO'
  },
  {
    name: 'Left Rear Inner',
    abbreviation: 'LRI'
  },
  {
    name: 'Right Front Outer',
    abbreviation: 'RFO'
  },
  {
    name: 'Right Front Inner',
    abbreviation: 'RFI'
  },
  {
    name: 'Right Rear Outer',
    abbreviation: 'RRO'
  },
  {
    name: 'Right Rear Inner',
    abbreviation: 'RRI'
  }
]

export const causesOfFailure: {
  name: CauseOfFailureType
  abbreviation: CauseOfFailureAbbreviationType
}[] = [
  {
    name: 'Brake Skid Damage',
    abbreviation: 'BSD'
  },
  {
    name: 'Damage to Crown Area',
    abbreviation: 'DCA'
  },
  {
    name: 'Damage to Sidewall Area',
    abbreviation: 'DSA'
  },
  {
    name: 'Excessive Wear Reaching Steel',
    abbreviation: 'EWS'
  },
  {
    name: 'Misaligned Hand Holes',
    abbreviation: 'MHH'
  },
  {
    name: 'Mismatched Tread Depth',
    abbreviation: 'MTD'
  },
  {
    name: 'Pull Point Low Tread',
    abbreviation: 'PPT'
  },
  {
    name: 'Flat Repair/Low Pressure',
    abbreviation: 'PSI'
  },
  {
    name: 'Run Flat/Blowout',
    abbreviation: 'RFB'
  },
  {
    name: 'Tire Warranty',
    abbreviation: 'TWY'
  },
  {
    name: 'Brake Skid Damage',
    abbreviation: 'BSD'
  }
]
