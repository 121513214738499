import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

/**
 * Custom email validator. It validates with angular's own Email validator, but it allows empty values
 */
export const EMAIL_VALIDATOR_FN: ValidatorFn = (control: AbstractControl) => {
  if (!control.value) {
    return null
  }

  return Validators.email(control)
}
