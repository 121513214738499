import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'
import { OrderFlowComponent } from './order-flow.component'

const orderFlowRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: [{ path: 'orderFlow', component: OrderFlowComponent }]
  }
]
@NgModule({
  imports: [RouterModule.forChild(orderFlowRoutes)],
  exports: [RouterModule]
})
export class OrderFlowRoutingModule {}
