import { Pipe, PipeTransform } from '@angular/core'

/**
 * Converts a string to capital case
 * If keepSpaces
 * Example 1: 'test' => 'Test'
 * Example 2: 'TEST' => 'Test'
 * Example 3: 'this is a string' => 'This Is A String'
 * Example 4: 'variableName' => 'VariableName'
 * If !keepSpaces
 * Example 1: 'test' => 'Test'
 * Example 2: 'TEST' => 'Test'
 * Example 3: 'this is a string' => 'ThisIsAString'
 * Example 4: 'variableName' => 'VariableName'
 */
@Pipe({
  name: 'capitalCasePipe'
})
export class CapitalCasePipe implements PipeTransform {
  public transform(str, keepSpaces = true) {
    if (!str || !str.length) {
      return str
    }

    if (str.length === 1) {
      return str[0].toUpperCase()
    }

    const strParts = str
      .split(' ')
      .map((s) => s[0].toUpperCase() + s.substring(1).toLowerCase())
    return strParts.join(keepSpaces ? ' ' : '')
  }
}
