import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { ToastContainerDirective, ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-poptart',
  template: `<div toastContainer></div>`,
  styleUrls: ['./poptart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopTartComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective

  constructor(private toastrService: ToastrService) {}
  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer
  }
}
