<form (submit)="uploadFile()">
  <div fxLayout="row">
    <h4 class="document-display-name">{{ document.displayName }}</h4>
  </div>
  <div fxLayout="row">
    <div fxFlex="32%">
      <input
        name="docNumber"
        class="document-input"
        [(ngModel)]="document.number"
        placeholder="{{ document.placeholder }}"
      />
    </div>
    <div fxFlex="68%" fxLayoutAlign="start center">
      <button
        type="button"
        mat-raised-button
        class="mat-raised file-btn file-choose-btn"
        (click)="chooseFile()"
      >
        Choose
      </button>
      <input
        class="hide-me"
        name="upload"
        #fileInput
        type="file"
        [(ngModel)]="document.selectedFile"
        (change)="fileInputChanged(document)"
      />
      <button
        *ngIf="!document.uploading"
        [disabled]="document.invalidType"
        mat-raised-button
        class="mat-raised file-btn file-upload-btn"
      >
        Upload
      </button>
      <button
        *ngIf="document.uploading"
        [disabled]="true"
        mat-raised-button
        class="mat-raised file-btn file-upload-btn"
      >
        Uploading
        <img src="../../../../assets/images/spinner.gif" height="25" />
      </button>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlexOffset="32%" fxLayoutAlign="start center" fxFlex>
      <div
        *ngIf="!document.invalidType"
        class="filename"
        [appFileEllipses]="document.selectedFileName"
      ></div>
      <div *ngIf="document.invalidType" class="filename error-message">
        Must be a PDF, image, or text file
      </div>
    </div>
  </div>
</form>
