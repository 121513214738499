import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ManufacturerService } from '../../manufacturer/client/manufacturer.service'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { ProductService } from './product.service'

@Component({
  selector: 'app-product-edit',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [ManufacturerService]
})
export class ProductComponent extends CoreResponseHandler implements OnInit {
  productId: string
  product: any = {}
  brands: any[]
  forkLiftId: string
  saveErrors: string[] = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    public manufacturerService: ManufacturerService
  ) {
    super()
  }

  ngOnInit() {
    // Retrieve data from the API

    this.manufacturerService.getManufacturers().subscribe((brandList) => {
      this.brands = brandList
    })

    this.route.params.subscribe((params) => {
      if (params.productId) {
        this.productService.getById(params.productId).subscribe((product) => {
          this.product = product
        })
      }
    })
  }

  handleResponse(response: CoreResponse): boolean {
    if (response.offendingKey) {
      this.saveErrors.push(response.message)
      return true
    } else {
      return false
    }
  }

  submit() {
    this.saveErrors = []
    if (this.product.productType === 'FORKLIFT') {
      this.product.wheelMatch = 'FORKLIFT'
      // this.product.brand.name = 'FORKLIFT';
    }

    if (this.product._id) {
      this.productService
        .updateProduct(this.product)
        .subscribe((updatedProduct) => {
          this.product = updatedProduct
          this.router.navigate(['/product/list'])
        })
    } else {
      this.productService.addProduct(this.product).subscribe(
        (newProduct) => {
          if (newProduct) {
            this.product = newProduct
            this.router.navigate(['/product/list'])
          }
        },
        (error) => {
          this.handleFailure(error)
        }
      )
    }
  }

  cancel() {
    this.router.navigate(['/product/list'])
  }

  remove() {
    if (
      confirm(
        'Are you sure you want to delete this product? This cannot be undone.'
      )
    ) {
      this.productService
        .removeProduct(this.product._id)
        .subscribe((removedProduct) => {
          this.router.navigate(['/product/list'])
        })
    }
  }

  handleUploadedImage(imageUrl: any) {
    this.product.image = imageUrl
  }

  productTypeSelected(product) {
    if (product.productType === 'FORKLIFT') {
      for (let i = 0; i < this.brands.length; i++) {
        if (this.brands[i].name === 'Forklift') {
          product.brand = this.brands[i]._id
          product.wheelMatch = 'FORKLIFT'
          break
        }
      }
    } else if (product.productType === 'WHEEL') {
      for (let i = 0; i < this.brands.length; i++) {
        if (this.brands[i].name === 'Wheels') {
          product.brand = this.brands[i]._id
          product.wheelMatch = null
          break
        }
      }
    } else {
      product.brand = null
      product.wheelMatch = null
    }
  }
}
