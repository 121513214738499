import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work

import { FlexLayoutModule } from '@angular/flex-layout'
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { VendorComponent } from './vendor.component'

import { VendorService } from './vendor.service'

import { VendorRoutingModule } from './vendor-routing.module'

@NgModule({
  declarations: [VendorComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    VendorRoutingModule,
    CoreModule,
    FlexLayoutModule,
    MaterialComponentsModule
  ],
  providers: [VendorService],
  exports: [VendorComponent]
})
export class VendorModule {}
