// tslint:disable:no-access-missing-member
import * as _ from 'lodash'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import * as moment from 'moment'
import { OrderService } from '../order.service'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-log',
  template: `
    <div *ngIf="isVisible" class="app-order-log order-part">
      <div class="order-part-column-container">
        <div class="list-header-container">
          <div class="desc-column">Action</div>
          <div class="desc-column">Description</div>
          <div class="desc-column">Date</div>
          <div class="desc-column">Author</div>
          <div class="desc-column">Account Type</div>
        </div>
        <div>
          <div
            *ngFor="let history of sortedHistoryItems"
            [ngClass]="{
              'history-log-row': 1,
              'history-log-modification': history.isModification
            }"
          >
            <div class="list-container">
              <div class="desc-column">
                {{ history?.stateDescription }}
              </div>
              <div
                class="desc-column"
                style="flex-direction: column; width: 100%; text-align: left"
              >
                <div
                  *ngFor="let d of history.description"
                  class="history-description-item"
                >
                  {{ d }}
                </div>
              </div>
              <div class="desc-column">
                {{ history?.date | date: 'short' }}
              </div>
              <div class="desc-column">
                {{ history?.user?.displayName }}
              </div>
              <div class="desc-column">
                {{ history?.user?.defaultAccountType }}
              </div>
            </div>
            <div
              class="list-container-note notes-yellow"
              *ngIf="history.actionType === 'STATE_CHANGED' && history.notes"
            >
              {{ history.notes }}
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../order.component.scss'],
  providers: []
})
export class OrderLogComponent extends OrderBaseComponent implements OnInit {
  historyItems: any[] = []
  constructor() {
    super()
  }

  get sortedHistoryItems() {
    return this.historyItems.sort(
      (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
    )
  }

  handleResponse(error: CoreResponse): boolean {
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes('log')) &&
      (this.order.location.fleet._id !== '58f1253791541b40c8d03c14' ||
        this.coreUser.accountType !== 'Location')
  }

  setOrder(order) {
    this.historyItems = []
    super.setOrder(order)
    let placeOrderFound = false
    _.forEach(order.stateHistory, (h: any) => {
      h.isModification = h.actionType === 'ORDER_MODIFIED'
      h.stateDescription = h.isModification ? 'Modified' : h.stateDescription
      if (placeOrderFound) {
        if (h.stateDescription !== 'Place Order') {
          this.historyItems.unshift(h)
        }
      } else {
        if (h.stateDescription === 'Place Order') {
          placeOrderFound = true
        }
        this.historyItems.unshift(h)
      }
    })
  }

  logOrderModification(
    type: string,
    item: any,
    originalQty: number,
    isForklift: boolean,
    oldDate?: Date,
    newDate?: Date
  ) {
    let doPushEntry = true
    let prefix = ''

    if (!this.order.pendingModificationLogs) {
      this.order.pendingModificationLogs = []
    }

    // generate a string id for this particular entry
    // this will allow us to update entries that are already in the list of changes
    let entryId: string

    if (type !== 'fleetCheckDueDateChanged') {
      entryId = item._id || item
      entryId += isForklift
        ? `forklift:${item.forkliftNumber}`
        : `wheel:${item.rim ? item.rim._id || item.rim : ''}`
    } else {
      entryId = 'fleetCheck'
    }

    // Find the matching existing entry
    let entry: any = _.find(
      this.order.pendingModificationLogs,
      (l) => l.entryId === entryId
    )

    if (!entry) {
      // If no existing entry, create a new one
      entry = { startType: type, startQty: originalQty, isNew: true }
    } else {
      // Pull out the existing entry so we can update it and prevent duplicates
      entry = _.remove(
        this.order.pendingModificationLogs,
        (l: Record<string, unknown>) => l.entryId === entryId
      )[0]
    }

    if (type !== 'fleetCheckDueDateChanged') {
      prefix = isForklift ? `Forklift #${item.forkliftNumber} ` : 'Product '

      item.item = item.tire ? item.tire.item : item.item
      const addText = `${item.item} (qty: ${item.qty}) added`
      const qtyText = `${item.item} changed from ${entry.startQty} to ${item.qty}`
      const delText = `${item.item} removed`
      const startType = entry.startType
      const endType = type

      if (startType === 'productAdded') {
        if (endType === 'productAdded') {
          entry.text = addText
        } else {
          doPushEntry = false
        }
      } else if (startType === 'quantityChange') {
        if (endType === 'quantityChange' || endType === 'productAdded') {
          entry.text = qtyText
        } else if (endType === 'productDeleted') {
          entry.text = delText
        } else {
          entry.text = qtyText
          doPushEntry = false
        }
      } else if (startType === 'productDeleted') {
        if (endType === 'productDeleted') {
          entry.text = delText
        } else if (endType === 'quantityChange' || endType === 'productAdded') {
          entry.text = qtyText
        } else {
          doPushEntry = false
        }
      }
    } else {
      entry.text = `Due Date changed from ${moment(oldDate).format(
        'MM/DD/YYYY'
      )} to ${moment(newDate).format('MM/DD/YYYY')}`
    }

    if (entry.isNew || doPushEntry) {
      entry.text = prefix + entry.text
      entry.startText = entry.startText || entry.text
      entry.type = type
      entry.entryId = entryId
      entry.isNew = false
      this.order.pendingModificationLogs.push(entry)
    }
  }
}
