<div class="top-nav">
  <div class="items-container">
    <div class="hamburger">
      <app-hamburger #topHamburger (click)="toggleSideNav()"></app-hamburger>
    </div>
    <div class="shopping-cart-icon" *ngIf="showAccountSwitcher">
      <app-account-switcher></app-account-switcher>
    </div>
    <div class="logged-in-user-info">
      <div class="default-user-info">
        <div class="account-type">Welcome</div>
        <div class="display-name">{{ loggedInUser?.displayName }}</div>
      </div>
      <div
        class="switched-user-info"
        *ngIf="
          (loggedInUser?.account?._id || loggedInUser?.account) !==
          loggedInUser?.defaultAccount
        "
      >
        <span class="info"
          >(Acting for <span class="name">{{ loggedInUser?.account.name }}</span
          >)</span
        >
      </div>
    </div>
    <div class="nav-logo">
      <i></i>
    </div>
  </div>
</div>
