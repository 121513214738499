import { Injectable } from '@angular/core'

import { Router } from '@angular/router'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable()
export class ProductService {
  productURL = `${environment.apiUrl}/product/`

  constructor(private http: HttpClient) {}

  // move to utils once everything is cleaned up
  toQueryString(obj) {
    const parts = []
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      }
    }
    return parts.join('&')
  }

  // Get all posts from the API
  getAllProducts(): Observable<any[]> {
    return this.http.get<any[]>(this.productURL)
  }

  getById(
    productId,
    options?: { includeSummary: 0 | 1; storeNumber: string }
  ): Observable<any> {
    return this.http.get<any>(
      this.productURL +
        productId +
        (options ? `?${this.toQueryString(options)}` : '')
    )
  }

  // get all products by type & use
  getAllByTypeAndUse(type, use): Observable<any[]> {
    let params = new HttpParams()
    params = params.append('productType', type)
    params = params.append('productUse', use)

    return this.http.post<any[]>(`${this.productURL}findMany`, { params })
  }

  /**
   * get all by productType
   */
  getAllByType(type): Observable<any[]> {
    let params = new HttpParams()
    params = params.append('productType', type)

    return this.http.post<any[]>(`${this.productURL}findMany`, { params })
  }

  addProduct(newProductJson): Observable<any> {
    return this.http.post<any>(this.productURL, newProductJson)
  }

  updateProduct(productJson): Observable<any> {
    return this.http.put<any>(this.productURL + productJson._id, productJson)
  }

  removeProduct(productJson): Observable<any> {
    return this.http.delete<any>(this.productURL + productJson)
  }

  getOptimalQuantity() {
    return 5
  }
}
