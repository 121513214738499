// tslint:disable:no-access-missing-member
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { ModalDialogsService } from '../../../core/client/modal-dialogs/modal-dialogs.service'
import { DocumentManagementService } from '../../../document-management/client/document-management.service'
import { OrderDocument } from './order-documents.component'

@Component({
  selector: 'app-order-document-upload',
  templateUrl: './order-document-upload.component.html',
  styleUrls: [
    '../order.component.scss',
    './order-document-upload.component.scss'
  ],
  providers: []
})
export class OrderDocumentUploadComponent {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef
  @Input() document: OrderDocument
  @Input() attachmentId: string
  @Input() fleet: any
  @Output() documentUploaded = new EventEmitter<OrderDocument>()

  constructor(
    private dialogService: ModalDialogsService,
    private documentManagementService: DocumentManagementService
  ) {}

  chooseFile() {
    this.fileInput.nativeElement.click()
  }

  fileInputChanged(document) {
    const validFileTypes = [
      'image/png',
      'image/jpeg',
      'text/plain',
      'application/pdf'
    ]
    const input = this.fileInput
    document.selectedFileName = null
    if (input.nativeElement.files && input.nativeElement.files.length) {
      const file = input.nativeElement.files[0]
      document.invalidType = !validFileTypes.includes(file.type)
      // set the filename
      // i put this in a timeout because setting invalid type to false takes a second to get rid of error message
      // this was causing filename text to be on two lines which caused an ellipses
      setTimeout(() => {
        document.selectedFileName = file.name
      }, 10)
    }
  }

  uploadFile() {
    this.document.uploading = false
    const input = this.fileInput.nativeElement
    if (!this.document.number && this.document.numberRequired) {
      this.dialogService.alert(
        'Missing Description',
        'Please enter a number or description.'
      )
    } else if (!input || !input.files || !input.files.length) {
      this.dialogService.alert('Missing File', 'Please select a file.')
    } else {
      this.document.uploading = true

      if (this.document.isWheelsOnly) {
        this.document.description = 'Wheels Only #'
        this.document.fileIdentity = 'wheels_only_wo'
      } else if (this.document.isBONL) {
        this.document.description = 'BON-L Ticket #'
        this.document.fileIdentity = 'bonL_ticket'
      }

      const description =
        this.document.description === 'Other'
          ? this.document.number
          : this.document.description + this.document.number

      const formData = new FormData()
      formData.append('upload', input.files[0])
      formData.append('description', description)
      formData.append('identity', this.document.fileIdentity)
      formData.append('attachTo', this.attachmentId)
      formData.append(
        'visibleToClient',
        (!this.fleet.options.requireSTMPaperworkValidation).toString()
      )

      this.documentManagementService.uploadDocument(formData).subscribe(
        () => {
          this.documentUploaded.emit(this.document)
        },
        (err) => {
          this.document.uploading = false
        },
        () => {
          this.document.uploading = false
        }
      )
    }
  }
}
