<div class="datatable-shadow-base">
  <div
    class="datatable"
    [ngStyle]="{ 'background-color': config.backgroundColor }"
  >
    <mat-expansion-panel
      [expanded]="config.expanded"
      [ngStyle]="{ 'background-color': config.headerBackgroundColor }"
    >
      <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
        <mat-panel-title>
          <!--List Widget Header-->
          <div
            class="datatable-header"
            [ngStyle]="{
              'background-color': config.headerBackgroundColor,
              color: config.headerTextColor
            }"
          >
            <div *ngIf="config.title" class="datatable-title">
              <div class="title-text">
                {{ config.title }}
              </div>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="config.pageSize > -1" class="pagination-container">
        <div fxLayout="row" fxFlex>
          <div fxLayout="column" fxFlex="15" fxLayoutAlign="center start">
            &nbsp;
          </div>
          <div fxLayout="column" fxFlex="70" fxLayoutAlign="center center">
            <pagination-controls
              id="{{ tableId }}"
              (pageChange)="getPageItems($event)"
              autoHide="true"
              maxSize="{{ dataTableService.mobileLayout ? '5' : '7' }}"
              previousLabel="{{
                dataTableService.mobileLayout ? 'Prev' : 'Previous'
              }}"
            ></pagination-controls>
          </div>
          <div
            fxLayout="column"
            fxFlex="15"
            fxLayoutAlign="center end"
            class="pagination-info"
          >
            <span *ngIf="totalItems"
              >{{ firstIndex }}-{{ lastIndex }} of {{ totalItems }} items</span
            >
          </div>
        </div>
      </div>
      <!--List Widget Data Table: COLUMN HEADERS SECTION-->
      <div class="datatable-column-headers">
        <div
          class="datatable-action-button-column"
          [ngStyle]="{ width: dataTableService.getActionBtnColumnWidth() }"
          *ngIf="config.includeActionButtons"
        ></div>
        <div
          class="datatable-table-data-column"
          [ngStyle]="{
            width:
              'calc(100% - ' + dataTableService.getActionBtnColumnWidth() + ')'
          }"
        >
          <div
            *ngFor="let column of config.columns"
            [ngStyle]="{ width: dataTableService.getAdjustedWidth(column) }"
            [ngClass]="{
              'column-header-container': 1,
              sortable: config.isSortable
            }"
            (window:resize)="dataTableService.toggleMobileLayout()"
            (click)="sort(column)"
          >
            <div
              *ngIf="dataTableService.hideColumn(column) === false"
              class="column-header"
            >
              <div class="column-header-text">
                {{ column.display }}
              </div>
              <div class="sort-icon-container">
                <mat-icon *ngIf="column.sort === 'asc'"
                  >arrow_drop_down</mat-icon
                >
                <mat-icon *ngIf="column.sort === 'desc'"
                  >arrow_drop_up</mat-icon
                >
              </div>
            </div>
          </div>
        </div>
        <div class="datatable-rush-order-icon-column"></div>
      </div>

      <!--List Widget Data Table: DATA SECTION -->
      <div
        class="datatable-data-list-container"
        [ngStyle]="{ 'min-height': loading ? '100px' : '50px' }"
      >
        <div class="loading-cover" *ngIf="loading">
          <div class="loading-background"></div>
          <div class="loading-spinner"></div>
        </div>
        <div *ngIf="config.pageSize > -1" class="paginated-results">
          <app-datatable-row
            *ngFor="let datatableRow of renderedItems | paginate: paginateOpts"
            [tableConfig]="config"
            [rowConfig]="{
              row: datatableRow,
              includeActionButtons: config.includeActionButtons,
              columns: config.columns,
              cssClassfes: [],
              idField: config.idField
            }"
          >
          </app-datatable-row>
        </div>
        <div *ngIf="config.pageSize === -1" class="non-paginated-results">
          <app-datatable-row
            *ngFor="let datatableRow of renderedItems"
            [tableConfig]="config"
            [rowConfig]="{
              row: datatableRow,
              includeActionButtons: config.includeActionButtons,
              columns: config.columns,
              cssClasses: [],
              idField: config.idField
            }"
          >
          </app-datatable-row>
        </div>
        <div
          class="empty-message"
          *ngIf="itemsResolved && config.emptyMessage && !totalItems"
        >
          <mat-icon
            *ngIf="config.emptyIcon"
            [ngStyle]="{ color: config.emptyIconColor }"
            >{{ config.emptyIcon }}</mat-icon
          >{{ config.emptyMessage }}
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>
