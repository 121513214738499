<div class="product-list-container">
  <h1>Product List</h1>
  <div class="product-list-buttons" *ngIf="products && products.length">
    <button
      *ngIf="!fleet && !location"
      mat-raised-button
      color="primary"
      (click)="newProduct()"
    >
      Create Product
    </button>
    <div *ngIf="fleet || location">
      <button mat-raised-button (click)="saveProductList()" color="primary">
        Save Product List
      </button>
      <button mat-raised-button (click)="cancel()" color="warn">Cancel</button>
    </div>
  </div>
  <h3 *ngIf="fleet && !location">
    Selecting default product list for fleet: {{ fleet?.name }}
  </h3>
  <h3 *ngIf="location">
    Selecting product list for location: {{ location?.name }}
    <small>({{ fleet?.name }})</small>
  </h3>
  <mat-checkbox
    *ngIf="location"
    [(ngModel)]="location.productList.useFleetDefault"
    (change)="useDefaultChanged()"
    >Use Fleet Default</mat-checkbox
  >
  <div class="table-responsive">
    <div fxLayout="row" *ngIf="fleet || location">
      <div fxLayoutAlign="start end" fxFlex class="legend-left">
        <p *ngIf="location"><strong>F</strong> = Product is in fleet default</p>
      </div>
      <div fxLayoutAlign="end end" fxFlex class="legend-right">
        <div fxLayout="column" fxLayoutGap="0">
          <p><strong>W</strong> = Require wheel selection</p>
          <!-- <p><strong>V</strong> = Vehicle # required</p> -->
        </div>
      </div>
    </div>
    <table *ngIf="products && products.length" class="table products-table">
      <thead>
        <tr>
          <th *ngIf="fleet || location">
            <mat-checkbox
              [(ngModel)]="allChecked"
              (change)="allCheckedChanged()"
              [disabled]="location && location.productList.useFleetDefault"
            ></mat-checkbox>
          </th>
          <th *ngIf="location">F</th>
          <th>Image</th>
          <th *ngIf="!fleet && !location"></th>
          <th>Item</th>
          <th>Descripton</th>
          <th>Type</th>
          <th>Use</th>
          <th>Wheel Match</th>
          <th>Brand</th>
          <th>PO Requirement</th>
          <th>Notes</th>
          <!-- <th *ngIf="fleet || location"><mat-checkbox [(ngModel)]="allRequiresWheelChecked" (change)="allRequiresWheelChanged()" [disabled]="location && location.productList.useFleetDefault"></mat-checkbox> W</th>
                    <th *ngIf="location"> </th> -->
          <!-- <th *ngIf="fleet || location"><mat-checkbox [(ngModel)]="allRequiresVehicleChecked" (change)="allRequiresVehicleChanged()" [disabled]="location && location.productList.useFleetDefault"></mat-checkbox> V</th>
                    <th *ngIf="location"> </th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let product of products"
          (click)="(!location || !fleet) && goToProduct(product._id)"
          [ngClass]="{
            selected:
              (location && product.selectedInLocation) ||
              (fleet && product.selectedInFleet)
          }"
        >
          <td *ngIf="fleet || location" (click)="$event.stopPropagation()">
            <mat-checkbox
              *ngIf="fleet && !location"
              [(ngModel)]="product.selectedInFleet"
              (change)="selectedChanged(product)"
              class="product-check"
            ></mat-checkbox>
            <mat-checkbox
              *ngIf="location"
              [(ngModel)]="product.selectedInLocation"
              [disabled]="location.productList.useFleetDefault"
              (change)="selectedChanged(product)"
              class="product-check"
            ></mat-checkbox>
          </td>
          <td *ngIf="location">{{ product.selectedInFleet ? 'F' : '' }}</td>
          <td><img [src]="product?.image" style="height: 50px" /></td>
          <td *ngIf="!fleet && !location">
            <button
              mat-mini-fab
              color="primary"
              (click)="duplicateProduct(product)"
              title="copy product"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
          </td>
          <td>{{ product?.item }}</td>
          <td>{{ product?.description }}</td>
          <td>{{ product?.productType }}</td>
          <td>{{ product?.productUse }}</td>
          <td>{{ product?.wheelMatch }}</td>
          <td>{{ product?.brand?.name }}</td>
          <td>{{ product?.poRequirement }}</td>
          <td>{{ product?.comment }}</td>
          <!-- <td *ngIf="(fleet || location) && product.productType !== 'WHEEL'"
                    (click)="$event.stopPropagation();">
                    <mat-checkbox
                        *ngIf="fleet && !location"
                        [(ngModel)]="product.requiresWheelInFleet"
                        (change)="allRequiresWheelChecked=false"
                        [disabled]="!product.selectedInFleet"
                        class="product-check"
                        title="{{product.selectedInFleet ? '' : 'Please select the product first.'}}"></mat-checkbox>
                    <mat-checkbox
                        *ngIf="location"
                        [(ngModel)]="product.requiresWheelInLocation"
                        [disabled]="location.productList.useFleetDefault || !product.selectedInLocation"
                        (change)="allRequiresWheelChecked=false"
                        class="product-check"
                        title="{{location.productList.useFleetDefault || product.selectedInLocation ? '' : 'Please select the product first.'}}"></mat-checkbox>
                </td>
                <td *ngIf="(fleet || location) && product.productType === 'WHEEL'">N/A</td>
                <td *ngIf="location">{{product.requiresWheelInFleet ? 'F' : ''}}</td> -->
          <!-- <td *ngIf="fleet || location"
                    (click)="$event.stopPropagation();">
                    <mat-checkbox
                        *ngIf="fleet && !location"
                        [(ngModel)]="product.requiresVehicleInFleet"
                        (change)="allRequiresVehicleChecked=false"
                        [disabled]="!product.selectedInFleet"
                        class="product-check"
                        title="{{product.selectedInFleet ? '' : 'Please select the product first.'}}"></mat-checkbox>
                    <mat-checkbox
                        *ngIf="location"
                        [(ngModel)]="product.requiresVehicleInLocation"
                        [disabled]="location.productList.useFleetDefault || !product.selectedInLocation"
                        (change)="allRequiresVehicleChecked=false"
                        class="product-check"
                        title="{{location.productList.useFleetDefault || product.selectedInLocation ? '' : 'Please select the product first.'}}"></mat-checkbox>
                </td> -->
          <!-- <td *ngIf="location">{{product.requiresVehicleInFleet ? 'F' : ''}}</td> -->
        </tr>
      </tbody>
    </table>
    <div class="product-list-buttons">
      <button
        *ngIf="!fleet && !location"
        mat-raised-button
        color="primary"
        (click)="newProduct()"
      >
        Create Product
      </button>
      <div *ngIf="fleet || location">
        <button mat-raised-button (click)="saveProductList()" color="primary">
          Save Product List
        </button>
        <button mat-raised-button (click)="cancel()" color="warn">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
