import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { io } from 'socket.io-client'
import { environment } from '../../../../environments/environment'

@Injectable()
export class StateBroadcastService {
  private url = environment.socketIOUrl
  private socket

  broadcastUpdate(id, orderType, stateWillChange = false) {
    this.socket.emit('an-order-was-updated', {
      orderId: id,
      orderType,
      stateWillChange
    })
  }

  broadcastDelete(id, orderType) {
    this.socket.emit('an-order-was-updated', {
      orderId: id,
      orderType,
      wasDeleted: true
    })
  }

  getStateUpdateBroadcast() {
    return new Observable<{
      orderId: string
      orderType: string
      stateWillChange: boolean
      wasDeleted: boolean
    }>((observer) => {
      this.socket = io(this.url)
      this.socket.on(
        'check-if-order-needs-refresh',
        (data: {
          orderId: string
          orderType: string
          stateWillChange: boolean
          wasDeleted: boolean
        }) => {
          observer.next(data)
        }
      )
    })
    // return observable;
  }
}
