export class UtilsClass {
  // Merges two objects by value allowing you to keep pointed reference
  static mergeObjByValues(copyTo: any, copyFrom: any) {
    Object.keys(copyFrom).forEach((key) => {
      copyTo[key] = copyFrom[key]
    })
  }

  constructor() {}
}
