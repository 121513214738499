'use strict'

// node_modules imports
import { Component, Input, OnInit } from '@angular/core'
// import {Router} from '@angular/router';

// local imports

@Component({
  selector: 'app-container-widget',
  templateUrl: './container-widget.component.html',
  styleUrls: ['./container-widget.component.scss'],
  providers: []
})
export class ContainerWidgetComponent implements OnInit {
  // instantiate containerWidget to an empty array

  @Input() title = ''
  @Input() headerBarColor = ''
  @Input() faIcon = ''
  @Input() materialIcon = ''
  @Input() iconColor = ''

  constructor() {}

  ngOnInit() {}
}
