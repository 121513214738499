import { FormControl, ValidatorFn } from '@angular/forms'

/**
 * Fairly flexible custom U.S. phone validator.
 * Ensures that there are 11, 7, or 10 digits and only +, -, ., (, and ) characters.
 * Allows empty value.
 */
export const PHONE_US_VALIDATOR_FN: ValidatorFn = (control: FormControl) => {
  if (!control.value) {
    return null
  }
  const digitValue = control.value.replace(/\D/g, '')
  const nonDigitChars = control.value.replace(/\d/g, '').split('')
  const validDigitCounts = [11, 10, 7]
  const validNonDigits = ['-', '(', ')', '+', '.', ' ']
  if (validDigitCounts.includes(digitValue.length)) {
    let nonDigitsAreValid = true
    for (let i = 0; i < nonDigitChars.length; i++) {
      if (!validNonDigits.includes(nonDigitChars[i])) {
        nonDigitsAreValid = false
        break
      }
    }

    if (nonDigitsAreValid) {
      return null
    }
  }

  return {
    validPhoneUS: {
      valid: false
    }
  }
}
