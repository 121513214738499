<div class="order-search-container">
  <div fxLayout="column" fxLayoutGap="25px">
    <h1>Order Search</h1>
    <mat-card>
      <h2>Search Criteria</h2>
      <form
        fxLayout="column"
        fxLayoutGap="10px"
        class="order-search-form"
        (submit)="findMatchingOrders($event)"
      >
        <div fxLayout="row" class="rush-radios">
          <span class="rush-radios-label">Match</span>
          <mat-radio-group name="matchAllFields" [(ngModel)]="matchAllFields">
            <mat-radio-button [value]="true">All Fields</mat-radio-button>
            <mat-radio-button [value]="false">Any Field</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex class="order-search-input short">
            <input
              matInput
              placeholder="Order #"
              name="orderNumber"
              [(ngModel)]="searchParams.orderNumber"
              type="number"
              min="1"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              placeholder="Location"
              name="selectedLocationId"
              [(ngModel)]="searchParams.location"
            >
              <mat-option [value]="null" class="none-opt"
                >-- NONE --</mat-option
              >
              <mat-option
                *ngFor="let location of locations"
                value="{{ location._id }}"
              >
                {{ location.name }}
                <span class="fleet-name">({{ location.fleet.name }})</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              placeholder="Fleet"
              fxFlex
              name="selectedFleetId"
              [(ngModel)]="searchParams.fleet"
            >
              <mat-option [value]="null" class="none-opt"
                >-- NONE --</mat-option
              >
              <mat-option *ngFor="let fleet of fleets" value="{{ fleet._id }}">
                {{ fleet.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              placeholder="Vendor"
              fxFlex
              name="selectedVendorId"
              [(ngModel)]="searchParams.vendor"
            >
              <mat-option [value]="null" class="none-opt"
                >-- NONE --</mat-option
              >
              <mat-option
                *ngFor="let vendor of vendors"
                value="{{ vendor._id }}"
              >
                {{ vendor.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
            <mat-select
              placeholder="State"
              fxFlex
              name="selectedStateId"
              [(ngModel)]="searchParams.state"
            >
              <mat-option [value]="null" class="none-opt"
                >-- NONE --</mat-option
              >
              <mat-option
                *ngFor="let state of orderStates"
                value="{{ state._id }}"
              >
                {{ state.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <span class="date-range-label">Placed Date</span>
          <div fxLayout="row" fxLayoutGap="10px" class="date-range-content">
            <span class="date-range-content-label">Between</span>
            <mat-form-field>
              <input
                matInput
                ngx-mydatepicker
                name="placedDateFrom"
                [(ngModel)]="placedDateFrom"
                [options]="datepickerOpts"
                #dpFrom="ngx-mydatepicker"
                (dateChanged)="onDateChanged($event)"
                (inputFieldChanged)="onDateChanged($event)"
              />
              <mat-icon matSuffix (click)="dpFrom.toggleCalendar()"
                >date_range</mat-icon
              >
              <mat-hint class="error-message" *ngIf="errors.placedDate">{{
                errors.placedDate
              }}</mat-hint>
            </mat-form-field>
            <span class="date-range-content-label">And</span>
            <mat-form-field>
              <input
                matInput
                ngx-mydatepicker
                name="placedDateTo"
                [(ngModel)]="placedDateTo"
                [options]="datepickerOpts"
                #dpTo="ngx-mydatepicker"
                (dateChanged)="onDateChanged($event)"
                (inputFieldChanged)="onDateChanged($event)"
              />
              <mat-icon matSuffix (click)="dpTo.toggleCalendar()"
                >date_range</mat-icon
              >
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex class="order-search-input">
            <input
              matInput
              placeholder="Placed By (name or username)"
              name="placedBy"
              [(ngModel)]="searchParams.placedBy"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex>
            <input
              matInput
              name="deliveryReceiptNumbers"
              placeholder="Delivery Receipt #'s (separate by commas)"
              [(ngModel)]="deliveryReceiptNumbersString"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex>
            <input
              matInput
              name="salesPONumbers"
              placeholder="Sales PO #'s (separate by commas)"
              [(ngModel)]="salesPONumbersString"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex>
            <input
              matInput
              name="servicePONumbers"
              placeholder="Service PO #'s (separate by commas)"
              [(ngModel)]="servicePONumbersString"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex>
            <input
              matInput
              name="workOrderNumbers"
              placeholder="Work Order #'s (separate by commas)"
              [(ngModel)]="workOrderNumbersString"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex class="order-search-input">
            <input
              matInput
              placeholder="Notes Contain"
              name="notesContain"
              [(ngModel)]="searchParams.notes"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" class="rush-radios">
          <span class="rush-radios-label">Rush Order?</span>
          <mat-radio-group name="rushOrder" [(ngModel)]="searchParams.rush">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
            <mat-radio-button [value]="null">Either</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxLayout="column" class="submit-container" fxLayoutGap="15px">
          <mat-hint class="error-message" *ngIf="!isValid"
            >Fix all errors with form before submitting</mat-hint
          >
          <div>
            <button
              type="submit"
              mat-raised-button
              [ngClass]="{ 'blue-btn': isValid, 'red-btn': !isValid }"
            >
              Find Orders
            </button>
          </div>
        </div>
      </form>
    </mat-card>
    <mat-card class="order-search-results">
      <div class="search-results-container" fxLayout="column">
        <app-datatable
          [config]="searchResultsTableConfig"
          #searchResultsTable
        ></app-datatable>
      </div>
    </mat-card>
  </div>
</div>
