interface Array<T> {
  remove(o: any): Array<any>
}

Array.prototype.remove = function (valueOrPredicate: any): any[] {
  const predicate =
    typeof valueOrPredicate === 'function'
      ? valueOrPredicate
      : function (value) {
          return value === valueOrPredicate
        }
  const removedValues = []
  for (let i = 0; i < this.length; i++) {
    const value = this[i]
    if (predicate(value)) {
      removedValues.push(value)
      this.splice(i, 1)
      i--
    }
  }

  return removedValues
}
