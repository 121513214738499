import { Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { CoreUserService } from './core-user.service'
import { LoginGuard } from './login.guard'

@Injectable()
export class AdminGuard
  extends LoginGuard
  implements CanActivate, CanActivateChild
{
  constructor(coreUserService: CoreUserService, router: Router) {
    super(coreUserService, router)
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return super.canActivate(route, state).pipe(
      mergeMap((canActivate) => {
        if (canActivate === false) {
          return of(false)
        } else {
          return of(this.coreUserService.isAdmin())
        }
      })
    )
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state)
  }
}
