// tslint:disable:no-access-missing-member
import { mergeMap } from 'rxjs/operators'
import { Component, OnInit, ViewChild } from '@angular/core'

import { IMyDateModel, INgxMyDpOptions } from 'ngx-mydatepicker'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { FleetService } from '../../fleet/client/fleet.service'
import { VendorService } from '../../vendor/client/vendor.service'
import { LocationService } from '../../location/client/location.service'
import { OrderStateService } from '../../order-state/client/order-state.service'
import { DashboardService } from '../../dashboard/client/dashboard.service'
import { DataTableComponent } from '../../core/client/datatable/datatable.component'
import { IDataTablePage } from '../../core/client/datatable/dataTableConfig.class'
import { OrderService } from './order.service'

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss'],
  providers: []
})
export class OrderSearchComponent
  extends CoreResponseHandler
  implements OnInit
{
  fleets: any[]
  locations: any[]
  vendors: any[]
  orderStates: any[]
  datepickerOpts: INgxMyDpOptions = {
    dateFormat: 'mm/dd/yyyy'
  }

  placedDateFrom = null
  placedDateTo = null
  deliveryReceiptNumbersString: string
  salesPONumbersString: string
  servicePONumbersString: string
  workOrderNumbersString: string
  isValid = true
  matchAllFields = true
  searchParams = {
    orderNumber: null,
    location: null,
    fleet: null,
    vendor: null,
    state: null,
    placedDateFrom: null,
    placedDateTo: null,
    placedBy: null,
    deliveryReceiptNumbers: [],
    salesPONumbers: [],
    servicePONumbers: [],
    workOrderNumbers: [],
    notes: null,
    rush: null,
    orderType: null
  }

  errors = {
    placedDate: null
  }

  @ViewChild('searchResultsTable', { static: true })
  searchResultsTable: DataTableComponent

  resultDocCount = 15
  searchResultColumns = [
    {
      width: 17,
      display: 'Fleet',
      field: 'location.fleet.logo',
      altText: 'location.fleet.name',
      type: 'image',
      hideOnMobile: true
    },
    {
      width: 18,
      display: 'Location',
      field: 'location.name',
      hideOnMobile: true
    },
    {
      width: 15,
      display: 'Ord. #',
      field: 'orderNumber',
      hideOnMobile: false
    },
    {
      width: 15,
      display: 'Placed On',
      field: 'placedDate',
      formatFunction: DashboardService.dateDisplay('In Cart'),
      hideOnMobile: true
    },
    {
      width: 20,
      display: 'Placed By',
      field: 'placedBy.displayName',
      hideOnMobile: true
    },
    {
      width: 15,
      display: 'Status',
      field: 'state.description',
      hideOnMobile: false
    }
  ]

  searchResultsTableConfig = DataTableComponent.createDataTableConfig({
    title: 'Search Results',
    columns: DataTableComponent.createDataTableColumnsConfig(
      this.searchResultColumns
    ),
    headerBackgroundColor: '#3FA103',
    defaultSortFields: ['-orderNumber'],
    includeActionButtons: true,
    dataArray: [],
    pageSize: this.resultDocCount,
    dataIsFromServer: true,
    emptyMessage: 'No matching orders found',
    emptyIcon: 'sentiment_very_dissatisfied',
    emptyIconColor: 'cornflowerblue',
    rowClassesFunction: this.getRowClass,
    getPageFn: (
      pageNumber: number,
      table: DataTableComponent,
      sortFields?: string[]
    ): Observable<IDataTablePage> => {
      return this.orderService
        .getSearchResultsPage(
          this.searchParams,
          this.matchAllFields,
          this.resultDocCount,
          pageNumber,
          sortFields
        )
        .pipe(
          mergeMap((resultsPage) => {
            this.addResults(resultsPage.pageItems)
            return of(resultsPage)
          })
        )
    }
  })

  searchResults: {
    order: { _id: string; orderNumber: number; location: any }
    matches: string[]
  }[] = []

  fleetLogoCache: { [logoId: string]: string } = {}

  constructor(
    private fleetService: FleetService,
    private vendorService: VendorService,
    private locationService: LocationService,
    private orderStateService: OrderStateService,
    private orderService: OrderService,
    private router: Router
  ) {
    super()
    if (this.orderService.savedSearchParams) {
      this.searchParams = this.orderService.savedSearchParams
      this.matchAllFields =
        this.orderService.savedMatchAllFields !== null
          ? this.orderService.savedMatchAllFields
          : true
      this.searchParams.deliveryReceiptNumbers = this.searchParams
        .deliveryReceiptNumbers
        ? this.searchParams.deliveryReceiptNumbers.toString().split(',')
        : null
      this.searchParams.salesPONumbers = this.searchParams.salesPONumbers
        ? this.searchParams.salesPONumbers.toString().split(',')
        : null
      this.searchParams.servicePONumbers = this.searchParams.servicePONumbers
        ? this.searchParams.servicePONumbers.toString().split(',')
        : null
      this.searchParams.workOrderNumbers = this.searchParams.workOrderNumbers
        ? this.searchParams.workOrderNumbers.toString().split(',')
        : null
      this.parseDateAndArrayParamsForView()
    }
    this.getMyFleets()
    this.getMyVendors()
    this.getMyLocations()
    this.getOrderStates()
  }

  handleResponse(error: CoreResponse): boolean {
    return false
  }

  ngOnInit() {}

  onDateChanged(event: IMyDateModel): void {
    // date changed event fires BEFORE update ngModel, so we use a timeout to run code after ngModel is updated
    setTimeout(() => {
      if (this.placedDateFrom) {
        this.placedDateFrom.jsdate.setHours(0, 0, 0, 0)
      }
      if (this.placedDateTo) {
        this.placedDateTo.jsdate.setHours(23, 59, 59, 999)
      }

      this.searchParams.placedDateFrom = this.placedDateFrom
        ? this.placedDateFrom.jsdate
        : null
      this.searchParams.placedDateTo = this.placedDateTo
        ? this.placedDateTo.jsdate
        : null
      if (
        (this.placedDateTo && this.placedDateTo.valid === false) ||
        (this.placedDateFrom && this.placedDateFrom.valid === false)
      ) {
        this.errors.placedDate = 'Invalid Date'
      } else if (
        this.searchParams.placedDateFrom &&
        this.searchParams.placedDateTo &&
        this.searchParams.placedDateFrom > this.searchParams.placedDateTo
      ) {
        this.errors.placedDate = 'Invalid Date Range'
      } else {
        this.errors.placedDate = null
      }

      this.validateForm()
    }, 0)
  }

  findMatchingOrders(event: any): void {
    event.stopPropagation()
    if (this.placedDateFrom && !this.placedDateTo) {
      const from = new Date(this.placedDateFrom.jsdate.getTime())
      from.setHours(23, 59, 59, 999)
      this.placedDateTo = { jsdate: from }
      this.searchParams.placedDateTo = this.placedDateTo.jsdate
    }
    if (!this.placedDateFrom && this.placedDateTo) {
      const to = new Date(this.placedDateTo.jsdate.getTime())
      to.setHours(0, 0, 0, 0)
      this.placedDateFrom = { jsdate: to }
      this.searchParams.placedDateFrom = this.placedDateFrom.jsdate
    }
    this.isValid = true
    this.searchResults = []
    this.searchParams.deliveryReceiptNumbers = this.convertStringToArray(
      this.deliveryReceiptNumbersString
    )
    this.cleanDeliveryReceiptInput()
    this.searchParams.salesPONumbers = this.convertStringToArray(
      this.salesPONumbersString
    )
    this.cleanSalesPOInput()
    this.searchParams.servicePONumbers = this.convertStringToArray(
      this.servicePONumbersString
    )
    this.cleanServicePOInput()
    this.searchParams.workOrderNumbers = this.convertStringToArray(
      this.workOrderNumbersString
    )
    this.cleanWorkOrderInput()

    this.validateForm()
    if (this.isValid) {
      this.searchResultsTable.setCurrentPage(1)
      this.searchResultsTable.refresh(null, true, true)
    }
  }

  goToOrder(orderId: string, orderType: string): void {
    this.router.navigate([`order/${orderId}`], {
      queryParams: { orderType }
    })
  }

  getMyLocations() {
    this.locationService.getLocations().subscribe((locations: any[]) => {
      this.locations = locations
    })
  }

  private getMyFleets() {
    this.fleetService.getFleets().subscribe((fleets: any[]) => {
      this.fleets = fleets
    })
  }

  private getMyVendors() {
    this.vendorService.getVendors().subscribe((vendors: any[]) => {
      this.vendors = vendors
    })
  }

  private getOrderStates() {
    this.orderStateService.getOrderStates().subscribe((orderStates: any[]) => {
      this.orderStates = orderStates
    })
  }

  private convertStringToArray(input: string): string[] {
    if (input) {
      let returnArr: string[] = input
        .trim()
        .split(',')
        .map((dr: string) => dr.trim())
      returnArr = returnArr.filter((str: string) => str !== '')
      return returnArr
    }

    return null
  }

  private cleanDeliveryReceiptInput(): void {
    this.deliveryReceiptNumbersString = this.searchParams.deliveryReceiptNumbers
      ? this.searchParams.deliveryReceiptNumbers.join(', ')
      : null
  }

  private cleanSalesPOInput(): void {
    this.salesPONumbersString = this.searchParams.salesPONumbers
      ? this.searchParams.salesPONumbers.join(', ')
      : null
  }

  private cleanServicePOInput(): void {
    this.servicePONumbersString = this.searchParams.servicePONumbers
      ? this.searchParams.servicePONumbers.join(', ')
      : null
  }

  private cleanWorkOrderInput(): void {
    this.workOrderNumbersString = this.searchParams.workOrderNumbers
      ? this.searchParams.workOrderNumbers.join(', ')
      : null
  }

  private validateForm(): void {
    if (this.errors) {
      for (const key in this.errors) {
        if (this.errors.hasOwnProperty(key)) {
          if (this.errors[key]) {
            this.isValid = false
            return
          }
        }
      }
    }

    this.isValid = true
  }

  private addResults(results: any[]): void {
    results.forEach((result: any) => {
      this.addResult(result)
      this.getFleetLogo(result)
    })
  }

  private addResult(result: any): void {
    result.actionBtn = DataTableComponent.createActionButton(
      'View',
      () => this.goToOrder(result._id, result.orderType),
      '#3FA103',
      'visibility'
    )
  }

  private getFleetLogo(result: any) {
    if (
      result.location.hasOwnProperty('fleet') &&
      !result.location.fleet.hasOwnProperty('logo')
    ) {
      if (typeof result.location.fleet === 'string') {
        const fleetId = result.location.fleet
        if (this.fleetLogoCache[fleetId] === undefined) {
          this.fleetService.getFleetLogo(fleetId).subscribe((fleetLogoUrl) => {
            this.fleetLogoCache[fleetId] = fleetLogoUrl.toString()
            result.location.fleet = {
              _id: fleetId,
              logo: fleetLogoUrl
            }
          })
        } else {
          result.location.fleet = {
            _id: fleetId,
            logo: this.fleetLogoCache[fleetId]
          }
        }
      }
    }
  }

  private parseDateAndArrayParamsForView(): void {
    this.deliveryReceiptNumbersString = this.searchParams.deliveryReceiptNumbers
      ? this.searchParams.deliveryReceiptNumbers.join(',')
      : null
    this.salesPONumbersString = this.searchParams.salesPONumbers
      ? this.searchParams.salesPONumbers.join(',')
      : null
    this.servicePONumbersString = this.searchParams.servicePONumbers
      ? this.searchParams.servicePONumbers.join(',')
      : null
    this.workOrderNumbersString = this.searchParams.workOrderNumbers
      ? this.searchParams.workOrderNumbers.join(',')
      : null
    this.placedDateFrom = this.searchParams.placedDateFrom
      ? { jsdate: this.searchParams.placedDateFrom }
      : null
    this.placedDateTo = this.searchParams.placedDateTo
      ? { jsdate: this.searchParams.placedDateTo }
      : null
  }

  private getRowClass(order: any): string[] {
    const cssClassArray = []

    if (order.rushRequested) {
      cssClassArray.push('rush-order-row')
    }
    if (order.isNoOrderNoPickup || order.isNoOrderWithPickup) {
      cssClassArray.push('no-order-row')
    }
    if (order.orderType === 'FleetCheck') {
      cssClassArray.push('fleet-check-row')
    }
    if (order.orderType === 'ServiceCall') {
      cssClassArray.push('service-call-row')
    }

    return cssClassArray
  }
}
