// "npm" modules
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms' // For forms or ngmodel wont work

// "app" modules
import { MyDatePickerModule } from 'mydatepicker'
import { FlexLayoutModule } from '@angular/flex-layout'
import { CoreModule } from '../../core/client/core-module'
import { LocationModule } from '../../location/client/location.module'
import { MaterialComponentsModule } from '../../material-components.module'
import { DashboardDirective } from './dashboard.directive'

// Services
import { DashboardService } from './dashboard.service'

// Routers
import { DashboardRoutingModule } from './dashboard-routing.module'

import { DashboardComponent } from './dashboard.component'
import { FleetCheckDialogComponent } from './fleet-check-dialog.component'
import { ServiceCallDialogComponent } from './service-call-dialog.component'

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardDirective,
    FleetCheckDialogComponent,
    ServiceCallDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DashboardRoutingModule,
    CoreModule,
    LocationModule,
    MaterialComponentsModule,
    MyDatePickerModule,
    FlexLayoutModule
  ],
  providers: [DashboardService],
  entryComponents: [FleetCheckDialogComponent, ServiceCallDialogComponent],
  exports: []
})
export class DashboardModule {}
