import { Component, OnInit } from '@angular/core'
import { CoreUserService } from '../../../core-user/client/core-user.service'

@Component({
  selector: 'app-side-nav-footer',
  templateUrl: 'side-nav-footer.component.html',
  styleUrls: ['side-nav-footer.component.scss'],
  providers: []
})
export class SideNavFooterComponent implements OnInit {
  state = 'closed'

  constructor(private coreUserService: CoreUserService) {}

  ngOnInit() {}

  setState(state: string) {
    this.state = state
  }

  // Footer
  setFooter() {}

  // User
  logout() {
    this.coreUserService.logout().subscribe()
  }
}
