<app-container-widget title="Naughty List">
  <div class="naughty-list-chart">
    <canvas
      baseChart
      [data]="pieChartData"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [colors]="pieChartColors"
    >
    </canvas>
  </div>
  <div class="naughty-list-locations">
    <div
      *ngFor="let dateGroup of naughtyNiceListByDate"
      class="naughty-list-day-group"
    >
      <h4>
        <span *ngIf="dateGroup.dueDate === 'Today'">Due </span
        >{{ dateGroup.dueDate }}
      </h4>
      <div class="naughty-list-entry" *ngFor="let item of dateGroup.items">
        <p>
          <span *ngIf="item.location.name">{{ item.location.name }}</span
          ><span *ngIf="!item.location.name"><i>No Name</i></span>
        </p>
        <p *ngIf="item.location.fleet && item.location.fleet.name">
          <small>{{ item.location.fleet.name }}</small>
        </p>
      </div>
    </div>
  </div>
  <div class="broadcast-reminder-button-container">
    <button
      class="blue-btn"
      mat-raised-button
      (click)="broadcastNaughtyListReminder()"
    >
      BROADCAST REMINDER
    </button>
  </div>
</app-container-widget>
