import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'

import { Observable, Subject } from 'rxjs'

import { HttpClient, HttpParams } from '@angular/common/http'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { StateActionType } from '../order-state-action-type.enum'
import { environment } from '../../../environments/environment'

interface IHasID {
  _id: string
  [prop: string]: any
}

@Injectable()
export class OrderService {
  savedSearchParams: any = null
  savedMatchAllFields: boolean = null
  genericUrl = `${environment.apiUrl}/order-base`
  regularOrderUrl = `${environment.apiUrl}/order-regular`
  fleetCheckUrl = `${environment.apiUrl}/fleet-check`
  serviceCallUrl = `${environment.apiUrl}/service-call`

  itemAddedToCartNotification = new Subject<any>()
  itemRemovedFromCartNotification = new Subject<any>()

  constructor(
    protected http: HttpClient,
    private coreUserService: CoreUserService
  ) {}

  notifyItemAddedToCart(item: any) {
    this.itemAddedToCartNotification.next(item)
  }

  notifyItemRemovedFromCart(item: any) {
    this.itemRemovedFromCartNotification.next(item)
  }

  /**
   * Get order by user accountType
   */
  getUserOrders(actionTypes?: StateActionType[]): Observable<any> {
    let url = `${this.genericUrl}/getUserOrders?cacheId=${this.getCacheId()}`
    if (actionTypes && actionTypes.length) {
      url += `&actionTypes=${actionTypes.join(',')}`
    }

    return this.http.get(url)
  }

  /**
   * Get a page of orders and the total number of orders
   * @param {number} pageDocCount
   * @param {number} pageNumber
   * @returns {Observable<{items: any[]; totalCount: number}>}
   */
  getUserOrdersPage(
    actionTypes?: StateActionType[],
    pageDocCount?: number,
    pageNumber?: number,
    sortFields?: string[],
    orderType?: string
  ): Observable<{ pageItems: any[]; totalCount: number }> {
    let url = `${this.getBaseUrl(
      orderType
    )}/getUserOrdersPage?cacheId=${this.getCacheId()}`
    if (actionTypes && actionTypes.length) {
      url += `&actionTypes=${actionTypes.join(',')}`
    }
    url += `&pageDocCount=${pageDocCount || 0}`
    url += `&pageNumber=${pageNumber || 0}`
    if (sortFields && sortFields.length) {
      url += `&sortFields=${sortFields.join(',')}`
    }
    return this.http.get<{ pageItems: any[]; totalCount: number }>(url)
  }

  getSearchResultsPage(
    searchParams: any,
    matchAllFields: boolean,
    pageDocCount?: number,
    pageNumber?: number,
    sortFields?: string[],
    orderType?: string
  ): Observable<{ pageItems: any[]; totalCount: number }> {
    this.savedSearchParams = searchParams
    this.savedMatchAllFields = matchAllFields
    const url = `${this.getBaseUrl(orderType)}/getSearchResultsPage`
    let params = new HttpParams()
    params = params.append('cacheId', this.getCacheId())

    const joinKeys = [
      'deliveryReceiptNumbers',
      'salesPONumbers',
      'servicePONumbers',
      'workOrderNumbers'
    ]
    for (const key in searchParams) {
      if (searchParams.hasOwnProperty(key) && searchParams[key]) {
        let paramValue: string
        if (joinKeys.includes(key)) {
          paramValue = searchParams[key].length
            ? searchParams[key].join(',')
            : null
        } else {
          paramValue = searchParams[key]
        }

        if (paramValue) {
          params = params.append(key, paramValue)
        }
      }
    }

    if (matchAllFields) {
      params = params.append('matchAllFields', 'true')
    }

    params = params.append('pageDocCount', (pageDocCount || 0).toString())
    params = params.append('pageNumber', (pageNumber || 0).toString())

    if (sortFields && sortFields.length) {
      params = params.append('sortFields', sortFields.join(','))
    }

    return this.http.get<{ pageItems: any[]; totalCount: number }>(url, {
      params
    })
  }

  /**
   * Get order by id
   * @param orderId
   */
  getOrderById(orderId, orderType): Observable<any> {
    if (!orderType) {
      throw new Error('Order type required')
    }
    return this.http.get<any>(
      `${this.getBaseUrl(orderType)}/${orderId}?cacheId=${this.getCacheId()}`
    )
  }

  isMyOrder(orderId, orderType): Observable<boolean> {
    if (!orderType) {
      throw new Error('Order type required')
    }
    return this.http
      .get<{ result: boolean }>(
        `${this.getBaseUrl(orderType)}/isMyOrder/${orderId}`
      )
      .pipe(
        map((res: { result: boolean }) => {
          return res.result
        })
      )
  }

  /**
   * update order, delete order item
   * @param orderId
   * @param orderJson
   */
  updateOrder(order: IHasID, orderJson: any): Observable<any> {
    if (!order.orderType) {
      throw new Error('Order type required')
    }
    return this.http.put<any>(
      `${this.getBaseUrl(order.orderType)}/${order._id}`,
      { orderJson }
    )
  }

  /**
   * delete order
   * @param orderId
   */
  deleteOrder(order: IHasID): Observable<any> {
    if (!order.orderType) {
      throw new Error('Order type required')
    }
    return this.http.delete<any>(
      `${this.getBaseUrl(order.orderType)}/${order._id}`
    )
  }

  /**
   * create an no order
   * @param orderJson
   */
  createNoOrder(orderJson): Observable<any> {
    return this.http.post<any>(
      `${this.regularOrderUrl}/createNoOrder`,
      orderJson
    )
  }

  createFleetCheck(orderJson): Observable<any> {
    return this.http.post<any>(`${this.fleetCheckUrl}`, { orderJson })
  }

  createServiceCall(orderJson): Observable<any> {
    return this.http.post<any>(`${this.serviceCallUrl}`, { orderJson })
  }

  /**
   * check for any orders with state of LocationProcessing_0_0
   * this means the location/yard has not submitted the order yet
   */
  getUserLocationProcessingOrder(orderType: string): Observable<any> {
    if (!orderType) {
      throw new Error('Order type required')
    }

    this.coreUserService.getLoggedInUser().subscribe((loggedInUser) => {
      if (loggedInUser.accountType !== 'Location') {
        return null
      }
    })

    return this.http.get<any>(
      `${this.getBaseUrl(
        orderType
      )}/getLocationUserProcessingOrder?cacheId=${this.getCacheId()}`
    )
  }

  hasBeenPlaced(order: IHasID): boolean {
    if (order.stateHistory && order.stateHistory.length) {
      for (let i = order.stateHistory.length - 1; i >= 0; i--) {
        if (order.stateHistory[i].actionType === 'ORDER_PLACED') {
          return true
        }
      }
    }
    return false
  }

  private getCacheId(): string {
    const cacheId = (
      Math.floor(Math.random() * 9999999999) + 1000000000
    ).toString()
    return cacheId
  }

  private getBaseUrl(orderType: string): string {
    switch (orderType) {
      case 'RegularOrder':
        return this.regularOrderUrl
      case 'FleetCheck':
        return this.fleetCheckUrl
      case 'ServiceCall':
        return this.serviceCallUrl
      default:
        return this.genericUrl
    }
  }
}
