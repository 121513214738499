'use strict'

// node_modules imports
import { Component, OnInit, ViewChild } from '@angular/core'
import { CoreUserComponent } from '../../core-user/client/core-user.component'
import { CoreUserService } from '../../core-user/client/core-user.service'

@Component({
  selector: 'app-user-profile',
  templateUrl: 'core-user-profile.template.html'
})
export class CoreUserProfileComponent implements OnInit {
  user: any

  // Create list view instances
  @ViewChild('userProfile', { static: true }) CoreUserProfile: CoreUserComponent

  constructor(private userService: CoreUserService) {}

  ngOnInit() {
    this.userService.getLoggedInUser().subscribe((loggedInUser) => {
      this.CoreUserProfile.setUser(loggedInUser)
      this.user = loggedInUser
      this.CoreUserProfile.formTitle = 'My Profile'
    })
  }
}
