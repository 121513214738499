import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { CoreUserService } from '../../../core-user/client/core-user.service'
import { SideNavHeaderComponent } from '../side-nav-header/side-nav-header.component'
import { SideNavFooterComponent } from '../side-nav-footer/side-nav-footer.component'

export class NavRoute {
  constructor(public name: string, public icon: string, public route: string) {
    this.name = name
    this.icon = icon
    this.route = route
  }
}

export interface INavagation {
  navRoutes: Array<NavRoute>

  addRoutes(routes: Array<NavRoute>): void
  deleteRoutes(routes: Array<NavRoute>): void
  clearRoutes(): void
}

@Component({
  selector: 'app-side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['side-nav.component.scss'],
  providers: []
})
export class SideNavComponent implements OnInit {
  static navRoutes: Array<NavRoute> = []

  static routeExists(route: NavRoute): boolean {
    for (let i = 0; i < SideNavComponent.navRoutes.length; i++) {
      if (
        SideNavComponent.navRoutes[i].name === route.name &&
        SideNavComponent.navRoutes[i].icon === route.icon &&
        SideNavComponent.navRoutes[i].route === route.route
      ) {
        return true
      }
    }
    return false
  }

  static addRoutes(routes: Array<NavRoute>): void {
    for (let i = 0; i < routes.length; i++) {
      if (SideNavComponent.routeExists(routes[i])) {
        continue
      }
      SideNavComponent.navRoutes.push(routes[i])
    }
    // console.log('Added Routes!');
    // console.log('SideNavComponent.navRoutes!');
    // console.log(SideNavComponent.navRoutes);
    // console.log('this.navRoutes!');
    // console.log(this.navRoutes);
  }

  static deleteRoutes(routes: Array<NavRoute>): void {
    for (let i = routes.length - 1; i >= 0; i--) {
      if (SideNavComponent.routeExists(routes[i])) {
        SideNavComponent.navRoutes.splice(i, 1)
      }
    }
  }

  static clearRoutes(): void {
    SideNavComponent.navRoutes = []
    const navRoutesLength = SideNavComponent.navRoutes.length
    for (let i = 0; i < navRoutesLength; i++) {
      SideNavComponent.navRoutes.pop()
    }
  }

  @ViewChild('header', { static: true })
  SideNavHeaderComponent: SideNavHeaderComponent

  @ViewChild('footer', { static: true })
  SideNavFooterComponent: SideNavFooterComponent

  navRoutes: Array<NavRoute> = []
  state = 'closed'

  constructor(
    private router: Router,
    private coreUserService: CoreUserService
  ) {}

  ngOnInit() {
    this.navRoutes = SideNavComponent.navRoutes
  }

  setState(state: string) {
    this.state = state
    this.SideNavHeaderComponent.setState(state)
    this.SideNavFooterComponent.setState(state)
  }

  // Routes
  activeRoute(routeName: string): boolean {
    return this.router.url.includes(routeName)
  }
}
