import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { environment } from '../../../environments/environment'

@Injectable()
export class DocumentManagementService {
  documentsURL = `${environment.apiUrl}/documents`

  constructor(private http: HttpClient) {}

  // Get locations from the API
  getDocuments(searchParams: object) {
    let params = new HttpParams()

    Object.keys(searchParams).forEach((key) => {
      params = params.append(key, searchParams[key])
    })

    return this.http.get(this.documentsURL, { params })
  }

  getOrderDocuments(orderId: string) {
    return this.http.get(`${this.documentsURL}/for/${orderId}`)
  }

  uploadDocument(formData) {
    return this.http.post(this.documentsURL, formData)
  }

  update(updatedDocument: any) {
    return this.http.put(
      `${this.documentsURL}/${updatedDocument._id}`,
      updatedDocument
    )
  }

  remove(document: any) {
    return this.http.delete(`${this.documentsURL}/${document._id}`)
  }
}
