import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {
  public transform(items, key: string, value: any) {
    return items.filter((item) => {
      if (item.hasOwnProperty(key)) {
        if (value === false && item[key] === false) {
          return true
        }
        if (value) {
          return item[key] === value
        } else {
          return false
        }
      } else {
        return false
      }
    })
  }
}
