<div fxLayout="row">
  <div class="info">
    <div class="info-card">
      <mat-toolbar
        >{{ manufacturer._id ? 'Edit' : 'New' }} Manufacturer</mat-toolbar
      >
      <mat-card-content>
        <form
          class="form"
          novalidate
          (ngSubmit)="save()"
          [formGroup]="manufacturerForm"
        >
          <div class="system-management-logo" fxLayout="row">
            <div class="image-box">
              <div class="logo-placeholder" *ngIf="!manufacturer.logo">
                Logo
              </div>
              <img
                height="100"
                [src]="manufacturer.logo"
                *ngIf="manufacturer.logo"
              />
            </div>
            <app-file-uploader
              autoUpload="true"
              identity="img:logo"
              (fileUploaded)="handleUploadedImage($event)"
            >
            </app-file-uploader>
          </div>
          <div fxLayout="row">
            <mat-form-field>
              <input
                matInput
                type="text"
                placeholder="Name"
                [(ngModel)]="manufacturer.name"
                [ngModelOptions]="{ standalone: true }"
                required
              />
              <mat-hint
                *ngIf="responses?.name"
                class="error-message"
                align="start"
                >{{ responses?.name }}</mat-hint
              >
            </mat-form-field>
          </div>

          <button mat-raised-button>
            {{ manufacturer._id ? 'Update' : 'Save' }}
          </button>
          <mat-hint *ngIf="responses?.saveSuccess" class="success-message">{{
            responses?.saveSuccess
          }}</mat-hint>
        </form>
      </mat-card-content>
    </div>
  </div>
</div>
