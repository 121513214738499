<div [hidden]="viewUserList">
  <div class="info-card">
    <mat-toolbar class="location-btn-toolbar">
      {{ location._id ? 'Edit' : 'New' }} Location
      <button
        *ngIf="location._id"
        mat-raised-button
        class="location-btn location-view-users-btn"
        (click)="viewUserListComponent()"
      >
        View Users
      </button>
      <button
        mat-raised-button
        class="location-btn"
        (click)="goToProductList()"
      >
        Set Product Catalog
      </button>
    </mat-toolbar>
    <div class="location-container">
      <form
        class="form"
        novalidate
        (ngSubmit)="submitLocationForm()"
        [formGroup]="locationForm"
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <!--Location Info-->

        <!-- Location Name -->
        <mat-form-field>
          <input
            matInput
            formControlName="name"
            type="text"
            placeholder="Name"
            required
          />
          <mat-hint
            *ngIf="
              !responses?.name &&
              nameControl?.errors?.required &&
              (formSubmitAttempted ||
                nameControl?.touched ||
                nameControl?.dirty)
            "
            class="error-message"
            align="start"
            >Name is required</mat-hint
          >
          <mat-hint
            *ngIf="responses?.name"
            class="error-message"
            align="start"
            >{{ responses?.name }}</mat-hint
          >
        </mat-form-field>

        <!-- Location Abbreviation -->
        <mat-form-field>
          <input
            matInput
            formControlName="abbreviation"
            type="text"
            placeholder="Abbreviation"
          />
          <mat-hint
            *ngIf="responses?.abbreviation"
            class="error-message"
            align="start"
            >{{ responses?.abbreviation }}</mat-hint
          >
        </mat-form-field>

        <section formGroupName="address">
          <div fxLayout="row">
            <!-- Location Address Street 1-->
            <mat-form-field>
              <input
                matInput
                formControlName="street1"
                type="text"
                placeholder="Address"
              />
              <mat-hint
                *ngIf="responses?.address?.responses?.street1"
                class="error-message"
                align="start"
                >{{ responses?.address?.responses?.street1 }}</mat-hint
              >
            </mat-form-field>
          </div>

          <div fxLayout="row">
            <!-- Location City -->
            <mat-form-field>
              <input
                matInput
                formControlName="city"
                type="text"
                placeholder="City"
              />
              <mat-hint
                *ngIf="responses?.address?.responses?.city"
                class="error-message"
                align="start"
                >{{ responses?.address?.responses?.city }}</mat-hint
              >
            </mat-form-field>

            <!-- Location State -->
            <mat-form-field>
              <input
                matInput
                formControlName="state"
                type="text"
                placeholder="State"
              />
              <mat-hint
                *ngIf="responses?.address?.responses?.city"
                class="error-message"
                align="start"
                >{{ responses?.address?.responses?.state }}</mat-hint
              >
            </mat-form-field>

            <!-- Location Zip -->
            <mat-form-field>
              <input
                matInput
                formControlName="zip"
                type="text"
                placeholder="Zip"
              />
              <mat-hint
                *ngIf="responses?.address?.responses?.zip"
                class="error-message"
                align="start"
                >{{ responses?.address?.responses?.zip }}</mat-hint
              >
            </mat-form-field>
          </div>

          <div fxLayout="row">
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Timezone" formControlName="timezone">
                <mat-option
                  *ngFor="let timezone of timezones"
                  [value]="timezone.value"
                >
                  {{ timezone.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>

        <!-- Location Phone -->
        <mat-form-field>
          <input
            matInput
            formControlName="phone"
            type="text"
            placeholder="Phone xxx-xxx-xxxx"
          />
          <mat-hint
            *ngIf="
              !responses?.phone &&
              phoneControl?.errors?.pattern &&
              (formSubmitAttempted ||
                phoneControl?.touched ||
                phoneControl?.dirty)
            "
            class="error-message"
            align="start"
            >Phone should be xxx-xxx-xxxx</mat-hint
          >
          <mat-hint
            *ngIf="responses?.phone"
            class="error-message"
            align="start"
            >{{ responses?.phone }}</mat-hint
          >
        </mat-form-field>

        <!-- Location Fax -->
        <mat-form-field>
          <input
            matInput
            formControlName="fax"
            type="text"
            placeholder="Fax xxx-xxx-xxxx"
          />
          <mat-hint
            *ngIf="
              !responses?.fax &&
              faxControl?.errors?.pattern &&
              (formSubmitAttempted || faxControl?.touched || faxControl?.dirty)
            "
            class="error-message"
            align="start"
            >Fax should be xxx-xxx-xxxx</mat-hint
          >
          <mat-hint
            *ngIf="responses?.fax"
            class="error-message"
            align="start"
            >{{ responses?.fax }}</mat-hint
          >
        </mat-form-field>

        <!-- Location Email -->
        <mat-form-field>
          <input
            matInput
            formControlName="email"
            type="text"
            placeholder="Email"
          />
          <mat-hint
            *ngIf="
              !responses?.email &&
              emailControl?.errors?.email &&
              (formSubmitAttempted ||
                emailControl?.touched ||
                emailControl?.dirty)
            "
            class="error-message"
            align="start"
            >Please enter valid email address</mat-hint
          >
          <mat-hint
            *ngIf="responses?.email"
            class="error-message"
            align="start"
            >{{ responses?.email }}</mat-hint
          >
        </mat-form-field>

        <div>
          <h3>Location Options</h3>
        </div>

        <!-- Order Flow Selection -->
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field>
            <mat-select
              placeholder="Order Flow"
              formControlName="orderFlow"
              (selectionChange)="orderFlowChanged()"
              required
            >
              <mat-option
                *ngIf="fleetDefaultOrderFlow"
                value="fleetDefault"
                title="Fleet Default (Currently {{
                  fleetDefaultOrderFlow.name
                }} - Version {{ fleetDefaultOrderFlow.versionNumber }})"
                >Fleet Default (Currently {{ fleetDefaultOrderFlow.name }} -
                Version {{ fleetDefaultOrderFlow.versionNumber }})</mat-option
              >
              <mat-option *ngFor="let flow of orderFlows" [value]="flow.name">
                {{ flow.name }}
              </mat-option>
            </mat-select>
            <mat-hint
              class="error-message"
              *ngIf="
                !responses['orderFlow'] &&
                (orderFlowCtrl?.errors?.required ||
                  orderFlowVersionCtrl?.errors?.required) &&
                (formSubmitAttempted ||
                  orderFlowVersionCtrl?.touched ||
                  orderFlowVersionCtrl?.dirty)
              "
              align="start"
              >Choose an order flow and version</mat-hint
            >
            <mat-hint
              class="error-message"
              *ngIf="responses['orderFlow']"
              align="start"
              >{{ responses['orderFlow'] }}</mat-hint
            >
          </mat-form-field>
          <mat-form-field
            *ngIf="selectedOrderFlow && selectedOrderFlow !== 'fleetDefault'"
          >
            <mat-select
              placeholder="Version"
              formControlName="orderFlowVersion"
              required
            >
              <mat-option
                *ngFor="let version of selectedOrderFlow.versions"
                [value]="version._id"
              >
                Version {{ version.versionNumber }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Vendor Selection -->
        <div formArrayName="vendors">
          <mat-hint
            class="flex-me select-hint error-message"
            *ngIf="responses?.vendors"
            align="start"
            >{{ responses?.vendors }}</mat-hint
          >
          <div
            *ngFor="
              let vendorControl of vendorControlList.controls;
              index as idx;
              first as isPrimary
            "
            fxLayout="column"
          >
            <div formGroupName="{{ idx }}" fxLayout="row">
              <mat-form-field fxFlex="30">
                <mat-select
                  formControlName="vendor"
                  placeholder="Vendor"
                  required
                >
                  <mat-option
                    *ngFor="let vendor of vendors | orderByPipe: 'name'"
                    [value]="vendor._id"
                    [disabled]="!vendor.available"
                  >
                    {{ vendor.name }}
                  </mat-option>
                </mat-select>
                <mat-hint
                  class="error-message"
                  *ngIf="
                    !responses['vendor' + idx] &&
                    vendorControl?.controls?.vendor?.errors?.required &&
                    (formSubmitAttempted ||
                      vendorControl?.controls?.vendor?.touched ||
                      vendorControl?.controls?.vendor?.dirty)
                  "
                  align="start"
                  >Choose a vendor</mat-hint
                >
                <mat-hint
                  class="flex-me select-hint error-message"
                  *ngIf="responses['vendor' + idx]"
                  align="start"
                  >{{ responses['vendor' + idx] }}</mat-hint
                >
              </mat-form-field>

              <mat-form-field *ngIf="idx === 0">
                <mat-label>Order Day(s)</mat-label>
                <mat-select formControlName="orderDays" multiple>
                  <mat-option *ngFor="let day of weekDays" [value]="day">{{
                    day
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="idx === 0">
                <mat-label>Delivery Day(s)</mat-label>
                <mat-select formControlName="deliveryDays" multiple>
                  <mat-option *ngFor="let day of weekDays" [value]="day">{{
                    day
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <div fxFlex="10" *ngIf="!isPrimary">
                <button
                  mat-mini-fab
                  type="button"
                  title="Delete This Vendor"
                  class="vendor-delete-button"
                  (click)="deleteVendor(idx)"
                >
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          class="vendor-add-button-container"
        >
          <button
            mat-mini-fab
            type="button"
            title="Add vendor"
            class="vendor-add-button"
            (click)="addVendor()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <!--Vendor User Access-->
        <div
          fxLayout="row"
          *ngIf="
            formPopulated && formVendorObjects && formVendorObjects[0]?.vendor
          "
        >
          <mat-card class="vendor-user-list-container">
            <mat-card-header>
              <mat-card-title>Vendor User Access</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="clear-all-button-container" *ngIf="location._id">
                <button
                  type="button"
                  mat-raised-button
                  class="mat-primary"
                  (click)="clearUserCheckboxValuesForAllVendors()"
                >
                  Clear All
                </button>
              </div>
              <mat-list formArrayName="vendorUsers" class="vendor-users-list">
                <div
                  *ngFor="
                    let vendorControl of vendorUserControlList.controls;
                    index as idx;
                    first as isFirst
                  "
                >
                  <div *ngIf="formVendorObjects[idx].vendor">
                    <h3 mat-subheader [ngClass]="{ 'no-border': isFirst }">
                      {{ formVendorObjects[idx].vendor.name }}
                    </h3>
                    <div formArrayName="{{ idx }}">
                      <div
                        *ngFor="
                          let vendorUserControl of vendorUserControlList
                            .controls[idx].controls;
                          index as subIdx
                        "
                      >
                        <mat-list-item>
                          <mat-checkbox
                            [labelPosition]="before"
                            formControlName="{{ subIdx }}"
                          >
                            {{
                              formVendorObjects[idx].users[subIdx].displayName
                            }}
                            <small
                              *ngIf="
                                formVendorObjects[idx].users[subIdx].vendorRoles
                                  ?.length
                              "
                            >
                              -
                              {{
                                formVendorObjects[idx].users[subIdx].vendorRoles
                                  | arrayToString
                              }}
                            </small>
                          </mat-checkbox>
                        </mat-list-item>
                      </div>
                      <mat-list-item
                        *ngIf="!formVendorObjects[idx].users?.length"
                        >No Users</mat-list-item
                      >
                    </div>
                  </div>
                </div>
              </mat-list>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxLayout="column">
          <mat-hint *ngIf="responses?.saveSuccess" class="success-message">{{
            responses?.saveSuccess
          }}</mat-hint>
          <mat-hint
            *ngIf="!locationForm.valid && responses?.formInvalid"
            class="error-message"
            >{{ responses?.formInvalid }}</mat-hint
          >
          <div>
            <button mat-raised-button [disabled]="saving" class="blue-btn">
              <span *ngIf="!saving">{{
                location._id ? 'Update' : 'Save'
              }}</span>
              <img
                *ngIf="saving"
                src="/assets/images/spinner.gif"
                height="30"
                width="30"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div [hidden]="!viewUserList">
  <app-list-view-widget #users></app-list-view-widget>
</div>
