import { Observable } from 'rxjs'
import { DataTableColumnConfig } from './dataTableColumnConfig.class'
import { DataTableComponent } from './datatable.component'
import { DataTableRowComponent } from './datatable-row.component'

export interface IDataTablePage {
  pageItems: any[]
  totalCount: number
}

export interface IDataTableConfig {
  title?: string
  backgroundColor?: string
  headerBackgroundColor?: string
  headerTextColor?: string
  columns?: DataTableColumnConfig[]
  includeActionButtons?: boolean
  dataArray?: any[]
  rowClassesFunction?: (data: any) => string[]
  pageSize?: number
  getPageFn?: (
    pageNumber: number,
    table: DataTableComponent,
    sortFields: string[]
  ) => Observable<IDataTablePage>
  dataIsFromServer?: boolean
  idField?: string
  isSortable?: boolean
  defaultSortFields?: string[]
  startPage?: number
  showTotalCount?: boolean
  emptyMessage?: string
  emptyIcon?: string
  emptyIconColor?: string
  expanded?: boolean
}

export class DataTableConfig implements IDataTableConfig {
  title = ''
  backgroundColor = '#F5F5F5'
  headerBackgroundColor = '#0C6BA1'
  headerTextColor = '#FFFFFF'
  columns: DataTableColumnConfig[] = []
  includeActionButtons = false
  dataArray = []
  rowClassesFunction = null
  pageSize = -1
  getPageFn: (
    pageNumber: number,
    table: DataTableComponent,
    sortFields: string[]
  ) => Observable<IDataTablePage>

  defaultSortFields: string[]
  isSortable = true
  dataIsFromServer = false
  idField = '_id'
  startPage = 1
  showTotalCount = true
  emptyMessage = null
  emptyIcon = null
  emptyIconColor = null
  expanded = true

  constructor(config: IDataTableConfig) {
    if (config.hasOwnProperty('title')) {
      this.title = config.title
    }
    if (config.hasOwnProperty('backgroundColor')) {
      this.backgroundColor = config.backgroundColor
    }
    if (config.hasOwnProperty('headerBackgroundColor')) {
      this.headerBackgroundColor = config.headerBackgroundColor
    }
    if (config.hasOwnProperty('headerTextColor')) {
      this.headerTextColor = config.headerTextColor
    }
    if (config.hasOwnProperty('columns')) {
      this.columns = config.columns
    }
    if (config.hasOwnProperty('includeActionButtons')) {
      this.includeActionButtons = config.includeActionButtons
    }
    if (config.hasOwnProperty('dataArray')) {
      this.dataArray = config.dataArray
    }
    if (config.hasOwnProperty('rowClassesFunction')) {
      this.rowClassesFunction = config.rowClassesFunction
    }
    if (config.hasOwnProperty('pageSize')) {
      this.pageSize = config.pageSize
    }
    if (config.hasOwnProperty('getPageFn')) {
      this.getPageFn = config.getPageFn
    }
    if (config.hasOwnProperty('dataIsFromServer')) {
      this.dataIsFromServer = config.dataIsFromServer
    }
    if (config.hasOwnProperty('idField')) {
      this.idField = config.idField
    }
    if (config.hasOwnProperty('isSortable')) {
      this.isSortable = config.isSortable
    }
    if (config.hasOwnProperty('defaultSortFields')) {
      this.defaultSortFields = config.defaultSortFields
    }
    if (config.hasOwnProperty('startPage')) {
      this.startPage = config.startPage
    }
    if (config.hasOwnProperty('showTotalCount')) {
      this.showTotalCount = config.showTotalCount
    }
    if (config.hasOwnProperty('emptyMessage')) {
      this.emptyMessage = config.emptyMessage
    }
    if (config.hasOwnProperty('emptyIcon')) {
      this.emptyIcon = config.emptyIcon
    }
    if (config.hasOwnProperty('emptyIconColor')) {
      this.emptyIconColor = config.emptyIconColor
    }
    if (config.hasOwnProperty('expanded')) {
      this.expanded = config.expanded
    }

    if (this.dataIsFromServer && !this.getPageFn) {
      throw new Error(
        'Datatable Config Error: getPageFn must be provided if data comes from server'
      )
    }
  }
}
