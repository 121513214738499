import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms' // For forms or ngmodel wont work
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { MyDatePickerModule } from 'mydatepicker'
import { FlexLayoutModule } from '@angular/flex-layout'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxMyDatePickerModule } from 'ngx-mydatepicker'
import { AngularMyDatePickerModule } from 'angular-mydatepicker'
import { StateBroadcastService } from '../../core/client/state-broadcast/state-broadcast.service'
import { CoreModule } from '../../core/client/core-module'
import { DocumentManagementModule } from '../../document-management/client/document-management.module'
import { MaterialComponentsModule } from '../../material-components.module'
import { OrderComponent } from './order.component'
import { OrderDetailComponent } from './order-parts/order-detail.component'
import { OrderItemsComponent } from './order-parts/order-items.component'
import { OrderVendorDetailComponent } from './order-parts/order-vendor-detail.component'
import { OrderDeliveryDateComponent } from './order-parts/order-delivery-date.component'
import { OrderLogComponent } from './order-parts/order-log.component'
import { OrderPOSalesComponent } from './order-parts/order-po-sales.component'
import { OrderPOServiceComponent } from './order-parts/order-po-service.component'
import { OrderVendorDenialComponent } from './order-parts/order-vendor-denial.component'
import { OrderDocumentsComponent } from './order-parts/order-documents.component'
import { OrderActionComponent } from './order-parts/order-action.component'
import {
  OrderBuilderComponent,
  WheelMatchFilterPipe
} from './order-builder.component'

import { OrderService } from './order.service'

import { OrderRoutingModule } from './order-routing.module'
import { OrderPaperworkRejectionComponent } from './order-parts/order-paperwork-rejection.component'
import { OrderSearchComponent } from './order-search.component'
import { FleetCheckComponent } from './order-parts/fleet-check.component'
import { OrderDocumentUploadComponent } from './order-parts/order-document-upload.component'
import { ServiceCallComponent } from './service-call-form.component'

@NgModule({
  declarations: [
    OrderComponent,
    OrderBuilderComponent,
    OrderDetailComponent,
    OrderItemsComponent,
    OrderVendorDetailComponent,
    OrderDeliveryDateComponent,
    OrderLogComponent,
    OrderPOSalesComponent,
    OrderPOServiceComponent,
    OrderVendorDenialComponent,
    OrderPaperworkRejectionComponent,
    OrderDocumentsComponent,
    OrderActionComponent,
    OrderSearchComponent,
    FleetCheckComponent,
    OrderDocumentUploadComponent,
    ServiceCallComponent,
    WheelMatchFilterPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    OrderRoutingModule,
    CoreModule,
    MaterialComponentsModule,
    NgxMyDatePickerModule.forRoot(),
    AngularMyDatePickerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MyDatePickerModule,
    FlexLayoutModule,
    DocumentManagementModule
  ],
  providers: [OrderService, StateBroadcastService],
  exports: [OrderComponent]
})
export class OrderModule {}
