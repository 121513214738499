import { mergeMap } from 'rxjs/operators'
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { FleetService } from '../../fleet/client/fleet.service'
import { LocationService } from '../../location/client/location.service'
import { CoreUserService } from '../../core-user/client/core-user.service'

@Component({
  selector: 'service-call-dialog',
  templateUrl: 'service-call-dialog.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class ServiceCallDialogComponent {
  loggedInUser: any = null
  fleets: any[] = null
  myLocations: any[] = []
  otherLocations: any[] = []
  userLocations: any[] = null
  selectedFleet: any = null
  selectedLocation: any = null
  error: string = null

  constructor(
    private coreUserService: CoreUserService,
    private fleetService: FleetService,
    private locationService: LocationService,
    public dialogRef: MatDialogRef<ServiceCallDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { readOnlyLocation: boolean; order: any }
  ) {
    this.coreUserService
      .getLoggedInUser()
      .pipe(
        mergeMap((user: any) => {
          this.loggedInUser = user
          this.userLocations = this.loggedInUser.allowedLocations.map(
            (l) => l._id || l
          )
          return this.fleetService.getFleets()
        }),
        mergeMap((fleets: any[]) => {
          this.fleets = fleets
          if (this.fleets.length === 1) {
            this.selectedFleet = this.fleets[0]._id
          }
          if (this.data && this.data.order) {
            this.selectedFleet =
              this.data.order.location.fleet._id ||
              this.data.order.location.fleet
          }

          return this.getLocations()
        })
      )
      .subscribe((locations: any[]) => {
        if (this.data && this.data.order) {
          this.selectedLocation = this.data.order.location._id
        }
      })
  }

  onFleetSelected(): void {
    this.myLocations = []
    this.otherLocations = []
    this.selectedLocation = null
    this.getLocations().subscribe()
  }

  getLocations(): Observable<any[]> {
    return this.locationService
      .getLocations({ fleet: this.selectedFleet })
      .pipe(
        mergeMap((locations: any[]) => {
          locations.forEach((l) => {
            if (this.userLocations.includes(l._id)) {
              this.myLocations.push(l)
            } else {
              this.otherLocations.push(l)
            }
          })

          this.myLocations.sort(this.locationNameSort)
          this.otherLocations.sort(this.locationNameSort)

          if (this.myLocations.length === 1) {
            this.selectedLocation = this.myLocations[0]._id
          } else if (
            this.myLocations.length === 0 &&
            this.otherLocations.length === 1
          ) {
            this.selectedLocation = this.otherLocations[1]._id
          }

          return of(locations)
        })
      )
  }

  submit() {
    if (!this.selectedFleet) {
      this.error = 'Please select a fleet'
    } else if (!this.selectedLocation) {
      this.error = 'Please select a location'
    } else {
      this.dialogRef.close({
        locationId: this.selectedLocation
      })
    }
  }

  private locationNameSort(a: any, b: any): number {
    if (a.name > b.name) {
      return 1
    }

    if (a.name < b.name) {
      return -1
    }

    return 0
  }
}
