import * as _ from 'lodash'

import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { LocationService } from '../../location/client/location.service'
import { UtilsClass } from '../../core/client/utils/utils.component'
import { CoreUserService } from './core-user.service'
import { passwordMatcher } from './password-matcher'

@Component({
  selector: 'app-user',
  templateUrl: 'core-user.template.html',
  styleUrls: ['core-user.style.scss']
})
export class CoreUserComponent extends CoreResponseHandler implements OnInit {
  vendorUserRolesFlags = {
    Vendor_Manager: false,
    Vendor_Sales: false,
    Vendor_Warehouse: false,
    'Vendor_Admin Assistant': false,
    Vendor_Delivery: false
  }

  vendorUserRoles = [
    'Vendor_Manager',
    'Vendor_Sales',
    'Vendor_Warehouse',
    'Vendor_Admin Assistant',
    'Vendor_Delivery'
  ]

  user: any = {}
  storeInfo: any = { storeNumber: null, storeUsers: null }
  stmUser: any
  responses: any = {}
  email: Boolean
  userForm: FormGroup
  formTitle: string
  locationList: { name: string; _id: string; visible: boolean }[] = []
  doSelectAllLocations = false
  loggedInUser: any
  passwordConfirm = null
  userIsAdmin = false
  loggedInIsAdmin = false
  saving = false

  constructor(
    private coreUserService: CoreUserService,
    private locationService: LocationService,
    private fb: FormBuilder
  ) {
    super()
    this.coreUserService.getLoggedInUser().subscribe((user) => {
      this.loggedInUser = user
    })
    this.user = this.createDefaultUser()
  }

  setUser(userObj) {
    if (!userObj) {
      return
    }

    this.user = userObj
    this.user.isStmUser = false
    if (
      (this.user &&
        this.user.defaultAccount &&
        this.user.defaultAccount.company &&
        this.user.defaultAccount.company === 'Southern Tire Mart') ||
      this.user.defaultAccountType === 'Stm'
    ) {
      this.user.isStmUser = true
    }

    this.userIsAdmin = this.user.roles && this.user.roles.includes('admin')

    if (this.user.defaultAccountType === 'Fleet' && this.user.defaultAccount) {
      this.getFleetLocationList()
    }

    if (this.user.defaultAccountType === 'Vendor') {
      if (this.user.roles && this.user.roles.length) {
        for (const role in this.vendorUserRolesFlags) {
          if (this.user.roles.includes(role)) {
            this.vendorUserRolesFlags[role] = true
          }
        }
      }
    }
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      account: this.fb.group(
        {
          password: ['', Validators.required],
          confirm: ['', Validators.required]
        },
        { validator: passwordMatcher }
      )
    })

    this.loggedInIsAdmin = this.coreUserService.isAdmin()
    this.setUser(this.user)
  }

  getFleetLocationList() {
    this.locationList = []
    this.doSelectAllLocations =
      this.user.allowedLocations && this.user.allowedLocations.includes('all')
    this.locationService
      .getLocations({
        fleet: this.user.defaultAccount._id || this.user.defaultAccount
      })
      .subscribe((locations) => {
        _.forEach(locations, (l: any) => {
          this.locationList.push({
            name: l.name,
            _id: l._id,
            visible:
              this.doSelectAllLocations ||
              (this.user.allowedLocations &&
                this.user.allowedLocations.includes(l._id))
          })
        })
      })
  }

  save(): void {
    this.saving = true
    if (
      this.userForm.get('account').touched &&
      this.userForm.get('account').hasError('nomatch')
    ) {
      return
    }
    this.responses = {}
    this.user.displayName = `${this.user.firstName} ${this.user.lastName}`
    this.user.newRoles = _.cloneDeep(this.user.roles) || []
    this.user.newRoles = this.createVendorUserRolesStringsArray()
    _.pull(this.user.newRoles, 'admin')
    if (this.userIsAdmin) {
      this.user.newRoles.push('admin')
    }

    this.coreUserService.save(this.user).subscribe(
      (response) => {
        if (!this.user._id) {
          UtilsClass.mergeObjByValues(this.user, response)
          this.clearPassword()
        } else {
          this.coreUserService.getUserById(this.user._id).subscribe((user) => {
            UtilsClass.mergeObjByValues(this.user, user)
            this.clearPassword()
          })
        }
        this.handleSuccess('saveSuccess', 'User saved successfully!')
        this.saving = false
      },
      (error) => {
        this.handleFailure(error)
        this.saving = false
      }
    )
  }

  clearPassword() {
    this.user.password = undefined
    delete this.user.password
    this.passwordConfirm = null
    this.userForm.get('account').markAsUntouched()
  }

  createDefaultUser() {
    const newUser: object = {
      notificationRoute: {
        textMessage: false,
        email: false
      },
      accountType: ''
    }

    return newUser
  }

  readOnlyChanged() {
    if (this.user.readOnly) {
      this.user.canPlaceOrder = false
    }

    // In the fleet user settings UI, readOnly === false AND canPlaceOrder === false means use fleet default
    // fleet user's by default can edit order items, but not place a new order
    if (!this.user.readOnly && !this.user.canPlaceOrder) {
      this.user.canEditOrderItems = true
    }
  }

  canPlaceOrderChanged() {
    if (this.user.canPlaceOrder) {
      this.user.readOnly = false
    }

    // In the fleet user settings UI, readOnly === false AND canPlaceOrder === false means use fleet default
    // fleet user's by default can edit order items, but not place a new order
    if (!this.user.readOnly && !this.user.canPlaceOrder) {
      this.user.canEditOrderItems = true
    }
  }

  toggleSelectAllLocations() {
    if (this.doSelectAllLocations) {
      this.locationList = _.map(this.locationList, (l) => {
        l.visible = true
        return l
      })
    }

    this.setUserAllowedLocations()
  }

  locationVisiblityChanged() {
    const allowedLocations = _.filter(this.locationList, (l) => l.visible)
    this.doSelectAllLocations =
      allowedLocations.length === this.locationList.length

    this.setUserAllowedLocations()
  }

  setUserAllowedLocations() {
    const allowedLocations = _.filter(this.locationList, (l) => l.visible)
    if (allowedLocations.length === this.locationList.length) {
      this.user.locationList = ['all']
    } else {
      this.user.locationList = _.map(allowedLocations, (l) => l._id)
    }
  }

  stmUserAssign() {
    this.coreUserService.getUserByKeycloakId(this.stmUser.keycloakId).subscribe(
      (foundUser: any) => {
        this.setUser(foundUser)
        this.stmUser.notificationRoute = foundUser.notificationRoute
        UtilsClass.mergeObjByValues(this.user, this.stmUser)
      },
      (error) => {
        if (error.status === 404) {
          UtilsClass.mergeObjByValues(this.user, this.stmUser)
        } else {
          throw error
        }
      }
    )
  }

  handleResponse(response: CoreResponse): boolean {
    // Only Errors have offending keys
    // TODO: be more explicit.
    if (response.offendingKey) {
      switch (response.offendingKey) {
        case 'firstName':
        case 'lastName':
        case 'email':
        case 'username':
        case 'password':
        case 'cell':
          this.responses[response.offendingKey] = response.message
          return true
        default:
          return false
      }
    } else {
      switch (response.type) {
        case 'saveSuccess':
        case 'updateSuccess':
        case 'deleteSuccess':
          this.responses[response.type] = response.message
          return true
        default:
          return false
      }
    }
  }

  toggleVendorUserRole(role) {
    this.vendorUserRolesFlags[role] = !this.vendorUserRolesFlags[role]
  }

  displayVendorRole(role): string {
    return role.slice(7)
  }

  createVendorUserRolesStringsArray(): string[] {
    const newRoles = _.cloneDeep(this.user.roles) || []
    for (const role in this.vendorUserRolesFlags) {
      if (this.vendorUserRolesFlags.hasOwnProperty(role)) {
        _.pull(newRoles, role)
        if (this.vendorUserRolesFlags[role]) {
          newRoles.push(role)
        }
      }
    }
    return newRoles
  }
}
