export interface IDataTableColumnConfig {
  width?: number
  display?: string
  field?: string
  altText?: string
  formatFunction?: (value: any) => any
  hideOnMobile?: boolean
  type?: string
  sort?: 'asc' | 'desc' | 'none'
}

export class DataTableColumnConfig implements IDataTableColumnConfig {
  width = 0
  display = ''
  field = ''
  altText = ''
  formatFunction = null
  hideOnMobile = false
  type: 'string' | 'number' | 'date' | 'image' = 'string'
  sort: 'asc' | 'desc' | 'none' = 'none'

  constructor(column: any) {
    if (column.hasOwnProperty('width')) {
      this.width = column.width
    }
    if (column.hasOwnProperty('display')) {
      this.display = column.display
    }
    if (column.hasOwnProperty('field')) {
      this.field = column.field
    }
    if (column.hasOwnProperty('type')) {
      this.type = column.type
    }
    if (column.hasOwnProperty('altText')) {
      this.altText = column.altText
    }
    if (column.hasOwnProperty('formatFunction')) {
      this.formatFunction = column.formatFunction
    }
    if (column.hasOwnProperty('hideOnMobile')) {
      this.hideOnMobile = column.hideOnMobile
    }
    if (column.hasOwnProperty('sort')) {
      this.sort = column.sort
    }
  }
}
