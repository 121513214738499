import { Component, OnInit, ViewChild } from '@angular/core'

import { FormBuilder, FormGroup } from '@angular/forms'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { ListViewWidgetComponent } from '../../core/client/object-list-with-dynamic-detail/list-view-widget.component'
import { LocationService } from '../../location/client/location.service'
import { LocationComponent } from '../../location/client/location.component'
import { CoreUserComponent } from '../../core-user/client/core-user.component'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { UtilsClass } from '../../core/client/utils/utils.component'
import { OrderFlowService } from './order-flow.service'

@Component({
  selector: 'app-order-flow',
  templateUrl: './order-flow.component.html',
  styleUrls: ['./order-flow.component.scss'],
  providers: []
})
export class OrderFlowComponent extends CoreResponseHandler implements OnInit {
  ngOnInit() {}

  handleResponse(response: CoreResponse): boolean {
    return false
  }
}
