import { map, switchMap } from 'rxjs/operators'
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Subject } from 'rxjs'

import * as _ from 'lodash'

import { CoreUserService } from '../../core-user/client/core-user.service'
import { ManufacturerService } from '../../manufacturer/client/manufacturer.service'
import { StateBroadcastService } from '../../core/client/state-broadcast/state-broadcast.service'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'
import { OrderService } from './order.service'
import { OrderItemsComponent } from './order-parts/order-items.component'
import { OrderDocumentsComponent } from './order-parts/order-documents.component'
import { OrderPOSalesComponent } from './order-parts/order-po-sales.component'
import { OrderPOServiceComponent } from './order-parts/order-po-service.component'
import { OrderBaseComponent } from './order-parts/order-base.class'
import { OrderLogComponent } from './order-parts/order-log.component'
import { OrderDeliveryDateComponent } from './order-parts/order-delivery-date.component'
import { OrderActionComponent } from './order-parts/order-action.component'
import { OrderVendorDenialComponent } from './order-parts/order-vendor-denial.component'
import { OrderVendorDetailComponent } from './order-parts/order-vendor-detail.component'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: []
})
export class OrderComponent extends CoreResponseHandler implements OnInit {
  order: any
  unModifiedOrder: any
  rush = false
  itemsInCart = 0
  coreUser: any
  updateCompleteEmitter: Subject<string> = new Subject()
  manufacturers: any[] = null

  @Input() modifyMode = false
  @Input() itemAddedNotification: Subject<any>
  @ViewChild(OrderDocumentsComponent, { static: true })
  public orderDocumentsComponent: OrderDocumentsComponent

  @ViewChild(OrderActionComponent, { static: true })
  public orderActionComponent: OrderActionComponent

  childComponents: OrderBaseComponent[] = []
  @ViewChild(OrderLogComponent, { static: true })
  private orderLogComponent: OrderLogComponent
  // createOrderObject: any = null;
  /* @ViewChild(OrderItemsComponent, { static: true }) private orderItemsComponent: OrderItemsComponent;
    @ViewChild(OrderDocumentsComponent, { static: true }) private orderDocumentsComponent: OrderDocumentsComponent;
    @ViewChild(OrderPOSalesComponent, { static: true }) private orderPOSalesComponent: OrderPOSalesComponent;
    @ViewChild(OrderPOServiceComponent, { static: true }) private orderPOServiceComponent: OrderPOServiceComponent;
    @ViewChild(OrderLogComponent, { static: true }) private orderLogComponent: OrderLogComponent;
    @ViewChild(OrderDeliveryDateComponent, { static: true }) private orderDeliveryDateComponent: OrderDeliveryDateComponent;
    @ViewChild(OrderActionComponent, { static: true }) private orderActionComponent: OrderActionComponent;
    @ViewChild(OrderVendorDenialComponent, { static: true }) private orderVendorDenialComponent: OrderVendorDenialComponent;
    @ViewChild(OrderVendorDetailComponent, { static: true }) private orderVendorDetailComponent: OrderVendorDetailComponent; */

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private coreUserService: CoreUserService,
    private manufacturerService: ManufacturerService,
    private stateBroadcastService: StateBroadcastService
  ) {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    return false
  }

  ngOnInit() {
    this.manufacturerService.getManufacturers().subscribe((manufacturers) => {
      this.manufacturers = manufacturers
    })

    this.coreUserService.getLoggedInUser().subscribe((loggedInUser) => {
      this.coreUser = loggedInUser
    })

    this.route.url
      .pipe(map((segments) => segments.join('')))
      .subscribe((routeUrl) => {
        if (routeUrl !== 'orderBuilder') {
          this.route.queryParams.subscribe((queryParams: Params) => {
            const orderType = queryParams.orderType
            this.route.params
              .pipe(
                switchMap((params: Params) => {
                  return this.orderService.getOrderById(params.id, orderType)
                })
              )
              .subscribe((order) => {
                this.setOrder(order)
                // this.showChildComponents = true;
              })
          })
        }
      })
  }

  registerOrderPart(childComponent: OrderBaseComponent) {
    this.childComponents.push(childComponent)
    if (this.order) {
      childComponent.setOrder(this.order)
    }
  }

  childRequestsUpdate(propToUpdate?: string, stateWillChange = false) {
    if (
      this.orderService.hasBeenPlaced(this.order) &&
      this.order.pendingModificationLogs
    ) {
      this.order.stateHistory = this.order.stateHistory || []
      this.order.stateHistory.unshift({
        state: this.order.state._id || this.order.state,
        stateDescription: this.order.state.orderActionLabel,
        actionType: 'ORDER_MODIFIED',
        description: this.order.pendingModificationLogs.map((e) => e.text),
        user: this.coreUser._id,
        date: new Date()
      })
      this.order.pendingModificationLogs = undefined
      delete this.order.pendingModificationLogs
    }

    let updateJson: any
    if (propToUpdate) {
      updateJson = { _id: this.order._id, state: this.order.state }
      updateJson[propToUpdate] = this.order[propToUpdate]
    } else {
      updateJson = this.order
    }

    this.orderService.updateOrder(this.order, updateJson).subscribe(
      (savedOrder) => {
        if (propToUpdate) {
          savedOrder = this.order
        }
        this.stateBroadcastService.broadcastUpdate(
          savedOrder._id,
          savedOrder.orderType,
          stateWillChange
        )
        this.setOrder(savedOrder)

        // let any children subscribed to update complete emitter know that update is finished
        this.updateCompleteEmitter.next(savedOrder._id)

        setTimeout(() => {
          this.handleSuccess('saveSuccess', 'Order saved successfully!')
        }, 500)
      },
      (err) => {
        this.handleFailure(err)
      }
    )
  }

  /**
   * put existing items in table
   */
  setOrder(order) {
    this.order = order
    if (
      this.order &&
      this.order.orderType === 'ServiceCall' &&
      !this.orderService.hasBeenPlaced(this.order)
    ) {
      this.modifyMode = true
    }
    this.unModifiedOrder = _.cloneDeep(this.order)
    /* this.orderItemsComponent.setOrder(this.order);
        this.orderDocumentsComponent.setOrder(this.order);
        // this.orderStmNotesComponent.setOrder(this.order);
        // this.orderPoComponent.setOrder(this.order);
        this.orderPOSalesComponent.setOrder(this.order);
        this.orderPOServiceComponent.setOrder(this.order);
        this.orderLogComponent.setOrder(this.order);
        this.orderDeliveryDateComponent.setOrder(this.order);
        this.orderActionComponent.setOrder(this.order);
        this.orderVendorDenialComponent.setOrder(this.order);
        this.orderVendorDetailComponent.setOrder(this.order); */
    this.addResponseHandlers(<CoreResponseHandler[]>this.childComponents)
    this.childComponents.forEach((c) => {
      c.setOrder(this.order)
    })
  }

  /**
   * add items to cart
   */
  addToCart(product: any) {
    this.addProduct(product)
    this.orderService.notifyItemAddedToCart(product)
  }

  addProduct(product) {
    // Gasper if we got this far we no longer have a no order and can remove that indicator
    this.removeNoOrder()
    if (product.productType === 'FORKLIFT') {
      this.addForkliftProduct(product)
    } else {
      this.addRegularProduct(product)
    }
  }

  addRegularProduct(product) {
    const newMaanufacturer = product.brand
    let manufacturerGroup = null
    for (const group of this.order.regularItems.manufacturers) {
      const existingId = group.manufacturer
        ? group.manufacturer._id || group.manufacturer
        : null
      const newId = newMaanufacturer._id || newMaanufacturer
      if (existingId === newId) {
        manufacturerGroup = group
        break
      }
    }

    if (!manufacturerGroup) {
      manufacturerGroup = this.addManufacturerGoup(newMaanufacturer)
    }

    this.addProductToManufacturerGroup(product, manufacturerGroup)
  }

  addManufacturerGoup(manufacturer) {
    const manufactObj = {
      manufacturer,
      items: []
    }
    this.order.regularItems.manufacturers.push(manufactObj)
    return manufactObj
  }

  addProductToManufacturerGroup(newProduct, manufacturerGroup) {
    // const newProduct = product.brand;
    let newGroup = false
    let productGroup = null
    let logType = ''
    for (const product of manufacturerGroup.items) {
      const savedWheelId = product.wheel ? product.wheel._id : null
      const newWheelId = newProduct.rim ? newProduct.rim._id : null
      if (product.tire._id === newProduct._id && savedWheelId === newWheelId) {
        productGroup = product
      }
    }

    if (!productGroup) {
      newGroup = true
      productGroup = {
        tire: newProduct,
        wheel: newProduct.rim,
        qty: 0
      }
      manufacturerGroup.items.push(productGroup)
    }
    const origionlQty = productGroup.qty
    productGroup.qty += newProduct.qty
    if (newGroup === true) {
      logType = 'productAdded'
    } else {
      logType = 'quantityChange'
    }

    this.orderLogComponent.logOrderModification(
      logType,
      newProduct,
      origionlQty,
      false
    )
  }

  addForkliftProduct(product) {
    const newForklift = product.forkliftNumber
    let forkliftGroup = null
    for (const forklift of this.order.forkliftItems) {
      if (forklift.forkliftNumber === newForklift) {
        forkliftGroup = forklift
      }
    }

    if (!forkliftGroup) {
      forkliftGroup = this.addForkliftGroup(newForklift)
    }

    this.addProductToForkliftGroup(product, forkliftGroup)
  }

  addForkliftGroup(forkliftNumber) {
    const forkliftObj = {
      forkliftNumber,
      items: []
    }
    this.order.forkliftItems.push(forkliftObj)
    return forkliftObj
  }

  addProductToForkliftGroup(newProduct, forkliftGroup) {
    // const newProduct = product.brand;
    let productGroup = null
    for (const product of forkliftGroup.items) {
      if (product.tire._id === newProduct._id) {
        productGroup = product
      }
    }

    if (!productGroup) {
      productGroup = {
        tire: newProduct,
        qty: 0
      }
      forkliftGroup.items.push(productGroup)
    }

    const originalQty = productGroup.qty
    productGroup.qty += newProduct.qty

    this.orderLogComponent.logOrderModification(
      'productAdded',
      newProduct,
      originalQty,
      true
    )
  }

  logOrderModification(modification: any) {
    if (modification.type === 'quantityChange') {
      let originalQty = 0

      // Find quantity changes for regular items
      this.unModifiedOrder.regularItems.manufacturers.forEach(
        (manufacturer) => {
          manufacturer.items.forEach((item) => {
            if (item._id === modification.item._id) {
              originalQty = item.qty
              this.orderLogComponent.logOrderModification(
                modification.type,
                modification.item,
                originalQty,
                false
              )
            }
          })
        }
      )

      // Find quantity changes for forklift items
      this.unModifiedOrder.forkliftItems.forEach((forklift) => {
        if (forklift.forkliftNumber === modification.forkliftNumber) {
          forklift.items.forEach((item) => {
            if (item._id === modification.item._id) {
              /* tslint:disable */
              originalQty = item.qty
              modification.item.forkliftNumber = forklift.forkliftNumber
              this.orderLogComponent.logOrderModification(
                modification.type,
                modification.item,
                originalQty,
                true
              )
              /* tslint:enable */
            }
          })
        }
      })
    } else if (modification.type === 'fleetCheckDueDateChanged') {
      this.orderLogComponent.logOrderModification(
        modification.type,
        null,
        null,
        null,
        modification.oldDate,
        modification.newDate
      )
    } else {
      if (modification.forkliftNumber) {
        modification.item.forkliftNumber = modification.forkliftNumber
      }
      this.orderLogComponent.logOrderModification(
        modification.type,
        modification.item,
        modification.item.qty,
        modification.forkliftNumber !== null &&
          modification.forkliftNumber !== undefined
      )
    }
  }

  // checks if has no order and removes it
  removeNoOrder() {
    this.order.isNoOrderNoPickup = false
    this.order.isNoOrderWithPickup = false
    this.order.noOrderText = ''
  }

  showDocuments() {
    this.orderDocumentsComponent.setUserVisibility()
  }
}
