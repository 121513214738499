// tslint:disable:no-access-missing-member
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
// import {OrderVendorDetailComponent} from './order-vendor-detail.component';
import { OrderService } from '../order.service'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-detail',
  template: ` <div *ngIf="isVisible" class="app-order-detail">
    <div
      class="order-detail-row-header"
      [class.rushHeader]="order?.rushRequested"
    >
      <div>Order Details</div>
    </div>
    <div class="order-part-column-container">
      <div class="order-detail-row">
        <div class="label">Yard</div>
        <div class="info">{{ order?.location?.name }}</div>
      </div>
      <div class="order-detail-row">
        <div class="label">Yard Address</div>
        <div class="info">
          {{ order?.location?.address?.street1 }}<br />{{
            order?.location?.address?.city
          }}
          <span
            *ngIf="
              order?.location?.address?.city && order?.location?.address?.state
            "
            >,</span
          >
          {{ order?.location?.address?.state }}
        </div>
      </div>
      <!--<div class="order-detail-row">-->
      <!--<div class="label"></div>-->
      <!--<div class="info">{{order?.location?.address?.city}}, {{order?.location?.address?.state}}</div>-->
      <!--</div>-->
      <div class="order-detail-row">
        <div class="label">Order Number</div>
        <div class="info">{{ order?.orderNumber }}</div>
      </div>
      <div class="order-detail-row">
        <div class="label">Status</div>
        <div class="info">{{ order?.state.description }}</div>
      </div>
      <div class="order-detail-row">
        <div class="label">Started On</div>
        <div class="info">
          {{ order?.createdDate | date: 'M/d/yyyy' }}
        </div>
      </div>
      <div class="order-detail-row">
        <div class="label">Started By</div>
        <div class="info">{{ order?.createdBy?.displayName }}</div>
      </div>
      <div class="order-detail-row">
        <div class="label">Placed On</div>
        <div class="info">
          {{ order?.placedDate | date: 'M/d/yyyy' }}
        </div>
      </div>
      <div class="order-detail-row">
        <div class="label">Placed By</div>
        <div class="info">{{ order?.placedBy?.displayName }}</div>
      </div>
      <div class="order-detail-row">
        <div class="label">Order Flow</div>
        <div class="info">
          {{ order?.orderFlowVersion?.name }} v.{{
            order?.orderFlowVersion?.versionNumber
          }}
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: ['../order.component.scss'],
  providers: []
})
export class OrderDetailComponent extends OrderBaseComponent implements OnInit {
  constructor() {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible = true
  }
}
