import { Observable, forkJoin as observableForkJoin, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

// tslint:disable:no-access-missing-member
import * as _ from 'lodash'

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms'
import { Router } from '@angular/router'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'

import { VendorService } from '../../vendor/client/vendor.service'
import { ListViewWidgetComponent } from '../../core/client/object-list-with-dynamic-detail/list-view-widget.component'
import { CoreUserComponent } from '../../core-user/client/core-user.component'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { UtilsClass } from '../../core/client/utils/utils.component'

import { OrderFlowService } from '../../order-flow/client/order-flow.service'
import { LocationService } from './location.service'
import { timezones } from './../../constants'

interface FormVendorObject {
  vendor: { _id: string; name: string; available: boolean }
  users: any[]
  orderDays?: string[]
  deliveryDays?: string[]
}

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: []
})
export class LocationComponent extends CoreResponseHandler implements OnInit {
  location: any = {}
  weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]

  responses: any = {}
  locationForm: FormGroup
  vendors: any[]
  populatedVendorUsers: { [vendorId: string]: any[] } = {} // {<vendor1 id>: [<vendor1 user list>], <vendor2 id>: [<vendor2 user list], etc}

  fleets: any
  orderFlows: any[] = []
  viewUserList: Boolean = false
  formPopulated = false
  formSubmitAttempted = false
  formVendorObjects: FormVendorObject[] = []
  nameControl
  emailControl
  phoneControl
  faxControl
  orderFlowCtrl
  orderFlowVersionCtrl
  vendorControlList: FormArray
  vendorUserControlList: FormArray
  fleetDefaultOrderFlow: any
  selectedOrderFlow: any
  saving = false
  timezones = timezones

  @ViewChild('users', { static: true })
  UsersListViewWidget: ListViewWidgetComponent

  constructor(
    private locationService: LocationService,
    private vendorService: VendorService,
    private userService: CoreUserService,
    private formBuilder: FormBuilder,
    private orderFlowService: OrderFlowService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    super()
    this.location = this.createDefaultLocation()
  }

  createDefaultLocation() {
    const newLocation: object = {
      address: {
        street1: '',
        city: '',
        state: '',
        zip: '',
        timezone: 'America/Chicago'
      },
      vendors: [],
      orderFlow: { useFleetDefault: true, flow: null }
    }

    return newLocation
  }

  ngOnInit() {
    this.buildStaticFormControls()
    this.getVendors()
      .pipe(
        mergeMap(() => {
          return this.orderFlowService.getOrderFlows()
        }),
        mergeMap((orderFlows) => {
          this.orderFlows = orderFlows
          this.setFleetDefaultOrderFlow()
          return this.finishSettingUpLocationForm()
        })
      )
      .subscribe()

    this.getLocationUsers()

    // User Component
    this.UsersListViewWidget.setListArray([], 'displayName', ['person'])
    this.UsersListViewWidget.setViewComponent(CoreUserComponent, 'user')
    this.UsersListViewWidget.modifyObject('user', 'accountType', 'Location')
    this.UsersListViewWidget.modifyObject('user', 'account', this.location._id)
  }

  viewUserListComponent() {
    this.viewUserList = true
    this.UsersListViewWidget.setTitle(`${this.location.name} Users`)
  }

  /**
   * Get list of users for this location
   */
  getLocationUsers() {
    if (this.location._id) {
      this.userService
        .getUsers({
          defaultAccountType: 'Location',
          account: this.location._id
        })
        .subscribe((users) => {
          this.UsersListViewWidget.viewModelArray = users
        })
    } else {
      this.UsersListViewWidget.viewModelArray = []
    }
  }

  /**
   * Get list of vendors from database and set available bool (if they are not already in this location's vendor list)
   */
  getVendors(): Observable<any[]> {
    // reset vendors array
    this.vendors = []
    // get the list of vendors from the db
    return this.vendorService.getVendors().pipe(
      mergeMap((vendors) => {
        // set available flag for each vendor (true if not already in the location's vendor list, false otherwise)
        _.forEach(vendors, (ven: any) => {
          ven.available =
            _.find(
              this.location.vendors,
              (vObj: any) => vObj.vendor._id === ven._id
            ) === undefined
        })
        // attach to the scope
        this.vendors = vendors
        return of(this.vendors)
      })
    )
  }

  setVendorAvailability(): void {
    _.forEach(this.vendors, (vendor) => {
      const match = _.find(this.formVendorObjects, (venObj) => {
        return venObj.vendor && venObj.vendor._id === vendor._id
      })
      vendor.available = match === undefined
    })
  }

  getUsersForAllVendors(useCached = true): Observable<any[]> {
    const getFns = []
    _.forEach(this.formVendorObjects, (venObj) => {
      getFns.push(this.getUsersForSingleVendor(venObj, useCached))
    })

    return getFns.length ? observableForkJoin(getFns) : of([])
  }

  getUsersForSingleVendor(
    vendorObject: any,
    useCached = true
  ): Observable<any> {
    const vendorId = vendorObject.vendor && vendorObject.vendor._id
    let getUsersFn: Observable<any> = of([])
    vendorObject.users = []

    if (vendorId) {
      // check to see if we already have vendor users for this vendor in the cache (populatedVendorUsers)
      if (!useCached || !this.populatedVendorUsers[vendorId]) {
        // if not cached, populate vendor users from db
        getUsersFn = this.getVendorUsers(vendorObject.vendor._id).pipe(
          mergeMap((users) => {
            // process roles and allowedLocations for each user in the list
            _.forEach(users, (usr) => {
              usr.allowedToSeeThisLocation = usr.allowedLocations.includes(
                this.location._id
              )
              usr.wasAllowedToSeeThisLocation = usr.allowedToSeeThisLocation // used to detect changes
              usr.allowedLocationsChanged = false
              const vendorRoles = _.filter(usr.roles, (role: string) =>
                role.includes('Vendor_')
              )
              usr.vendorRoles = _.map(vendorRoles, (vRole: string) =>
                vRole.replace('Vendor_', '')
              )
            })

            vendorObject.users = users
            this.populatedVendorUsers[vendorId] = users
            return of(vendorObject.users)
          })
        )
      } else {
        // if cached, populate vendor users from cache
        vendorObject.users = this.populatedVendorUsers[vendorId]
        getUsersFn = of(vendorObject.users)
      }
    }

    return getUsersFn
  }

  getVendorUsers(vendorId: string): Observable<any[]> {
    return this.userService.getUsers({
      defaultAccountType: 'Vendor',
      defaultAccount: vendorId
    })
  }

  finishSettingUpLocationForm(): Observable<void> {
    return this.resetFormVendorObjects(false).pipe(
      mergeMap(() => {
        this.setVendorAvailability()
        this.buildAllVendorDropdownGroups()
        this.buildUserCheckboxesForAllVendors()
        this.populateAllFormValues()
        return of(null)
      })
    )
  }

  setFleetDefaultOrderFlow() {
    const defaultFlowId =
      this.location.fleet && this.location.fleet.defaultOrderFlow
        ? this.location.fleet.defaultOrderFlow._id ||
          this.location.fleet.defaultOrderFlow
        : null
    if (defaultFlowId) {
      for (let i = 0; i < this.orderFlows.length; i++) {
        const foundVersion = this.orderFlows[i].versions.find(
          (version: any) => version._id === defaultFlowId
        )
        if (foundVersion !== undefined) {
          this.fleetDefaultOrderFlow = foundVersion
          break
        }
      }
    }
  }

  buildStaticFormControls() {
    // create all of the static form controls
    this.locationForm = this.formBuilder.group({
      name: this.formBuilder.control(null),
      abbreviation: this.formBuilder.control(null),
      address: this.formBuilder.group({
        street1: this.formBuilder.control(null),
        city: this.formBuilder.control(null),
        state: this.formBuilder.control(null),
        zip: this.formBuilder.control(null),
        timezone: this.formBuilder.control('America/Chicago')
      }),
      phone: this.formBuilder.control(null, this.customUSPhoneValidator),
      fax: this.formBuilder.control(null, this.customUSPhoneValidator),
      email: this.formBuilder.control(null, this.customEmailValidator),
      orderFlow: this.formBuilder.control(null),
      orderFlowVersion: this.formBuilder.control(null),
      vendors: this.formBuilder.array([]),
      vendorUsers: this.formBuilder.array([])
    })

    // get handlers for controls that will have validation attached
    this.vendorUserControlList = this.locationForm.get(
      'vendorUsers'
    ) as FormArray
    this.vendorControlList = this.locationForm.get('vendors') as FormArray
    this.nameControl = this.locationForm.get('name')
    this.emailControl = this.locationForm.get('email')
    this.phoneControl = this.locationForm.get('phone')
    this.faxControl = this.locationForm.get('fax')
    this.orderFlowCtrl = this.locationForm.get('orderFlow')
    this.orderFlowVersionCtrl = this.locationForm.get('orderFlowVersion')
  }

  buildAllVendorDropdownGroups() {
    // reset the vendor control list to empty array
    this.deleteAllVendorDropdownGroups()

    // Add the controls of the locations vendors (vendor, orderDays, and deliveryDays selects)
    if (this.formVendorObjects && this.formVendorObjects.length) {
      _.forEach(this.formVendorObjects, (vendorObj, index) => {
        const formGroup = this.formBuilder.group({
          vendor: this.formBuilder.control(null),
          orderDays: this.formBuilder.control(null),
          deliveryDays: this.formBuilder.control(null)
        })

        this.vendorControlList.push(formGroup)
      })
    }
  }

  buildUserCheckboxesForAllVendors() {
    // reset the vendor user control list to empty array
    this.deleteAllUserCheckboxesForAllVendors()

    // Add the checkboxes for each user of the location's vendors
    _.forEach(this.formVendorObjects, (vendorObj: any) => {
      this.buildUserCheckboxesForSingleVendor(vendorObj)
    })
  }

  buildUserCheckboxesForSingleVendor(vendor: any, index?: number) {
    const vendorUsers = vendor.users

    // create a checkbox control for each user
    const userControls = _.map(vendorUsers, (u: any) => {
      return this.formBuilder.control(null)
    })

    const userGroup = this.formBuilder.array(userControls)

    if (index !== undefined && index !== null) {
      this.vendorUserControlList.setControl(index, userGroup)
    } else {
      this.vendorUserControlList.push(userGroup)
    }
  }

  populateAllFormValues() {
    this.formPopulated = false

    // add data from location to static controls
    this.locationForm.get('name').setValue(this.location.name)
    this.locationForm.get('abbreviation').setValue(this.location.abbreviation)
    if (this.location.address) {
      this.locationForm
        .get('address.street1')
        .setValue(this.location.address.street1)
      this.locationForm.get('address.city').setValue(this.location.address.city)
      this.locationForm
        .get('address.state')
        .setValue(this.location.address.state)
      this.locationForm.get('address.zip').setValue(this.location.address.zip)
      this.locationForm
        .get('address.timezone')
        .setValue(this.location.address.timezone)
    }
    this.locationForm.get('phone').setValue(this.location.phone)
    this.locationForm.get('fax').setValue(this.location.fax)
    this.locationForm.get('email').setValue(this.location.email)

    let locationOrderFlow: any = null
    let locationOrderFlowVersion: any = null
    if (this.location.orderFlow) {
      if (!this.location.orderFlow.useFleetDefault) {
        const locationFlowId = this.location.orderFlow.flow
          ? this.location.orderFlow.flow._id || this.location.orderFlow.flow
          : null
        for (let i = 0; i < this.orderFlows.length; i++) {
          const foundVersion = this.orderFlows[i].versions.find(
            (version: any) => version._id === locationFlowId
          )
          if (foundVersion !== undefined) {
            locationOrderFlow = this.orderFlows[i]
            locationOrderFlowVersion = foundVersion
            break
          }
        }
      }
    }

    if (locationOrderFlow) {
      this.selectedOrderFlow = locationOrderFlow
      this.locationForm.get('orderFlow').setValue(locationOrderFlow.name)
    } else {
      this.selectedOrderFlow = 'fleetDefault'
      this.locationForm.get('orderFlow').setValue('fleetDefault')
    }
    if (locationOrderFlowVersion) {
      this.locationForm
        .get('orderFlowVersion')
        .setValue(locationOrderFlowVersion._id)
    }

    // populate dynamic controls
    this.populateAllVendorDropdownGroupValues()
    this.populateUserCheckboxValuesForAllVendors()

    this.formPopulated = true
  }

  populateAllVendorDropdownGroupValues() {
    // Populate the controls of the locations vendors (vendor, orderDays, and deliveryDays selects)
    if (this.formVendorObjects && this.formVendorObjects.length) {
      _.forEach(this.formVendorObjects, (vendorObj, index) => {
        this.populateSingleVendorDropdownGroupValues(vendorObj, index)
      })
    }

    this.vendorControlList.valueChanges.subscribe(() => {
      this.handleVendorChange()
    })
  }

  populateSingleVendorDropdownGroupValues(vendorObj: any, index: number) {
    const vendorId = vendorObj.vendor
      ? vendorObj.vendor._id || vendorObj.vendor
      : null

    const backupVendorObjects = _.cloneDeep(this.formVendorObjects)
    if (this.vendorControlList.at(index)) {
      this.vendorControlList.at(index).patchValue({
        vendor: vendorId,
        orderDays: vendorObj.orderDays,
        deliveryDays: vendorObj.deliveryDays
      })
    } else {
      throw new Error(
        `There is no vendor control at index ${index}.
                        (vendorControlList.length = ${this.vendorControlList.length})`
      )
    }
  }

  populateUserCheckboxValuesForAllVendors() {
    // Add the checkboxes for each user of the location's vendors
    _.forEach(this.formVendorObjects, (vendorObj: any, vendorIndex: number) => {
      this.populateUserCheckboxValuesForSingleVendor(vendorObj, vendorIndex)
    })
  }

  populateUserCheckboxValuesForSingleVendor(vendor: any, vendorIndex: number) {
    const vendorUsers = vendor.users

    _.forEach(vendorUsers, (user: any, userIndex: number) => {
      if (
        this.vendorUserControlList.at(vendorIndex) &&
        (this.vendorUserControlList.at(vendorIndex) as FormArray).at(userIndex)
      ) {
        const userControl = (
          this.vendorUserControlList.at(vendorIndex) as FormArray
        ).at(userIndex)
        userControl.setValue(user.allowedToSeeThisLocation)
      } else {
        const controlListLength = this.vendorUserControlList.length
        const subListLength = this.vendorUserControlList.at(vendorIndex)
          ? (this.vendorUserControlList.at(vendorIndex) as FormArray).length
          : null

        throw new Error(
          `There is no vendor user control at index [${vendorIndex}][${userIndex}].
                    (vendorUserControlList.length = ${controlListLength}),
                    (vendorUserControlList[${vendorIndex}].length = ${subListLength})`
        )
      }
    })
  }

  resetFormVendorObjects(useCached = true): Observable<any[]> {
    if (this.location.vendors && this.location.vendors.length) {
      this.formVendorObjects = _.cloneDeep(this.location.vendors)
    } else {
      this.formVendorObjects = [
        {
          vendor: null,
          users: [],
          orderDays: null,
          deliveryDays: null
        }
      ]
    }

    return this.getUsersForAllVendors(useCached)
  }

  deleteAllVendorDropdownGroups() {
    _.forEachRight(this.vendorControlList, (val, idx: number) => {
      this.vendorControlList.removeAt(idx)
    })
  }

  deleteAllUserCheckboxesForAllVendors() {
    _.forEachRight(this.vendorUserControlList, (val, idx: number) => {
      this.vendorUserControlList.removeAt(idx)
    })
  }

  /**
   * Clear users from the vendor user access list
   * @param vendorId If passed, will only clear the specified vendor's users
   */
  clearUserCheckboxValuesForAllVendors() {
    _.forEach(
      this.vendorUserControlList.controls,
      (vendorUserControlGroup: FormGroup) => {
        _.forEach(vendorUserControlGroup.controls, (ctrl: FormControl) =>
          ctrl.setValue(false)
        )
      }
    )
  }

  addVendor() {
    const newFormVendorObject = {
      vendor: null,
      orderDays: null,
      deliveryDays: null,
      users: []
    }

    this.formVendorObjects.push(newFormVendorObject)

    this.vendorControlList.push(
      this.formBuilder.group({
        vendor: this.formBuilder.control(null),
        orderDays: this.formBuilder.control(null),
        deliveryDays: this.formBuilder.control(null)
      })
    )

    this.cdRef.detectChanges()

    this.populateSingleVendorDropdownGroupValues(
      newFormVendorObject,
      this.vendorControlList.length - 1
    )

    this.vendorUserControlList.push(this.formBuilder.array([]))
  }

  deleteVendor(index: number) {
    ;(this.vendorUserControlList.at(index) as FormArray).controls.forEach(
      (c) => {
        c.setValue(false)
      }
    )
    this.vendorControlList.removeAt(index)
    this.vendorUserControlList.removeAt(index)
    this.formVendorObjects.splice(index, 1)
    this.setVendorAvailability()
  }

  handleVendorChange() {
    // get the vendor ids from form vendor array (should be old value of vendorControlList)
    const oldVendorIds = _.map(this.formVendorObjects, (venObj) =>
      venObj.vendor ? venObj.vendor._id : null
    )

    // if a vendor was removed or added, we don't need to do anything with the vendor user controls, so just get out
    if (oldVendorIds.length !== this.vendorControlList.length) {
      this.setVendorAvailability()
      return
    }

    _.forEach(oldVendorIds, (oldId: string, index: number) => {
      const newVendorObj = this.vendorControlList.value[index]
      const newId = newVendorObj.vendor

      if (oldId !== newId) {
        // reset user controls at the specified index to []
        this.vendorUserControlList.setControl(index, this.formBuilder.array([]))

        // update formVendorObjects at the specified index and set users to empty array
        this.formVendorObjects[index] = {
          vendor: this.hydrateVendor(newId),
          orderDays: newVendorObj.orderDays,
          deliveryDays: newVendorObj.deliveryDays,
          users: []
        }

        // get the vendorUsers for the vendor at that index
        this.getUsersForSingleVendor(this.formVendorObjects[index]).subscribe(
          () => {
            // build vendor user controls
            this.buildUserCheckboxesForSingleVendor(
              this.formVendorObjects[index],
              index
            )

            // set the values vendor user controls
            this.populateUserCheckboxValuesForSingleVendor(
              this.formVendorObjects[index],
              index
            )

            // reset which vendors are available for selection
            this.setVendorAvailability()
          }
        )
      }
    })
  }

  hydrateVendor(vendorId: string) {
    const vendor = _.find(this.vendors, (ven) => ven._id === vendorId)
    return _.cloneDeep(vendor)
  }

  submitLocationForm(): void {
    this.saving = true

    this.responses = {}
    this.formSubmitAttempted = true
    if (this.locationForm.valid) {
      const locationChanges = _.cloneDeep(this.locationForm.value)
      const userControlValues = _.cloneDeep(locationChanges.vendorUsers)
      locationChanges.vendorUsers = undefined
      delete locationChanges.vendorUsers
      const updatedLocation = _.merge({}, this.location, locationChanges)
      const changedVendorIds = locationChanges.vendors.map((v) => v.vendor)
      const changedUsers = []
      for (const vendor of updatedLocation.vendors) {
        if (!changedVendorIds.includes(vendor.vendor._id || vendor.vendor)) {
          vendor.markedForDeletion = true
          const deletedVendorUsers =
            this.populatedVendorUsers[vendor.vendor._id || vendor.vendor]
          deletedVendorUsers.forEach((vu) => {
            vu.allowedToSeeThisLocation = false
            changedUsers.push(vu)
          })
        }
      }

      if (updatedLocation.orderFlow === 'fleetDefault') {
        updatedLocation.orderFlow = {
          useFleetDefault: true,
          flow: null
        }
      } else {
        updatedLocation.orderFlow = {
          useFleetDefault: false,
          flow: updatedLocation.orderFlowVersion
        }
      }

      _.forEach(this.formVendorObjects, (vendorObj, index) => {
        const currentVendorUsers = vendorObj.users
        const currentVendorUserValues = userControlValues[index]

        _.forEach(currentVendorUsers, (user, subIndex) => {
          user.allowedToSeeThisLocation = !!currentVendorUserValues[subIndex]
          if (
            user.wasAllowedToSeeThisLocation !== user.allowedToSeeThisLocation
          ) {
            changedUsers.push(user)
          }
        })
      })

      updatedLocation.vendors = updatedLocation.vendors.filter(
        (v) => !v.markedForDeletion
      )

      this.saveLocation(updatedLocation)
        .pipe(
          mergeMap(() => {
            return this.saveAllowedUsers(changedUsers)
          })
        )
        .subscribe(
          () => {
            this.handleSuccess('saveSuccess', 'Location saved successfully!')
            this.formSubmitAttempted = false
            this.saving = false
          },
          (err) => {
            this.handleFailure(err)
            this.saving = false
          }
        )
    } else {
      this.responses.formInvalid =
        'Please correct the problems on the form and try again.'
      this.saving = false
    }
  }

  /**
   * Save the current location
   */
  saveLocation(locationObj): Observable<any> {
    this.responses = {}
    this.cleanLocationVendorsArray(locationObj)

    return this.locationService.save(locationObj).pipe(
      mergeMap((response) => {
        if (!this.location._id) {
          UtilsClass.mergeObjByValues(this.location, response)
          this.UsersListViewWidget.modifyObject(
            'user',
            'account',
            this.location._id
          )
        } else {
          this.locationService
            .getLocationById(this.location._id)
            .subscribe((location) =>
              UtilsClass.mergeObjByValues(this.location, location)
            )
        }
        return of(this.location)
      })
    )
  }

  cleanLocationVendorsArray(locationObj) {
    _.forEach(locationObj.vendors, (vendorObj: any) => {
      vendorObj.users = undefined
      delete vendorObj.users
      vendorObj.vendor = vendorObj.vendor._id || vendorObj.vendor
      vendorObj.orderDays = vendorObj.orderDays || undefined
      vendorObj.deliveryDays = vendorObj.deliveryDays || undefined
    })
  }

  saveAllowedUsers(changedUsers: any[]): Observable<any[]> {
    const updates = []

    _.forEach(changedUsers, (chUser) => {
      const locationPresent = chUser.allowedLocations
        ? chUser.allowedLocations.includes(this.location._id)
        : false
      chUser.locationList = _.cloneDeep(chUser.allowedLocations)
      if (chUser.allowedToSeeThisLocation && !locationPresent) {
        chUser.locationList.push(this.location._id)
      } else {
        _.remove(chUser.locationList, (loc: any) => loc === this.location._id)
      }

      updates.push(this.userService.save(chUser))
    })

    const updatesFn = updates.length ? observableForkJoin(updates) : of(null)
    return updatesFn
  }

  orderFlowChanged() {
    const flowName = this.orderFlowCtrl.value
    if (flowName === 'fleetDefault') {
      this.selectedOrderFlow = 'fleetDefault'
    } else if (flowName && flowName !== 'fleetDefault') {
      this.selectedOrderFlow = this.orderFlows.find(
        (flow: any) => flow.name === flowName
      )
    } else {
      this.selectedOrderFlow = null
    }
    this.cdRef.detectChanges()
  }

  goToProductList(): void {
    this.router.navigate([`/product/list/location/${this.location._id}`])
  }

  /**
   * Handle server responses
   * @param response
   * @returns {boolean}
   */
  handleResponse(response: CoreResponse): boolean {
    if (response.offendingKey) {
      switch (response.offendingKey) {
        case 'name':
        case 'abbreviation':
        case 'address.errors.street1':
        case 'address.errors.state':
        case 'address.errors.zip':
        case 'address.errors.timezone':
        case 'phone':
        case 'fax':
        case 'email':
        case 'deliveryDays':
        case 'orderDays':
        case 'vendors':
          this.responses[response.offendingKey] = response.message
          return true
        default:
          if (response.offendingKey.includes('vendors.')) {
            const offKey = response.offendingKey
            const badIndexPosition = offKey.indexOf('.') + 1
            const badKeyPosition = offKey.indexOf('.', badIndexPosition) + 1
            const badIndex = offKey.substring(
              badIndexPosition,
              badKeyPosition - 1
            )
            const badKey = offKey.substring(badKeyPosition)
            const responseKey = badKey + badIndex // like orderDay1, deliveryDay2, etc..
            this.responses[responseKey] = response.message
            return true
          }
          return false
      }
    } else {
      switch (response.type) {
        case 'saveSuccess':
        case 'updateSuccess':
        case 'deleteSuccess':
        case 'formInvalid':
          this.responses[response.type] = response.message
          return true
        default:
          return false
      }
    }
  }

  // TODO put these somewhere it can be reused
  /**
   * Custom email validator, because for now angular shows empty string as invalid
   * see (https://github.com/angular/angular/issues/16183)
   * This validator returns error if angular email validator fails but only if value is not empty string
   * @param control
   * @returns {any}
   */
  private customEmailValidator(control: AbstractControl): {
    [key: string]: any
  } {
    const emailError = Validators.email(control)
    if (control.value && emailError) {
      return { email: {} }
    }

    return null
  }

  private customUSPhoneValidator(control: AbstractControl): {
    [key: string]: any
  } {
    const phoneRegExp = new RegExp(/\d{3}-\d{3}-\d{4}/)
    return Validators.pattern(phoneRegExp)(control)
  }
}
