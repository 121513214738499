import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreLayoutComponent } from '../../core-layout/client/core-layout.component'
import { LoginGuard } from '../../core-user/client/login.guard'
import { FleetComponent } from './fleet.component'
// import { FleetCorporateDetailComponent }    from './fleet-corporate-detail.component';

const fleetRoutes: Routes = [
  {
    path: '',
    component: CoreLayoutComponent,
    canActivateChild: [LoginGuard],
    canActivate: [LoginGuard],
    children: [{ path: 'fleet', component: FleetComponent }]
  }
]
@NgModule({
  imports: [RouterModule.forChild(fleetRoutes)],
  exports: [RouterModule]
})
export class FleetRoutingModule {}
