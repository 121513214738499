import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CoreUserService } from '../../../core-user/client/core-user.service'
import { LocationService } from '../../../location/client/location.service'
import { VendorService } from '../../../vendor/client/vendor.service'

@Component({
  selector: 'app-account-switcher',
  templateUrl: 'account-switcher.component.html',
  styleUrls: ['account-switcher.component.scss']
})
export class AccountSwitcherComponent implements OnInit {
  isActive = false
  loggedInUser: any = {}
  allowedLocations: { name: string; _id: string }[] = []
  allowedVendors: { name: string; _id: string }[] = []
  showLocations = false
  showVendors = false
  showFleets = false
  isDefaultAccount = true

  constructor(
    private router: Router,
    private coreUserService: CoreUserService,
    private locationService: LocationService,
    private vendorService: VendorService
  ) {}

  ngOnInit() {
    this.coreUserService.getLoggedInUser().subscribe((user) => {
      this.loggedInUser = user
      const accountId = (
        user && user.account ? user.account._id || user.account : 'null'
      ).toString()
      const defaultAccountId = (
        user && user.defaultAccount
          ? user.defaultAccount._id || user.defaultAccount
          : 'null'
      ).toString()
      this.isDefaultAccount = accountId === defaultAccountId
      this.showLocations =
        (user.accountType === 'Fleet' || user.accountType === 'Location') &&
        user.canPlaceOrder
      this.showVendors = user.accountType === 'Stm'

      if (this.showLocations) {
        this.locationService.getAllowedLocations().subscribe((locations) => {
          this.showLocations =
            locations.length > 1 ||
            (locations.length === 1 &&
              locations[0]._id.toString() !==
                (user.account._id || user.account).toString())
          this.allowedLocations = locations.map((l) => {
            return {
              name: l.name,
              _id: l._id
            }
          })
          this.allowedLocations.sort((a, b) => {
            if (a.name > b.name) {
              return 1
            }
            if (a.name < b.name) {
              return -1
            }
            return 0
          })
        })
      }

      if (this.showVendors) {
        this.vendorService.getVendors().subscribe((vendors) => {
          this.showVendors =
            vendors.length > 1 ||
            (vendors.length === 1 &&
              vendors[0]._id.toString() !==
                (user.account._id || user.account).toString())
          this.allowedVendors = vendors.map((v) => {
            return {
              name: v.name,
              _id: v._id
            }
          })
          this.allowedVendors.sort((a, b) => {
            if (a.name > b.name) {
              return 1
            }
            if (a.name < b.name) {
              return -1
            }
            return 0
          })
        })
      }
    })
  }

  switchAccount(accountType, location) {
    this.coreUserService
      .switchAccount(accountType, location._id)
      .subscribe((user) => {
        this.router.navigate(['/redirect'], {
          queryParams: { returnUrl: '/dashboard' }
        })
      })
  }

  switchToDefaultAccount() {
    this.coreUserService.switchToDefaultAccount().subscribe((user) => {
      this.router.navigate(['/redirect'], {
        queryParams: { returnUrl: '/dashboard' }
      })
    })
  }
}
