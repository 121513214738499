// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { IEnvironmentConfig } from './environment.interface'

const baseUrl = 'https://tom.demo.stmtires.com'
const apiUrl = 'https://tom.dev.stmtires.com'

export const environment: IEnvironmentConfig = {
  appName: 'Tire Order Manager',
  environmentName: 'Staging',
  production: false, // setting to true may cause real emails/texts to be sent on server start
  socketIOUrl: apiUrl,
  apiUrl,

  keycloak: {
    API_ROOT_URL: 'https://id.stmtires.com',
    callbackRoot: baseUrl,
    realm: 'STM_Dev',
    clientID: 'STM_TOM',
    strategy: 'openidconnect',
    scope: ['email', 'profile'],
    featureName: 'tom' // this must be present in the keycloak user's stm_features array in order to use this app
  }
}
