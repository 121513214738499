<div class="dashboard-container">
  <div *ngIf="showCreateOrder" class="action-buttons-column">
    <mat-card class="action-buttons-container mat-elevation-z4">
      <span *ngIf="orderDays">
        <p>Order Day(s):</p>
        <h4>{{ orderDays }}</h4>
      </span>
      <span *ngIf="deliveryDays">
        <p>Delivery Day(s):</p>
        <h4>{{ deliveryDays }}</h4>
      </span>
      <button
        mat-button
        mat-raised-button
        class="blue-btn"
        color="primary"
        (click)="goToNewOrder()"
      >
        <div class="title">New Order</div>
      </button>

      <button
        mat-button
        mat-raised-button
        class="red-btn"
        color="accent"
        (click)="noOrderClicked()"
        *ngIf="
          coreUser.account.fleet &&
          coreUser.account.fleet.options &&
          coreUser.account.fleet.options.hasSchedule
        "
      >
        <div class="title">No Order</div>
        <div class="secondary-label">(No Order / No Pickup)</div>
      </button>

      <button
        mat-button
        mat-raised-button
        class="red-btn"
        color="accent"
        (click)="pickupOnlyClicked()"
      >
        <div class="title">Pickup Only</div>
        <div class="secondary-label">(No Order / Need Pickup)</div>
      </button>
    </mat-card>
  </div>
  <div
    [ngClass]="{
      'list-column-100': useFullScreenWidth,
      'list-column-80': !useFullScreenWidth
    }"
  >
    <div class="dashboard-top-row">
      <div class="dashboard-top-row-left">
        <div class="datatable-legend">
          <div class="datatable-legend-entry">
            <div class="datatable-legend-color-box legend-rush"></div>
            <div class="datatable-legend-description">Rush Order</div>
          </div>
          <div class="datatable-legend-entry">
            <div class="datatable-legend-color-box legend-no-order"></div>
            <div class="datatable-legend-description">No Order/Pickup Only</div>
          </div>
          <div class="datatable-legend-entry">
            <div class="datatable-legend-color-box legend-fleet-check"></div>
            <div class="datatable-legend-description">Fleet Check</div>
          </div>
          <div class="datatable-legend-entry">
            <div class="datatable-legend-color-box legend-service-call"></div>
            <div class="datatable-legend-description">Service Call</div>
          </div>
        </div>
      </div>
      <div class="dashboard-top-row-right">
        <button
          *ngIf="showFleetCheckButton"
          mat-button
          mat-raised-button
          class="fleet-check-btn"
          color="default"
          (click)="fleetCheckClicked()"
        >
          <div class="title">Order Fleet Check</div>
        </button>
        <button
          *ngIf="showServiceCallButton"
          mat-button
          mat-raised-button
          class="fleet-check-btn"
          color="default"
          (click)="serviceCallClicked()"
        >
          <div class="title">New Service Call</div>
        </button>
      </div>
    </div>
    <app-datatable
      [config]="userActionOrdersConfig"
      #userActionTable
    ></app-datatable>
    <app-datatable
      [config]="noUserActionOrdersConfig"
      #noUserActionTable
    ></app-datatable>
    <app-datatable
      [config]="closedOrdersConfig"
      #closedOrdersTable
    ></app-datatable>
  </div>

  <div *ngIf="showNaughtyList" class="column-container action-panel-column">
    <img
      *ngIf="showFleetLogo"
      [src]="coreUserFleetLogo"
      class="mat-avatar"
      style="height: 100px"
    />
    <div class="naughty-list-container">
      <app-naughty-list></app-naughty-list>
    </div>
  </div>

  <div *ngIf="!showNaughtyList" class="column-container action-panel-column">
    <img
      *ngIf="showFleetLogo"
      [src]="coreUserFleetLogo"
      class="mat-avatar"
      style="height: 100px"
    />
  </div>
</div>
