<div class="datatable-row" [ngClass]="rowConfig.cssClasses">
  <div
    *ngIf="rowConfig.includeActionButtons"
    class="datatable-action-button-column"
    [ngStyle]="{ width: dataTableService.getActionBtnColumnWidth() }"
  >
    <button
      mat-button
      mat-raised-button
      *ngIf="!dataTableService.useMobileLayout()"
      (click)="rowConfig.row.actionBtn?.action(rowConfig.row)"
      class="action-btn-regular"
      [ngStyle]="{ 'background-color': rowConfig.row.actionBtn?.btn.color }"
    >
      <span>{{ rowConfig.row.actionBtn?.label }}</span>
    </button>
    <button
      mat-mini-fab
      *ngIf="dataTableService.useMobileLayout()"
      (click)="rowConfig.row.actionBtn?.action(rowConfig.row)"
      class="action-btn-fab"
      [ngStyle]="{ 'background-color': rowConfig.row.actionBtn?.btn.color }"
    >
      <mat-icon>{{ rowConfig.row.actionBtn?.btn.icon }}</mat-icon>
    </button>
  </div>
  <div
    class="datatable-table-data-column"
    [ngStyle]="{
      width: 'calc(100% - ' + dataTableService.getActionBtnColumnWidth() + ')'
    }"
  >
    <div
      *ngFor="let column of rowConfig.columns"
      [ngStyle]="{ width: dataTableService.getAdjustedWidth(column) }"
      class="datatable-column-item"
    >
      <div *ngIf="dataTableService.hideColumn(column) === false">
        <img
          class="datatable-column-img"
          *ngIf="column.type === 'image'"
          [src]="getColumnPropertyValue(column)"
          alt="{{ getColumnAltText(column) }}"
          title="{{ getColumnAltText(column) }}"
        />
        <span *ngIf="column.type !== 'image'">
          {{ getColumnPropertyValue(column) }}
        </span>
      </div>
    </div>
  </div>
  <div class="datatable-rush-order-icon-column">
    <div
      class="rush-order-icon-container"
      *ngIf="rowConfig.row.orderType === 'RUSH'"
    >
      <mat-icon class="rush-order-icon">whatshot</mat-icon>
      <div class="yellow-underlay"></div>
    </div>
  </div>
</div>
