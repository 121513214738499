// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

interface FleetCheckItem {
  hasDR: boolean
  woNumber: string
  drNumber: string
  servicePONumber: string
  tirePONumber: string
}

@Component({
  selector: 'app-fleet-check-items',
  templateUrl: 'fleet-check.component.html',
  styleUrls: ['../order.component.scss', './fleet-check.component.scss'],
  providers: []
})
export class FleetCheckComponent extends OrderBaseComponent implements OnInit {
  woInputDisabled = false
  poInputDisabled = false
  fleetCheckHasItemsOnStart: boolean

  constructor() {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      this.order.orderType === 'FleetCheck' &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes('fleet-check-items'))
  }

  setOrder(order) {
    super.setOrder(order)
    this.poInputDisabled = this.coreUser.accountType !== 'Fleet'
    this.woInputDisabled = this.coreUser.accountType !== 'Stm'
    if (this.fleetCheckHasItemsOnStart === undefined) {
      this.fleetCheckHasItemsOnStart =
        this.order.items && this.order.items.length > 0
    }
    // if (this.fleetCheckHasItemsOnStart) {
    //     this.woInputDisabled = true;
    // }
    if (this.order.items) {
      this.order.items.forEach((item: FleetCheckItem) => {
        item.hasDR = !!item.drNumber
      })
    }
  }

  addFleetCheckItem(): void {
    this.order.items.push({
      hasDR: false,
      woNumber: null,
      drNumber: null,
      servicePONumber: null,
      tirePONumber: null
    })
  }

  removeFleetCheckItem(index: number): void {
    this.order.items.splice(index, 1)
  }

  onDRNumberEntered(item: FleetCheckItem) {
    if (item.drNumber && item.drNumber !== '') {
      item.hasDR = true
    }
  }
}
