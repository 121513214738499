import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'brandArray' })
export class BrandArrayPipe implements PipeTransform {
  transform(brands: Array<any>, pType: string): Array<any> {
    // eslint-disable-next-line array-callback-return
    return brands.filter((brand) => {
      if (pType === 'FORKLIFT' || pType === 'WHEEL') {
        if (pType === brand.name.toUpperCase()) {
          return brand
        }
      } else {
        return brand
      }
    })
  }
}
