import { mergeMap } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { CoreUserService } from './core-user.service'

@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild {
  constructor(
    protected coreUserService: CoreUserService,
    protected router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.coreUserService.isLoggedIn().pipe(
      mergeMap((loggedIn) => {
        if (loggedIn === false) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url }
          })
          return of(false)
        } else {
          return of(true)
        }
      })
    )
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state)
  }
}
