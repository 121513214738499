<div *ngIf="isVisible" class="order-part w-full">
  <div *ngIf="order">
    <app-container-widget title="Fleet Check Items">
      <mat-card class="fleet-check-container" fxFlex>
        <ul>
          <li
            *ngFor="let item of order?.items; let idx = index"
            style="list-style: none"
            class="fleet-check-item"
            fxLayout="column"
          >
            <div class="fleet-check-item-container" fxLayout="row" fxFlex>
              <div fxFlex="90" fxLayout="column" fxLayout.gt-xs="row">
                <div
                  fxFlex.gt-xs="50%"
                  fxLayout="column"
                  fxLayoutGap="0"
                  fxLayoutAlign="middle start"
                >
                  <div
                    fxFlex
                    fxLayout="row"
                    fxLayoutGap="10px"
                    fxLayoutAlign="start center"
                  >
                    <div>WO #</div>
                    <div *ngIf="woInputDisabled">{{ item.woNumber }}</div>
                    <div *ngIf="!woInputDisabled">
                      <input
                        class="fleet-check-item-input"
                        [(ngModel)]="item.woNumber"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="item.hasDR || !woInputDisabled"
                    fxFlex
                    fxLayout="row"
                    fxLayoutGap="10px"
                    fxLayoutAlign="start center"
                  >
                    <div class="dr-num-label">DR #&nbsp;</div>
                    <div *ngIf="woInputDisabled && item.hasDR">
                      {{ item.drNumber }}
                    </div>
                    <div *ngIf="!woInputDisabled">
                      <input
                        class="fleet-check-item-input"
                        [(ngModel)]="item.drNumber"
                        (change)="onDRNumberEntered(item)"
                      />
                    </div>
                  </div>
                </div>
                <div fxFlex.gt-xs="50%" fxLayout="column" fxLayoutGap="0">
                  <div
                    fxFlex
                    fxLayout="row"
                    fxLayoutGap="10px"
                    fxLayoutAlign="start center"
                  >
                    <div>Service PO #</div>
                    <div *ngIf="poInputDisabled">
                      {{ item.servicePONumber }}
                    </div>
                    <div *ngIf="!poInputDisabled">
                      <input
                        class="fleet-check-item-input"
                        [(ngModel)]="item.servicePONumber"
                        placeholder="SERVICE PO#"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="!woInputDisabled || item.hasDR"
                    [style.visibility]="item.hasDR ? 'visible' : 'hidden'"
                    fxFlex
                    fxLayout="row"
                    fxLayoutGap="10px"
                    fxLayoutAlign="start center"
                  >
                    <div class="dr-num-label">
                      Tire PO #&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div *ngIf="poInputDisabled">{{ item.tirePONumber }}</div>
                    <div *ngIf="!poInputDisabled">
                      <input
                        class="fleet-check-item-input"
                        [(ngModel)]="item.tirePONumber"
                        placeholder="TIRE PO#"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                fxFlex="10%"
                fxLayoutAlign="center center"
                *ngIf="coreUser.accountType === 'Stm' && !woInputDisabled"
              >
                <button
                  (click)="removeFleetCheckItem(idx)"
                  mat-mini-fab
                  class="remove-fleet-check-btn"
                >
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div
          *ngIf="
            coreUser.accountType === 'Stm' &&
            !woInputDisabled &&
            !order?.items?.length
          "
          class="no-items-msg"
        >
          No items have been added. Press the + button to begin.
        </div>
        <div
          *ngIf="coreUser.accountType === 'Stm' && !woInputDisabled"
          class="add-fleet-check-btn-container"
        >
          <button
            (click)="addFleetCheckItem()"
            mat-mini-fab
            class="add-fleet-check-btn"
          >
            <mat-icon>add</mat-icon>
          </button>
          Add Items
        </div>
      </mat-card>
    </app-container-widget>
  </div>
</div>
