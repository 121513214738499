'use strict'

// node_modules imports
import { Component, OnInit, ViewChild } from '@angular/core'
import * as _ from 'lodash'
import { ListViewWidgetComponent } from '../../core/client/object-list-with-dynamic-detail/list-view-widget.component'
import { CoreUserComponent } from '../../core-user/client/core-user.component'
import { CoreUserService } from '../../core-user/client/core-user.service'
import { FleetService } from '../../fleet/client/fleet.service'
import { VendorService } from '../../vendor/client/vendor.service'
import { VendorComponent } from '../../vendor/client/vendor.component'
import { FleetComponent } from '../../fleet/client/fleet.component'
import { ManufacturerComponent } from '../../manufacturer/client/manufacturer.component'
import { ManufacturerService } from '../../manufacturer/client/manufacturer.service'

const CORPROATE_STORE_NUMBER = 99
@Component({
  selector: 'app-system-management',
  templateUrl: './system-management.component.html',
  styleUrls: ['./system-management.component.scss'],
  providers: []
})
export class SystemManagementComponent implements OnInit {
  // Create list view instances
  @ViewChild('users', { static: true })
  UsersListViewWidget: ListViewWidgetComponent

  @ViewChild('vendors', { static: true })
  VendorsListViewWidget: ListViewWidgetComponent

  @ViewChild('fleets', { static: true })
  FleetsListViewWidget: ListViewWidgetComponent

  @ViewChild('manufacturers', { static: true })
  ManufacturersListViewWidget: ListViewWidgetComponent

  data: any
  // TODO change this to any[] when listviewWidget modifyObject function takes any as last param
  stmCorporateKeycloakUsers: any = []
  stmCorporateSavedUsers: any = []

  constructor(
    private userService: CoreUserService,
    private vendorService: VendorService,
    private fleetService: FleetService,
    private manufacturerService: ManufacturerService
  ) {}

  ngOnInit() {
    this.setData()
    this.buildDynamicComponents()
  }

  buildDynamicComponents() {
    // User Component
    this.UsersListViewWidget.setTitle('STM Corporate Users')
    // this.UsersListViewWidget.disableCreateNew();
    this.UsersListViewWidget.setListArray([], 'displayName', ['person'])
    this.UsersListViewWidget.setViewComponent(CoreUserComponent, 'user')
    this.UsersListViewWidget.modifyObject('user', 'accountType', 'Stm')

    // Vendor Component
    this.VendorsListViewWidget.setTitle('Vendors')
    this.VendorsListViewWidget.setListArray([], 'name', [
      'store_mall_directory'
    ])
    this.VendorsListViewWidget.setViewComponent(VendorComponent, 'vendor')

    // Fleet Component
    this.FleetsListViewWidget.setTitle('Fleets')
    this.FleetsListViewWidget.setListArray([], 'name', ['local_shipping'])
    this.FleetsListViewWidget.setViewComponent(FleetComponent, 'fleet')

    // Manufacturer Component
    this.ManufacturersListViewWidget.setTitle('Brands')
    this.ManufacturersListViewWidget.setListArray([], 'name', [
      'store_mall_directory'
    ])
    this.ManufacturersListViewWidget.setViewComponent(
      ManufacturerComponent,
      'manufacturer'
    )
  }

  setData() {
    this.getUsers()
    this.getVendors()
    this.getFleets()
    this.getManufacturers()
    this.getVendorUsers()
    this.getStmCorporateUsers()
  }

  getUsers() {
    this.userService
      .getUsers({ defaultAccountType: 'Stm' })
      .subscribe((users) => {
        this.UsersListViewWidget.viewModelArray = users
      })
  }

  getStmCorporateUsers() {
    this.userService
      .getStmStoreUsers(CORPROATE_STORE_NUMBER)
      .subscribe((stmStoreUsers) => {
        this.stmCorporateKeycloakUsers = _.map(<any[]>stmStoreUsers, (u) => {
          return {
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            username: u.email,
            notificationRoute: {
              textMessage: false,
              email: false
            },
            alreadyInKeycloak: true,
            account: null, // not id because we want the populated object
            accountType: 'Stm'
          }
        })

        // We only want to show stm keycloak users that don't already exist in our system
        this.removeSavedUsersFromStmUsers()
        this.UsersListViewWidget.modifyObject(
          'storeInfo',
          'storeUsers',
          this.stmCorporateKeycloakUsers
        )
        this.UsersListViewWidget.modifyObject(
          'storeInfo',
          'storeNumber',
          CORPROATE_STORE_NUMBER.toString()
        )
      })
  }

  // We only want to show stm keycloak users that don't already exist in our system
  removeSavedUsersFromStmUsers() {
    if (this.stmCorporateKeycloakUsers && this.stmCorporateSavedUsers) {
      _.remove(<any[]>this.stmCorporateKeycloakUsers, (u) => {
        return _.find(
          <any[]>this.stmCorporateSavedUsers,
          (su) => su.email === u.email
        )
      })
    }
  }

  getVendorUsers() {
    this.userService
      .getUsers({ defaultAccountType: 'Stm' })
      .subscribe((users) => {
        this.UsersListViewWidget.viewModelArray = users
        this.stmCorporateSavedUsers = users
        this.removeSavedUsersFromStmUsers()
      })
  }

  getVendors() {
    this.vendorService.getVendors().subscribe((vendors) => {
      this.VendorsListViewWidget.viewModelArray = vendors
    })
  }

  getFleets() {
    this.fleetService.getFleets().subscribe((fleets) => {
      this.FleetsListViewWidget.viewModelArray = fleets
    })
  }

  getManufacturers() {
    this.manufacturerService.getManufacturers().subscribe((manufacturers) => {
      this.ManufacturersListViewWidget.viewModelArray = manufacturers
    })
  }
}
