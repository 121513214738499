// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core'
import { CoreResponse } from '../../../core/client/client-error-handler/error-handler.class'
import { OrderBaseComponent } from './order-base.class'

@Component({
  selector: 'app-order-paperwork-rejection',
  templateUrl: './order-paperwork-rejection.component.html',
  styleUrls: [
    '../order.component.scss',
    './order-vendor-denial.component.scss'
  ],
  providers: []
})
export class OrderPaperworkRejectionComponent
  extends OrderBaseComponent
  implements OnInit
{
  isPaperworkRejected = false
  rejectReason: string
  rejector: string
  rejectTime: Date

  constructor() {
    super()
  }

  handleResponse(error: CoreResponse): boolean {
    if (error.offendingKey) {
      switch (error.offendingKey) {
        case 'firstName':
          return true
        default:
          return false
      }
    }
    return false
  }

  ngOnInit() {
    super.ngOnInit()
  }

  setUserVisibility() {
    this.isVisible =
      this.order &&
      !this.order.isNoOrderNoPickup &&
      !this.order.isNoOrderWithPickup &&
      (!this.order.state.blacklistedOrderParts ||
        !this.order.state.blacklistedOrderParts.includes(
          'paperwork-rejection'
        )) &&
      this.isPaperworkRejected &&
      (this.coreUser.accountType === 'Stm' ||
        this.coreUser.accountType === 'Vendor')
  }

  setOrder(order) {
    super.setOrder(order)

    const history = (this.order && this.order.stateHistory) || []

    // We only want to show if rejection was the last or next to loast thing to happen
    // Because if we get past that point, STM has approved and it's no longer rejected
    if (history.length) {
      const latest = history[0]
      const nextToLast = history.length > 1 ? history[1] : null
      let rejectedHistoryItem
      switch (latest.stateDescription) {
        case 'Reject Paperwork':
          rejectedHistoryItem = latest
          break
        case 'Submit Paperwork/Confirm Delivery':
          if (
            nextToLast &&
            nextToLast.stateDescription === 'Reject Paperwork'
          ) {
            rejectedHistoryItem = nextToLast
          }
          break
        default:
          break
      }

      if (rejectedHistoryItem) {
        this.isPaperworkRejected = true
        this.rejectReason =
          rejectedHistoryItem.description &&
          rejectedHistoryItem.description.length
            ? rejectedHistoryItem.description[0]
            : 'Unknown Reason'
        this.rejector =
          rejectedHistoryItem.user && rejectedHistoryItem.user.displayName
            ? rejectedHistoryItem.user.displayName
            : 'Unknown User'
        this.rejectTime = rejectedHistoryItem.date
      }
    }

    this.setUserVisibility()
  }
}
