import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import {
  CoreResponse,
  CoreResponseHandler
} from '../../core/client/client-error-handler/error-handler.class'

import { UtilsClass } from '../../core/client/utils/utils.component'
import { ManufacturerService } from './manufacturer.service'

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss'],
  providers: []
})
export class ManufacturerComponent
  extends CoreResponseHandler
  implements OnInit
{
  manufacturer: any = {}
  responses: any = {}
  manufacturerForm: FormGroup

  constructor(
    private manufacturerService: ManufacturerService,
    private fb: FormBuilder
  ) {
    super()
    this.manufacturer = this.newDefaultManufacturer()
  }

  ngOnInit() {
    this.manufacturerForm = this.fb.group({})
  }

  save(): void {
    this.responses = {}
    this.manufacturerService.save(this.manufacturer).subscribe(
      (response) => {
        if (!this.manufacturer._id) {
          UtilsClass.mergeObjByValues(this.manufacturer, response)
        } else {
          this.manufacturerService
            .getManufacturerById(this.manufacturer._id)
            .subscribe((manufacturer) =>
              UtilsClass.mergeObjByValues(this.manufacturer, manufacturer)
            )
        }

        this.handleSuccess('saveSuccess', 'Manufacturer saved successfully!')
      },
      (error) => {
        this.handleFailure(error)
      }
    )
  }

  handleUploadedImage(imageUrl: any) {
    this.manufacturer.logo = imageUrl
  }

  newDefaultManufacturer() {
    const newManufacturer: object = {
      name: ''
    }

    return newManufacturer
  }

  handleResponse(response: CoreResponse): boolean {
    if (response.offendingKey) {
      switch (response.offendingKey) {
        case 'name':
          this.responses[response.offendingKey] = response.message
          return true
        default:
          return false
      }
    } else {
      switch (response.type) {
        case 'saveSuccess':
        case 'updateSuccess':
        case 'deleteSuccess':
          this.responses[response.type] = response.message
          return true
        default:
          return false
      }
    }
  }
}
