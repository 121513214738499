'use strict'

// node_modules imports
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms' // For forms or ngmodel wont work

// local imports
// "app" modules

// Services & Guards
import { CoreModule } from '../../core/client/core-module'
import { MaterialComponentsModule } from '../../material-components.module'
import { SystemManagementService } from './system-management.service'

// Routers
import { SystemManagementRoutingModule } from './system-management-routing.module'

// Components
import { SystemManagementComponent } from './system-management.component'

@NgModule({
  declarations: [
    SystemManagementComponent
    // UserManagementDetailComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SystemManagementRoutingModule,
    CoreModule,
    MaterialComponentsModule
  ],
  providers: [SystemManagementService],
  exports: [SystemManagementComponent]
})
export class SystemManagementModule {}
