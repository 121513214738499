// tslint:disable:no-access-missing-member
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core'
import { DataTableColumnConfig } from './dataTableColumnConfig.class'
import { DataTableConfig } from './dataTableConfig.class'
import { DataTableService } from './datatable.service'

interface DataTableRowConfig {
  row: any
  includeActionButtons: boolean
  columns: DataTableColumnConfig[]
  cssClasses: string[]
  idField: string
}

@Component({
  selector: 'app-datatable-row',
  templateUrl: 'datatable-row.component.html',
  styleUrls: ['datatable.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class DataTableRowComponent implements OnInit {
  @Input() rowConfig: DataTableRowConfig
  @Input() tableConfig: DataTableConfig

  constructor(public dataTableService: DataTableService) {}

  ngOnInit() {
    if (this.tableConfig.rowClassesFunction) {
      this.rowConfig.cssClasses = this.tableConfig.rowClassesFunction(
        this.rowConfig.row
      )
    }
  }

  getColumnPropertyValue(column): any {
    const pValue = this.rowConfig.row[column.field]

    if (column.formatFunction) {
      return column.formatFunction(pValue)
    } else {
      return pValue
    }
  }

  getColumnAltText(column): any {
    if (!column.altText) {
      return ''
    }

    return this.rowConfig.row[column.altText]
  }
}
