import { FormControl, ValidatorFn, Validators } from '@angular/forms'

/**
 * Zip code validator. Allows empty value, #####-#### or #####
 */
export const ZIPCODE_VALIDATOR_FN: ValidatorFn = (control: FormControl) => {
  if (!control.value) {
    return null
  }

  return Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)(control)
}
