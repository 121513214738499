<div [hidden]="viewLocationList || viewUserList">
  <div class="info-card">
    <mat-toolbar>
      {{ fleet._id ? 'Edit' : 'New' }} Fleet
      <button
        *ngIf="fleet._id"
        mat-raised-button
        class="black"
        (click)="viewLocationListComponent()"
      >
        View Locations
      </button>
      <button
        *ngIf="fleet._id"
        mat-raised-button
        class="black"
        (click)="viewUserListComponent()"
      >
        View Fleet Users
      </button>
      <button
        *ngIf="fleet._id"
        mat-raised-button
        class="black"
        (click)="goToProductList()"
      >
        Set Product Catalog
      </button>
    </mat-toolbar>
    <div class="fleet-container">
      <form
        class="form"
        novalidate
        (ngSubmit)="save()"
        [formGroup]="fleetForm"
        fxLayout="column"
      >
        <div class="system-management-logo" fxLayout="row">
          <div class="image-box">
            <div class="logo-placeholder" *ngIf="!fleet.logo">Logo</div>
            <img height="100" [src]="fleet.logo" *ngIf="fleet.logo" />
          </div>
          <app-file-uploader
            autoUpload="true"
            identity="img:logo"
            (fileUploaded)="handleUploadedImage($event)"
          >
          </app-file-uploader>
        </div>

        <div fxLayout="row">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Name"
              [(ngModel)]="fleet.name"
              [ngModelOptions]="{ standalone: true }"
              required
            />
            <mat-hint
              *ngIf="responses?.name"
              class="error-message"
              align="start"
              >{{ responses?.name }}</mat-hint
            >
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Abbreviation"
              [(ngModel)]="fleet.abbreviation"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.abbreviation"
              class="error-message"
              align="start"
              >{{ responses?.abbreviation }}</mat-hint
            >
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Address"
              [(ngModel)]="fleet.address.street1"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.address?.responses?.street1"
              class="error-message"
              align="start"
              >{{ responses?.address?.responses?.street1 }}</mat-hint
            >
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="City"
              [(ngModel)]="fleet.address.city"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.address?.responses?.city"
              class="error-message"
              align="start"
              >{{ responses?.address?.responses?.city }}</mat-hint
            >
          </mat-form-field>

          <mat-form-field class="state-input-container">
            <input
              matInput
              type="text"
              placeholder="State"
              [(ngModel)]="fleet.address.state"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.address?.responses?.state"
              class="error-message"
              align="start"
              >{{ responses?.address?.responses?.state }}</mat-hint
            >
          </mat-form-field>
          <mat-form-field class="zip-input-container">
            <input
              matInput
              type="text"
              placeholder="Zip"
              [(ngModel)]="fleet.address.zip"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.address?.responses?.zip"
              class="error-message"
              align="start"
              >{{ responses?.address?.responses?.zip }}</mat-hint
            >
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field fxFlex="50">
            <mat-select
              placeholder="Timezone"
              [(ngModel)]="fleet.address.timezone"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-option
                *ngFor="let timezone of timezones"
                [value]="timezone.value"
              >
                {{ timezone.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Phone"
              [(ngModel)]="fleet.phone"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.phone"
              class="error-message"
              align="start"
              >{{ responses?.phone }}</mat-hint
            >
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Fax"
              [(ngModel)]="fleet.fax"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.fax"
              class="error-message"
              align="start"
              >{{ responses?.fax }}</mat-hint
            >
          </mat-form-field>
        </div>

        <div fxLayout="row">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Email"
              [(ngModel)]="fleet.email"
              [ngModelOptions]="{ standalone: true }"
            />
            <mat-hint
              *ngIf="responses?.email"
              class="error-message"
              align="start"
              >{{ responses?.email }}</mat-hint
            >
          </mat-form-field>
        </div>
        <div>
          <h3>Fleet Options</h3>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex="50">
            <mat-select
              placeholder="Default Order Flow"
              [(ngModel)]="selectedOrderFlow"
              [ngModelOptions]="{ standalone: true }"
              (selectionChange)="selectedOrderFlowChanged()"
              required
            >
              <mat-option *ngFor="let flow of orderFlows" [value]="flow">
                {{ flow.name }}
              </mat-option>
            </mat-select>
            <mat-hint
              *ngIf="responses?.defaultOrderFlow"
              class="error-message"
              align="start"
              >{{ responses?.defaultOrderFlow }}</mat-hint
            >
          </mat-form-field>
          <mat-form-field
            *ngIf="selectedOrderFlow && fleet.defaultOrderFlow"
            fxFlex="50"
          >
            <mat-select
              placeholder="Version"
              [(ngModel)]="fleet.defaultOrderFlow._id"
              [ngModelOptions]="{ standalone: true }"
              required
            >
              <mat-option
                *ngFor="let version of selectedOrderFlow.versions"
                [value]="version._id"
              >
                Version {{ version.versionNumber }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Has Schedule"
            [(ngModel)]="fleet.options.hasSchedule"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Has Scheduled Order/Delivery Days
          </mat-checkbox>
          <mat-icon
            title="If selected, an order day and delivery day will be required for locations and estimated delivery date will automatically be calculated on each order."
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Require Service Work Order"
            [(ngModel)]="fleet.options.requireServiceWO"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Require Service Work Order
          </mat-checkbox>
          <mat-icon
            title="If selected, tire dealers will be required to upload a service work order before confirming delivery."
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Require Retread Work Order"
            [(ngModel)]="fleet.options.requireRetreadWO"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Require Retread Work Order
          </mat-checkbox>
          <mat-icon
            title="If selected, tire dealers will be required to upload a retread work order before confirming delivery."
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Require STM Paperwork Validation"
            [(ngModel)]="fleet.options.requireSTMPaperworkValidation"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Require STM Paperwork Validation
          </mat-checkbox>
          <mat-icon
            title="If selected, fleet users will not be able to access tire dealer paperwork until an STM admin has marked it to be viewed by client. If not selected, fleet users will see paperwork as soon as it is uploaded."
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="One PO per manufacturer?"
            [(ngModel)]="fleet.options.onePOPerManufacturer"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            One PO Per Manufacturer
          </mat-checkbox>
          <mat-icon
            title="If selected, each manufacturer on the order will have a spot for a PO # to be entered."
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Allow General PO for forklift only orders?"
            [(ngModel)]="fleet.options.allowGeneralPOForForkliftOrders"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Allow General PO for forklift only orders?
          </mat-checkbox>
          <mat-icon
            title="If selected, all forklift orders will be presented with an option to enter a purchase order number."
            class="info-icon"
          >
            info_outline
          </mat-icon>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Mounted wheels program"
            [(ngModel)]="fleet.options.mountedWheelsProgram"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Mounted Wheels Program
          </mat-checkbox>
          <mat-icon
            title="If selected, users will have to select a wheel with every tire and no unmounted wheels will be sold."
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Show Used Inventory"
            [(ngModel)]="fleet.options.showUsedInventoryInput"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Show Used Inventory
          </mat-checkbox>
          <mat-icon
            title="If selected, inputs are shown to enter number of used inventory items on the order"
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-checkbox
            placeholder="Show Picked Up Casings"
            [(ngModel)]="fleet.options.showPickedUpCasingsInput"
            [ngModelOptions]="{ standalone: true }"
            required
          >
            Show Picked Up Casings
          </mat-checkbox>
          <mat-icon
            title="If selected, dealers will be able to input number of casings picked up from customer"
            class="info-icon"
            >info_outline</mat-icon
          >
        </div>
        <div fxLayout="column" class="fleet-save-button">
          <mat-hint *ngIf="responses?.saveSuccess" class="success-message">{{
            responses?.saveSuccess
          }}</mat-hint>
          <div>
            <button mat-raised-button [disabled]="saving">
              <span *ngIf="!saving">{{ fleet._id ? 'Update' : 'Save' }}</span>
              <img
                *ngIf="saving"
                src="/assets/images/spinner.gif"
                height="30"
                width="30"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div [hidden]="!viewLocationList || viewUserList">
  <app-list-view-widget #locations></app-list-view-widget>
</div>

<div [hidden]="!viewUserList || viewLocationList">
  <app-list-view-widget #users></app-list-view-widget>
</div>
