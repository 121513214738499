import { Injectable } from '@angular/core'

@Injectable()
export class DataTableService {
  static readonly mobileThreshold = 768
  private mobileLayout: boolean = window.innerWidth < 480
  private mobileColumnWidthMultiplier = 0
  private actionBtnColumnWidth = '0'
  private includeActionButtons = false

  useMobileLayout(): boolean {
    return this.mobileLayout
  }

  setActionBtnStatus(status: boolean) {
    this.includeActionButtons = status
  }

  setActionBtnColumnWidth(w: string) {
    this.actionBtnColumnWidth = w
  }

  getActionBtnColumnWidth(): string {
    return this.actionBtnColumnWidth
  }

  getDataColumnsWidth(): string {
    return `calc(100%-${this.actionBtnColumnWidth})`
  }

  toggleMobileLayout() {
    if (window.innerWidth < DataTableService.mobileThreshold) {
      this.mobileLayout = true
      if (this.includeActionButtons) {
        this.actionBtnColumnWidth = '30px'
      }
    } else if (window.innerWidth >= DataTableService.mobileThreshold) {
      this.mobileLayout = false
      if (this.includeActionButtons) {
        this.actionBtnColumnWidth = '100px'
      }
    }
  }

  hideColumn(column) {
    return column.hideOnMobile && this.mobileLayout
  }

  getAdjustedWidth(column) {
    let returnColumnWidth = 0
    if (column.hideOnMobile && this.mobileLayout) {
      return '0'
    } else {
      returnColumnWidth =
        column.width *
        (this.mobileLayout ? this.mobileColumnWidthMultiplier : 1)
    }
    returnColumnWidth = Math.floor(returnColumnWidth)
    return `${String(returnColumnWidth)}%`
  }

  setMobileColumnWidthMultiplier(c: number) {
    this.mobileColumnWidthMultiplier = c
  }

  getMobileColumnWidthMultiplier() {
    return this.mobileColumnWidthMultiplier
  }

  getAdjustedRowTitleWidth(rowTitleWidth) {
    let returnRowTitleWidth =
      rowTitleWidth * (this.mobileLayout ? this.mobileColumnWidthMultiplier : 1)
    returnRowTitleWidth = Math.floor(returnRowTitleWidth)
    return `${String(returnRowTitleWidth)}%`
  }
}
