import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import * as moment from 'moment'

import { CoreUserService } from '../../core-user/client/core-user.service'

@Injectable()
export class DashboardService {
  static dateDisplay(nullVal: string): (v: any) => string {
    return (v: any) => {
      if (!v) {
        return nullVal || ''
      }
      const d = moment(v)
      return d.isValid() ? moment(v).format('MM/DD/YY') : 'Invalid Date'
    }
  }

  constructor(
    private coreUserService: CoreUserService,
    private router: Router
  ) {}

  viewOrder = (rowItem) => {
    this.router.navigate(['order', rowItem._id], {
      queryParams: { orderType: rowItem.orderType }
    })
  }

  buildOrder = (orderId: string, orderType: string) => {
    if (orderId) {
      return () => {
        this.router.navigate(['orderBuilder'], {
          queryParams: { orderId, orderType }
        })
      }
    } else {
      this.router.navigate(['orderBuilder'])
    }
  }
}
